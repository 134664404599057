import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DocumentService } from '../../shared/document.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsResolver {
  constructor(private documentService: DocumentService) { }
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.documentService.listDocuments();
  }
}
