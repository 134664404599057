<input
    type="file"
    class="form-control-file d-none"
    id="excelInput"
    name="excelInput"
    accept=".xls, .xlsx"
    (change)="this.onExcelInput($event)"
    #inputFile
>
<button
    class="btn btn-sm btn-primary"
    (click)="this.onBtnClick()"
>{{this.label}}</button>
<button
    class="btn btn-sm btn-secondary ms-1"
    (click)="this.downloadDemo()"
    i18n="@@downloadDemo"
>Demo herunterladen</button>
<ng-container *ngIf="this.importedData">
    <table
        class="table table-striped bg-white m-0 mt-1 component-table table-sm"
        aria-label="importedData"
    >
        <thead class="thead-light">
            <tr>
                <th *ngFor="let key of this.importedData.keys">{{key}}</th>
            </tr>
        </thead>
        <tbody></tbody>
        <tr *ngFor="let user of this.importedData.values">
            <td *ngFor="let key of this.importedData.keys">
                <ng-container [ngSwitch]="key">
                    <ng-container *ngSwitchCase="'benchmarkValues'">
                        <ng-container *ngFor="let b of user[key]">
                            <small class="badge bg-primary text-start text-white m-1">
                                {{getLabelOfBenchmarkValue(b.id)}}:
                                {{b.value}}</small>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'userGroups'">
                        <ng-container *ngFor="let ug of user[key]">
                            <small
                                class="badge bg-primary text-start text-white m-1">{{this.mainService.userGroupsService.userGroupsMap.get(ug).name}}</small>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'adminGroups'">
                        <ng-container *ngFor="let ag of user[key]">
                            <small
                                class="badge bg-primary text-start text-white m-1">{{this.adminGroupsService.adminGroupsMap.get(ag).name}}</small>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{user[key]}}
                    </ng-container>
                </ng-container>
            </td>
        </tr>
    </table>
    <br>
    <button
        class="btn btn-sm btn-primary"
        (click)="this.clickCreate()"
        i18n="@@excelImportBtn"
    >Importieren</button>
</ng-container>