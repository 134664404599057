import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BenchmarkValue, CheckNominationImportResult, NominationCreationData, Perspective } from '@reflact/prmfeedback';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { LoginService } from '../../views/login/login.service';
import { CustomerService } from '../customer.service';
import { FeedbackInfo } from '../main.service';
import { NominationService } from '../nomination.service';

@Component({
  selector: 'app-nomination-import',
  templateUrl: './nomination-import.component.html',
  styleUrls: ['./nomination-import.component.scss']
})
export class NominationImportComponent {
  @Input('feedbackInfo') feedbackInfo: FeedbackInfo;
  @Input('redirectAfterImport') redirectAfterImport: string = null;
  @ViewChild('importInput') importInput: ElementRef;
  public nominationImportCheckResult: CheckNominationImportResult;

  constructor(
    private loginService: LoginService,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private nominationService: NominationService,
    private router: Router) {
  }

  public exportTemplate() {
    const data = [['perspective', 'firstname', 'lastname', 'email', 'language']];

    //FEED-363 this.customerService.customerMap.get(this.loginService.loggedInUser$.value.customer_id).benchmarkConfigs.forEach((benchmarkConfig: BenchmarkConfig) => {
    // data[0].push(benchmarkConfig.label);
    //});

    this.feedbackInfo.survey.perspectives.forEach((p: Perspective) => {
      const perspectiveRow = [p.name, 'Dummy', 'User', 'dummyuser@email.com', 'de'];
      data.push(perspectiveRow);
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(data, { skipHeader: true }))
    XLSX.writeFileXLSX(workbook, 'nomination_import_template_' + this.feedbackInfo.feedback.name + '.xlsx');
  }

  public async submitImport() {
    for (const nomination of this.nominationImportCheckResult.nominations) {
      if (nomination.create === true) {
        const benchmarkValues: BenchmarkValue[] = [];
        //FEED-363 this.customerService.customerMap.get(this.loginService.loggedInUser$.value.customer_id).benchmarkConfigs.forEach((benchmarkConfig: BenchmarkConfig) => {
        //   if (benchmarkConfig.label in nomination) {
        //     benchmarkValues.push({ id: benchmarkConfig.id, value: nomination[benchmarkConfig.label] });
        //   }
        // });
        const perspective = this.feedbackInfo.survey.perspectives.find(p => p.name == nomination.perspective);
        const nominationCreationData: NominationCreationData = {
          customer_id: this.feedbackInfo.survey.customer_id,
          feedback_id: this.feedbackInfo.feedback._id,
          perspective_id: perspective.id,
          feedbackgiver: {
            email: nomination.email,
            firstname: nomination.firstname,
            lastname: nomination.lastname,
            language: nomination.language,
            benchmarkValues: benchmarkValues
          },
        };
        await this.nominationService.createNomination(nominationCreationData);
      }
    }
    this.toastrService.info($localize`:@@nominationImportDone:Die Nominierungen wurden importiert.`);
    this.router.routeReuseStrategy.shouldReuseRoute = () => { return false; };
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.redirectAfterImport]);
  }

  public translateMessage(message: string) {
    let translation = '';
    switch (message) {
      case 'perspective max exceeded':
        translation = $localize`:@@nominationImportPerspectiveMaxExceeded:Max. für diese Perspektive erreicht`;
        break;
      case 'wrong perspective':
        translation = $localize`:@@nominationImportWrongPerspective:Perspektive existiert nicht`;
        break;
      case 'duplicate user':
        translation = $localize`:@@nominationImportDuplicateUser:Bereits nominiert`;
        break;
      case 'invalid email':
        translation = $localize`:@@nominationImportInvalidEmail:Ungültige E-Mail`;
        break;
      case 'empty fields':
        translation = $localize`:@@nominationImportEmptyFields:Vorname, Nachname oder Sprache leer`;
        break;
      default:
        translation = 'Translation missing: ' + message;
        break;
    }
    return translation;
  }

  showFileBrowser() {
    this.importInput.nativeElement.click();
  }

  onExcelInput(event) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length > 1) {
      this.importInput.nativeElement.value = '';
      this.toastrService.info($localize`:@@excelImportSelectOneOnly:Bitte nur eine Excel-Datei auswählen.`);
    }

    let data;
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      data = XLSX.utils.sheet_to_json(ws);
    };

    reader.readAsBinaryString(target.files[0]);

    reader.onloadend = (e) => {
      this.nominationService.checkNominationImport(this.feedbackInfo.survey._id, this.feedbackInfo.feedback._id, data).then(
        (result) => { this.nominationImportCheckResult = result; }
      );
      this.importInput.nativeElement.value = '';
    }
  }
}
