<div class="gallery-grid ">
  <div
    class="card shadow border-0 card-extra-round w-100"
    *ngFor="let fact of allfacts"
  >
    <div class="card-header bg-transparent border-0">
      <small class="text-muted">{{fact.text}}:</small>
      <p><b>{{fact.groupName}}</b></p>
    </div>
    <div class="card-footer p-2  border-0 bg-transparent">
      <p class="display-1 {{directionByMode(fact)}} m-0">
        <ng-container *ngIf="typeByMode(fact) == 'percentage'">
          <div class="div">
            <div class="col ">
              {{fact.value | number: '1.0-0'}}%
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="typeByMode(fact) == 'value'">

          {{fact.value | number: '1.2-2'}}
        </ng-container>
      </p>
    </div>
  </div>
</div>