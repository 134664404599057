import { Injectable } from '@angular/core';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(public socketService: SocketService) {
  }


}
