import { AfterViewInit, Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PrmUserType } from '@reflact/prmfeedback';
import { INavData, navItemsAdmin, navItemsFeedbackgiver, navItemsFeedbackreceiver, navItemsGroupAdmin, navItemsHr } from '../../_nav';
import { CustomerService } from '../../shared/customer.service';
import { LayoutService } from '../../shared/layout.service';
import { Languages, MainService } from '../../shared/main.service';
import { UserService } from '../../shared/user.service';
import { LoginService } from '../../views/login/login.service';

@Injectable()
export class SidebarNavHelper {
  public itemType(item: { divider?: any; title?: any; children?: any; label?: any; }) {
    if (item.divider) {
      return 'divider';
    } else if (item.title) {
      return 'title';
    } else if (item.children) {
      return 'dropdown';
    } else if (item.label) {
      return 'label';
    } else if (!Object.keys(item).length) {
      return 'empty';
    } else {
      return 'link';
    }
  }

  public isActive(router: { isActive: (arg0: any, arg1: boolean) => any; }, item: { url: any; }) {
    return router.isActive(item.url, false);
  }

  public hasBadge = (item: { badge: any; }) => Boolean(item.badge);
  public hasIcon = (item: { icon: any; }) => Boolean(item.icon);

  public getIconClass(item: { icon: any; }) {
    const classes = {
      'nav-icon': true
    };
    return classes;
  }
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements AfterViewInit {
  public sidebarMinimized = false;
  public showMobile = false;
  public navItemsAdmin = navItemsAdmin;
  public navItemsHr = navItemsHr;
  public navItemsGroupAdmin = navItemsGroupAdmin;
  public navItemsFeedbackreceiver = navItemsFeedbackreceiver;
  public navItemsFeedbackgiver = navItemsFeedbackgiver;
  public sidebarMenuTop: INavData[] = [];
  public sidebarMenuBottom: INavData[] = [];

  constructor(public router: Router, public mainService: MainService, public loginService: LoginService, public customerService: CustomerService, public userService: UserService, public layoutService: LayoutService) {
    this.loginService.loggedInUser$.subscribe((u) => {
      if (u) this.layoutService.setCustomerId(u.customer_id);
    })
    router.events.subscribe((e) => {
      this.showMobile = false
    })
  }

  // returns if an admin has logged in as another user
  get loggedInAsOtherUser() {
    return localStorage.getItem('adminToken') != null;
  }

  ngAfterViewInit(): void {
    //@ts-ignore
    new coreui.Sidebar(document.querySelector('#sidebar'));
  }

  public updateSelectCustomer(e) {
    this.loginService.switchAdminCustomer(e.target.value);
  }

  public toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  public userTypeToName(type: PrmUserType) {
    switch (type) {
      case "hr": return $localize`:@@globalHR:HR-Administrator*in`;
      case "prmadmin": return $localize`:@@globalPRM:PRM-Administrator*in`;
      case "feedbackgiver": return $localize`:@@globalFG:Feedbackgeber*in`;
      case "feedbackreceiver": return $localize`:@@globalFN:Feedbacknehmer*in`;
      case "groupadmin": return $localize`:@@globalGroupAdmin:Team-Administrator*in`;
    }
  }

  public async setLanguage(language: Languages["value"]) {
    const user = this.loginService.loggedInUser;
    user.language = language;
    await this.userService.updateUser(user);
    window.location.pathname = `/app/${language}/`;
  }

  public isAdmin() {
    return this.loginService.loggedInUser$.value.type === 'prmadmin';
  }

  public isHR() {
    return this.loginService.loggedInUser$.value.type === 'hr';
  }

  public isGroupAdmin() {
    return this.loginService.loggedInUser$.value.type === 'groupadmin';
  }

  public isFeedbackReciever() {
    return this.loginService.loggedInUser$.value.type === 'feedbackreceiver';
  }

  public isFeedbackGiver() {
    return this.loginService.loggedInUser$.value.type === 'feedbackgiver';
  }
}
