<div class="container text-center">
    <div class="row">
        <div class="col">
            <img
                src="assets/Falscher_Weg.png"
                i18n-alt="@@errorWrongWay"
                alt="Falscher Weg"
            >
        </div>
    </div>
    <div class="row mt-4">
        <div class="col">
            <h1 i18n="@@errorOops">Ups, falsch abgebogen?</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            {{this.getErrorMessage(this.errorCode)}}
        </div>
    </div>
    <div class="row mt-4">
        <div class="col">
            <a [routerLink]="['/']">
                <i class="ri ri-arrow-left-fill"></i>
                <span i18n="errorBackToHome">Zurück zur Startseite</span>
            </a>
        </div>
    </div>
</div>