import { AfterViewInit, Component, HostListener } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BenchmarkFilter, Perspective, ReportingResponseRate } from '@reflact/prmfeedback';
import { ObjectId } from 'bson';
import dayjs from 'dayjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPopperjsDirective, NgxPopperjsTriggers } from 'ngx-popperjs';
import { ColorService } from '../../shared/color.service';
import { BenchmarkInfoMap, FeedbackReportService, QuestionGroupIdToNameMap, ReportData, ScaleEntry } from '../../shared/feedback-report.service';
import { ShareReportService } from '../../shared/share-report.service';
import { LoginService } from '../../views/login/login.service';
import { LayoutService } from './../../shared/layout.service';
import { BenchmarkFilterModalComponent } from './benchmark-filter-modal/benchmark-filter-modal.component';
require('dayjs/locale/de')

export type FactMode = 'highestValue' | 'lowestValue' | 'highestConsens' | 'lowestConsens';

@Component({
  selector: "app-reporting",
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  providers: [
    ColorService
  ]
})

// function that rounds a number on 2 decimals in js
export class ReportingComponent implements AfterViewInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changeScaleHeight();
  }


  public popperTriggerClick = NgxPopperjsTriggers.click;
  public feedbackId: ObjectId
  public surveyId: ObjectId
  public onlyText: boolean = false;
  public questionGroupIdToNameMap: QuestionGroupIdToNameMap;
  public scale: ScaleEntry[];
  public scaleMin: number;
  public scaleMax: number;
  public selectedPerspectives: Perspective[];
  public allPerspectives: Perspective[];
  private responseRates: ReportingResponseRate[];
  // @jode-reflact = map nicht kompatibel zum input
  public responseRatesMap: Map<string, number>;
  public nominationRatesMap: Map<string, number>
  public data: ReportData[] = [];
  public modalRef: BsModalRef<BenchmarkFilterModalComponent>;
  public benchmarkInfoMap: BenchmarkInfoMap
  public benchmarkFilters: BenchmarkFilter[]
  public isSurveyView: boolean = false
  public feedbackName?: string = undefined;
  public feedbackReceiverName?: string = undefined;

  constructor(private aRoute: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    public colorService: ColorService,
    private reportService: FeedbackReportService,
    public loginService: LoginService,
    public modalService: BsModalService,
    private shareReportService: ShareReportService
  ) {
    const params = aRoute.snapshot.params as { survey_id?: string, feedback_id?: string, share_token?: string };

    if (params.share_token != null) {
      this.loginService.shareToken = params.share_token;
    } else {
      this.loginService.shareToken = undefined;
    }

    if (params.survey_id != null) {
      this.isSurveyView = true
      this.surveyId = new ObjectId(params.survey_id);
      this.loadData(this.surveyId, true);
    } else {
      this.isSurveyView = false
      this.feedbackId = new ObjectId(params.feedback_id);
      this.loadData(this.feedbackId, false);
    }
  }

  ngAfterViewInit(): void {
    this.changeScaleHeight();
  }

  public getLanguage() {
    if (this.loginService.loggedInUser) {
      return this.loginService.loggedInUser.language;
    }
    if (navigator.language) {
      return navigator.language;
    }
    return 'de';
  }

  public openBenchmarkFilterModal() {
    this.modalRef?.hide();
    this.modalRef = this.modalService.show(BenchmarkFilterModalComponent,
      { initialState: { benchmarkInfoMap: this.benchmarkInfoMap, benchmarkFilters: this.benchmarkFilters } })
    this.modalRef.content.myRef = this.modalRef
    this.modalRef.content.onFilterSubmit.subscribe((v) => {
      const queryParams: Params = {
        b: JSON.stringify(v)
      }
      this.router.navigate(
        [],
        {
          relativeTo: this.aRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided

        });

    })
  }

  async loadData(feedbackId: ObjectId, isSurveyId: boolean, benchmarkFilters: BenchmarkFilter[] = []) {
    const result = await this.reportService.loadFullDataForReport(feedbackId, this.getLanguage(), isSurveyId, benchmarkFilters);
    this.scale = result.scale;
    if (this.scale.length <= 0) {
      this.onlyText = true;
    }
    this.questionGroupIdToNameMap = result.questionGroupIdToNameMap;
    this.allPerspectives = result.perspectives;

    this.selectedPerspectives = [...this.allPerspectives];
    this.scaleMax = result.scaleMax;
    this.scaleMin = result.scaleMin;
    this.data = result.data;

    this.benchmarkInfoMap = result.benchmarkInfoMap

    this.responseRates = result.responseRates;
    this.responseRatesMap = new Map(this.responseRates.map(rP => [rP.perspective_id, rP.responseCount]));
    this.nominationRatesMap = new Map(this.responseRates.map(rP => [rP.perspective_id, rP.maxResponses]));

    this.feedbackName = result.feedbackName;
    this.feedbackReceiverName = result.feedbackReceiverName

    this.aRoute.queryParamMap.subscribe((p) => {
      this.queryParamsChanged(p)
    })
  }

  getReponseRate(perspectiveId: string): number {
    const rP = this.responseRates.find(rP => rP.perspective_id === perspectiveId);
    if (rP) {
      return rP.responseCount
    }
    return 0
  }

  getNominationRate(perspectiveId: string): number {
    const rP = this.responseRates.find(rP => rP.perspective_id === perspectiveId);
    if (rP) {
      return rP.maxResponses;
    }
    return 0
  }

  getGlobalResponseRate() {
    if (this.responseRates == null) {
      return 0;
    }
    return this.responseRates.map(rP => rP.responseCount).reduce((a, b) => a + b, 0);
  }

  queryParamsChanged(queryMap: any) {
    if (this.allPerspectives == null) {
      return
    }
    const perspectiveIdsStr = queryMap.get("p")
    if (perspectiveIdsStr == null) {
      this.selectedPerspectives = this.allPerspectives
    } else {
      const perspectiveIds = perspectiveIdsStr.split(",")
      this.selectedPerspectives = this.allPerspectives.filter(p => perspectiveIds.indexOf(p.id) >= 0)
    }

    if (!this.surveyId) {
      return
    }
    const bfFilterString = queryMap.get("b")
    if (bfFilterString != null) {
      if (JSON.stringify(this.benchmarkFilters) != bfFilterString) {  // hat sich was geändert?
        this.benchmarkFilters = JSON.parse(bfFilterString)
        this.loadData(this.surveyId, true, this.benchmarkFilters)
      }
    }
  }

  togglePerspectiveFilter(perspectiveId: string) {
    let newSelectedPIds: string[]
    if (this.selectedPerspectives.some(p => p.id == perspectiveId)) {
      newSelectedPIds = this.selectedPerspectives.filter(p => p.id != perspectiveId).map(p => p.id)
    } else {
      newSelectedPIds = [...this.selectedPerspectives.map(p => p.id), this.allPerspectives.find(p => p.id == perspectiveId).id]
    };
    const queryParams: Params = {
      p: newSelectedPIds.join(",")
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.aRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

  }

  isSelected(perspectiveId: string) {
    return this.selectedPerspectives.some(p => p.id === perspectiveId);
  }

  public getFacts() {
    return [
      this.getFact('highestValue'),
      this.getFact('lowestValue'),
      this.getFact('highestConsens'),
      this.getFact('lowestConsens')
    ]
  }

  private getFact(mode: FactMode): { groupId: string, groupName: string, value: number, text: string, mode: FactMode } {
    const dataCopy = [...this.data].filter(d => "" + d.aggMean != "NaN")

    if (dataCopy == null || dataCopy.length === 0) {
      return { groupId: '0', groupName: '', value: 0, text: 'Not found', mode }
    }
    if (mode === 'highestValue') {
      dataCopy.sort((a, b) => b.aggMean - a.aggMean)
      const group = dataCopy[0];
      return { groupId: group.groupId, groupName: group.groupName, value: group.aggMean, text: $localize`:@@reportFactHighestValue:Kompetenz: Beste Bewertung (Fremdeinschätzung)`, mode };
    }
    if (mode === 'lowestValue') {
      dataCopy.sort((a, b) => a.aggMean - b.aggMean)
      const group = dataCopy[0];
      return { groupId: group.groupId, groupName: group.groupName, value: group.aggMean, text: $localize`:@@reportFactLowestValue:Kompetenz: Kritischste Bewertung (Fremdeinschätzung)`, mode };
    }
    if (mode === 'highestConsens') {
      dataCopy.sort((a, b) => Math.abs(a.aggSEDifference) - Math.abs(b.aggSEDifference))
      const group = dataCopy[0];
      return { groupId: group.groupId, groupName: group.groupName, value: group.aggSEDifference, text: $localize`:@@reportFactHighestConsens:Fremdeinschätzung – Selbsteinschätzung: Höchste Übereinstimmung`, mode };
    }
    if (mode === 'lowestConsens') {
      dataCopy.sort((a, b) => Math.abs(b.aggSEDifference) - Math.abs(a.aggSEDifference));
      const group = dataCopy[0]
      return { groupId: group.groupId, groupName: group.groupName, value: group.aggSEDifference, text: $localize`:@@reportFactLowestConsens:Fremdeinschätzung – Selbsteinschätzung: Niedrigste Übereinstimmung`, mode };
    }
    return { groupId: '0', groupName: '', value: 0, text: 'not found', mode }
  }

  public getDownloadUrl() {
    const arr = window.location.hash.split("?")
    const queryString = arr.length > 1 ? arr[1] : ""
    let url = "/api/pdf/feedback/" + this.feedbackId
    if (this.isSurveyView) {
      url = "/api/pdf/survey/" + this.surveyId
    }
    url += '/' + this.getLanguage() + '/' + localStorage.getItem('authToken') + "/" + queryString
    return url;
  }

  public async copyShareReportLink() {
    const result = await this.shareReportService.getFeedbackReportShareLink(this.feedbackId);
    this.copyMessage(result.link);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    navigator.clipboard.writeText(selBox.value)
    document.body.removeChild(selBox);
  }

  popperShow(event: NgxPopperjsDirective) {
    setTimeout(() => {
      event.hide();
    }, 3000);
  }

  public getDateString() {
    return dayjs().locale(this.getLanguage()).format("MMMM YYYY")
  }

  private changeScaleHeight() {
    const header = document.getElementById("reportingGroupHeader");
    if (header != null) {
      const headerHeight = header.getBoundingClientRect().height;
      const scaleHeader = document.getElementById("scaleHeader")
      if (scaleHeader != null) {
        const newTop = headerHeight - 40;
        scaleHeader.style.top = newTop + "px"
      }
    }
  }
}