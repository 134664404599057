import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fact-card',
  templateUrl: './fact-card.component.html',
  styleUrls: ['./fact-card.component.scss']
})
export class FactCardComponent {

  @Input() allfacts: any[] = [];



  public directionByMode(fact: any): "high" | "low" | "" {
    if (fact.mode.includes("highest")) {
      return "high";
    }
    if (fact.mode.includes("lowest")) {
      return "low";
    }

  }
  public typeByMode(fact: any): "value" | "percentage" | "" {
    if (fact.mode.includes("Value")) {
      return "value";
    }
    if (fact.mode.includes("Consens")) {
      return "value";
      // consens now also an value type return "percentage";
    }
    return "";
  }
}
