import { Injectable } from '@angular/core';

export const topicsArray = ["adminGroups", "feedbackInvitation", "userGroups", "surveys", "subsidiaries", "usermanagement", "nomination", "myDocuments", "myFeedback", "helpArea", "mailManager", "documents", "allFeedbacks", "coaches"] as const;
export type Topic = typeof topicsArray[number];

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private topicToId: Map<Topic, string> = new Map(
    [
      ["myFeedback", "17"],
      ["myDocuments", "6"],
      ["feedbackInvitation", "7"],
      ["helpArea", "8"],
      ["usermanagement", "9"],
      ["userGroups", "10"],
      ["surveys", "11"],
      ["documents", "12"],
      ["allFeedbacks", "13"],
      ["mailManager", "14"],
      ["subsidiaries", "15"],
      ["coaches", "16"],
      ["nomination", "17"],
      ["adminGroups", "19"]
    ]
  );

  public getFAQLink(topic: Topic) {
    const url = `https://faq.feedback-suite.de/index.php?action=show&cat=${this.getIdByTopic(topic)}`
    return url;
  }

  public getIdByTopic(topic: Topic) {
    if (this.topicToId.has(topic)) {
      return this.topicToId.get(topic);
    }
  }
}