import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AllPrmUserType, Coach, FeedbackDocument, Survey, UserGroup } from '@reflact/prmfeedback';
import { AdminGroupWithGranted } from '../admingroups.component';


export const BucketListItemTypeArray = ["assignedbucketmanager", "feedbackreceiver", "feedbackgiver", "userGroups", "surveys", "documents", "coaches"] as const;
export type BucketListItemType = typeof BucketListItemTypeArray[number];

export type BucketListItem = {
  label: string,
  checked: boolean,
  type: BucketListItemType,
  data: AllPrmUserType | Survey | FeedbackDocument | UserGroup | Coach;
}

@Component({
  selector: 'app-bucketselect',
  templateUrl: './bucketselect.component.html',
  styleUrls: ['./bucketselect.component.scss']
})

export class BucketselectComponent implements OnChanges {

  @Input() public selectedItems: AdminGroupWithGranted = null;
  @Input() public availableItems: AdminGroupWithGranted = null;
  @Output() addStack: EventEmitter<BucketListItem[]> = new EventEmitter<BucketListItem[]>();
  @Output() removeStack: EventEmitter<BucketListItem[]> = new EventEmitter<BucketListItem[]>();
  public selectedBucketItems: BucketListItem[];
  public availableBucketItems: BucketListItem[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems) {
      this.selectedBucketItems = this.getBucketsFromGroup(changes.selectedItems.currentValue);
    }
    if (changes.availableItems) {
      this.availableBucketItems = this.getBucketsFromGroup(changes.availableItems.currentValue);
    }
  }
  private labelForUser(u: AllPrmUserType | Coach) {
    return u.firstname + " " + u.lastname + " - " + u.email
  }
  getBucketsFromGroup(group: AdminGroupWithGranted): BucketListItem[] {
    let ret: BucketListItem[] = [];
    group.coaches.forEach((c: Coach) => {
      ret.push({ label: this.labelForUser(c), type: "coaches", checked: false, data: c });
    })
    group.documents.forEach((d: FeedbackDocument) => {
      ret.push({ label: d.filename, type: "documents", checked: false, data: d });
    })
    group.surveys.forEach((s: Survey) => {
      ret.push({ label: s.name, type: "surveys", checked: false, data: s });
    });
    group.userGroups.forEach((ug: UserGroup) => {
      ret.push({ label: ug.name, type: "userGroups", checked: false, data: ug });
    })
    group.feedbackgiver.forEach((u: AllPrmUserType) => {

      ret.push({ label: this.labelForUser(u), type: "feedbackgiver", checked: false, data: u });
    })
    group.feedbackreceiver.forEach((u: AllPrmUserType) => {
      ret.push({ label: this.labelForUser(u), type: "feedbackreceiver", checked: false, data: u });
    })
    group.assignedbucketmanager.forEach((u: AllPrmUserType) => {
      ret.push({ label: this.labelForUser(u), type: "assignedbucketmanager", checked: false, data: u });
    })



    return ret;

  }

}

