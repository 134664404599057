import { Component, OnDestroy } from '@angular/core';
import { NgxForm } from '@reflact/ngx-forms';
import { AllPrmUserType, PrmUser } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService } from '@reflact/rag-datasupply';
import { Subscription } from 'rxjs';
import { translateTexts } from '../../app.component';
import { UserService } from '../../shared/user.service';
import { LoginService } from '../login/login.service';

@Component({
  templateUrl: './my-user.component.html',
  styleUrls: []
})
export class MyUserComponent implements OnDestroy {
  public subscriptions: Subscription[] = [];
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["firstname", $localize`:@@globalFirstname:Vorname`],
    ["lastname", $localize`:@@globalLastname:Nachname`],
    ["email", $localize`:@@globalMail:E-Mail`],
    ["type", $localize`:@@globalType:Typ`]
  ])
  public translateTexts = translateTexts;
  public myUser: AllPrmUserType;
  public buttons: string[] = [];

  constructor(public loginService: LoginService, public frontendService: RagDatasupplyFrontendService<PrmUser>, public userService: UserService) {
    this.loginService.loggedInUser$.subscribe(user => {
      this.myUser = user;
    });

    this.buttons.push($localize`:@@myProfileTabHeading:Mein Profil`);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public async updateItem(i: AllPrmUserType) {
    await this.userService.updateUser(i);

  }

}
