import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { RagDatasupplyOverlayAssideComponent } from '@reflact/rag-datasupply';
import { FaqService, Topic } from '../faq.service';

@Component({
  selector: 'app-new-and-help-row',
  templateUrl: './new-and-help-row.component.html',
  styleUrls: []
})
export class NewAndHelpRowComponent {
  @Input() showNewButton: boolean = true;
  @Input() newBtnLabel: string = "not defined";
  @Input() newFormTpl: TemplateRef<any>;

  @Input() showImportButton: boolean = false;
  @Input() importBtnLabel: string = "not defined";
  @Input() importFormTpl: TemplateRef<any>;

  @Output() newBtnClick: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild("newOverlay") newOverlay: RagDatasupplyOverlayAssideComponent
  @ViewChild("importOverlay") importOverlay: RagDatasupplyOverlayAssideComponent
  @ViewChild("help") helpOverlay: RagDatasupplyOverlayAssideComponent


  @Input() topic: Topic;

  constructor(public faqService: FaqService) {

  }
}
