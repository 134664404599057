import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../views/login/login.service';

@Injectable()
export class CustomerGuard {
  constructor(public loginService: LoginService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.loginService.loggedInUser$.value;
    return user && (user.type === 'prmadmin' || user.type === 'hr' || user.type == 'groupadmin');
  }
}