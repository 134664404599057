import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { AdminGroup, AllPrmUserType, Coach, PrmUser, PrmUserType, PrmUserTypeArr, Subsidiary, UserGroup } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { MainService } from '../../shared/main.service';
import { UserGroupsService } from '../../shared/user-groups.service';
import { UserService } from '../../shared/user.service';
import { CustomerService } from './../../shared/customer.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: []
})
export class UsersComponent implements AfterViewInit {
  @ViewChild("userFieldSeperator", { read: TemplateRef }) userFieldSeperator: TemplateRef<any>;
  @ViewChild("userGroups", { read: TemplateRef }) userGroups: TemplateRef<any>;
  public subsidiaries: Subsidiary[];
  public coaches: Coach[];
  public type: PrmUserType | 'all' = 'all';
  public types: { label: string, value: PrmUserType }[] = [];
  public allTypes: { label: string, value: (PrmUserType | 'all') }[] = [];
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["firstname", $localize`:@@globalFirstname:Vorname`],
    ["lastname", $localize`:@@globalLastname:Nachname`],
    ["email", $localize`:@@globalMail:E-Mail`],
    ["language", $localize`:@@globalLanguage:Sprache`],
    ["type", $localize`:@@globalType:Typ`],
    ["subsidiaryId", $localize`:@@globalSubsidiary:Tochtergesellschaft`],
    ["coach_id", $localize`:@@gobalCoach:Coach`],
    ["creationDate", $localize`:@@gobalCreationDate:Erstellt am`],
    ['adminGroups', $localize`:@@globalAdminGroups:Teams`]
  ]);

  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public formCreationData: Object = { firstname: '', lastname: '', email: '', language: 'de', type: (this.type === 'all') ? 'hr' : this.type, benchmarkValues: [], userGroups: [], adminGroups: [] }
  public formFields: NgxForm.FormField[] = []
  public NotFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;
  public selectableGroups: UserGroup[] = [];
  public selectedGroup: UserGroup;
  public adminGroups: AdminGroup[];

  constructor(
    public frontendService: RagDatasupplyFrontendService<PrmUser>,
    public customerService: CustomerService,
    public route: ActivatedRoute,
    public userService: UserService,
    public mainService: MainService,
    public userGroupsService: UserGroupsService
  ) {
    this.allTypes.push({ label: $localize`:@@gobalAll:Alle`, value: 'all' });
    this.allTypes.push(...PrmUserTypeArr.filter(type => type !== 'prmadmin').map(t => ({ label: this.userService.userTypeToName(t), value: t })));
    this.types.push(...PrmUserTypeArr.filter(type => (this.mainService.loginService.loggedInUser.type == 'groupadmin' && type !== 'prmadmin' && type !== 'hr') || (this.mainService.loginService.loggedInUser.type != 'groupadmin' && type !== 'prmadmin')).map(t => ({ label: this.userService.userTypeToName(t), value: t })));
    this.route.data.subscribe(d => {
      this.subsidiaries = d.subsidiaries;
      this.coaches = d.coaches;
      this.adminGroups = d.adminGroups;
    });
  }

  ngAfterViewInit(): void {
    this.formFields = [
      { fieldName: 'firstname', label: 'Vorname', type: 'text', required: true },
      { fieldName: 'lastname', label: 'Nachname', type: 'text', required: true },
      { fieldName: 'email', label: 'E-Mail', type: 'text', required: true },
      {
        fieldName: 'language', label: 'Sprache', type: 'select', required: true, options: {
          selectValues: this.mainService.languages,
          handleValuesAsIds: true,
          bindLabel: 'label',
          bindValue: 'value'
        }
      },
      {
        fieldName: 'type', label: 'Benutzerrolle', required: true, type: 'select', options: {
          selectValues: this.types,
          bindLabel: 'label',
          bindValue: 'value'
        }
      },
      {
        fieldName: 'adminGroups', type: 'ng-multiselect-dropdown', required: this.mainService.loginService.loggedInUser.type == 'groupadmin',
        options: {
          placeholder: '...',
          enableCheckAll: true,
          handleValuesAsIds: true,
          dropdownList: this.adminGroups,
          idField: '_id',
          textField: 'name',
          unSelectAllText: $localize`:@@unselectAllText:Alle abwählen`,
          selectAllText: $localize`:@@selectAllText:Alle auswählen`
        }
      },
      {
        fieldName: '', type: 'template', options: { template: this.userFieldSeperator }
      },
      {
        fieldName: 'coach_id', type: 'select', options: {
          selectValues: this.coaches,
          bindValue: '_id',
          bindLabel: 'email'
        }
      },
      { fieldName: '', type: 'template', options: { template: this.userGroups } },
      {
        fieldName: 'subsidiaryId', label: 'Tochtergesellschaft', type: 'select', options: {
          selectValues: this.subsidiaries,
          bindValue: '_id',
          bindLabel: 'company'
        }
      }
    ]
  }

  public switchToUser(email: string) {
    this.mainService.loginService.switchToUser(email);
  }

  public checkAdminToken() {
    return this.mainService.loginService.checkAdminToken();
  }

  public buildNameForMobile(user: PrmUser) {
    return user.firstname + ' ' + user.lastname;
  }

  filterSelectableGroups(gids: string[]) {
    this.selectedGroup = undefined;
    this.selectableGroups = this.userGroupsService.userGroups.filter(g => gids == null || !gids.includes(g._id));
  }

  async addGroupToUser(groupId: string, user: AllPrmUserType) {
    this.selectedGroup = undefined;
    user.userGroups.push(groupId);
    await this.userService.updateUser(user);
    this.filterSelectableGroups(user.userGroups);
  }

  async removeGroupFromUser(groupId: string, user: AllPrmUserType) {
    user.userGroups = user.userGroups.filter(gid => gid !== groupId);
    await this.userService.updateUser(user);
    this.filterSelectableGroups(user.userGroups);
  }

  public adminGroupIdToName(id: string) {
    return this.adminGroups.find(ag => ag._id == id).name;
  }
}