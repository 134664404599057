<app-page-header>
    <app-ds-search
        (searchEvent)="dsSearch($event)"
        placeholder="Dokumente durchsuchen"
        i18n-placeholder="@@myDocumentsSearchDocuments"
    ></app-ds-search>
</app-page-header>
<lib-rag-datasupply-overlay-asside
    #overlay
    (closed)="this.urlHelper.setSelectedId(undefined)"
>
    <div
        *ngIf="overlay.selectedObject"
        class="p-5 h-100"
    >
        <div class="row">
            <div class="col-6">
                <b i18n="@@myDocumentsFileName">Dateiname: </b>
                {{overlay.selectedObject.metadata.name}}
            </div>
            <div class="col-6">
                <b i18n="@@myDocumentsContentType">Dateiformat: </b>
                {{overlay.selectedObject.contentType}}
            </div>
            <div class="col-6">
                <b i18n="@@globalDetailsUploadedAt">Hochgeladen am:</b>
                {{getFormattedDate(overlay.selectedObject)}}
            </div>
        </div>

        <b i18n="@@myDocumentsPreview">Vorschau: </b>
        <iframe
            *ngIf="getUrl(overlay.selectedObject._id) != null"
            [src]="getUrl(overlay.selectedObject._id) | safe"
            style="height: 85vh"
            width="100%"
            title="docpreview"
        ></iframe>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="
            content-grid">
    <app-content-headline
        i18n-pagetitle="@@myDocumentsCardTitle"
        pagetitle="Meine Dokumente"
        #headline
    ></app-content-headline>
    <app-new-and-help-row
        [showNewButton]="false"
        topic="myDocuments"
    >

    </app-new-and-help-row>
    <div class="content-grid-content overflow-visible">

        <ng-container *ngIf="headline.switcher.isGrid()">
            <lib-rag-datasupply-grid
                [template]="tile"
                [service]="this.frontendService"
                [templateEmptyData]="templateEmptyData"
            >
            </lib-rag-datasupply-grid>
        </ng-container>
        <ng-container *ngIf="headline.switcher.isTable()">
            <app-card>

                <lib-rag-datasupply-table
                    #table
                    (sort)="this.tableSort.sort($event)"
                    [service]="this.frontendService"
                    [columns]="[
                { fieldName: 'metadata.name', label: translatedLabels[1].translated ,renderFunc:this.getMetaName },
                { fieldName: 'uploadDate', label: translatedLabels[0].translated ,renderFunc:this.getFormattedDate },
                ]"
                    (onSelect)="this.overlay.selectedObject = table.selectedObject; this.urlHelper.setSelectedId(table.selectedObject._id)"
                    [noDataText]="this.noDataText"
                    [translate]="translatedLabels"
                >
                    <ng-container *ngIf="table.selectedObject">
                    </ng-container>
                </lib-rag-datasupply-table>
            </app-card>
        </ng-container>
    </div>
</div>

<!-- TEMPLATES -->

<ng-template
    #tile
    let-data="data"
>
    <app-fbs-grid-card
        (click)="overlay.selectedObject=data; this.urlHelper.setSelectedId(data._id)"
        [config]="{
            icon: 'ri-file-text-line',
            iconColor: '#A78042',
            headerBgColor: '#E2DACC'
        }"
    >
        <ng-container card-body>
            <div class="row">
                <div class="col-1"></div>
                <div class="col">
                    <b>{{data.metadata.name}}</b>
                </div>
            </div>
        </ng-container>
    </app-fbs-grid-card>
</ng-template>

<ng-template #templateEmptyData>
    <div
        class="alert alert-info mt-5"
        role="alert"
    >
        {{ this.noDataText }}
    </div>
</ng-template>