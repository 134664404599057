<app-card [useCardBody]="false">
    <div
        class="card-header text-center"
        [ngStyle]="{'background-color': this.config?.headerBgColor ? this.config.headerBgColor : '#c0c0c0'}"
    >
        <div
            *ngIf="this.config?.badge"
            class="badge position-absolute top-0 end-0 m-1"
            [ngStyle]="{'background-color': this.config?.badgeBgColor ? this.config?.badgeBgColor : '#F6F3EF'}"
        >
            <i
                *ngIf="this.config.badge.icon"
                class="{{this.config.badge.icon}} me-1"
            ></i>
            {{this.config.badge.text}}
        </div>
        <i
            class="{{this.config.icon}} ri-2x"
            [ngStyle]="{'color': this.config?.iconColor ? this.config.iconColor : '#000000'}"
        ></i>
    </div>
    <div class="card-body">
        <ng-content select="[card-body]"></ng-content>
    </div>
    <div class="card-footer bg-white p-2 border-0 w-100">
        <ng-content select="[card-buttons]"></ng-content>
    </div>
</app-card>