import { Component } from '@angular/core';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { AllPrmUserType, PrmUser, UserGroup } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { MainService } from '../../shared/main.service';
import { UserGroupsService } from '../../shared/user-groups.service';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: []
})
export class AdminsComponent {
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["firstname", $localize`:@@globalFirstname:Vorname`],
    ["lastname", $localize`:@@globalLastname:Nachname`],
    ["email", $localize`:@@globalMail:E-Mail`],
    ["language", $localize`:@@globalLanguage:Sprache`]
  ]);

  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)


  public NotFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;

  public selectableGroups: UserGroup[] = [];
  public selectedGroup: UserGroup;
  constructor(public frontendService: RagDatasupplyFrontendService<PrmUser>, public mainService: MainService, public userService: UserService, public userGroupsService: UserGroupsService) { }


  filterSelectableGroups(gids: string[]) {
    this.selectedGroup = undefined;
    this.selectableGroups = this.userGroupsService.userGroups.filter(g => !gids.includes(g._id));
  }

  async addGroupToUser(groupId: string, user: AllPrmUserType) {
    this.selectedGroup = undefined;
    user.userGroups.push(groupId);
    await this.userService.updateUser(user);
    this.filterSelectableGroups(user.userGroups);
  }

  async removeGroupFromUser(groupId: string, user: AllPrmUserType) {
    user.userGroups = user.userGroups.filter(gid => gid !== groupId);
    await this.userService.updateUser(user);
    this.filterSelectableGroups(user.userGroups);
  }
}