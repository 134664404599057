import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  private colors;
  constructor() {
    this.colors = [
      ['#e69f00', ''],
      ['#56b4e9', ''],
      ['#009e74', ''],
      ['#f0e442', ''],
      ['#0071b2', ''],
      ['#d55c00', ''],
      ['#cc79a7', ''],
      ['#477CFF', 'SE', 'Selbsteinschätzung', 'Self-Evaluation'], //vorher #3574FC
    ];
  }

  public stringToColor(str: string): string {
    str = "" + str
    try {
      let colorIndex = this.colors.findIndex(entry => { return entry.includes(str) });
      if (colorIndex === -1) {
        colorIndex = this.colors.findIndex(entry => { return entry[1] === '' });
        this.colors[colorIndex][1] = str;
      }
      return this.colors[colorIndex][0];
    } catch (e) {
      return this.stringToColor2(str)
    }
  }

  public stringToColor2(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      let rawString = '00' + value.toString(16)
      colour += rawString.substring(rawString.length - 2);
    }
    return colour;
  }

  /*
  public stringToFontColor(str: string): string {
    return stc.StrToColor.fontColor(this.stringToColor(str));
  }
  */
}
