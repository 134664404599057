import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../views/login/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public router: Router, private loginService: LoginService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Nicht die eleganteste lösung aber es pressiert
    if (this.router.url.indexOf("shared-feedback-report/") >= 0) {
      const shareToken = this.loginService.getShareToken();
      if (shareToken != undefined) {
        const sharedReportReq = request.clone({ setHeaders: { shareToken } });
        return next.handle(sharedReportReq);
      }
    }
    const authToken = this.loginService.getBearerHeader();
    if (authToken == undefined) {
      // wenn kein authToken da ist einfach den alten request nexten
      return next.handle(request);
    }
    const authReq = request.clone({ setHeaders: { Authorization: authToken } });
    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
