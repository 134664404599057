<div class="rowForcePerspective row align-items-center  ms-0 me-0  {{evenOrOdd}}">


  <div class="col-2   ">

    <ng-content select="[colA]"></ng-content>
  </div>
  <div
    class="col-2 py-2 "
    [ngClass]="{'me-print-n5': isSub}"
  >
    <ng-content select="[colB]"></ng-content>
  </div>
  <div
    class="col colC"
    [ngClass]="{'p-0': isSub}"
  >

    <ng-content select="[colC]"></ng-content>
  </div>

</div>