<app-page-header>
    <app-ds-search
        (searchEvent)="this.dsSearch($event)"
        placeholder="Befragungen durchsuchen"
        i18n-placeholder="@@surverysSearchSurveys"
    ></app-ds-search>
</app-page-header>

<lib-rag-datasupply-overlay-asside
    #overlay
    (closed)="this.urlHelper.setSelectedId(undefined)"
>
    <div
        *ngIf="overlay.selectedObject"
        class="p-5"
    >
        <rag-form
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            (delete)="this.deleteItem($event); overlay.selectedObject = undefined"
            (submit)="this.updateItem($event)"
            [hideDeleteButton]="overlay.selectedObject.status != 'draft' || this.loginService.loggedInUser.type == 'groupadmin'"
            [hideSaveButton]="this.loginService.loggedInUser.type == 'groupadmin'"
            [data]="overlay.selectedObject"
            [formFields]="[
                { fieldName: 'perspectives', type: 'template', readonly:this.loginService.loggedInUser.type == 'groupadmin',options: {template: perspectives}},
                { fieldName: 'name',readonly:this.loginService.loggedInUser.type == 'groupadmin', type: 'text'},
                { fieldName: 'status', readonly:this.loginService.loggedInUser.type == 'groupadmin',type: 'template', options: {}},
                { fieldName: '', type: 'template', options:{template:headlineTpl}},
                { fieldName: 'self_sid', type: 'select',readonly:this.loginService.loggedInUser.type == 'groupadmin', options: {
                    selectValues: this.customerService.currentCustomersLimeSurveys$ | async,
                    bindValue: 'sid',
                    bindLabel: 'surveyls_title',
                 
                }},
                { fieldName: 'other_sid', type: 'select',readonly:this.loginService.loggedInUser.type == 'groupadmin', options: {
                    selectValues: this.customerService.currentCustomersLimeSurveys$ | async,
                    bindValue: 'sid',
                    bindLabel: 'surveyls_title'
                }},
                { fieldName: 'customGroup',readonly:this.loginService.loggedInUser.type == 'groupadmin', type: 'template', options: { template: customGroup}},
                { fieldName: 'userGroups', type: 'template', options: { template: userGroups}},
                { fieldName: 'status',readonly:this.loginService.loggedInUser.type == 'groupadmin', type:'select', options: {selectValues:overlay.selectedObject.status == 'draft' ? ['draft', 'published'] : ['published','archived']}},
                { fieldName: 'feedbackDuration',readonly:this.loginService.loggedInUser.type == 'groupadmin',type:'number', options: {min: 0}},
                { fieldName: '', type: 'template', options: { template: responseCountView}}
            ]"
        ></rag-form>
        <div class="mt-3">
            <b i18n="@@globalMoreOptions">Weitere Optionen:</b>
            <div class="row">
                <div class="col-auto pe-0">
                    <a
                        [routerLink]="'/surveys/report/'+overlay.selectedObject._id"
                        [href]="'/report/' + userLanguage + '/#/hr/survey/' + overlay.selectedObject._id"
                        class="btn-sm btn-cta btn"
                        i18n="@@globalReport"
                    ><i class="ri-file-chart-line me-1"></i>Bericht</a>
                </div>
                <div class="col-auto">
                    <a
                        *ngIf="this.loginService.loggedInUser.type != 'groupadmin'"
                        [routerLink]="['/', 'mail-manager', overlay.selectedObject._id]"
                        class="btn-sm btn-cta btn"
                        i18n="@@feedbackMailTemplatesBtnText"
                    ><i class="ri-mail-line me-1"></i>E-Mailvorlagen für {{overlay.selectedObject.name}}</a>
                </div>
            </div>
        </div>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        i18n-pagetitle="@@surveysCardTitle"
        pagetitle="Befragungen"
        #headline
    ></app-content-headline>

    <app-new-and-help-row
        #newhelpRow
        [showNewButton]="this.loginService.loggedInUser.type != 'groupadmin'"
        newBtnLabel="Anlegen"
        i18n-newBtnLabel="@@globalAdd"
        [newFormTpl]="newFormTpl"
        topic="surveys"
    >
    </app-new-and-help-row>

    <div class="content-grid-content overflow-visible">
        <app-card>
            <lib-rag-datasupply-table
                #table
                (sort)="this.tableSort.sort($event)"
                (onSelect)="overlay.selectedObject = table.selectedObject; this.urlHelper.setSelectedId(table.selectedObject._id);"
                [columns]="[
                    {fieldName:'name'},
                    {fieldName:'surveyNames', renderFunc: this.allSidToName.bind(this)},
                    {fieldName:'status'},
                    {fieldName:'',template: reportingView}
                ]"
                [noDataText]="this.noDataText"
                [service]="this.frontendService"
                [translatedLabels]="translatedLabels"
            >
                <ng-container *ngIf="table.selectedObject">
                </ng-container>
            </lib-rag-datasupply-table>
        </app-card>
    </div>
</div>

<ng-template
    #responseCountView
    let-data="data"
>
    <div
        class="col col-12 col-form-label col-lg-2"
        i18n="@@globalResponseRate"
    >Rücklauf</div>


    <table
        *ngIf="this.surveyInfoReponse != null"
        class="table table-striped bg-white m-0 table-sm"
        aria-label="responserates"
    >
        <thead class="thead-light">
            <tr>
                <th i18n="@@globalPerspective">Perspektive</th>
                <th i18n="@@globalResponseRate">Rücklauf</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let perspective of this.surveyInfoReponse.survey.perspectives">
                <td>{{this.loginService.translate(perspective.name)}}</td>
                <td *ngIf=" this.getReponseRateforPerspective(perspective.id)!=null">
                    {{this.getReponseRateforPerspective(perspective.id)?.responseCount}} /
                    {{this.getReponseRateforPerspective(perspective.id)?.maxResponses}}</td>
                <td *ngIf=" this.getReponseRateforPerspective(perspective.id)==null">0 / 0</td>
            </tr>
        </tbody>
    </table>
</ng-template>
<ng-template
    #reportingView
    let-data="data"
>
    <a
        [routerLink]="'/surveys/report/'+data._id"
        [href]="'/report/' + userLanguage + '/#/hr/survey/' + data._id"
        class="btn-sm btn-cta btn"
        i18n="@@globalReport"
    ><i class="ri-file-chart-line me-1"></i>Bericht</a>
    <a
        *ngIf="this.loginService.loggedInUser.type != 'groupadmin'"
        [routerLink]="['/', 'mail-manager', data._id]"
        class="btn-sm btn-cta btn"
        i18n="@@feedbackMailTemplatesTableBtnText"
    ><i class="ri-mail-line me-1"></i>Befragungsspezifische E-Mailvorlagen</a>
</ng-template>

<ng-template #headlineTpl>
    <h4 i18n="@@globalQuestionnaires">
        Fragebögen
    </h4>
</ng-template>

<ng-template
    #selfSidSelect
    let-data="data"
>
    <ng-select
        [(ngModel)]="data.self_sid"
        [clearable]="false"
        [closeOnSelect]="true"
        [disabled]="this.data.status != 'draft'"
        [items]="this.customerService.currentCustomersLimeSurveys$ | async"
        [multiple]="false"
        bindLabel="surveyls_title"
        bindValue="sid"
        class="form-control"
        i18n-placeholder="@@surveysSelectLS"
        placeholder="LimeSurveys auswählen"
    >
    </ng-select>
</ng-template>

<ng-template
    #customGroup
    let-data="data"
>
    <h4 i18n="@@globalCustomGroups">Zusatzgruppierung von Fragen</h4>

    <ul *ngIf="this.reportingQuestionResponse != null">
        <li *ngFor="let group of this.reportingQuestionResponse.customGroups">{{group.groupName}}</li>
    </ul>
    <a
        *ngIf="this.loginService.loggedInUser.type != 'groupadmin'"
        [routerLink]="['/','customgroup',data._id]"
        class="btn btn-sm btn-primary"
        i18n="@@globalEdit"
    >Bearbeiten</a>
</ng-template>

<ng-template
    #userGroups
    let-data="data"
>
    <h4 i18n="@@globalUserGroups">Befragungsgruppen</h4>
    <div class="row">
        <ng-container *ngIf="this.loginService.loggedInUser.type !='groupadmin'">
            <div class="col">
                <ng-select
                    [(ngModel)]="selectedGroup"
                    [clearable]="false"
                    [items]="selectableGroups"
                    bindLabel="name"
                    class="form-control"
                    i18n-placeholder="@@surveysAddUserGroups"
                    placeholder="Befragungsgruppe zuweisen"
                >
                </ng-select>
            </div>
            <div class="col-auto">
                <div
                    (click)="addGroupToSurvey(selectedGroup._id, data)"
                    [ngClass]=" {'disabled':!selectedGroup}"
                    class="btn btn-primary btn-sm"
                    style="height: 36px;  padding-top: calc(0.25rem + 2px)"
                >
                    <i class="ri-add-line"></i>
                </div>
            </div>
        </ng-container>
        <ul class="col-12 list-group list-group-flush">
            <ng-container *ngFor="let group of data.userGroups">
                <li
                    *ngIf="this.userGroupsService.userGroupsMap?.get(group) != null"
                    class="list-group-item pe-0"
                >
                    <div class="row">
                        <div class="col">
                            {{this.userGroupsService.userGroupsMap?.get(group).name}}
                        </div>
                        <div class="col-auto">
                            <div
                                *ngIf="this.loginService.loggedInUser.type != 'groupadmin'"
                                (click)="removeGroupFromSurvey(group, data)"
                                class="btn btn-outline-danger btn-sm"
                            >
                                <i class="ri-close-line"></i>
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
</ng-template>

<ng-template
    #perspectives
    let-data="data"
>
    <h4 i18n="@@surveysPerspective">Perspektiven</h4>
    <div style="border:1px solid #A2A8B4">
        <table
            class="table table-striped bg-white m-0 table-sm"
            aria-label="perspectives"
        >
            <tbody>
                <tr *ngFor="let p of data.perspectives">
                    <td style="width: 0px;">
                        <a
                            *ngIf="this.loginService.loggedInUser.type != 'groupadmin'"
                            [routerLink]="['/', 'perspective',data._id,p.id]"
                            class="btn ri-edit-fill text-primary"
                            i18n="@@globalEdit"
                        ></a>
                    </td>
                    <td style="vertical-align: middle;">
                        {{this.loginService.translate(p.name)}}
                    </td>
                    <td style="vertical-align: middle;">
                        Min:
                        {{p.min}}
                    </td>
                    <td style="vertical-align: middle;">
                        Max:
                        {{p.max}}
                    </td>
                    <td
                        style="vertical-align: middle;"
                        i18n="@@surveyAnonymity"
                    >
                        Anonymität:
                        {{p.anonymity}}
                    </td>
                    <td
                        *ngIf="data.status == 'draft'"
                        style="text-align: right;"
                    >
                        <button
                            (click)="removePerspective(data._id, p.id)"
                            class="btn btn-sm btn-danger"
                            i18n="@@globalDelete"
                        >Löschen</button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <a
        [routerLink]="['/','perspective',data._id]"
        class="btn btn-sm btn-primary mt-2"
        i18n="@@globalAddPerspective"
        *ngIf="data.status == 'draft'"
    >Perspektive hinzufügen</a>
</ng-template>

<ng-template
    #otherSidSelect
    let-data="data"
>
    <ng-select
        [(ngModel)]="data.other_sid"
        [clearable]="false"
        [closeOnSelect]="true"
        [disabled]="this.data.status != 'draft'"
        [items]="this.customerService.currentCustomersLimeSurveys$ | async"
        [multiple]="false"
        bindLabel="surveyls_title"
        bindValue="sid"
        class="form-control"
        i18n-placeholder="@@surveysSelectLS"
        placeholder="LimeSurveys auswählen"
    >
    </ng-select>
</ng-template>

<ng-template #newFormTpl>
    <div class="p-5">
        <rag-form
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            [type]="'create'"
            (submit)="newhelpRow.newOverlay.close(); this.createItem($event)"
            [data]="{name: '', status: 'draft', self_sid: undefined, other_sid: undefined, feedbackDuration: undefined, perspectives: [], userGroups: []}"
            [formFields]="[
                {fieldName: 'name', type: 'text', required: true},
                {fieldName: 'self_sid', type: 'select', required: true, options: {
                    selectValues: this.customerService.currentCustomersLimeSurveys$ | async,
                    bindValue: 'sid',
                    bindLabel: 'surveyls_title'
                }},
                {fieldName: 'other_sid', type: 'select', required: true, options: {
                    selectValues: this.customerService.currentCustomersLimeSurveys$ | async,
                    bindValue: 'sid',
                    bindLabel: 'surveyls_title'
                }},
                {fieldName: 'feedbackDuration', type: 'number', options: {min: 1}},
                {fieldName: 'status', type: 'text', readonly: true}
                ]"
        ></rag-form>
    </div>
</ng-template>