import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-switcher',
  templateUrl: './table-switcher.component.html',
  styleUrls: []

})
export class TableSwitcherComponent implements OnInit {
  @Input() show: boolean = true;
  public mode: "table" | "grid" = "grid";

  private tableGridConfig: { [key: string]: "table" | "grid" }
  private currentPath: string;

  constructor(private router: Router) {
    if (this.show) {
      this.currentPath = router.parseUrl(router.url).root.children.primary.segments[0].path
    }
  }


  ngOnInit(): void {
    if (this.show) {
      this.tableGridConfig = JSON.parse(localStorage.getItem("table-grid-configs"))
      if (this.tableGridConfig == undefined) localStorage.setItem("table-grid-configs", JSON.stringify({}))
      if (!this.tableGridConfig[this.currentPath]) {
        this.tableGridConfig[this.currentPath] = this.mode;
        localStorage.setItem("table-grid-configs", JSON.stringify(this.tableGridConfig))
      } else {
        this.mode = this.tableGridConfig[this.currentPath]
      }
    }
  }

  public switchMode(mode: "table" | "grid") {
    this.mode = mode;
    if (this.show) {
      this.tableGridConfig[this.currentPath] = this.mode;
      localStorage.setItem("table-grid-configs", JSON.stringify(this.tableGridConfig))
    }
  }

  public isGrid() {
    return this.mode == "grid";
  }
  public isTable() {
    return this.mode == "table";
  }
}
