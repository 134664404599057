<app-page-header></app-page-header>

<lib-rag-datasupply-overlay-asside #overlay>
    <div
        class="p-5"
        *ngIf="overlay.selectedObject"
    >
        <rag-form
            (delete)="this.deleteItem($event);overlay.selectedObject = undefined"
            (submit)="this.updateItem($event)"
            [formFields]="[
                { fieldName: 'company', type:'text', required: true },
                { fieldName: 'contact', type:'text',  required: true },
                { fieldName: 'email', type:'text',  required: true },
                { fieldName: 'country', type:'text',  required: true },
                { fieldName: 'city', type:'text',  required: true },
                { fieldName: 'postalcode', type:'text',  required: true },
                { fieldName: 'street', type:'text',  required: true },
                { fieldName: 'orderNumber', type:'text',  required: true },
                { fieldName: 'vatId', type:'text',  required: true }
            ]"
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            [data]="overlay.selectedObject"
        ></rag-form>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        i18n-pagetitle="@@subsidiariesCardTitle"
        pagetitle="Tochtergesellschaften"
        #headline
    >
    </app-content-headline>

    <app-new-and-help-row
        [showNewButton]="true"
        newBtnLabel="Anlegen"
        i18n-newBtnLabel="@@globalAdd"
        [newFormTpl]="newFormTpl"
        topic="subsidiaries"
    >
    </app-new-and-help-row>

    <div class="content-grid-content overflow-visible">
        <app-card>
            <lib-rag-datasupply-table
                #table
                (sort)="this.tableSort.sort($event)"
                (onSelect)="overlay.selectedObject = table.selectedObject"
                [service]="this.frontendService"
                [columns]="[
                    { fieldName: 'company', label: 'Firma'},
                    { fieldName: 'contact', label: 'Kontakt'},
                    { fieldName: 'email', label: 'E-Mail'},
                    { fieldName: 'country', label: 'Land'},
                    { fieldName: 'city', label: 'Stadt'},
                    { fieldName: 'postalcode', label: 'PLZ'},
                    { fieldName: 'street', label: 'Straße'}
                ]"
                [translatedLabels]="translatedLabels"
            >
                <ng-container *ngIf="table.selectedObject">
                </ng-container>
            </lib-rag-datasupply-table>
        </app-card>
    </div>
</div>

<!-- TEMPLATES -->

<ng-template #newFormTpl>
    <rag-form
        [translateTexts]="translateTexts"
        [translatedLabels]="translatedLabelsForm"
        [type]="'create'"
        (submit)="this.createItem($event)"
        [data]="{company: '', contact: '', email: '', country: '', city: '', postalcode: '', street: '', orderNumber: '', vatId: ''}"
        [formFields]="[
            { fieldName: 'company',type: 'text', required: true },
            { fieldName: 'contact', type:'text',  required: true },
            { fieldName: 'email', type:'text',  required: true },
            { fieldName: 'country', type:'text',  required: true },
            { fieldName: 'city', type:'text',  required: true },
            { fieldName: 'postalcode', type:'text',  required: true },
            { fieldName: 'street', type:'text',  required: true },
            { fieldName: 'orderNumber', type:'text',  required: true },
            { fieldName: 'vatId', type:'text',  required: true }
        ]"
    ></rag-form>
</ng-template>