import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { BenchmarkConfig, Customer } from '@reflact/prmfeedback';
import { DatasupplyRequest, RagDatasupplyFrontendService, RagDatasupplyLocalBackendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { translateTexts } from '../../app.component';
import { TableSortUtil } from '../../shared/TableSortUtil';
import { CustomerService } from '../../shared/customer.service';
import { LayoutService } from '../../shared/layout.service';
import { MainService } from '../../shared/main.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: [],
  providers: [
    RagDatasupplyLocalBackendService
  ]
})
export class CustomersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  public subscriptions: Subscription[] = [];
  public customerService: CustomerService;
  public buttons = [
    $localize`:@@globalOverview:Übersicht`,
    $localize`:@@globalAdd:Anlegen`,
  ];
  public btnDisabled: boolean = true;
  public NotFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;


  public tableSort: TableSortUtil<Customer>;
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["name", "Name"],
    ["limesurvey_ids", $localize`:@@customersLimesurvey_ids:Befragungen`],
    ["logo", "Logo"],
    ["senderMail", $localize`:@@customersSenderMail:E-Mail - Name des Absenders...@mail.feedback-suite.de`],
    ["feedbackreceiverCount", $localize`:@@customerfeedbackreceiverCount:Feedbacknehmer`],
    ['unSelectAllText', $localize`:@@unselectAllText:Alle abwählen`],
    ['selectAllText', $localize`:@@selectAllText:Alle auswählen`]
  ])
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public translateTexts = translateTexts;
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["name", "senderMail", "feedbackreceiverCount"]
  }


  public tmpSelectedFiles: any = {}

  constructor(
    public toaster: ToastrService,
    public mainService: MainService,
    public frontendService: RagDatasupplyFrontendService<Customer>,
    public router: Router,
    public layoutService: LayoutService,
    public backendService: RagDatasupplyLocalBackendService<Customer>,
    public urlHelper: RagDatasupplyOverlayUrlService

  ) {
    this.customerService = mainService.customerService;
    this.backendService.initialRequest(this.standardRequest)
  }
  ngAfterViewInit(): void {
    this.subscriptions.push(this.urlHelper.newSelection$.subscribe(s => {
      this.overlay.selectedObject = this.customerService.customers.find(f => f._id == s)
    }))
    this.urlHelper.getSelectedId(true);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  //  write a code returning hello world
  ngOnInit(): void {


    this.backendService.loadData = () => {
      return new Promise<Customer[]>((resolve, reject) => {
        if (this.customerService.customers) {
          resolve([...this.customerService.customers])
        } else {
          reject([])
        }
      })
    }
    this.tableSort = new TableSortUtil(this.frontendService)
    this.subscriptions.push(
      this.mainService.isDataLoaded.subscribe((done) => {
        if (done) {
          this.backendService.load(this.standardRequest)
        }
      })
    );
    this.subscriptions.push(this.backendService.results$.subscribe(results => {
      this.frontendService.fromArray(results);
    }))
    this.subscriptions.push(
      this.customerService.error$.subscribe((i) => {
        this.toaster.error(i.message);
      })
    );


    this.subscriptions.push(
      this.customerService.customerCreated$.subscribe((i) => {
        this.router.navigate([], { queryParams: { selected: i._id } });
        this.toaster.success($localize`:@@globalCreatedCustomer:Kunde erfolgreich angelegt`);
      })
    );

    this.subscriptions.push(
      this.customerService.customerUpdated$.subscribe((i) => {
        this.toaster.success($localize`:@@globalCustomerSaved:Kunde erfolgreich gespeichert`);
      })
    );

    this.subscriptions.push(
      this.customerService.benchmarkUpdated$.subscribe((i) => {
        this.toaster.success($localize`:@@globalBenchmarkAttributeSaved:Benchmark-Attribut gespeichert`);
      })
    );
  }

  public dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest)

  }

  public onFileChange(event: any, fieldName: string) {
    this.tmpSelectedFiles[fieldName] = event.target.files;
  }

  public async uploadLogo(i: Customer) {
    if (this.tmpSelectedFiles.logo === '' || this.tmpSelectedFiles.logo == undefined) {
      this.customerService.deleteLogo(i._id).then(() => {
        this.layoutService.reloadLogo();
        this.toaster.success($localize`:@@globalLogoRemoved:Logo entfernt`);
      });
    } else {
      const fileList = this.tmpSelectedFiles.logo as FileList;
      this.customerService.uploadLogo(fileList.item(0), i._id).then(() => {
        this.layoutService.reloadLogo();
        this.toaster.success($localize`:@@globalLogoUploadComplete:Logo hochgeladen`);
        window.location.reload();
      });
    }
  }
  public async updateCustomer(i: Customer) {
    await this.customerService.updateCustomer(i);
  }
  public async createCustomer(i: Customer) {
    await this.customerService.createCustomer(i);
  }

  public async deleteItem(i: Customer) {
    await this.customerService.deleteCustomer(i);
    this.toaster.success($localize`:@@globalCustomerDeleted:Kunde erfolgreich gelöscht`);
  }
  public checkDisabled(event: Event) {
    if ((event.target as HTMLInputElement).value.trim() === '') {
      this.btnDisabled = true;
    } else {
      this.btnDisabled = false;
    }
  }

  public surveyIdsToNames(data: Customer) {
    let result = '';
    data.limesurvey_ids.forEach((id) => {
      const survey = this.customerService.limeSurveys.find(
        (s) => s.sid === id
      );
      result += survey ? survey.surveyls_title + ' ' : 'Not found ';
    });
    return result;
  }

  public getUUID() {
    return uuidv4();
  }

  public addBenchmarkConfig(label: string, data: any) {
    this.btnDisabled = true;
    const newitem = { label: label, id: uuidv4() };
    data.benchmarkConfigs.push(newitem);
    this.customerService.updateBenchmarks(data);
    return data.benchmarkConfigs;
  }

  // lorem ipsu
  public addNewBenchmarkConfig(label: string, c: any) {
    this.btnDisabled = true;
    c.benchmarkConfigs.push({ label: label, id: uuidv4() });
    return c.benchmarkConfigs;
  }

  public removeBenchmarkConfig(configForRem: BenchmarkConfig, data: any) {
    data.benchmarkConfigs.splice(data.benchmarkConfigs.indexOf(configForRem), 1);
    this.customerService.updateBenchmarks(data);
    return data.benchmarkConfigs;
  }
}
