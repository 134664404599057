<app-reporting-force-group>
  <span
    colA
    class="text-smaller d-print-none"
    i18n="@@reportingGroups"
  >Themenfeld</span>
  <!-- header-->
  <app-reporting-force-perspective colB>
    <span
      colA
      class="text-smaller text-center d-block"
      i18n="@@globalPerspective"
    >Perspektive</span>
    <span
      colB
      class="text-smaller text-center d-block"
    >
      <i class="ri-team-fill "></i><br>
      <span
        class="d-none d-lg-block d-print-flex"
        i18n="@@reportingConsideredResponses"
      >
        Berück&shy;sichtigte Antworten</span></span>
    <span
      colC
      class="text-smaller"
    >
      <div class="row align-items-center ">
        <div
          class="col text-center text-smaller"
          *ngFor="let s of scale"
        > <span
            [innerHTML]="s.text"
            class="scaletext d-none  d-xl-block d-print-block"
          ></span>
          <span class="d-block d-xl-none d-print-none"> {{s.key}}</span>
        </div>
      </div>

    </span>
  </app-reporting-force-perspective>
  <span colC>
    <i class="ri-arrow-down-s-line ri-2x invisible "></i>
  </span>

</app-reporting-force-group>