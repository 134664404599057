import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FieldsForUserCreation, PrmUserType, PrmUserTypeArr } from '@reflact/prmfeedback';
import { RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { AdminGuard } from '../../shared/admin.guard';
import { ExcelImportComponent } from '../../shared/excel-import/excel-import.component';
import { CustomerService } from './../../shared/customer.service';
import { MainService } from './../../shared/main.service';
import { UserGroupsService } from './../../shared/user-groups.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: []
})
export class ImportComponent implements OnChanges {
  @Input() isAdminArea: boolean = false;
  @ViewChild(ExcelImportComponent) excelImportComp: ExcelImportComponent;
  public fields: RagDatasupplyTypes.FormField[] = [];
  public buttons = [$localize`:@@globalOverview:Übersicht`];
  public type: PrmUserType = 'hr';
  public allTypes = PrmUserTypeArr as unknown as PrmUserType[];
  public userGroupsService: UserGroupsService;
  public customerService: CustomerService;

  constructor(private adminGuard: AdminGuard, private mainService: MainService) {
    this.userGroupsService = mainService.userGroupsService;
    this.customerService = mainService.customerService;
    this.mainService.isDataLoaded.subscribe(loaded => {
      if (loaded) {
        // Befüllt Fields Array mit allen benötigten FieldNames zum erstellen z.B. email
        this.fields = [];
        FieldsForUserCreation.forEach(fieldName => {
          if (fieldName === 'benchmarkValues') { return; }
          if (fieldName === 'userGroups') { return; }
          if (fieldName === 'subsidiaryId') { return; }
          this.fields.push({ required: true, fieldName })
        });
        // Fügt das hidden type Field hinzu, um den Typ der importieren Nutzer zu kennen.
        this.fields.push({
          fieldName: 'type',
          required: false,
          defaultValue: 'hr'
        });

        const customer = this.customerService.customerMap.get(this.mainService.loginService.loggedInUser.customer_id)
        const benchmarkConfigs = customer.benchmarkConfigs
        benchmarkConfigs.forEach(b => {
          this.fields.push({
            fieldName: "benchmark." + b.label,
            required: false,
            defaultValue: ""
          })
        })
        const userGroups = this.userGroupsService.userGroups;
        userGroups.forEach(ug => {
          this.fields.push({
            fieldName: "userGroups." + ug.name,
            required: false,
            defaultValue: ""
          });
        });
        // Blendet die Option Admins zu importieren für alle nicht admins aus
        // if (!this.adminGuard.canActivate())
        this.allTypes = this.allTypes.filter(type => type !== 'prmadmin');
        if (this.mainService.loginService.loggedInUser.type == 'groupadmin') {
          this.allTypes = this.allTypes.filter(type => type !== 'hr' && type !== 'groupadmin');
          this.type = 'feedbackreceiver';
          this.fields.find(f => f.fieldName === 'type').defaultValue = 'feedbackreceiver';
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isAdminArea && changes.isAdminArea.isFirstChange() && changes.isAdminArea.currentValue === true) {
      const type = this.fields.find(f => f.fieldName === 'type');
      if (type) {
        type.defaultValue = 'prmadmin';
      }
    }
  }

  public onTypeSelected() {
    this.fields.find(f => f.fieldName === 'type').defaultValue = this.type;
    this.excelImportComp.clearImportedData();
  }

  public userTypeToName(type: PrmUserType) {
    switch (type) {
      case 'hr': return $localize`:@@globalHR:HR-Administrator*in`;
      case 'prmadmin': return $localize`:@@globalPRM:PRM-Administrator*in`;
      case 'feedbackgiver': return $localize`:@@globalFG:Feedbackgeber*in`;
      case 'feedbackreceiver': return $localize`:@@globalFN:Feedbacknehmer*in`;
      case 'groupadmin': return $localize`:@@globalGroupAdmin:Team-Administrator*in`;
    }
  }

  public getType() {
    return this.fields.find(f => f.fieldName === 'type')?.defaultValue;
  }
}