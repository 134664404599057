<div
  class=" mainrow mx-0 "
  style="page-break-after: always;"
  [ngClass]="{
    'even ': !isOdd,
    'odd': isOdd ,
    'isopen': this.viewMode != 'close',
    'align-items-center': this.viewMode == 'close'
    }"
>



  <ng-container *ngIf="this.viewMode != 'open'">

    <app-reporting-force-group>
      <span
        colA
        class="text-smaller  print-groupname"
      >
        {{this.group.groupName}}
      </span>
      <span colB>
        <div class="row d-none d-print-flex">
          <div class="col">
            <app-reporting-header-with-scala [scale]="scale">
            </app-reporting-header-with-scala>
          </div>
        </div>
        <div class="row m-0 align-items-center ">

          <div class="col px-0">
            <ng-container *ngFor="let pdata of filterPerspectiveData(group.perspectiveData); let pIndex = index">

              <app-reporting-force-perspective [evenOrOdd]="evenOrOdd(pIndex)">
                <span
                  colA
                  class="text-smaller  "
                >

                  <app-perspective-name-display
                    [name]="perspectiveNameById(pdata.perspectiveId)"
                    [value]="pdata.mean"
                    [color]="this.colorService.stringToColor(perspectiveNameById(pdata.perspectiveId))"
                  ></app-perspective-name-display>
                </span>
                <span
                  colB
                  class="text-smaller text-center d-block"
                >
                </span>
                <div
                  class="row mx-n4"
                  colC
                >

                  <div class="col ">
                    <!-- CLOSED -->


                    <app-scale-renderer
                      [scaleMin]="this.scaleMin"
                      [scaleMax]="this.scaleMax"
                      [scale]="scale"
                      [sePoint]="this.group.selfEvaluation.mean"
                      [meanPoint]="(pdata.perspectiveId != 'SE') ? pdata.mean : 0"
                      [meanColor]="this.colorService.stringToColor(perspectiveNameById(pdata.perspectiveId))"
                    ></app-scale-renderer>

                  </div>
                </div>

              </app-reporting-force-perspective>
            </ng-container>

          </div>
        </div>

      </span>
      <span colC>
        <!-- CLOSED -->
        <i
          class=" ri-2x cursor-pointer"
          [ngClass]="{
                'invisible': this.viewMode == 'print',
                'ri-arrow-up-s-line': this.viewMode != 'close',
                'ri-arrow-down-s-line': this.viewMode == 'close'
            }"
          (click)="changeViewMode()"
        ></i></span>
    </app-reporting-force-group>
  </ng-container>

  <!-- GEÖFFNETE ROW-->
  <ng-container *ngIf="this.viewMode != 'close'">

    <div style="border-left:0px solid var(--cui-primary)">
      <div class="row m-0 mb-n3 d-print-none ">
        <div class="col ms-2 mt-3  text-smaller ps-1">

          {{this.group.groupName}}:
        </div>
        <div class="col-auto">
          <i
            class=" ri-2x cursor-pointer"
            [ngClass]="{
              'invisible': this.viewMode == 'print',
              'ri-arrow-up-s-line': this.viewMode == 'open',
              'ri-arrow-down-s-line': this.viewMode != 'open'
      }"
            (click)="changeViewMode()"
          ></i>
        </div>

      </div>

      <ng-container *ngFor="let groupQuestion of this.group.groupQuestions">
        <div class="row m-0 groupQuestionsRow  d-print-none ">
          <div class="col text-smaller ms-2 mt-1 mb-3 text-bold ps-1">
            <b>{{groupQuestion.questionText}}</b>
          </div>
        </div>

        <ng-container *ngFor="let subQuestion of groupQuestion.subQuestions">

          <app-reporting-force-group>
            <span
              colA
              class=" text-start d-block "
            >
              <app-anker-col
                [subQuestion]="subQuestion"
                [groupQuestion]="groupQuestion"
              ></app-anker-col>
            </span>
            <span colB>

              <app-reporting-force-perspective>

                <app-reporting-se-header
                  colC
                  [scaleMin]="this.scaleMin"
                  [scaleMax]="this.scaleMax"
                  [seValue]="subQuestion.selfEvaluation.mean"
                ></app-reporting-se-header>

              </app-reporting-force-perspective>
              <div class="  ms-print-5">
                <div
                  class="row  ms-0 align-items-center me-0"
                  *ngFor="let pdata of filterPerspectiveData(subQuestion.perspectiveData); let pIndex = index"
                >


                  <div class="col px-0">
                    <app-reporting-force-perspective
                      [isSub]="true"
                      [evenOrOdd]="0 === pIndex % 2 ? 'even':'odd'"
                    >
                      <span
                        colA
                        class="text-smaller "
                      >

                        <app-perspective-name-display
                          [name]="perspectiveNameById(pdata.perspectiveId)"
                          [value]="pdata.mean"
                          [color]="this.colorService.stringToColor(perspectiveNameById(pdata.perspectiveId))"
                        >
                        </app-perspective-name-display>

                      </span>
                      <span
                        colB
                        class="text-smaller text-center d-block"
                      >
                        <i
                          class="ri-team-fill text-muted"
                          style="position: relative;top:1px"
                        ></i>
                        {{pdata.count}}/{{this.getResponseRate(pdata.perspectiveId)}}</span>
                      <span
                        colC
                        class="text-smaller"
                      >
                        <div class="row">
                          <div class="col p-0">
                            <!--open-->
                            <app-scale-renderer
                              class="subScale"
                              [scaleMin]="this.scaleMin"
                              [scaleMax]="this.scaleMax"
                              [scale]="this.scale"
                              [feMax]="pdata.max"
                              [feMin]="pdata.min"
                              [sePoint]="subQuestion.selfEvaluation.mean"
                              [meanPoint]="(pdata.perspectiveId != 'SE') ? pdata.mean : 0"
                              [meanColor]="this.colorService.stringToColor(perspectiveNameById(pdata.perspectiveId))"
                            ></app-scale-renderer>

                          </div>

                        </div>
                      </span>
                    </app-reporting-force-perspective>
                  </div>
                </div>
              </div>
            </span>
            <span colC>
              <!-- HEADER INVISIBLE -->
              <i class="ri-arrow-up-s-line ri-2x cursor-pointer invisible"></i>
            </span>
          </app-reporting-force-group>

        </ng-container>

      </ng-container>
    </div>
  </ng-container>
</div>