import { Injectable } from '@angular/core';
import { CustomGroup, CustomGroupCreationData } from '@reflact/prmfeedback';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class CustomgroupService {

  constructor(public socketService: SocketService) {
  }
  public loadCustomGroups(surveyId: string) {
    return new Promise<CustomGroup[]>((resolve, reject) => {
      this.socketService.socket.emit("getCustomGroups", { surveyId }, (data) => {
        if (data.status == "ok") {
          resolve(data.customGroups)
        } else {
          reject(data)
        }
      })
    })
  }
  public editCustomGroup(customGroup: CustomGroup) {
    return new Promise<CustomGroup>((resolve, reject) => {
      this.socketService.socket.emit("editCustomGroup", { customGroup }, (data) => {
        if (data.status == "ok") {
          resolve(data.customGroup)
        } else {
          reject(data)
        }
      })
    })
  }
  public createCustomGroup(customGroup: CustomGroupCreationData) {
    return new Promise<CustomGroup>((resolve, reject) => {
      this.socketService.socket.emit("createCustomGroup", { customGroup }, (data) => {
        if (data.status == "ok") {
          resolve(data.customGroup)
        } else {
          reject(data)
        }
      })
    })
  }
  public deleteCustomGroup(customGroupId: string) {
    return new Promise<string>((resolve, reject) => {
      this.socketService.socket.emit("deleteCustomGroup", { customGroupId }, (data) => {
        if (data.status == "ok") {
          resolve(data.deletedId)
        } else {
          reject(data)
        }
      })
    })
  }

}
