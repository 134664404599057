<div
    class="row"
    [ngStyle]="{'visibility': show? 'visible': 'hidden'}"
>
    <div class="col px-0">

        <i
            class="ri-grid-fill ri-2x "
            [ngClass]="{'text-secondary': this.mode != 'grid'}"
            (click)="this.switchMode('grid')"
        ></i>
    </div>
    <div class="col">
        <i
            class="ri-table-fill ri-2x"
            [ngClass]="{'text-secondary': this.mode != 'table'}"
            (click)="this.switchMode('table')"
        ></i>
    </div>

</div>