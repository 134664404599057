<ng-template #mandantselect>

  <div class="row bg-white m-0 pb-2 ">
    <div
      class="col"
      i18n="@@feedbackSuiteClient"
    >
      <b>Mandant</b>
      <select
        class="form-select form-select-sm"
        (change)="this.updateSelectCustomer($event)"
      >
        <ng-container *ngFor="let customer of this.customerService.customers">
          <option
            *ngIf="this.loginService.loggedInUser?.customer_id == customer._id"
            selected='selected'
            [value]="customer._id"
          >{{customer.name}}</option>
          <option
            *ngIf="this.loginService.loggedInUser?.customer_id != customer._id"
            [value]="customer._id"
          >{{customer.name}}</option>
        </ng-container>
      </select>
    </div>
  </div>
</ng-template>
<div
  class="sidebar sidebar-dark sidebar-fixed sidebar-self-hiding-xl no-print"
  id="sidebar"
  [ngClass]="{'hide':sidebarMinimized, 'show': showMobile}"
>
  <div class="sidebar-brand d-none d-md-flex  overflow-hidden bg-white">
    <img
      class="sidebar-brand-full"
      height="46"
      [src]="layoutService.getLogoUrl()"
      alt="logo"
    >
    <div style="overflow: hidden;position: relative;left:15px">
      <img
        class="sidebar-brand-narrow"
        height="46"
        style="position: relative;left:-75px"
        [src]="layoutService.getLogoUrl()"
        alt="logo"
      >
    </div>
  </div>
  <ng-container *ngIf="this.loginService.loggedInUser?.type == 'prmadmin'">
    <ng-container *ngTemplateOutlet="mandantselect">
    </ng-container>
  </ng-container>
  <!-- TOP MENUS-->
  <app-sidebar-nav
    *ngIf="isAdmin()"
    [navItems]="this.navItemsAdmin"
    [showChildren]="true"
    [hideDropArrow]="true"
  ></app-sidebar-nav>

  <app-sidebar-nav
    *ngIf="isHR()"
    [navItems]="this.navItemsHr"
    [showChildren]="true"
    [hideDropArrow]="true"
  ></app-sidebar-nav>

  <app-sidebar-nav
    *ngIf="isGroupAdmin()"
    [navItems]="this.navItemsGroupAdmin"
    [showChildren]="true"
    [hideDropArrow]="true"
  ></app-sidebar-nav>

  <app-sidebar-nav
    *ngIf="isFeedbackReciever()"
    [navItems]="this.navItemsFeedbackreceiver"
    [showChildren]="true"
    [hideDropArrow]="true"
  ></app-sidebar-nav>

  <app-sidebar-nav
    *ngIf="isFeedbackGiver()"
    [navItems]="this.navItemsFeedbackgiver"
    [showChildren]="true"
    [hideDropArrow]="true"
  ></app-sidebar-nav>

  <div class="text-center">
    <div
      class="btn btn-outline-primary m-2 btn-logout px-5"
      (click)="this.loginService.logout()"
    >Logout</div>
  </div>
</div>
<div class="wrapper d-flex flex-column min-vh-100">

  <div class="container-fluid px-5">

    <div
      class="d-md-none bg-white mx-n5 py-2"
      style="position: fixed; z-index: 999;top:0px; width: 100%;"
      [scrollFadeOut]="50"
    >
      <div class="row px-2 align-items-center">
        <div class="col-auto ps-5">
          <div
            style="font-size: 1.5rem;"
            (click)="this.showMobile = !this.showMobile"
          >
            <i class="ri-menu-line text-info"></i>
          </div>
        </div>
        <div class="col">
          <h5
            style="margin-bottom: 0.25rem;"
            class="smaller-until-414"
          >
            Feedback-Suite
          </h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center pe-3">
            <div
              class="col ms-2 pe-0"
              *ngIf="loggedInAsOtherUser"
              i18n="@@temporaryLoggedInAs"
            >Temporär eingeloggt als:</div>
            <div
              class="col-auto ms-2 pe-0  "
              [ngClass]="{'ps-0':loggedInAsOtherUser}"
            >
              <app-prm-user-display [user]="(this.loginService.loggedInUser$ | async)"></app-prm-user-display>
            </div>

            <div
              class="col-auto "
              *ngIf="loggedInAsOtherUser"
            >
              <div
                class="badge bg-cta text-white cursor-pointer"
                i18n="@@switchBackToAdmin"
                (click)="mainService.loginService.switchToUser()"
              >zurück wechseln</div>
            </div>
            <div class="col-auto pe-0">
              <div
                class="btn-group "
                dropdown
              >
                <button
                  id="button-basic"
                  dropdownToggle
                  type="button"
                  class="dropdown-toggle btn-sm btn "
                  style="border:0px"
                  aria-controls="dropdown-basic"
                >
                  <span
                    class="fi fi-{{this.mainService.getLanguageImg((this.loginService.loggedInUser$ | async).language)}}"
                  ></span>
                </button>
                <ul
                  id="dropdown-basic"
                  *dropdownMenu
                  class="dropdown-menu"
                  role="menu"
                  style="left:-60px !important; top:0px; z-index:9999999999; transform: tra"
                  aria-labelledby="button-basic"
                >
                  <ng-container *ngFor="let language of this.mainService.languages">
                    <li (click)="this.setLanguage(language.value)">
                      <a
                        class="dropdown-item "
                        href="#"
                        [ngClass]="{'disabled': language.value == (this.loginService.loggedInUser$ | async).language}"
                      >
                        <span class="fi fi-{{language.img}}"></span>
                        {{language.label}}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div
      class="d-none d-md-block"
      style="position: fixed; z-index: 999;right:10px;top:10px;"
      [scrollFadeOut]="250"
    >
      <div class=" bg-white alert  py-1 shadow-sm no-print">
        <div class="row align-items-center">
          <div
            class="col ms-2 pe-0"
            *ngIf="loggedInAsOtherUser"
            i18n="@@temporaryLoggedInAs"
          >Temporär eingeloggt als:</div>
          <div
            class="col-auto ms-2 pe-0  "
            [ngClass]="{'ps-0':loggedInAsOtherUser}"
          >
            <app-prm-user-display [user]="(this.loginService.loggedInUser$ | async)"></app-prm-user-display>
          </div>

          <div
            class="col-auto "
            *ngIf="loggedInAsOtherUser"
          >
            <div
              class="badge bg-cta text-white cursor-pointer"
              i18n="@@switchBackToAdmin"
              (click)="mainService.loginService.switchToUser()"
            >zurück wechseln</div>
          </div>
          <div class="col-auto pe-0">
            <div
              class="btn-group "
              dropdown
            >
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="dropdown-toggle btn-sm btn "
                style="border:0px"
                aria-controls="dropdown-basic"
              >
                <span
                  class="fi fi-{{this.mainService.getLanguageImg((this.loginService.loggedInUser$ | async).language)}}"
                ></span>
              </button>
              <ul
                id="dropdown-basic"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                style="left:-60px !important; top:0px; z-index:9999999999; transform: tra"
                aria-labelledby="button-basic"
              >
                <ng-container *ngFor="let language of this.mainService.languages">
                  <li (click)="this.setLanguage(language.value)">
                    <a
                      class="dropdown-item "
                      href="#"
                      [ngClass]="{'disabled': language.value == (this.loginService.loggedInUser$ | async).language}"
                    >
                      <span class="fi fi-{{language.img}}"></span>
                      {{language.label}}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 100%;">
      <router-outlet></router-outlet>
    </div>

    <footer class="footer bg-white d-none">
      <div></div>
      <div class="ms-auto">

        <ng-select
          [items]="['de', 'en']"
          [clearable]="false"
          [(ngModel)]="this.loginService.loggedInUser$.value.language"
          (change)="this.setLanguage($event)"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
          >
            <img
              src="assets/img/{{item}}.svg"
              alt="language"
            >
          </ng-template>

          <ng-template
            ng-label-tmp
            let-item="item"
          >
            <img
              height="15"
              width="15"
              src="assets/img/{{item}}.svg"
              alt="language"
            >
          </ng-template>
        </ng-select>

      </div>
    </footer>
    <div
      class="sidebar-backdrop fade"
      [ngClass]="{'d-none':!showMobile, 'show': showMobile}"
      (click)="this.showMobile = !this.showMobile"
    ></div>
  </div>
</div>