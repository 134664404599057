import { Injectable } from '@angular/core';
import { AdminGroup, AssignGroupRequest, AssignGroupResult, CreateAdminGroupRequest, EditAdminGroupRequest } from '@reflact/prmfeedback';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class AdminGroupsService {
  public adminGroups: AdminGroup[] = []
  public adminGroupsMap: Map<string, AdminGroup> = new Map()

  constructor(public socketService: SocketService) { }

  public clearAllData() {
    this.adminGroups.splice(0);
    this.adminGroupsMap.clear()
  }

  public async loadData() {
    this.clearAllData();
    this.adminGroups.push(...(await this.getAdminGroups()));
    this.adminGroupsMap = new Map(this.adminGroups.map(a => [a._id, a]));
  }

  public async getAdminGroups() {
    return new Promise<AdminGroup[]>((resolve, reject) => {
      this.socketService.socket.emit("getAdminGroups", {}, (data) => {
        if (data.status == "ok") {
          resolve(data.adminGroups);
        } else {
          reject(data);
        }
      })
    })
  }

  public async addAdminGroup(group: CreateAdminGroupRequest) {
    return new Promise<AdminGroup>((resolve, reject) => {
      this.socketService.socket.emit("createAdminGroup", group, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data.adminGroup);
        } else {
          reject(data);
        }
      })
    })
  }

  public async editAdminGroup(group: EditAdminGroupRequest) {
    return new Promise<AdminGroup>((resolve, reject) => {
      this.socketService.socket.emit("editAdminGroup", group, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data.adminGroup);
        } else {
          reject(data);
        }
      })
    })
  }

  public async deleteAdminGroup(groupId: string) {
    return new Promise<string>((resolve, reject) => {
      this.socketService.socket.emit("deleteAdminGroup", { _id: groupId }, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data.adminGroup._id);
        } else {
          reject(data);
        }
      })
    })
  }

  public async assignAdminGroup(assignData: AssignGroupRequest) {
    return new Promise<AssignGroupResult>((resolve, reject) => {
      this.socketService.socket.emit("assignAdminGroup", assignData, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }

  public async unassignAdminGroup(assignData: AssignGroupRequest) {
    return new Promise<AssignGroupResult>((resolve, reject) => {
      this.socketService.socket.emit("removeFromAdminGroup", assignData, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }

}
