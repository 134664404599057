import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxForm } from '@reflact/ngx-forms';
import { AdminGroup, AllPrmUserType, Survey, UserGroup } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTableQuicklookAsideComponent } from '@reflact/rag-datasupply';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest } from 'rxjs';
import { translateTexts } from '../../app.component';
import { TableSortUtil } from './../../shared/TableSortUtil';
import { CustomerService } from './../../shared/customer.service';
import { MainService } from './../../shared/main.service';
import { UserGroupsService } from './../../shared/user-groups.service';
import { UserService } from './../../shared/user.service';

interface UserGroupWithGranted {
  userGroup: UserGroup;
  users: AllPrmUserType[];
  surveys: Survey[];
}

@Component({
  selector: 'app-usergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: []
})
export class UsergroupsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('table') table: RagDatasupplyTableQuicklookAsideComponent<UserGroup>;
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  public userGroups: UserGroup[];
  public subscriptions: Subscription[] = [];
  public currentGroup: UserGroupWithGranted = null;
  public selectedUser: AllPrmUserType = null;
  public selectedSurvey: Survey = null;
  public surveys: Survey[] = [];
  public users: AllPrmUserType[] = [];
  public translateTexts = translateTexts;
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ['name', $localize`:@@globalName:Name`],
    ['adminGroups', $localize`:@@globalAdminGroups:Teams`],
    ['unSelectAllText', $localize`:@@unselectAllText:Alle abwählen`],
    ['selectAllText', $localize`:@@selectAllText:Alle auswählen`]
  ])

  public tableSort: TableSortUtil<UserGroup>
  public userGroupService: UserGroupsService;
  public userService: UserService;
  public customerService: CustomerService;
  public adminGroups: AdminGroup[];

  constructor(private route: ActivatedRoute,
    public router: Router,
    public toaster: ToastrService,
    public mainService: MainService,
    public frontendService: RagDatasupplyFrontendService<UserGroup>,
    public urlHelper: RagDatasupplyOverlayUrlService
  ) {
    this.route.data.subscribe(d => {
      this.userGroups = d.data;
      this.adminGroups = d.adminGroups;
    });

    this.userGroupService = mainService.userGroupsService;
    this.userService = mainService.userService;
    this.customerService = mainService.customerService;
  }
  ngAfterViewInit(): void {
    combineLatest([this.route.queryParams, this.mainService.isDataLoaded]).subscribe(([params, done]) => {
      if (this.table.selectedObject && done) { this.loadGroup(this.table.selectedObject._id); }
    });
    this.subscriptions.push(
      this.table.onSelect.subscribe(g => this.loadGroup(g._id))
    );
  }

  loadGroup(id: string) {
    this.selectedUser = undefined;
    this.selectedSurvey = undefined;
    const logginInUser = this.mainService.loginService.loggedInUser
    this.users = this.userService.users.filter(u => u.type != "prmadmin" && u.type != "feedbackgiver" && !u.userGroups.includes(id));
    this.surveys = this.customerService.surveys.filter(s => !s.userGroups.includes(id));
    if (logginInUser.type == "groupadmin") {
      this.surveys = this.surveys.filter(s => s.adminGroups.some(ag => logginInUser.adminGroupsAdmin.includes(ag)))
    }
    this.currentGroup = {
      userGroup: this.userGroups.find(g => g._id === id),
      users: this.userService.users.filter(u => u.type != "prmadmin" && u.userGroups.includes(id)),
      surveys: this.customerService.surveys.filter(s => s.userGroups.includes(id))
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public ngOnInit() {
    this.tableSort = new TableSortUtil(this.frontendService);
    this.subscriptions.push(
      this.mainService.isDataLoaded.subscribe((done) => {
        if (done) {
          this.frontendService.fromArray(this.userGroups);
        }
      })
    );
    this.subscriptions.push(
      this.urlHelper.newSelection$.subscribe(s => {
        this.overlay.selectedObject = this.userGroups.find(u => u._id == s)
        if (this.overlay.selectedObject != null) {
          this.overlay.selectedObject.adminGroups = this.mainService.filterAdminGroupIdsForGroupAdmin(this.mainService.loginService.loggedInUser, this.overlay.selectedObject.adminGroups)
        }
      })
    );
  }

  public async deleteItem(i) {
    await this.userGroupService.deleteUserGroup(i._id);
    this.userGroups = await this.userGroupService.getUserGroups();
    this.frontendService.fromArray(this.userGroups);
    this.toaster.success($localize`:@@userGroupsRemovedGroup:Befragungsgruppe entfernt`);
  }

  public async updateItem(i) {
    await this.userGroupService.editUserGroup(i);
    this.toaster.success($localize`:@@userGroupsSavedGroup:Befragungsgruppe gespeichert`);
  }

  public async createItem(i) {
    const newGroup = await this.userGroupService.addUserGroup(i);
    this.userGroups = await this.userGroupService.getUserGroups();
    this.frontendService.fromArray(this.userGroups);
    this.loadGroup(newGroup._id)
    this.toaster.success($localize`:@@userGroupsAddedGroup:Befragungsgruppe hinzugefügt`);

    this.urlHelper.setSelectedId(newGroup._id);

    this.urlHelper.getSelectedId(true)

    this.overlay.selectedObject = newGroup
    if (this.overlay.selectedObject != null) {
      this.overlay.selectedObject.adminGroups = this.mainService.filterAdminGroupIdsForGroupAdmin(this.mainService.loginService.loggedInUser, this.overlay.selectedObject.adminGroups)
    }
  }


  async addGroupToUser(groupId: string, user: AllPrmUserType) {
    user.userGroups.push(groupId);
    this.selectedUser = undefined;
    await this.userService.updateUser(user);
    this.loadGroup(groupId);
  }

  async removeGroupFromUser(groupId: string, user: AllPrmUserType) {
    user.userGroups = user.userGroups.filter(gid => gid !== groupId);
    await this.userService.updateUser(user);
    this.loadGroup(groupId);
  }

  async addGroupToSurvey(groupId: string, survey: Survey) {
    survey.userGroups.push(groupId);
    this.selectedSurvey = undefined;
    await this.userGroupService.addSurveyToUserGroup(groupId, survey._id);
    this.loadGroup(groupId);
  }

  async removeGroupFromSurvey(groupId: string, survey: Survey) {
    survey.userGroups = survey.userGroups.filter(gid => gid !== groupId);
    await this.userGroupService.removeSurveyFromUserGroup(groupId, survey._id);
    this.loadGroup(groupId);
  }

  searchFn(term: string, item: AllPrmUserType) {
    return (item.firstname + item.lastname + item.email).toLowerCase().includes(term.toLowerCase());
  }
}
