import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckRecoverycodeResult, StatusMsg } from '@reflact/prmfeedback';
import { SocketService } from '../../shared/SocketService';

@Component({
  selector: 'app-dashboard',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./../login/login.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  private recoveryCode = '';
  private email = '';
  public statusMessage = '';
  public passwordValid: boolean = false;
  public minLength: number = 12;
  formGroup: UntypedFormGroup;

  constructor(private socketService: SocketService, private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, @Inject(LOCALE_ID) public locale: "en" | "de"
  ) {
    this.formGroup = this.formBuilder.group({});
    this.formGroup.addControl('password', new UntypedFormControl(null, [Validators.required]));
    this.formGroup.addControl('password-repeat', new UntypedFormControl(null, [Validators.required]));
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.recoveryCode = params.recovery_code;
      this.email = params.email;
    });
    this.socketService.socket.emit('checkRecoveryCode', { recoveryCode: this.recoveryCode }, (result: CheckRecoverycodeResult) => {
      if (!result.valid) {
        this.statusMessage = 'code-error';
      }
    });
  }

  public onSavePassword() {
    const password = this.formGroup.controls.password.value;
    const passwordRepeat = this.formGroup.controls['password-repeat'].value;
    if (!this.formGroup.valid) {
      return;
    };
    if (password === passwordRepeat) {
      this.socketService.socket.emit('recoverpw', { recoverToken: this.recoveryCode, password: password }, (statusMessage: StatusMsg) => {
        if (statusMessage.status === 'ok') {
          this.router.navigate(['/login'], { queryParams: { email: this.email, code: true } });
        } else {
          this.statusMessage = 'code-error';
        }
      });
    } else {
      this.statusMessage = 'password-mismatching';
    }
  }
  onPasswordValidationChange($event: boolean) {
    this.passwordValid = $event;
  }
}
