<app-page-header></app-page-header>
<div class="content-grid">

    <app-content-headline
        i18n-pagetitle="@@nominationHeader"
        pagetitle="Personen nominieren"
        [showSwitcher]="false"
    >
        <div
            before
            class="no-print"
        ><a
                class="text-primary text-decoration-none d-flex align-middle"
                style="font-family: sans-serif;"
                [routerLink]="'/my-feedbacks'"
                [queryParams]="{selected: this.feedback_id}"
                i18n="@@reportingBackBtn"
            ><i class="ri-arrow-left-line"></i> Feedback-Übersicht</a></div>
    </app-content-headline>
    <app-new-and-help-row
        #newhelpRow
        [showNewButton]="false"
        [showImportButton]="true"
        importBtnLabel="Importieren"
        i18n-importBtnLabel="@@globalImport"
        [importFormTpl]="importTpl"
        i18n="@@nominationCol1"
        topic="nomination"
    >
    </app-new-and-help-row>
    <div class="content-grid-content overflow-visible">
        <app-card *ngIf="this.feedbackInfo">
            <ul class="nav nav-tabs">
                <li
                    class="nav-item"
                    *ngFor="let perspective of feedbackInfo?.survey.perspectives; let i = index"
                >
                    <a
                        (click)="this.selectedPerspectiveTab = i"
                        [ngClass]="{'active': this.selectedPerspectiveTab == i}"
                        style="cursor: pointer"
                        class="nav-link  "
                        aria-current="page"
                    >
                        <span
                            class="badge "
                            [ngClass]="{
                                'bg-primary': nominationCount(perspective.id) >= perspectiveIdToMinCount.get(perspective.id),
                                'bg-secondary': nominationCount(perspective.id) < perspectiveIdToMinCount.get(perspective.id)
                            }"
                        >
                            {{nominationCount(perspective.id)}}
                        </span>
                        {{this.loginService.translate(perspective.name)}}
                        <small>
                            (min {{perspectiveIdToMinCount.get(perspective.id)}}
                            max {{perspectiveIdToMaxCount.get(perspective.id)}})
                        </small></a>
                </li>
            </ul>
            <div class="div mt-4">
                <app-feedback-cta-txt
                    [showCtaButtons]="true"
                    [feedback]="feedbackInfo.feedback"
                    #ctaText
                ></app-feedback-cta-txt>
            </div>
            <ng-container *ngFor="let perspective of feedbackInfo?.survey.perspectives; let i = index">
                <ng-container *ngIf="this.selectedPerspectiveTab == i">
                    <div
                        class="callout callout-primary"
                        style="background-color: #004FA021;"
                    >
                        <div
                            class="row align-items-center"
                            *ngIf="!isInAddMode(perspective.id)"
                        >
                            <div
                                class="col-auto"
                                *ngIf="perspective.type === 'unbound' || perspective.type === 'both'"
                            >
                                <div
                                    class="btn btn-cta"
                                    [ngClass]="{'disabled': maximumCountReached(perspective.id) || feedbackInfo.feedback.status == 'ended'}"
                                    (click)="!maximumCountReached(perspective.id) && addEmptyUser(perspective.id)"
                                    i18n="@@nominationAddLine"
                                >Nominierung hinzufügen</div>


                            </div>
                            <div
                                class="col-auto"
                                *ngIf="perspective.type === 'both'"
                                i18n="@@globalOr"
                            >oder </div>
                            <div
                                class="col-6"
                                *ngIf="perspective.type === 'bound' || perspective.type === 'both'"
                            >
                                <ng-select
                                    class="w-100"
                                    i18n-placeholder="@@searchPerson"
                                    placeholder="Person suchen"
                                    [items]="(nominationService.userSearchResult$ | async)"
                                    [minTermLength]="3"
                                    [readonly]="maximumCountReached(perspective.id) || feedbackInfo.feedback.status == 'ended'"
                                    [clearable]="false"
                                    [loading]="userSearchLoading"
                                    i18n-typeToSearchText="@@nominationSearchPlaceholder"
                                    typeToSearchText="Bitte geben Sie mindestens ein Wort ein"
                                    [typeahead]="userSearchInput$"
                                    (change)="confirmUserSelected(confirmAddNominee, $event, perspective.id)"
                                >
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                    >
                                        {{item.firstname}} {{item.lastname}}
                                        ({{item.email}})
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <ng-container *ngIf="isInAddMode(perspective.id)">

                            <div
                                class="row mb-5 mb-xl-2"
                                *ngVar="perspectiveToUserCreationData.get(perspective.id); let user"
                            >
                                <div class="col-12 col-lg-6 col-xl mb-2 mb-xl-0">
                                    <input
                                        placeholder="Vorname"
                                        i18n-placeholder="@@globalFirstname"
                                        class="form-control bg-white"
                                        [disabled]="user._id"
                                        [(ngModel)]="user.firstname"
                                        (blur)="this.touchArray.push('firstname')"
                                        [ngClass]="{'is-valid': this.touchArray.includes('firstname') && stringValidation(user.firstname) && !user._id, 
                                                        'is-invalid': this.touchArray.includes('firstname') && !stringValidation(user.firstname) && !user._id}"
                                    >
                                    <div
                                        class="invalid-feedback"
                                        i18n="@@globalFieldNeedsToBeFilled"
                                    >
                                        Feld darf nicht leer sein!
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 col-xl mb-2 mb-xl-0">
                                    <input
                                        (blur)="this.touchArray.push('lastname')"
                                        placeholder="Nachname"
                                        i18n-placeholder="@@globalLastname"
                                        class="form-control bg-white"
                                        [disabled]="user._id"
                                        [(ngModel)]="user.lastname"
                                        [ngClass]="{
                                                            'is-valid': this.touchArray.includes('lastname')&& stringValidation(user.lastname) && !user._id, 
                                                            'is-invalid': this.touchArray.includes('lastname')&& !stringValidation(user.lastname) && !user._id}"
                                    >
                                    <div
                                        class="invalid-feedback"
                                        i18n="@@globalFieldNeedsToBeFilled"
                                    >
                                        Feld darf nicht leer sein!
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 col-xl-4 mb-2 mb-xl-0">
                                    <input
                                        placeholder="E-Mail"
                                        i18n-placeholder="@@globalMail"
                                        class="form-control bg-white"
                                        type="email"
                                        [disabled]="user._id"
                                        [(ngModel)]="user.email"
                                        (blur)="this.touchArray.push('email')"
                                        [ngClass]="{'is-valid':this.touchArray.includes('email')&& emailValidation(user.email) && !user._id,
                                                         'is-invalid':this.touchArray.includes('email') && !emailValidation(user.email) && !user._id}"
                                        email="true"
                                    >
                                    <div
                                        class="invalid-feedback"
                                        i18n="@@globalFieldNeedsToBeFilled"
                                        *ngIf="!stringValidation(user.email)"
                                    >
                                        Feld darf nicht leer sein!
                                    </div>
                                    <div
                                        class="invalid-feedback"
                                        i18n="@@notAValidEmail"
                                        *ngIf="!emailValidation(user.email) && stringValidation(user.email)"
                                    >
                                        Keine gültige E-Mail-Adresse!
                                    </div>
                                </div>
                                <div class="col col-xl mb-2 mb-xl-0">
                                    <ng-select
                                        [searchable]="false"
                                        [clearable]="false"
                                        [disabled]="user._id"
                                        [(ngModel)]="user.language"
                                        placeholder="Sprache"
                                        i18n-placeholder="@@globalLanguage"
                                    >
                                        <ng-option value="de"><img
                                                src="assets/img/de.svg"
                                                width="20px"
                                                height="20px"
                                                alt="language"
                                            />
                                            &nbsp;<span i18n="@@globalDE">Deutsch</span>
                                        </ng-option>
                                        <ng-option value="en">
                                            <img
                                                src="assets/img/en.svg"
                                                width="20px"
                                                height="20px"
                                                alt="language"
                                            />
                                            &nbsp;<span i18n="@@globalEN">Englisch</span>
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-auto mb-2 mb-xl-0 text-end">
                                    <ng-container *ngIf="!user._id">
                                        <button
                                            class="btn btn-sm btn-cta"
                                            (click)="userIsFilled(user) && confirmUserSelected(confirmAddNominee, user, perspective.id, true)"
                                            id="saveNomination"
                                        ><i
                                                class="ri-check-fill"
                                                style="position: relative; top: 2px"
                                            ></i>
                                        </button>
                                        <button
                                            class="btn btn-sm btn-sm btn-outline-primary "
                                            (click)="removeEmptyUser(perspective.id)"
                                        >
                                            <i
                                                class="ri-close-fill"
                                                style="position: relative; top: 2px"
                                            ></i>

                                        </button>
                                    </ng-container>
                                    <button
                                        *ngIf="user._id && feedbackInfo.feedback.status === 'nomination'"
                                        class="btn btn-sm btn-danger"
                                        (click)="removeUser(user, perspective.id)"
                                    ><i
                                            class="ri-delete-bin-2-line"
                                            style="position: relative; top: 2px"
                                        ></i>
                                    </button>
                                </div>

                            </div>
                        </ng-container>
                    </div>
                    <!-- LISTE -->
                    <div class="row">
                        <div
                            class="col-12 col-lg-6 col-xl-4"
                            *ngFor="let user of perspectiveIdToNominatedUsers.get(perspective.id)"
                        >
                            <div
                                class="card p-2 mt-2 mb-1"
                                *ngIf="user._id"
                            >
                                <div class="row mt-2">
                                    <div class="col-auto">
                                        <ngx-avatars [name]="user.firstname +' '+user.lastname"></ngx-avatars>
                                    </div>
                                    <div class="col">
                                        {{user.firstname}} {{user.lastname}}<br>
                                        <small class="text-muted">{{user.email}}</small>
                                    </div>
                                    <div class="col-auto">


                                        <div
                                            *ngIf="feedbackInfo.feedback.status !== 'ended'"
                                            class="btn-group"
                                            dropdown
                                            #dropdown="bs-dropdown"
                                            placement="left"
                                        >
                                            <button
                                                dropdownToggle
                                                class="btn btn-ghost-white btn-sm"
                                                aria-controls="dropdown-basic"
                                                placement="left"
                                            >
                                                <i class="ri-more-fill"></i>
                                            </button>
                                            <ul
                                                *dropdownMenu
                                                placement="left"
                                                class="dropdown-menu"
                                                aria-labelledby="button-basic"
                                            >
                                                <li
                                                    role="menuitem"
                                                    *ngIf="user._id"
                                                >
                                                    <a
                                                        i18n="@@nominationSendReminder"
                                                        class="dropdown-item"
                                                        (click)="sendSingleReminder(user._id)"
                                                        [ngClass]="{'disabled': this.feedbackInfo.feedback.status != 'running'}"
                                                    >Erinnerung
                                                        senden</a>
                                                </li>
                                                <li
                                                    role="menuitem"
                                                    *ngIf="user._id"
                                                ><a
                                                        class="dropdown-item cursor-pointer"
                                                        i18n="@@globalRemove"
                                                        (click)="removeUser(user, perspective.id)"
                                                    >Entfernen</a>
                                                </li>


                                            </ul>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">





                            <div class="col">



                            </div>

                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </app-card>
    </div>
</div>



<!-- TEMPLATES -->
<ng-template #confirmAddNominee>
    <div class="modal-header">
        <h4
            class="modal-title pull-left"
            i18n="@@confirmAddNomineeTitle"
        >Nominierung hinzufügen</h4>
        <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="this.modalRefconfirmAddNominee?.hide()"
        ><span>&times;</span>
        </button>
    </div>
    <div
        class="modal-body"
        i18n="@@confirmAddNomineeText"
    >
        {{this.selectedUser.user.firstname}}
        {{this.selectedUser.user.lastname}} zur Nominierung hinzufügen und direkt per E-Mail
        ({{this.selectedUser.user.email}}) einladen.
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            i18n="@@globalCancel"
            (click)="this.modalRefconfirmAddNominee?.hide()"
        >Abbrechen</button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="this.addUserIsConfirmed()"
            i18n="@@confirmAddNominee"
        >Nominieren & Einladen</button>
    </div>
</ng-template>

<ng-template #importTpl>
    <app-nomination-import
        *ngIf="this.feedbackInfo"
        [feedbackInfo]="this.feedbackInfo"
        [redirectAfterImport]="'my-feedbacks/' + this.feedbackInfo.feedback._id + '/nomination'"
    ></app-nomination-import>
</ng-template>