import { DatasupplySort, RagDatasupplyFrontendService } from '@reflact/rag-datasupply';


export type TableSortConf<T> = {
    customSortFunctions: {
        [key: string]: (a: T, b: T) => number
    }

}
export class TableSortUtil<T> {
    constructor(public service: RagDatasupplyFrontendService<T>, public conf: TableSortConf<T> = null) {

    }


    public sort(e: DatasupplySort) {
        if (this.conf != null && this.conf.customSortFunctions[e.sortField] != null) {
            if (e.sortDirection === 'asc') {
                this.service.rawData.sort((a, b) => this.conf.customSortFunctions[e.sortField](a, b));
            } else {
                this.service.rawData.sort((b, a) => this.conf.customSortFunctions[e.sortField](a, b));
            }
        }

        if (e.sortDirection === 'asc') {
            this.service.rawData.sort((a, b) => ('' + a[e.sortField]).localeCompare(('' + b[e.sortField])));
        } else {
            this.service.rawData.sort((b, a) => ('' + a[e.sortField]).localeCompare(('' + b[e.sortField])));
        }
        this.service.fromArray(this.service.rawData);
    }
}