import { Component } from '@angular/core';

@Component({
  selector: 'app-reporting-force-group',
  templateUrl: './reporting-force-group.component.html',
  styleUrls: []
})
export class ReportingForceGroupComponent {
  public printMode: boolean;
  constructor() {
    this.printMode = localStorage.getItem("openAll") == "true";
  }
}
