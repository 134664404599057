import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../views/login/login.service';

@Injectable()
export class AuthGuard  {


  constructor(public loginService: LoginService, public router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.loginService.isLoggedIn()) {
      return true;
    } else {
      const loginWorked: boolean = await this.loginService.loginByToken();
      if (loginWorked) {
        return true;
      }
    }
    this.router.navigate(['login']);
    return false;
  }
}
