import { Component, OnInit } from '@angular/core';
import { LoginHistoryEntry, PrmUserType } from '@reflact/prmfeedback';
import { DatasupplyRequest, RagDatasupplyFrontendService, RagDatasupplyLocalBackendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TableSortUtil } from '../../shared/TableSortUtil';
import { MainService } from '../../shared/main.service';
import { LoginService } from '../login/login.service';

@Component({
  templateUrl: './login-history.component.html',
  styleUrls: []
})
export class LoginHistoryComponent implements OnInit {
  public type: PrmUserType | 'all' = 'all';
  public noLoginHistoryText: string = $localize`:@@noLoginHistoryText:Keine Login-Historie vorhanden.`;
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = [
    {
      fieldName: 'userName', translated: $localize`:@@loginHistoryUserName:Name des Nutzers`
    },
    {
      fieldName: 'userType', translated: $localize`:@@loginHistoryUserType:Rolle des Nutzers`
    },
    {
      fieldName: 'adminUserName', translated: $localize`:@@loginHistoryAdminUserName:Name des Administrators`
    },
    {
      fieldName: 'loginDate', translated: $localize`:@@loginHistoryLoginDate:Login-Datum`
    },
    {
      fieldName: 'loginMethod', translated: $localize`:@@loginHistoryLoginMethod:Login-Methode`
    }
  ];


  public tableSort: TableSortUtil<LoginHistoryEntry>;

  public loginHistory: LoginHistoryEntry[] = []
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["userName", "userType", "adminUserName", "loginDate", "loginMethod"]
  }
  constructor(
    private mainService: MainService,
    private loginService: LoginService,
    public loginHistoryService: RagDatasupplyFrontendService<LoginHistoryEntry>,
    public backendService: RagDatasupplyLocalBackendService<LoginHistoryEntry>
  ) {
    dayjs.extend(relativeTime);
    this.backendService.initialRequest(this.standardRequest)
  }

  async ngOnInit(): Promise<void> {
    this.backendService.loadData = () => {
      return new Promise<LoginHistoryEntry[]>((resolve, reject) => {
        if (this.loginHistory) {
          resolve([...this.loginHistory])
        } else {
          reject([])
        }
      })
    }
    this.tableSort = new TableSortUtil(this.loginHistoryService)
    this.backendService.results$.subscribe(results => {
      this.loginHistoryService.fromArray(results);
    })
    const loginHistory = await this.loginService.getLoginHistory();
    loginHistory.forEach(historyEntry => {
      historyEntry.loginDate = dayjs(historyEntry.loginDate).format('DD.MM.YYYY HH:mm')
        + ', '
        + dayjs(historyEntry.loginDate).fromNow();
      if (historyEntry.userType === 'hr') { historyEntry.userType = $localize`:@@globalHR:HR-Administrator*in`; }
      if (historyEntry.userType === 'prmadmin') { historyEntry.userType = $localize`:@@globalPRM:PRM-Administrator*in`; }
      if (historyEntry.userType === 'feedbackgiver') { historyEntry.userType = $localize`:@@globalFG:Feedbackgeber*in`; }
      if (historyEntry.userType === 'feedbackreceiver') { historyEntry.userType = $localize`:@@globalFN:Feedbacknehmer*in`; }

      if (historyEntry.adminUserName === 'not set') { historyEntry.adminUserName = '---'; }
      if (historyEntry.loginMethod === 'login') { historyEntry.loginMethod = $localize`:@@standardLogin:Standard-Login`; }
      if (historyEntry.loginMethod === 'simulate_user') { historyEntry.loginMethod = $localize`:@@simulateUser:Nutzer-Simulation`; }
    });
    this.loginHistory = loginHistory

    this.backendService.loadInitialRequest()

  }

  public dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest)
  }
}
