import { ColorService } from '../../shared/color.service';
import { ScaleEntry } from './../../shared/feedback-report.service';

import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-scale-renderer',
  templateUrl: './scale-renderer.component.html',
  styleUrls: ['./scale-renderer.component.scss']
})
export class ScaleRendererComponent {
  @Input() public class = "";
  @Input() public scaleMin = 0;
  @Input() public scaleMax = 0;
  @Input() public meanPoint = 0;
  @Input() public meanColor: string = "#fff";
  @Input() public feMin = 0;
  @Input() public feMax = 0;
  @Input() public scale: ScaleEntry[] = [];
  @Input() public sePoint = 0;
  public debug = true;

  constructor(public colorService: ColorService) { }

  public feLineWidth() {
    let diff = this.pointToPos(this.feMin) - this.pointToPos(this.feMax);
    return Math.abs(diff);
  }
  public meanLineWidth() {
    let diff = this.pointToPos(this.meanPoint) - this.pointToPos(this.sePoint);
    return Math.abs(diff);
  }
  public mathMin(a, b) {
    return Math.min(a, b);
  }
  public pointToPos(point: number) {
    return this.transformLinear(this.scaleMin - 0.5, this.scaleMax + 0.5, 0, 100, point);
  }

  transformLinear(minInput: number, maxInput: number, minOutput: number, maxOutput: number, inputValue: number) {
    const inputBetween0And1 = (inputValue - minInput) / (maxInput - minInput)
    return minOutput + (inputBetween0And1 * (maxOutput - minOutput))
  }
}
