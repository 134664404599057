import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reporting-force-perspective',
  templateUrl: './reporting-force-perspective.component.html',
  styleUrls: []
})
export class ReportingForcePerspectiveComponent {
  @Input() public evenOrOdd = '';
  @Input() public isSub = false;



}
