<div class="search-box ps-0 w-auto d-flex align-items-center pt-1">
  <i
    class="ri-search-line text-muted"
    style="font-size: 1.5rem;"
  ></i>
  <input
    class="search-input text-muted w-50"
    [placeholder]="this.placeholder"
    (input)="emitSearch($event)"
  >
</div>