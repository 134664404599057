import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserGroup } from '@reflact/prmfeedback';
import { UserGroupsService } from './../../shared/user-groups.service';

@Injectable({
  providedIn: 'root'
})
export class UsergroupsResolver  {
  constructor(private userGroupsService: UserGroupsService) { }
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UserGroup[]> {
    return await this.userGroupsService.getUserGroups();
  }
}
