import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NominationCreationData, PrmUser } from '@reflact/prmfeedback';
import { mapEnsureInitialVal, removeFromArray } from '@reflact/tsutil';
import * as EmailValidator from 'email-validator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { FeedbackCtaTxtComponent } from '../../shared/feedback-cta-txt/feedback-cta-txt.component';
import { FeedbackInfo, MainService } from '../../shared/main.service';
import { NominationService } from '../../shared/nomination.service';
import { LoginService } from './../login/login.service';

@Component({
  selector: 'app-nomination',
  templateUrl: './nomination.component.html',
  styleUrls: []
})
export class NominationComponent implements OnInit, OnDestroy {
  public modalRefconfirmAddNominee: BsModalRef;
  public selectedUser;
  public selectedPerspectiveTab = 0;
  private subscriptions: Subscription[] = [];
  public touchArray = [];
  public feedbackInfo: FeedbackInfo;
  public userSearchLoading: boolean;
  public nominationService: NominationService;
  public userSearchInput$ = new Subject<string>();
  public selectedPerspectiveName = '';
  public perspectiveIdToNominatedUsers: Map<string, PrmUser[]> = new Map();
  public perspectiveIdToMinCount: Map<string, number> = new Map();
  public perspectiveIdToMaxCount: Map<string, number> = new Map();
  public changeNumber = 0;
  @ViewChild('ctaText') public ctaText: FeedbackCtaTxtComponent;

  public perspectiveToUserCreationData: Map<string, Partial<PrmUser>> = new Map();;
  public feedback_id: string;
  constructor(private aRoute: ActivatedRoute, public loginService: LoginService, private mainService: MainService, private router: Router, public toaster: ToastrService, public modalService: BsModalService) {
    this.nominationService = this.mainService.nominationService;
    aRoute.params.subscribe((r) => {
      this.selectedPerspectiveName = r.perspective;
    });

    this.feedback_id = this.aRoute.snapshot.paramMap.get('feedback_id');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.feedbackInfo = this.mainService.getFeedbackInfo(this.feedback_id);
        if (!this.feedbackInfo) {
          this.router.navigate(['/', 'my-feedbacks']);
          return;
        }



        this.feedbackInfo.survey.perspectives.forEach(p => {
          mapEnsureInitialVal(this.perspectiveIdToNominatedUsers, p.id, []);
          this.perspectiveIdToMinCount.set(p.id, p.min);
          this.perspectiveIdToMaxCount.set(p.id, p.max);
        });
        this.feedbackInfo.nominations.forEach(n => {
          mapEnsureInitialVal(this.perspectiveIdToNominatedUsers, n.perspective_id, []);
          this.perspectiveIdToNominatedUsers.get(n.perspective_id).push(this.feedbackUser(n.feedbackgiver_id));
        });
      }
    }));

    this.nominationService.nominamtionCreated$.subscribe(nomination => {
      this.perspectiveIdToNominatedUsers.get(nomination.perspective_id).push(this.feedbackUser(nomination.feedbackgiver_id));
    });

    this.nominationService.nominationDeleted$.subscribe(nomination => {
      const map = this.perspectiveIdToNominatedUsers.get(nomination.perspective_id);
      removeFromArray(map, (u => u._id === nomination.feedbackgiver_id));
    });

    this.userSearchInput$.subscribe(search => {
      if (search == null || search === '') {
        this.nominationService.userSearchResult$.next([]);
        return;
      }
      this.nominationService.searchUserForNomination(search, this.feedbackInfo.feedback._id);
    });
  }

  emailValidation(email: string): boolean {
    return EmailValidator.validate(email);
  }
  stringValidation(text: string): boolean {
    return text != null && text !== '';
  }


  addUserIsConfirmed() {
    this.modalRefconfirmAddNominee.hide();
    this.userSelected(this.selectedUser.user, this.selectedUser.perspectiveId, this.selectedUser.newUser);
    this.ctaText.update();
  }

  confirmUserSelected(template: TemplateRef<any>, user: PrmUser, perspectiveId: string, newUser?: boolean) {
    if (this.feedbackInfo.feedback.status === 'running') {
      this.selectedUser = { user, perspectiveId, newUser };
      this.modalRefconfirmAddNominee = this.modalService.show(template);
    } else {
      this.userSelected(user, perspectiveId, newUser);
    }
  }

  async userSelected(user: PrmUser, perspectiveId: string, newUser?: boolean) {
    this.changeNumber++;
    if (!newUser && this.maximumCountReached(perspectiveId)) { return; }
    if (!EmailValidator.validate(user.email)) {
      this.toaster.error($localize`:@@globalInvalidMail:Ungültige E-Mail` + ': ' + user.email);
      return;
    }

    const nomination: NominationCreationData = {
      feedback_id: this.feedbackInfo.feedback._id,
      customer_id: this.feedbackInfo.feedback.customer_id,
      perspective_id: perspectiveId,
      feedbackgiver: user
    };
    const result = await this.nominationService.createNomination(nomination);
    // duplicated user fall:
    if (result == null) {
      this.toaster.error($localize`:@@globalDuplicateUser:Dieser Nutzer existiert bereits.`);
      return;
    }
    this.perspectiveToUserCreationData.delete(perspectiveId);
    this.ctaText.update();
  }

  addEmptyUser(perspectiveId: string) {
    this.perspectiveToUserCreationData.set(perspectiveId, {
      firstname: undefined,
      lastname: undefined,
      email: undefined
    })
  }

  removeEmptyUser(perspectiveId: string) {
    this.perspectiveToUserCreationData.delete(perspectiveId)
  }

  removeUser(user: PrmUser, perspectiveId: string) {
    this.changeNumber++;
    this.nominationService.deleteNomination(this.feedbackInfo.nominations.find(n => n.feedbackgiver_id === user._id)._id, this.feedbackInfo.feedback._id);
    this.ctaText.update();
  }

  feedbackUser(id: string) {
    return this.mainService.userService.userMap.get(id);
  }

  isInAddMode(perspective_id: string) {
    return this.perspectiveToUserCreationData.has(perspective_id);

  }

  userIsFilled(user: Partial<PrmUser>) {
    if (user.email === '' || !user.email) { return false; }
    if (user.firstname === '' || !user.firstname) { return false; }
    if (user.lastname === '' || !user.lastname) { return false; }
    if (user.language === '' || !user.language) { return false; }
    return true;
  }

  maximumCountReached(perspectiveId: string): boolean {
    return this.perspectiveIdToNominatedUsers.get(perspectiveId).length >= this.perspectiveIdToMaxCount.get(perspectiveId);
  }

  minimumCountReached(perspectiveId: string): boolean {
    return this.perspectiveIdToNominatedUsers.get(perspectiveId).filter(n => n.firstname && n.lastname && n.email && n._id).length >= this.perspectiveIdToMinCount.get(perspectiveId);
  }
  nominationCount(perspectiveId: string) {
    if (!this.perspectiveIdToNominatedUsers.get(perspectiveId)) {
      return 0;
    }
    return this.perspectiveIdToNominatedUsers.get(perspectiveId).filter(n => n && n.firstname && n.lastname && n.email && n._id).length;
  }



  findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array)) {
        return l;
      }
    }
    return -1;
  }
  async sendSingleReminder(userId: string) {
    await this.mainService.feedbackService.sendSingleReminder(this.feedbackInfo.feedback, userId);
    this.toaster.success($localize`:@@nominationSendSingleReminder:Erinnerung gesendet!`);
  }
  hasAnswered(userId: string): boolean {
    if (this.feedbackInfo.feedback.status !== 'running') {
      return false;
    }
    return this.feedbackInfo.nominations.some(n => n.feedbackgiver_id === userId && n.feResultUpdatedDate != null);
  }
}
