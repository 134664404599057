import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as MM from '@reflact/mail-manager';
import { MailManagerService } from '@reflact/mail-manager';
import { TemplateItem } from '@reflact/mailmanager-types';
import { ToastrService } from 'ngx-toastr';
import { MainService } from '../../shared/main.service';
import { LoginService } from './../login/login.service';


@Component({
  selector: 'app-mail-manager',
  templateUrl: './mail-manager.component.html',
  styleUrls: [],
  providers: [MailManagerService]
})
export class MailManagerComponent implements OnInit {
  @ViewChild("mailmanager") mailManager: MM.MailManagerComponent;
  public buttons = [$localize`:@@mailmanOverview:Übersicht`];
  public adminView: boolean = false;
  public mandant: string = 'default';
  public survey: string = '';
  // so gelöst, weil i18n das im html nicht checkt
  public nonAdminPageTitle: string = $localize`:@@mailmanNonSurveyPageTitle:E-Mailvorlagen`
  constructor(
    public loginService: LoginService,
    public mailManagerService: MailManagerService,
    public toastrService: ToastrService,
    private aRoute: ActivatedRoute,
    private mainService: MainService) {
    this.adminView = this.aRoute.snapshot.data.adminView;
  }

  ngOnInit(): void {
    this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.setMandant(this.aRoute.snapshot.paramMap.get('survey_id'));
      }
    });
    this.mailManagerService.templateError.subscribe(error => {
      this.toastrService.error(error.message);
    });

    this.mailManagerService.templateEdited.subscribe((templateItem: TemplateItem) => {
      this.toastrService.success($localize`:@@MailTemplateSaved:E-Mail Vorlage erfolgreich gespeichert`);
    });

    this.mailManagerService.templateDummySend.subscribe((templateDummySend: boolean) => {
      if (templateDummySend) {
        this.toastrService.success($localize`:@@MailTemplateDummySend:E-Mail Vorlage erfolgreich verschickt`);
      } else {
        this.toastrService.error($localize`:@@MailTemplateDummyNotSend:E-Mail Vorlage konnte nicht verschickt werden`);
      }
    });

    this.mailManagerService.templateReverted.subscribe((templateItem: TemplateItem) => {
      this.toastrService.success($localize`:@@MailTemplateReverted:E-Mail Vorlage erfolgreich zurückgesetzt`);
    });
  }

  public dsSearch(search: string) {
    let req = this.mailManager.backendService.getIninitialRequest();
    req.search = search;
    this.mailManager.backendService.load(req)
  }

  private setMandant(surveyId: string) {
    if (!this.adminView) {
      this.mandant = this.loginService.loggedInUser.customer_id;
      if (surveyId) {
        this.survey = this.mainService.customerService.surveyMap.get(surveyId)?.name;
        this.nonAdminPageTitle = this.survey + ': ' + $localize`:@@mailmanNonSurveyPageTitle:E-Mailvorlagen`;
        this.mandant += '_' + surveyId;
      }
    }
  }

}
