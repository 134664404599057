<div class="row align-items-center flex-nowrap">
  <div class="col d-none d-lg-flex ps-2 pe-0 d-print-block">{{name}}</div>
  <div class="col col-lg-auto text-center  pe-0 ps-0">
    <span
      *ngIf="value !== 0"
      class="badge text-center"
      style="width: 33px"
      [ngStyle]="{'background-color':color}"
    >{{value| number: '1.2-2'}}</span>
    <span
      *ngIf="value === 0"
      class="badge text-center"
      style="width: 33px"
      [ngStyle]="{'background-color':color}"
    >-</span>
  </div>
</div>