<div
  class="row "
  style="min-width: 400px;"
>
  <div class="col  ">

    <button
      type="button"
      class="btn btn-outline-primary  position-relative  btn-sm "
      style="border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;border-bottom: 0px;"
      *ngFor="let type of buttonTypes"
      [ngClass]="{'btn-primary text-white': selectedFilter == type}"
      (click)="selectedFilter = type"
    >
      <i
        class="nav-link-icon ri-file-text-line me-1"
        [ngClass]="getItemClassObject(type)"
      ></i>
      <ng-container *ngIf="showNumbersInTabs">
        <span
          class="position-absolute  p-1 bg-danger border border-light rounded-circle"
          style="font-size: 0px;right: 4px"
          [ngClass]="{'d-none ': !itemOfThisTypeSelected(type)}"
        >
        </span>
      </ng-container>
      <ng-container *ngIf="!showNumbersInTabs">{{itemsOfThisTypeCount(type)}}</ng-container>
    </button>
  </div>
  <div
    class="col-auto"
    *ngIf="checkedItems().length > 0"
  >
    <button
      type="button"
      class="btn btn-outline-primary  position-relative  btn-sm "
      style="border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;border-bottom: 0px;"
      [ngClass]="{'btn-primary text-white': selectedFilter == 'none'}"
      (click)="selectedFilter = 'none'"
    >
      <i
        class="nav-link-icon ri-file-text-line me-1"
        [ngClass]="getItemClassObject('none')"
      ></i>
      <ng-container *ngIf="showNumbersInTabs">
        <span
          class="position-absolute  p-1 bg-danger border border-light rounded-circle"
          style="font-size: 0px;right: 4px"
          [ngClass]="{'d-none ': !itemOfThisTypeSelected('none')}"
        >

        </span>
      </ng-container>
      <ng-container *ngIf="!showNumbersInTabs"> {{this.checkedItems().length}} </ng-container>
    </button>
  </div>
</div>
<div
  class="col"
  style="border-top:1px solid #004FA0"
>
  <div
    class="list-group"
    style="border: 1px solid #D8DBE0;border-top-right-radius: 0px;border-top-left-radius: 0px;border-bottom-right-radius: 0px;border-bottom-left-radius: 0px;border-bottom-width: 0px;border-top:0px"
  >
    <label class="list-group-item  "> <input
        [placeholder]="this.translatedFilter.get(selectedFilter) "
        type="text"
        class="form-control"
        [(ngModel)]="this.search"
      >
    </label>
  </div>
  <div
    class="list-group"
    style="overflow: scroll;height: 400px;border:1px solid #D8DBE0; border-top-left-radius: 0px;border-top-right-radius: 0px;"
  >
    <ng-container *ngFor="let item of list">
      <label
        class="list-group-item bg-primary"
        [ngClass]="{
        'd-none':itemHiddenByFilter(item),
        'bg-primary text-white': item.checked
    }"
      >
        <input
          class="form-check-input me-1"
          type="checkbox"
          [(ngModel)]="item.checked"
        >
        <i
          class="nav-link-icon ri-file-text-line me-2"
          [ngClass]="getItemClassObject(item.type)"
        ></i><span [innerHTML]="highlighted(item.label)"></span>
      </label>
    </ng-container>

  </div>
  <div class="row mt-2">
    <div class="col">
      <label class="list-group-item ms-3 mt-3 d-inline">
        <input
          class="form-check-input me-1"
          type="checkbox"
          [ngModel]="allVisibleAreChecked()"
          (ngModelChange)="toggleAll($event)"
        >
        <span i18n="@@globalAll">Alle</span>
      </label>
    </div>
  </div>

</div>
<br><br><br><br><br><br>