import { DecimalPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { MailManagerModule } from '@reflact/mail-manager';
import { NgContenttypeIconModule, NgValidatePasswordModule } from '@reflact/ng-common-components';
import { NgxFormsModule } from '@reflact/ngx-forms';
import { RagDatasupplyModule } from '@reflact/rag-datasupply';
import { CalendarA11y, CalendarDateFormatter, CalendarEventTitleFormatter, CalendarUtils } from 'angular-calendar';
import { AvatarModule } from 'ngx-avatars';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { DefaultLayoutComponent } from './containers';
import { ScrollFadeOutDirective } from './directives/scroll-fade-out.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { PageHeaderComponent } from './page-header/page-header.component';
import { AdminGuard } from './shared/admin.guard';
import { AuthGuard } from './shared/auth.guard';
import { CalloutComponent } from './shared/callout/callout.component';
import { CardComponent } from './shared/card/card.component';
import { ContentHeadlineComponent } from './shared/content-headline/content-headline.component';
import { CustomerGuard } from './shared/customer.guard';
import { DsSearchComponent } from './shared/ds-search/ds-search.component';
import { ExcelImportComponent } from './shared/excel-import/excel-import.component';
import { FbsGridCardComponent } from './shared/fbs-grid-card/fbs-grid-card.component';
import { FeedbackCtaBtnComponent } from './shared/feedback-cta-btn/feedback-cta-btn.component';
import { FeedbackCtaTxtComponent } from './shared/feedback-cta-txt/feedback-cta-txt.component';
import { FeedbackService } from './shared/feedback.service';
import { FeedbackreceiverGuard } from './shared/feedbackreceiver.guard';
import { MainService } from './shared/main.service';
import { NewAndHelpRowComponent } from './shared/new-and-help-row/new-and-help-row.component';
import { NgVarDirective } from './shared/ng-var.directive';
import { NominationImportComponent } from './shared/nomination-import/nomination-import.component';
import { PrmUserDisplayComponent } from './shared/prm-user-display/prm-user-display.component';
import { SafePipe } from './shared/safe.pipe';
import { TableSwitcherComponent } from './shared/table-switcher/table-switcher.component';
import { TruncatePipe } from './shared/truncate.pipe';
import { UserSelectionFilterPipe } from './shared/userselection-filter.pipe';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import { AdmingroupsComponent } from './views/admingroups/admingroups.component';
import { BucketlistComponent } from './views/admingroups/bucketselect/bucketlist/bucketlist.component';
import { BucketselectComponent } from './views/admingroups/bucketselect/bucketselect.component';
import { AdminsComponent } from './views/admins/admins.component';
import { CoachesComponent } from './views/coaches/coaches.component';
import { CustomersComponent } from './views/customers/customers.component';
import { CustomgroupComponent } from './views/customgroup/customgroup.component';
import { CustomgroupResolver } from './views/customgroup/customgroup.resolver';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DocumentPreviewComponent } from './views/document-preview/document-preview.component';
import { DocumentComponent } from './views/document/document.component';
import { ErrorComponent } from './views/error/error.component';
import { FeedbackInvitationsComponent } from './views/feedback-invitations/feedback-invitations.component';
import { FeedbacksComponent } from './views/feedbacks/feedbacks.component';
import { HelpAreaComponent } from './views/help-area/help-area.component';
import { ImportComponent } from './views/import/import.component';
import { LoginHistoryComponent } from './views/login-history/login-history.component';
import { LoginComponent } from './views/login/login.component';
import { MailManagerComponent } from './views/mail-manager/mail-manager.component';
import { MyDocumentsComponent } from './views/my-documents/my-documents.component';
import { MyFeedbacksComponent } from './views/my-feedbacks/my-feedbacks.component';
import { MyUserComponent } from './views/my-user/my-user.component';
import { NominationComponent } from './views/nomination/nomination.component';
import { PageUserManagementComponent } from './views/page-user-management/page-user-management.component';
import { PasswordRecoveryComponent } from './views/password-recovery/password-recovery.component';
import { PerspectiveComponent } from './views/perspective/perspective.component';
import { SubsidiariesComponent } from './views/subsidiaries/subsidiaries.component';
import { SurveysComponent } from './views/surveys/surveys.component';
import { UsergroupsComponent } from './views/usergroups/usergroups.component';
import { UsersComponent } from './views/users/users.component';
import { AnkerColComponent } from './views_reporting/anker-col/anker-col.component';
import { PerspectiveNameDisplayComponent } from './views_reporting/perspective-name-display/perspective-name-display.component';
import { ReportingForceGroupComponent } from './views_reporting/reporting-force-group/reporting-force-group.component';
import { ReportingForcePerspectiveComponent } from './views_reporting/reporting-force-perspective/reporting-force-perspective.component';
import { ReportingForceScaleComponent } from './views_reporting/reporting-force-scale/reporting-force-scale.component';
import { ReportingGroupComponent } from './views_reporting/reporting-group/reporting-group.component';
import { ReportingSeHeaderComponent } from './views_reporting/reporting-se-header/reporting-se-header.component';
import { BenchmarkFilterModalComponent } from './views_reporting/reporting/benchmark-filter-modal/benchmark-filter-modal.component';
import { FactCardComponent } from './views_reporting/reporting/fact-card/fact-card.component';
import { ReportingHeaderWithScalaComponent } from './views_reporting/reporting/reporting-header-with-scala/reporting-header-with-scala.component';
import { ReportingComponent } from './views_reporting/reporting/reporting.component';
import { TextCommentsGroupComponent } from './views_reporting/reporting/text-comments-group/text-comments-group.component';
import { ScaleRendererComponent } from './views_reporting/scale-renderer/scale-renderer.component';
import { SharedReportingComponent } from './views_reporting/shared-reporting/shared-reporting.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  imports: [
    AvatarModule,
    TooltipModule,
    RagDatasupplyModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    NgxPopperjsModule.forRoot({ placement: NgxPopperjsPlacements.TOP, trigger: NgxPopperjsTriggers.hover }),
    ToastrModule.forRoot({ positionClass: 'toast-top-center', preventDuplicates: true }),
    NgSelectModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    NgValidatePasswordModule,
    NgContenttypeIconModule,
    HttpClientModule,
    MailManagerModule,
    NgxFormsModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    DashboardComponent,
    LoginComponent,
    ExcelImportComponent,
    AdminsComponent,
    PasswordRecoveryComponent,
    PageUserManagementComponent,
    MyUserComponent,
    CustomersComponent,
    SurveysComponent,
    FeedbacksComponent,
    MyFeedbacksComponent,
    FeedbackInvitationsComponent,
    ImportComponent,
    PerspectiveComponent,
    NominationComponent,
    UsersComponent,
    UserSelectionFilterPipe,
    SidebarNavComponent,
    PageHeaderComponent,
    CustomgroupComponent,
    CalloutComponent,
    CardComponent,
    HelpAreaComponent,
    MailManagerComponent,
    FeedbackCtaBtnComponent,
    DocumentComponent,
    MyDocumentsComponent,
    DocumentPreviewComponent,
    FeedbackCtaTxtComponent,
    LoginHistoryComponent,
    PrmUserDisplayComponent,
    NominationImportComponent,
    UsergroupsComponent,
    SafePipe,
    TruncatePipe,
    ReportingComponent,
    DsSearchComponent,
    TableSwitcherComponent,
    FbsGridCardComponent,
    ContentHeadlineComponent,
    NewAndHelpRowComponent,
    SubsidiariesComponent,
    ReportingGroupComponent,
    ReportingForceGroupComponent,
    ReportingForcePerspectiveComponent,
    ReportingForceScaleComponent,
    ScrollFadeOutDirective,
    PerspectiveNameDisplayComponent,
    ScaleRendererComponent,
    ReportingSeHeaderComponent,
    NgVarDirective,
    FactCardComponent,
    TextCommentsGroupComponent,
    BenchmarkFilterModalComponent,
    AnkerColComponent,
    ReportingHeaderWithScalaComponent,
    SharedReportingComponent,
    CoachesComponent,
    ErrorComponent,
    AdmingroupsComponent,
    BucketselectComponent,
    BucketlistComponent

  ],
  exports: [TableSwitcherComponent, NgVarDirective],
  providers: [
    MainService,
    CalendarA11y,
    FeedbackService,
    CalendarEventTitleFormatter,
    DecimalPipe,
    CalendarDateFormatter,
    CalendarUtils,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthGuard,
    AdminGuard,
    CustomerGuard,
    CustomgroupResolver,
    FeedbackreceiverGuard,
    UserSelectionFilterPipe,
    SafePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ScrollFadeOutDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
