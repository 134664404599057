import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BenchmarkFilter } from '@reflact/prmfeedback';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BenchmarkInfoMap } from '../../../shared/feedback-report.service';

type BfSingleItem = {
  id: string,
  label: string
  value: string
  selected: boolean
}

@Component({
  selector: 'app-benchmark-filter-modal',
  templateUrl: './benchmark-filter-modal.component.html',
  styleUrls: []
})
export class BenchmarkFilterModalComponent implements OnInit {


  @Output() onFilterSubmit: EventEmitter<BenchmarkFilter[]> = new EventEmitter()

  public myRef: BsModalRef<BenchmarkFilterModalComponent>
  public benchmarkInfoMap: BenchmarkInfoMap
  public benchmarkFilters: BenchmarkFilter[]

  public items: BfSingleItem[]


  applyFilter() {
    this.onFilterSubmit.emit(this.items.filter(i => i.selected).map((i) => { return { benchmarkId: i.id, benchmarkValue: i.value } }))
    this.myRef.hide()
  }
  removeFilter() {
    this.onFilterSubmit.emit([])
    this.myRef.hide()
  }
  cancelFilter() {
    this.myRef.hide()
  }

  ngOnInit(): void {
    this.items = []
    if (this.benchmarkFilters == null) this.benchmarkFilters = []
    this.benchmarkInfoMap.forEach((biItem) => {
      biItem.values.forEach(val => {
        this.items.push({
          id: biItem.id,
          label: biItem.label,
          value: val,
          selected: this.benchmarkFilters.some(bf => bf.benchmarkId == biItem.id && bf.benchmarkValue == val)
        })
      })
    })
  }
}
