<app-page-header></app-page-header>

<lib-rag-datasupply-overlay-asside
  #overlay
  (closed)="this.urlHelper.setSelectedId(undefined)"
>
  <div
    class="p-5"
    *ngIf="overlay.selectedObject"
  >
    <rag-form
      [translateTexts]="this.translateTexts"
      [translatedLabels]="this.translatedLabelsForm"
      (delete)="this.deleteItem($event);overlay.selectedObject = undefined"
      (submit)="this.updateItem($event)"
      [formFields]="[
        { fieldName: 'name',type: 'text', label: 'Name', required: true },
        {
            fieldName: 'adminGroups', type: 'ng-multiselect-dropdown',required: this.mainService.loginService.loggedInUser.type == 'groupadmin',
            options: {
                placeholder: '...',

                enableCheckAll: true,
                handleValuesAsIds: true,
                dropdownList: this.adminGroups,
                idField: '_id',
                textField: 'name',
                unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
            }
        },
        { fieldName: '', type: 'template', options: {template: userAndSurverySelector}}
      ]"
      [data]="overlay.selectedObject"
    ></rag-form>
  </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
  <app-content-headline
    [showSwitcher]="false"
    i18n-pagetitle="@@userGroupsCardTitle"
    pagetitle="Befragungsgruppen"
    #headline
  >
  </app-content-headline>
  <app-new-and-help-row
    #newhelpRow
    [showNewButton]="true"
    newBtnLabel="Anlegen"
    i18n-newBtnLabel="@@globalAdd"
    [newFormTpl]="newFormTpl"
    topic="userGroups"
  >
  </app-new-and-help-row>

  <div class="content-grid-content overflow-visible">
    <app-card>

      <lib-rag-datasupply-table
        #table
        (sort)="this.tableSort.sort($event)"
        (onSelect)="overlay.selectedObject = table.selectedObject; this.urlHelper.setSelectedId(table.selectedObject._id)"
        [service]="this.frontendService"
        [columns]="[{ fieldName: 'name', label: 'Name'}]"
      >
        <ng-container *ngIf="table.selectedObject">

        </ng-container>
      </lib-rag-datasupply-table>
    </app-card>
  </div>
</div>

<!-- TEMPLATES -->

<ng-template #newFormTpl>
  <rag-form
    [translateTexts]="this.translateTexts"
    [translatedLabels]="this.translatedLabelsForm"
    [type]="'create'"
    [data]="{name: '', adminGroups: []}"
    [formFields]="[
      { fieldName: 'name', type: 'text', label: 'Name',  required: true },
      {
          fieldName: 'adminGroups', type: 'ng-multiselect-dropdown', required: this.mainService.loginService.loggedInUser.type == 'groupadmin',
          options: {
              placeholder: '...',
              enableCheckAll: true,
              handleValuesAsIds: true,
              dropdownList: this.adminGroups,
              idField: '_id',
              textField: 'name',
              unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
          }
      }
    ]"
    (submit)="this.createItem($event); newhelpRow.newOverlay.close();"
  ></rag-form>
</ng-template>

<ng-template
  #userAndSurverySelector
  let-data="data"
>
  <hr>
  <div
    class="row"
    style="margin-left: 0rem !important"
    *ngIf="data"
  >
    <div class="col-12 col-xl-6 mb-3">
      <div class="row">
        <div class="col p-0">
          <ng-select
            #userSelect
            [items]="users"
            i18n-placeholder="@@userGroupsAddUserGroups"
            placeholder="Nutzer zuweisen"
            bindLabel="email"
            [searchFn]="searchFn.bind(this)"
            [clearable]="false"
            [(ngModel)]="this.selectedUser"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
            >
              {{item.firstname}} {{item.lastname}}
              ({{item.email}})
            </ng-template>
          </ng-select>
        </div>
        <div class="col-auto">
          <div
            class="btn btn-primary btn-sm"
            style="height: 36px;  padding-top: calc(0.25rem + 2px)"
            [ngClass]=" {'disabled':!selectedUser}"
            (click)="addGroupToUser(data._id, this.selectedUser)"
          >
            <i class="ri-add-line"></i>
          </div>
        </div>
        <ul class="col-12 list-group list-group-flush">
          <li
            class="list-group-item pe-0"
            *ngFor="let user of currentGroup?.users"
          >
            <div class="row">
              <div class="col">
                {{user.firstname + " " + user.lastname + " - " + user.email}}
              </div>
              <div class="col-auto">
                <div
                  class="btn btn-outline-danger btn-sm"
                  (click)="removeGroupFromUser(data._id, user)"
                >
                  <i class="ri-close-line"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-xl-6 mb-3">
      <div class="row">
        <div class="col p-0">
          <ng-select
            #surveySelect
            [items]="surveys"
            i18n-placeholder="@@userGroupsAddSurvey"
            placeholder="Befragung zuweisen"
            bindLabel="name"
            [searchFn]="searchFn.bind(this)"
            [clearable]="false"
            [(ngModel)]="this.selectedSurvey"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
            >
              {{item.name}}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-auto">
          <div
            class="btn btn-primary btn-sm"
            style="height: 36px; padding-top: calc(0.25rem + 2px)"
            [ngClass]=" {'disabled':!selectedSurvey}"
            (click)="this.addGroupToSurvey(data._id, this.selectedSurvey)"
          >
            <i class="ri-add-line"></i>
          </div>
        </div>
        <ul class="col-12 list-group list-group-flush align-top">
          <li
            class="list-group-item pe-0"
            *ngFor="let survey of currentGroup?.surveys"
          >
            <div class="row">
              <div class="col">
                {{survey.name}}
              </div>
              <div class="col-auto">
                <div
                  class="btn btn-outline-danger btn-sm"
                  (click)="removeGroupFromSurvey(data._id, survey)"
                >
                  <i class="ri-close-line"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>