<app-page-header>
    <app-ds-search
        (searchEvent)="dsSearch($event)"
        placeholder="Feedbacks durchsuchen"
        i18n-placeholder="@@myFeedbacksSearchFeedback"
    ></app-ds-search>
</app-page-header>

<lib-rag-datasupply-overlay-asside
    #overlay
    (closed)="this.urlHelper.setSelectedId(undefined)"
>
    <div
        *ngIf="overlay.selectedObject"
        class="p-5"
    >
        <rag-form
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            [data]="overlay.selectedObject"
            [formFields]="[
                {fieldName: '', type: 'template', options: {template: statusDisplay}},
                {fieldName: '', type: 'template', options: {template: nominationsTable}},
                {fieldName: 'name', required: true, type: 'text'},
            ]"
            [hideDeleteButton]="overlay.selectedObject.status != 'nomination'"
            (submit)="this.updateItem($event)"
            (delete)="this.deleteItem($event); overlay.selectedObject = undefined;"
        ></rag-form>
        <div
            class="btn btn-secondary mt-2"
            *ngIf="overlay.selectedObject.status === 'running'"
            (click)="showReminderModal(confirmReminder, overlay.selectedObject)"
            i18n="@@myFeedbacksSendReminders"
        >Erinnerungen senden</div>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
    <app-content-headline
        i18n-pagetitle="@@myFeedbacksCardTitle"
        pagetitle="Meine Feedbacks"
        #headline
    ></app-content-headline>
    <app-new-and-help-row
        #newAndHelpRow
        newBtnLabel="Neues Feedback"
        i18n-newBtnLabel="@@myFeedbacksNewFeedback"
        [newFormTpl]="newFormTpl"
        [showNewButton]="headline.switcherMode == 'table'"
        topic="myFeedback"
    >
    </app-new-and-help-row>

    <div class="content-grid-content overflow-visible">
        <ng-container *ngIf="headline.switcher.isGrid()">
            <lib-rag-datasupply-grid
                [templateFirstItem]="firstTile"
                [template]="tile"
                [service]="this.frontendService"
            >
            </lib-rag-datasupply-grid>
        </ng-container>
        <ng-container *ngIf="headline.switcher.isTable()">

            <app-card>
                <lib-rag-datasupply-table
                    i18n-quicklookTitle="@@myFeedbackTitle"
                    quicklookTitle="Feedback"
                    [noDataText]="this.noDataText"
                    #table
                    (onSelect)="this.overlay.selectedObject = table.selectedObject; this.urlHelper.setSelectedId(table.selectedObject._id)"
                    (sort)="this.tableSort.sort($event)"
                    [service]="this.frontendService"
                    [columns]="[
                        {fieldName: 'name', label: 'Name'},
                        {fieldName: 'survey_id',renderFunc: this.surveyIdToName.bind(this)},
                        {fieldName: 'reponserate', template:responseRater},
                        {fieldName: 'action', template:cta}
                    ]"
                    [translatedLabels]="translatedLabels"
                >
                    <ng-container *ngIf="table.selectedObject">
                    </ng-container>
                </lib-rag-datasupply-table>
            </app-card>
        </ng-container>
    </div>
</div>


<!-- TEMPLATES -->
<ng-template
    #statusDisplay
    let-data="data"
>
    <div class="row m-0">
        <div class="col-auto ps-0">
            <span
                [ngStyle]="{'color': data.status =='nomination' ? '#004FA0' :'grey'}"
                i18n="@@myFeedbackPhaseNomination"
            >Nominierung</span>
        </div>
        <div class="col-auto">
            <span
                [ngStyle]="{'color': data.status =='running' ? '#004FA0' :'grey'}"
                i18n="@@myFeedbackPhaseRunning"
            >Durchführung</span>
        </div>
        <div class="col-auto">
            <span
                [ngStyle]="{'color': data.status =='ended' ? '#004FA0' :'grey'}"
                i18n="@@myFeedbackPhaseEnded"
            >Abgeschlossen</span>

        </div>
    </div>
</ng-template>

<ng-template
    #nominationsTable
    let-data="data"
>
    <ng-container *ngIf="data != null">
        <app-feedback-cta-txt [feedback]="data"></app-feedback-cta-txt>
        <app-feedback-cta-btn [feedback]="data"></app-feedback-cta-btn>
        <h4
            *ngIf="data.status !='nomination'"
            i18n="@@myFeedbackResponseRate"
        >Rücklauf</h4>
        <h4
            *ngIf="data.status =='nomination'"
            i18n="@@myFeedbackNominatePerson"
        >Personen nominieren</h4>

        <div style="border:1px solid #A2A8B4; overflow-x:auto">
            <popper-content #copied>
                <span i18n="@@globalAnonymityLimit">
                    Anonymitätsgrenze
                </span>
            </popper-content>
            <table
                class="table table-striped bg-white m-0 table-sm table-hover clear-asside-padding"
                aria-label="nominatepersons"
            >
                <thead class="thead-light">
                    <tr>
                        <th i18n="@@globalPerspective">Perspektive</th>
                        <th>Min.</th>
                        <th>Max.</th>
                        <th *ngIf="data.status !='nomination'">
                            <span i18n="@@globalAnonymityShort">Ano.</span>
                            <i
                                [popper]="copied"
                                [popperTrigger]="popperTriggerClick"
                                (popperOnShown)="popperShow($event)"
                                class="d-none d-sm-inline ri-question-line  "
                            ></i>
                        </th>
                        <th
                            i18n="@@myFeedbackResponseRate"
                            *ngIf="data.status !='nomination'"
                        >Rücklauf</th>
                        <th
                            i18n="@@globalNominations"
                            style="width:0px"
                        >Nominierungen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td i18n="@@myFeedbackSelfEvaluation">Selbsteinschätzung</td>
                        <td>-</td>
                        <td>-</td>
                        <td *ngIf="data.status !='nomination'">-</td>
                        <td *ngIf="data.status !='nomination'">{{this.mainService.getResponseCount(data._id, "-1")}}
                        </td>
                        <td style="text-align:center">1 </td>
                    </tr>
                    <tr *ngFor="let i of this.mainService.getFeedbackInfo(data._id).survey.perspectives">

                        <td>{{this.loginService.translate(i.name)}}</td>
                        <td>
                            {{i.min}}
                            <i
                                *ngIf="this.mainService.getNominations(data._id, i.id).length >= i.min"
                                class="ri-check-line text-success"
                            ></i>
                        </td>
                        <td>{{i.max}}</td>
                        <td *ngIf="data.status !='nomination'">{{i.anonymity}}</td>
                        <td *ngIf="data.status !='nomination'">
                            {{this.mainService.getResponseCount(data._id,i.id)}}
                            <i
                                *ngIf="this.mainService.getResponseCount(data._id,i.id) >= i.anonymity"
                                class="ri-check-line text-success"
                            ></i>
                        </td>
                        <td style="text-align:center">
                            {{this.mainService.getNominations(data._id, i.id).length}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</ng-template>

<ng-template
    #responseRater
    let-data="data"
>
    {{responseRateStatus(data)}}
</ng-template>

<ng-template
    #cta
    let-data="data"
>
    <app-feedback-cta-btn
        [buttonsOnly]="true"
        [feedback]="data"
    ></app-feedback-cta-btn>
</ng-template>

<ng-template #confirmMoveOnAfterStart>
    <div
        class="modal-header"
        i18n="@@navGiveFeedback"
    >Feedback geben</div>
    <div
        class="modal-body"
        i18n="@@confirmMoveOnAfterStart"
    >
        Den Link zu Ihrer Selbsteinschätzung finden Sie nun direkt unter "Feedback geben".
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            i18n="@@globalOkay"
            (click)="this.modalRef?.hide()"
        >Ok</button>
    </div>
</ng-template>

<ng-template #confirmReminder>
    <div class="modal-header">
        <h4
            class="modal-title pull-left"
            i18n="@@confirmSendReminderTitle"
        >Versand bestätigen</h4>
        <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="this.modalRef?.hide()"
        >
            <span
                aria-hidden="true"
                class="visually-hidden"
            >&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p i18n="@@confirmSendReminderText">
            Die Erinnerungs-E-Mails werden an alle Teilnehmer mit fehlendem Feedbackrücklauf versendet.
        </p>
        <p i18n="@@confirmSendReminderAreYouSure">Sind Sie sicher?</p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            i18n="@@globalCancel"
            (click)="this.modalRef?.hide()"
        >Abbrechen</button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="this.sendReminder()"
            i18n="@@confirmSendReminder"
        >Bestätigen</button>
    </div>
</ng-template>

<ng-template #firstTile>
    <div
        class="btn btn-outline-primary d-flex  shadow "
        (click)="newAndHelpRow.newOverlay.open()"
    >
        <i class="ri-add-line ri-5x align-self-center m-auto"></i>
    </div>
</ng-template>

<ng-template
    #tile
    let-data="data"
>
    <app-fbs-grid-card
        (click)="overlay.selectedObject=data; this.urlHelper.setSelectedId(data._id)"
        [config]="{
            icon: 'ri-chat-3-line',
            iconColor: '#A78042',
            headerBgColor: '#E2DACC',
            badge: this.getCardBadge(data)
        }"
    >
        <ng-container card-body>
            <div class="row">
                <div class="col-1"></div>
                <div
                    class="col "
                    style="line-height: 1.5;"
                >
                    <b
                        style=" --lines:3"
                        class="line-clamp"
                    >{{data.name }}</b>

                </div>
            </div>
            <div class="row mt-2">
                <div class="col-1 my-auto align-top mt-0">
                    <i class="ri-question-fill"></i>
                </div>
                <div
                    class="col"
                    style="line-height: 1.1;"
                >
                    <small i18n="@@globalSurvey">Befragung</small><br>
                    <small
                        style="--lines:3"
                        class="line-clamp"
                    >
                        {{ this.surveyIdToName(data) }}
                    </small>
                </div>
            </div>
            <div
                *ngIf="data.status == 'running' && dayjs(data.endDate).isAfter(dayjs())"
                class="row mt-2"
            >
                <div class="col-1 my-auto  mt-0 align-top">
                    <i class="ri-time-fill"></i>
                </div>
                <div
                    class="col "
                    style="line-height: 1.1;"
                >
                    <small i18n="@@surveyEndTime">Feedback bis</small><br>
                    <small> {{ data.endDate | date }}</small>
                </div>
            </div>
        </ng-container>
        <ng-container card-buttons>
            <app-feedback-cta-btn
                [feedback]="data"
                [buttonsForCard]="true"
            ></app-feedback-cta-btn>
        </ng-container>
    </app-fbs-grid-card>
</ng-template>

<ng-template #newFormTpl>
    <rag-form
        [translateTexts]="translateTexts"
        [translatedLabels]="translatedLabelsForm"
        [type]="'create'"
        [data]="{name: '', survey_id: undefined, status: 'nomination'}"
        (submit)="this.createItem($event);newAndHelpRow.newOverlay.close();"
        [formFields]="[
            {fieldName: 'name', type: 'text', required: true},
            {fieldName: 'survey_id', type: 'select', required: true, options: {
                selectValues: this.availableSurveys,
                bindValue: '_id',
                bindLabel: 'name'
            }},
            {fieldName: 'status', type: 'template', options: {}}
        ]"
    ></rag-form>

</ng-template>