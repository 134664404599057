import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { AdminGroup, Coach } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TableSortUtil } from '../../shared/TableSortUtil';
import { MainService } from '../../shared/main.service';
import { translateTexts } from './../../app.component';

@Component({
  selector: 'app-coaches',
  templateUrl: './coaches.component.html',
  styleUrls: []
})
export class CoachesComponent implements OnInit {
  public buttons = [$localize`:@@globalOverview:Übersicht`, $localize`:@@globalAdd:Anlegen`];
  public coaches: Coach[];
  public adminGroups: AdminGroup[];
  public subscriptions: Subscription[] = [];
  public tableSort: TableSortUtil<Coach>;
  public notFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ['firstname', $localize`:@@globalFirstname:Vorname`],
    ['lastname', $localize`:@@globalLastname:Nachname`],
    ['email', $localize`:@@globalEmail:E-Mail`],
    ['languages', $localize`:@@globalLanguages:Sprachen`],
    ['adminGroups', $localize`:@@globalAdminGroups:Teams`],
    ['unSelectAllText', $localize`:@@unselectAllText:Alle abwählen`],
    ['selectAllText', $localize`:@@selectAllText:Alle auswählen`],
    ['search', $localize`:@@globalSearch:Suche...`],
  ])
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public translateTexts = translateTexts;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public toaster: ToastrService,
    public mainService: MainService,
    public frontendService: RagDatasupplyFrontendService<Coach>
  ) {
    this.route.data.subscribe(d => {
      this.coaches = d.coaches;
      this.adminGroups = d.adminGroups;
    });
  }

  async ngOnInit() {
    this.tableSort = new TableSortUtil(this.frontendService);
    this.subscriptions.push(
      this.mainService.isDataLoaded.subscribe((done) => {
        if (done) {
          this.frontendService.fromArray(this.coaches);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public async deleteItem(coach: Coach) {
    await this.mainService.coachService.deleteCoach(coach._id);
    this.coaches = await this.mainService.coachService.getCoaches();
    this.frontendService.fromArray(this.coaches);
    this.toaster.success($localize`:@@coachesRemovedCoach:Coach entfernt`);
  }

  public async updateItem(coach: Coach) {
    await this.mainService.coachService.editCoach(coach);
    this.toaster.success($localize`:@@coachesUpdatedCoach:Coach gespeichert`);
  }

  public async createItem(coach: Coach) {
    await this.mainService.coachService.addCoach(coach);
    this.coaches = await this.mainService.coachService.getCoaches();
    this.frontendService.fromArray(this.coaches);
    this.toaster.success($localize`:@@coachesAddedCoach:Coach hinzugefügt`);
  }
}
