<ng-template
    #logoUploadTpl
    let-data="data"
>
    <input
        [id]="'logo'"
        [name]="'logo'"
        type="file"
        style="display: none"
        (change)="onFileChange($event, 'logo')"
    >
    <label
        class="btn btn-outline-primary"
        [for]="'logo'"
        *ngIf="!this.tmpSelectedFiles.logo"
    >
        <i class="ri-image-add-line"></i>&nbsp;Logo
    </label>
    <div class="row">
        <div class="col-auto">
            <div
                *ngIf="this.tmpSelectedFiles.logo"
                class="btn btn-primary "
                (click)="uploadLogo(data)"
            ><i class="ri-upload-2-fill"></i> Upload
            </div>
        </div>
        <div class="col-auto">
            <div
                *ngIf="this.tmpSelectedFiles.logo"
                class="pt-2"
            >
                {{this.tmpSelectedFiles.logo[0].name}}
            </div>
        </div>
    </div>
</ng-template>


<app-page-header>
    <app-ds-search
        (searchEvent)="dsSearch($event)"
        placeholder="Mandanten durchsuchen"
        i18n-placeholder="@@customersSearchCustomers"
    >
    </app-ds-search>
</app-page-header>

<lib-rag-datasupply-overlay-asside
    #overlay
    (closed)="this.urlHelper.setSelectedId(undefined)"
>
    <div
        class="p-5"
        *ngIf="overlay.selectedObject"
    >
        <rag-form
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            (delete)="this.deleteItem($event); table.selectedObject = undefined"
            (submit)="this.updateCustomer($event)"
            [data]="overlay.selectedObject"
            [formFields]="[
                { fieldName: 'name', type: 'text', required: true },
                { fieldName: 'senderMail', type: 'text',  required: false },
                { fieldName: 'feedbackreceiverCount', readonly:true, type: 'text' },
                { fieldName: 'limesurvey_ids', type: 'ng-multiselect-dropdown', options: {
                        enableCheckAll:false,
                        handleValuesAsIds:true,
                        dropdownList: this.customerService.limeSurveys$ | async,
                        selectValues: this.customerService.limeSurveys$ | async,
                        idField: 'sid',
                        textField: 'surveyls_title',
                        bindValue: 'sid',
                        bindLabel: 'surveyls_title',
                        allowRemoteDataSearch:false,
                        unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
                    }
                },
                { fieldName: 'logo', type: 'template', options: {template: logoUploadTpl} },
                { fieldName: '', type: 'template', options: {template: benchmarkConfigSelect}}
            ]"
        >
        </rag-form>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
    <app-content-headline
        #headline
        [showSwitcher]="false"
        pagetitle="Mandanten"
        i18n-pagetitle="@@customersCardTitle"
    ></app-content-headline>
    <app-new-and-help-row
        [showNewButton]="true"
        newBtnLabel="Anlegen"
        i18n-newBtnLabel="@@globalAdd"
        [newFormTpl]="newFormTpl"
    >
        <div class="row">
            <div
                class="col"
                i18n="@@customersCol1"
            >
                Unter "<b>Übersicht</b>" sind alle aktiven Kunden der Feedback-Suite aufgelistet.
            </div>
            <div
                class="col"
                i18n="@@customersCol2"
            >
                Über "<b>Anlegen</b>" können neue Kunden angelegt werden.
            </div>
        </div>
    </app-new-and-help-row>
    <div class="content-grid-content overflow-visible">
        <app-card>
            <lib-rag-datasupply-table
                #table
                (onSelect)="overlay.selectedObject = table.selectedObject;this.urlHelper.setSelectedId(table.selectedObject._id)"
                (sort)="this.tableSort.sort($event)"
                [service]="this.frontendService"
                [columns]="[{ fieldName: 'name', }, {fieldName:'feedbackreceiverCount' }]"
                [translatedLabels]="translatedLabels"
            >
                <ng-container *ngIf="table.selectedObject">
                </ng-container>
            </lib-rag-datasupply-table>
        </app-card>
    </div>
</div>


<!-- TEMPLATES -->

<ng-template
    #benchmarkConfigSelect
    let-data="data"
>

    <label
        [for]="benchmarkInput"
        class="fw-bold"
    >Benchmark Configs</label>
    <div class="input-group mb-3">
        <input
            #benchmarkInput
            id="benchmarkInput"
            class="form-control"
            i18n-placeholder="@@customersAddBenchmarkAttribute"
            placeholder="Benchmark-Attribut hinzufügen"
            (keyup)="checkDisabled($event)"
        />
        <div class="input-group-append">
            <div
                class="btn btn-primary"
                (click)="addBenchmarkConfig(benchmarkInput.value, data); benchmarkInput.value = ''"
                [ngClass]="{'disabled': btnDisabled}"
            >
                <i class="ri-add-line"></i>
            </div>
        </div>
    </div>
    <ul class="list-group list-group-flush">
        <li
            class="list-group-item pe-0"
            *ngFor="let bc of data.benchmarkConfigs"
        >
            <div class="row">
                <div class="col">
                    {{ bc.label }}
                </div>
                <div class="col-auto">
                    <div
                        class="btn btn-outline-danger btn-sm"
                        (click)="removeBenchmarkConfig(bc, data)"
                    >
                        <i class="ri-close-line"></i>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</ng-template>



<ng-template
    #limeSurveysSelect
    let-data="data"
>
    <ng-select
        class="form-control"
        [items]="this.customerService.limeSurveys$ | async"
        [multiple]="true"
        [closeOnSelect]="false"
        [hideSelected]="true"
        bindLabel="surveyls_title"
        bindValue="sid"
        i18n-placeholder="@@surveysSelectLS"
        placeholder="LimeSurveys auswählen"
        [(ngModel)]="data.limesurvey_ids"
    >
    </ng-select>
</ng-template>

<ng-template #newFormTpl>
    <rag-form
        [translateTexts]="translateTexts"
        [translatedLabels]="translatedLabelsForm"
        [type]="'create'"
        [data]="{name: '', senderMail: '', limesurvey_ids: []}"
        [formFields]="[
            { fieldName: 'name', type: 'text', required: true },
            { fieldName: 'senderMail', type: 'text',  required: false },
            { fieldName: 'limesurvey_ids', type: 'ng-multiselect-dropdown', options: {
                placeholder: '...',
                enableCheckAll:false,
                handleValuesAsIds:true,
                dropdownList: this.customerService.limeSurveys$ | async,
                selectValues: this.customerService.limeSurveys$ | async,
                idField: 'sid',
                textField: 'surveyls_title',
                bindValue: 'sid',
                bindLabel: 'surveyls_title',
                allowRemoteDataSearch:false,
                unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
            }
        },
        ]"
        (submit)="this.createCustomer($event)"
    ></rag-form>
</ng-template>