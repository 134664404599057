<app-page-header></app-page-header>

<lib-rag-datasupply-overlay-asside #overlay>
    <div
        class="p-5"
        *ngIf="overlay.selectedObject"
    >
        <rag-form
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            (delete)="this.deleteItem($event);overlay.selectedObject = undefined"
            (submit)="this.updateItem($event)"
            [data]="overlay.selectedObject"
            [formFields]="[
                { fieldName: 'firstname', type: 'text', required: true },
                { fieldName: 'lastname', type: 'text', required: true },
                { fieldName: 'email', type: 'text', required: true },
                { fieldName: 'languages', type: 'ng-multiselect-dropdown', required: true,
                    options: {
                        searchPlaceholderText: this.translatedLabelsForm.get('search'),
                        allowSearchFilter: true,
                        placeholder: '...',
                        enableCheckAll:false,
                        handleValuesAsIds:true,
                        dropdownList: this.mainService.allLanguages,
                        idField: 'value',
                        textField: 'label',
                        unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
                    }
                },
                {
                    fieldName: 'adminGroups', type: 'ng-multiselect-dropdown',  required: this.mainService.loginService.loggedInUser.type == 'groupadmin',
                    options: {
                        placeholder: '...',
                        enableCheckAll: true,
                        handleValuesAsIds: true,
                        dropdownList: this.adminGroups,
                        idField: '_id',
                        textField: 'name',
                        unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
                    }
                }
            ]"
        ></rag-form>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        i18n-pagetitle="@@coachesCardTitle"
        pagetitle="Coaches"
        #headline
    >
    </app-content-headline>

    <app-new-and-help-row
        [showNewButton]="true"
        newBtnLabel="Anlegen"
        i18n-newBtnLabel="@@globalAdd"
        [newFormTpl]="newFormTpl"
        topic="coaches"
    >
    </app-new-and-help-row>

    <div class="content-grid-content overflow-visible">
        <app-card>
            <lib-rag-datasupply-table
                #table
                (sort)="this.tableSort.sort($event)"
                (onSelect)="overlay.selectedObject = table.selectedObject"
                [service]="this.frontendService"
                [columns]="[
                    { fieldName: 'firstname', label: 'Vorname'},
                    { fieldName: 'lastname', label: 'Nachname'},
                    { fieldName: 'email', label: 'E-Mail'},
                ]"
                [translatedLabels]="translatedLabels"
            >
                <ng-container *ngIf="table.selectedObject">
                </ng-container>
            </lib-rag-datasupply-table>
        </app-card>
    </div>
</div>

<!-- TEMPLATES -->

<ng-template #newFormTpl>
    <rag-form
        [translateTexts]="this.translateTexts"
        [translatedLabels]="this.translatedLabelsForm"
        [type]="'create'"
        (submit)="this.createItem($event)"
        [data]="{firstname: '', lastname: '', email: '', languages: [], adminGroups: []}"
        [formFields]="[
            { fieldName: 'firstname', type: 'text', required: true },
            { fieldName: 'lastname', type: 'text', required: true },
            { fieldName: 'email', type: 'text', required: true },
            {
                fieldName: 'languages', type: 'ng-multiselect-dropdown', required: true,
                options: {
                    searchPlaceholderText: this.translatedLabelsForm.get('search'),
                    allowSearchFilter: true,
                    placeholder: '...',
                    enableCheckAll:false,
                    handleValuesAsIds:true,
                    dropdownList: this.mainService.allLanguages,
                    idField: 'value',
                    textField: 'label',
                        unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
                }
            },
            {
                fieldName: 'adminGroups', type: 'ng-multiselect-dropdown', required: this.mainService.loginService.loggedInUser.type == 'groupadmin',
                options: {
                    placeholder: '...',
                    enableCheckAll: true,
                    handleValuesAsIds: true,
                    dropdownList: this.adminGroups,
                    idField: '_id',
                    textField: 'name',
                    unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                    selectAllText: translatedLabelsForm.get('selectAllText')
                }
            }
        ]"
    >
    </rag-form>
</ng-template>