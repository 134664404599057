import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { ReportingQuestionResponse, Survey, SurveyCreationData, SurveysInfoResponse, UserGroup } from '@reflact/prmfeedback';
import {
  DatasupplyRequest,
  RagDatasupplyFrontendService,
  RagDatasupplyLocalBackendService,
  RagDatasupplyOverlayAssideComponent,
  RagDatasupplyOverlayUrlService,
  RagDatasupplyTableQuicklookAsideComponent,
  RagDatasupplyTypes
} from '@reflact/rag-datasupply';
import { ObjectId } from "bson";
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest } from 'rxjs';
import { translateTexts } from '../../app.component';
import { CustomerService } from '../../shared/customer.service';
import { FeedbackReportService } from '../../shared/feedback-report.service';
import { MainService } from '../../shared/main.service';
import { SurveyService } from '../../shared/survey.service';
import { LoginService } from '../login/login.service';
import { TableSortUtil } from './../../shared/TableSortUtil';
import { UserGroupsService } from './../../shared/user-groups.service';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: [],
  providers: [
    RagDatasupplyLocalBackendService
  ]
})
export class SurveysComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('table') table: RagDatasupplyTableQuicklookAsideComponent<Survey>;
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  public subscriptions: Subscription[] = [];
  public customerService: CustomerService;
  public userGroupsService: UserGroupsService;
  public loginService: LoginService;
  public tableSort: TableSortUtil<Survey>
  public buttons = [$localize`:@@globalOverview:Übersicht`, $localize`:@@globalAdd:Anlegen`];
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["self_sid", $localize`:@@myFeedbackSelfEvaluation:Selbsteinschätzung`],
    ["other_sid", $localize`:@@globalExternalEvaluation:Fremdeinschätzung`],
    ["perspectives", $localize`:@@globalPerspective:Perspektive`],
    ["surveyNames", $localize`:@@surveyNames:Umfragen`],
    ["status", $localize`:@@globalPublished:Status`],
    ["customGroup", $localize`:@@customGroupCardTitle:Zusatzgruppierung von Fragen`],
    ["feedbackDuration", $localize`:@@gobalFeedbackDuration:Dauer des Befragungszeitraums in Tagen`],
    ["name", $localize`:@@globalTitle:Titel`],
    ["userGroups", $localize`:@@gobalUserGroups:Befragungsgruppen`]
  ])
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public translateTexts = translateTexts;
  public userLanguage: string;
  public noDataText: string = $localize`:@@noDataTextSurveys:Klicken Sie auf ANLEGEN um Ihre erste Befragung anzulegen`;
  public NotFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;
  public selectableGroups: UserGroup[] = [];
  public selectedGroup: UserGroup;
  public reportingQuestionResponse: ReportingQuestionResponse;
  public surveyInfoReponse: SurveysInfoResponse;
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["name", "status"]
  };

  constructor(public route: ActivatedRoute,
    public toaster: ToastrService,
    public mainService: MainService,
    public router: Router,
    public frontendService: RagDatasupplyFrontendService<Survey>,
    public surveyService: SurveyService,
    public backendService: RagDatasupplyLocalBackendService<Survey>,
    public urlHelper: RagDatasupplyOverlayUrlService,
    public feedbackReportService: FeedbackReportService
  ) {
    this.customerService = mainService.customerService;
    this.userGroupsService = mainService.userGroupsService;
    this.loginService = this.mainService.loginService;

    this.backendService.initialRequest(this.standardRequest);
  }

  ngAfterViewInit(): void {
    combineLatest([this.route.queryParams, this.mainService.isDataLoaded]).subscribe(async ([params, done]) => {
      const selectedSurveyId = params.selected
      if (selectedSurveyId != null && done) {
        this.surveyInfoReponse = await this.feedbackReportService.loadSurveyInfo(new ObjectId("" + selectedSurveyId))
        this.reportingQuestionResponse = await this.feedbackReportService.loadReportQuestions(new ObjectId("" + selectedSurveyId), this.mainService.loginService.loggedInUser.language)
        if (this.overlay.selectedObject) {
          this.filterSelectableGroups(this.overlay.selectedObject?.userGroups);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.tableSort = new TableSortUtil(this.frontendService);

    this.backendService.loadData = () => {
      return new Promise<Survey[]>((resolve, reject) => {
        if (this.customerService.surveys) {
          resolve([...this.customerService.surveys])
        } else {
          reject([])
        }
      })
    }

    this.subscriptions.push(this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.backendService.loadInitialRequest();
      }
    }));

    this.subscriptions.push(this.backendService.results$.subscribe(results => {
      if (this.loginService.loggedInUser.type == "groupadmin") {
        results = results.filter(s => s.adminGroups.some(ag => this.loginService.loggedInUser.adminGroupsAdmin.indexOf(ag) >= 0))

      }

      this.frontendService.fromArray(results)
      this.urlHelper.getSelectedId(true);
    }))


    this.subscriptions.push(this.customerService.error$.subscribe((i) => {
      this.toaster.error(i.message);
    }));
    this.subscriptions.push(this.customerService.surveyCreated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalCreatedSurvey:Befragung erfolgreich angelegt`);
      //this.backendService.load(this.standardRequest)
      this.overlay.selectedObject = i
      this.urlHelper.setSelectedId(i._id)
    }));
    this.subscriptions.push(this.customerService.surveyUpdated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalUpdatedSurvey:Befragung erfolgreich gespeichert`);
    }));

    this.subscriptions.push(this.customerService.surveyDeleted$.subscribe((i) => {
      this.toaster.success($localize`:@@globalDeleteSurvey:Befragung erfolgreich gelöscht`);
      this.backendService.load(this.standardRequest)
      this.urlHelper.setSelectedId(undefined)
    }));

    this.loginService.loggedInUser$.subscribe(user => {
      this.userLanguage = user.language;
    });
    this.subscriptions.push(this.urlHelper.newSelection$.subscribe(s => {
      this.overlay.selectedObject = this.customerService.surveys.find(d => d._id == s)
    }))
  }

  public getReponseRateforPerspective(id: string) {
    return this.surveyInfoReponse.responseRates.find(p => p.perspective_id == id)
  }

  public dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest)
  }

  public async updateItem(i) {
    await this.customerService.updateSurvey(i);
    this.backendService.loadInitialRequest();
  }

  public async deleteItem(i) {
    this.customerService.deleteSurvey(i);
  }

  public async createItem(i: SurveyCreationData) {
    i.userGroups = []
    const survey = await this.customerService.createSurvey(i);
    this.frontendService.fromArray([survey, ...this.frontendService.rawData])
    //this.backendService.loadInitialRequest(); // scheint ohne zu gehen mit wird falsch wegnavigiert
  }

  public filterSelectableGroups(gids: string[]) {
    this.selectedGroup = undefined;
    this.selectableGroups = this.userGroupsService.userGroups.filter(g => !gids.includes(g._id));
    if (this.loginService.loggedInUser.type == "groupadmin") {
      this.selectableGroups = this.selectableGroups.filter(g => g.adminGroups.some(gid => this.loginService.loggedInUser.adminGroupsAdmin.includes(gid)))
    }
  }

  public selfSidToName(data: Survey) {
    const survey = this.customerService.limeSurveys.find(s => s.sid === data.self_sid);
    return survey ? survey.surveyls_title : 'Not found';
  }

  public otherSidToName(data: Survey) {
    const survey = this.customerService.limeSurveys.find(s => s.sid === data.other_sid);
    return survey ? survey.surveyls_title : 'Not found';
  }

  public allSidToName(data: Survey) {
    return this.selfSidToName(data) + " / " + this.otherSidToName(data)
  }

  public removePerspective(surveyId, perspectiveId) {
    const survey = this.mainService.customerService.surveyMap.get(surveyId);
    const perspectiveIndex = survey.perspectives.findIndex(p => {
      return p.id === perspectiveId;
    });
    survey.perspectives.splice(perspectiveIndex, 1);
    this.customerService.updateSurvey(survey);
  }

  async addGroupToSurvey(groupId: string, survey: Survey) {
    survey.userGroups.push(groupId);
    this.selectedGroup = undefined;
    await this.customerService.updateSurvey(survey);
    this.filterSelectableGroups(survey.userGroups);
  }

  async removeGroupFromSurvey(groupId: string, survey: Survey) {
    survey.userGroups = survey.userGroups.filter(gid => gid !== groupId);
    await this.customerService.updateSurvey(survey);
    this.filterSelectableGroups(survey.userGroups);
  }
}