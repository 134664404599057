import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FeedbackDocument } from '@reflact/prmfeedback';
import { DatasupplyRequest, RagDatasupplyFrontendService, RagDatasupplyLocalBackendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import dayjs from 'dayjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TableSortUtil } from '../../shared/TableSortUtil';
import { DocumentService } from './../../shared/document.service';
import { MainService } from './../../shared/main.service';
import { LoginService } from './../login/login.service';

@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html',
  styleUrls: [],
  providers: [
    RagDatasupplyLocalBackendService
  ]
})
export class MyDocumentsComponent implements OnInit {
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  public buttons = [$localize`:@@globalOverview:Übersicht`];
  public documents: FeedbackDocument[];
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = [
    { fieldName: 'uploadDate', translated: $localize`:@@globalUploadedAt:Hochgeladen am` },
    { fieldName: 'metadata.name', translated: $localize`:@@globalName:Name` },
  ];
  public noDataText: string = $localize`:@@noDataTextDocuments:Noch keine Dokumente vorhanden.`;
  bsModalRef?: BsModalRef;
  expanded: boolean = false;
  public url: SafeResourceUrl;
  public filename: string;
  private userLang = 'de';


  public tableSort: TableSortUtil<FeedbackDocument>;
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["metadata__name", "uploadDate"]
  }
  constructor(
    public mainService: MainService,
    public documentService: DocumentService,
    private loginService: LoginService,
    public sanitizer: DomSanitizer,
    public frontendService: RagDatasupplyFrontendService<FeedbackDocument>,
    public backendService: RagDatasupplyLocalBackendService<FeedbackDocument>,
    public urlHelper: RagDatasupplyOverlayUrlService
  ) {
    this.backendService.initialRequest(this.standardRequest)
  }

  ngOnInit(): void {

    this.backendService.loadData = () => {
      return new Promise<FeedbackDocument[]>((resolve, reject) => {
        if (this.documents) {
          resolve([...this.documents])
        } else {
          reject([])
        }
      })
    }
    this.tableSort = new TableSortUtil(this.frontendService);
    this.loginService.loggedInUser$.subscribe(l => {
      this.userLang = l.language;
    });
    this.loadDocuments();

    this.backendService.results$.subscribe(results => {
      this.frontendService.fromArray(results);
      this.urlHelper.getSelectedId(true);
    })

    this.urlHelper.newSelection$.subscribe(s => {
      this.overlay.selectedObject = this.documents.find(d => d._id == s)
    })
  }

  public dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest)
  }

  getFormattedDate(document: FeedbackDocument) {
    return dayjs(document.uploadDate).format('DD.MM.YYYY HH:mm (Z)');
  }

  getMetaName(o: FeedbackDocument) {
    return o.metadata?.name;
  }

  loadDocumentPreview(id: string) {
    const temp = '/api/document/file/' + id + '?token=' + localStorage.getItem('authToken');
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(temp);
  }
  getUrl(id: string) {
    return '/api/document/file/' + id + '?token=' + localStorage.getItem('authToken');
  }

  private async loadDocuments() {
    console.log("der1")
    this.documents = await this.documentService.listDocuments();
    this.backendService.loadInitialRequest()
  }


}
