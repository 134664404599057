<ul class="sidebar-nav">
  <ng-container *ngFor="let item of navItemsArray">
    <ng-container>
      <li
        *ngIf="item.title"
        class="nav-title"
      >
        {{item.name}}
      </li>
      <li
        *ngIf="item.url != undefined"
        class="nav-item"
      >
        <a
          class="nav-link"
          [routerLinkActive]="'active'"
          [routerLink]="item.url"
        >
          <i
            [class]="item.icon +' me-3 nav-link-icon'"
            [routerLinkActive]="'active'"
            style="margin-left:8px; margin-right:25px !important; transform: scale(1.3);"
          ></i>
          {{item.name}}
          <span
            *ngIf="item.url === '/feedback-invitations' && (openInvitations$ | async) > 0"
            style="margin-left:8px; margin-right:25px !important; transform: scale(1); color: white;"
            class="badge rounded-pill bg-danger"
          >{{openInvitations$ | async}}</span>
          <span
            class="ms-auto"
            *ngIf="!hideDropArrow && item.children"
            (click)="showChildren = ! showChildren"
          >
            <i
              *ngIf="!showChildren"
              class="ri-arrow-drop-down-line"
            ></i>
            <i
              *ngIf="showChildren"
              class="ri-arrow-drop-up-line"
            ></i>
          </span>
        </a>

        <ng-container *ngIf="item.children?.length && showChildren">

          <app-sidebar-nav
            *ngIf="item.children"
            [navItems]="item.children"
          ></app-sidebar-nav>
        </ng-container>

      </li>
    </ng-container>
  </ng-container>
</ul>