<app-page-header>
    <app-ds-search
        (searchEvent)="dsSearch($event)"
        [placeholder]="this.searchPlaceholder"
    ></app-ds-search>
</app-page-header>

<lib-rag-datasupply-overlay-asside
    #overlay
    (closed)="this.urlHelper.setSelectedId(undefined)"
>
    <div
        class="p-5"
        *ngIf="overlay.selectedObject && this.overlay.selectedObject.benchmarkValues"
    >
        <rag-form
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            (delete)="this.deleteItem($event);overlay.selectedObject = undefined"
            (submit)="this.updateItem($event)"
            [data]="overlay.selectedObject"
            [hideDeleteButton]="true"
            [formFields]="this.formFields"
        ></rag-form>
        <ng-container *ngIf="overlay.selectedObject">
            <div class="mt-3">
                <b i18n="@@globalMoreOptions">Weitere Optionen:</b>
                <div class="row">
                    <div class="col-auto pe-0 me-1">
                        <button
                            *ngIf="myUser.type === 'prmadmin' && overlay.selectedObject._id !== myUser._id"
                            type="button"
                            class="btn btn-secondary"
                            i18n="@@globalSimulateUser"
                            (click)="mainService.loginService.switchToUser(overlay.selectedObject.email)"
                        >Nutzer simulieren</button>
                    </div>
                    <div class="col-auto ps-0">
                        <div
                            *ngIf="overlay.selectedObject.type != 'feedbackgiver' && overlay.selectedObject.type == 'feedbackreceiver'"
                            class="btn btn-info"
                            i18n="@@globalToInvite"
                            (click)="this.inviteUser(overlay.selectedObject)"
                        >Einladen</div>
                    </div>
                    <div class="col text-end">
                        <button
                            type="button"
                            class="btn btn-danger text-white"
                            i18n="@@globalToArchive"
                            (click)="this.archiveUserConfirm(archiveUserModal, overlay.selectedObject)"
                        >Archivieren</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        i18n-pagetitle="@@usersCardTitle"
        [pagetitle]="this.pageTitle"
        #headline
    >
        <div
            class="form-group row  m-0"
            *ngIf="!isAdminArea"
        >
            <label
                class="col-auto col-form-label"
                i18n="@@usersRoleFilter"
            >
                Filter
            </label>
            <div class="col">
                <ng-select
                    [items]="allTypes"
                    bindLabel="label"
                    bindValue="value"
                    [searchable]="false"
                    [clearable]="false"
                    [(ngModel)]="backendServiceUserType"
                    (ngModelChange)="this.loadData()"
                >
                </ng-select>
            </div>
        </div>
    </app-content-headline>
    <app-new-and-help-row
        #newhelpRow
        [showNewButton]="true"
        [showImportButton]="true"
        [newBtnLabel]="newUserText"
        importBtnLabel="Importieren"
        i18n-importBtnLabel="@@globalImport"
        [newFormTpl]="newFormTpl"
        [importFormTpl]="importFormTpl"
        (newBtnClick)="this.userCreate.emit($event)"
        topic="usermanagement"
    >
    </app-new-and-help-row>
    <div class="content-grid-content overflow-visible">
        <app-card>
            <lib-rag-datasupply-table
                #table
                [service]="this.service"
                [columns]="this.columns"
                [translatedLabels]="translatedLabels"
                (sort)="this.tableSort.sort($event)"
                (onSelect)="overlay.selectedObject = table.selectedObject; this.tableSelect.emit(table.selectedObject); this.urlHelper.setSelectedId(table.selectedObject._id)"
            >
                <ng-container *ngIf="table.selectedObject">

                </ng-container>
            </lib-rag-datasupply-table>
        </app-card>
    </div>
</div>
<ng-template #archiveUserModal>
    <div class="modal-header">
        <h4
            class="modal-title pull-left"
            i18n="@@globalToArchive"
        >Archivieren</h4>
        <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="this.archiveUserModalRef?.hide()"
        >
            <span
                aria-hidden="true"
                class="visually-hidden"
            >&times;</span>
        </button>
    </div>
    <div
        class="modal-body"
        i18n="@@archiveReminderText"
    >
        Soll der ausgewählte Benutzer archiviert werden?
        <ul>
            <li>Der Benutzer kann sich nicht mehr anmelden</li>
            <li>Die Benutzerdaten (Vorname, Nachname & E-Mail) werden anonymisiert</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-secondary"
            (click)="this.archiveUserModalRef?.hide()"
            i18n="@@globalCancel"
        >Abbrechen</button>
        <button
            type="button"
            class="btn btn-danger text-white"
            (click)="this.archiveUser()"
            i18n="@@globalToArchive"
        >Archivieren</button>
    </div>
</ng-template>

<ng-template #importFormTpl>
    <app-import [isAdminArea]="isAdminArea"></app-import>
</ng-template>

<ng-template #newFormTpl>
    <rag-form
        [translateTexts]="translateTexts"
        [translatedLabels]="translatedLabelsForm"
        [type]="'create'"
        *ngIf="this.formCreationData && this.formFields"
        [data]="this.formCreationData"
        [formFields]="this.formFields"
        (submit)="this.createItem($event, newhelpRow)"
    ></rag-form>
</ng-template>