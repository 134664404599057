<app-page-header></app-page-header>
<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        i18n-pagetitle="@@globalCustomGroups"
        pagetitle="Zusatzgruppierung von Fragen"
    >
        <div
            before
            class="no-print"
        ><a
                class="text-primary text-decoration-none d-flex align-middle"
                style="font-family: sans-serif;"
                [routerLink]="'/surveys'"
                [queryParams]="{selected: this.survey_id}"
                i18n="@@customGroupBackBtn"
            ><i class="ri-arrow-left-line"></i> Befragungsübersicht</a></div>
    </app-content-headline>
    <div class="content-grid-area">
        <div class="row mb-3">
            <div class="col">
                <button
                    class="btn btn-primary"
                    (click)="createCustomGroup({surveyId: survey_id, questionTitles: [], groupName: 'Neue Gruppe'})"
                    i18n="@@globalAddGroup"
                >Gruppe hinzufügen</button>
            </div>
        </div><app-card>


            <ng-container *ngFor="let customGroup of customGroups">
                <div class="callout  ">
                    <div class="row">
                        <div class="col">
                            <div class="form-floating mb-2">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    [(ngModel)]="customGroup.groupName"
                                >
                                <label
                                    id="name"
                                    for="name"
                                    i18n="@@globalName"
                                >Name</label>
                            </div>

                            <div class="form-floating mb-2">
                                <ng-select
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    [items]="questions"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    [hideSelected]="true"
                                    bindLabel="label"
                                    groupBy="group"
                                    bindValue="title"
                                    i18n-placeholder="@@customGroupSelectQuestions"
                                    placeholder="Fragen auswählen"
                                    [(ngModel)]="customGroup.questionTitles"
                                >
                                </ng-select>
                                <label
                                    id="name"
                                    for="name"
                                    i18n="@@customGroupQuestions"
                                >Fragen</label>
                            </div>
                            <button
                                class="btn btn-sm btn-primary ms-4"
                                style="float:right"
                                (click)="editCustomGroup(customGroup)"
                                i18n="@@globalSave"
                            >Sichern</button>
                            <button
                                class="btn btn-sm btn-danger"
                                style="float:right"
                                (click)="deleteCustomGroup(customGroup)"
                                i18n="@@globalDelete"
                            >Löschen</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-card>
    </div>
</div>