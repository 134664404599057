import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subsidiary } from '@reflact/prmfeedback';
import { SubsidiaryService } from '../../shared/subsidiary.service';

@Injectable({
  providedIn: 'root'
})
export class SubsidiariesResolver  {
  constructor(private subsidiariesService: SubsidiaryService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Subsidiary[]> {
    return await this.subsidiariesService.getSubsidiaries();
  }
}
