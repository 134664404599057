import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { waitSeconds } from "@reflact/tsutil";

export interface ColorPair {
  backgroundColor: any;
  foregroundColor: any;
}

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  currentRoute: string;

  //only demo
  public demoColor: ColorPair = {
    backgroundColor: "#ff00ff",
    foregroundColor: "00ff00",
  };
  public demoArrayOfColorPairs: ColorPair[] = [];
  public logoUrl: string;
  private customer_id: string = "";
  //@Output() public onLogoChane: EventEmitter<any> = new EventEmitter();

  public setCustomerId(customer_id?) {
    this.customer_id = customer_id;
    this.logoUrl = "/api/customer/getlogo/" + this.customer_id;

  }
  public async reloadLogo() {
    this.logoUrl = "";
    await waitSeconds(2);
    this.logoUrl = "/api/customer/getlogo/" + this.customer_id + "?=" + new Date().getTime();

  }
  public getLogoUrl() {
    return this.logoUrl;
  }
  constructor(private router: Router) {

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && ev.urlAfterRedirects) {
        this.currentRoute = ev.urlAfterRedirects;
      }
    });
  }


}
