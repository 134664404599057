import { Component, Input } from '@angular/core';
import { AllPrmUserType } from '@reflact/prmfeedback';

@Component({
  selector: 'app-prm-user-display',
  templateUrl: './prm-user-display.component.html',
  styleUrls: ['./prm-user-display.component.scss']
})
export class PrmUserDisplayComponent {
  @Input() public user: AllPrmUserType = null;

}
