<div class="modal-header">
  <div>
    <h4
      class="modal-title"
      i18n="@@benchmarkFilterModalHeader"
    >Bericht anzeigen für:</h4>
    <small
      class="text-muted"
      i18n="@@benchmarkFilterModalSubtitle"
    >Filter kann nachträglich über "Bericht anzeigen für" angepasst werden.</small>
  </div>
</div>
<div class="modal-body">
  <div class="card bg-secondary bg-opacity-25 border-0">
    <div class="card-body">
      <div class="row">
        <div
          class="col-auto p-0"
          *ngFor="let bField of this.items"
        >
          <span
            (click)="bField.selected=false"
            *ngIf="bField.selected"
            class="badge badge-pill bg-secondary bg-opacity-75 text-black d-flex align-middle"
            [ngClass]="{'m-1': bField.selected}"
          >{{bField.label}} ({{bField.value}}) <i
              class="ri-close-line text-muted"
              style="font-size: 1.3em"
            ></i></span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <b i18n="@@benchmarkFilterMoreOptions">Weitere Optionen:</b>
  </div>

  <div class="row p-2">
    <div
      class="col-auto p-0"
      *ngFor="let bField of this.items"
    >
      <span
        (click)="bField.selected=true"
        *ngIf="!bField.selected"
        class="badge badge-pill bg-secondary bg-opacity-75 text-black d-flex align-middle"
        [ngClass]="{'m-1': !bField.selected}"
      >{{bField.label}} ({{bField.value}})</span>
    </div>
  </div>



</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-sm btn-primary"
    (click)="applyFilter()"
    i18n="@@benchmarkFilterApplyFilter"
  ><i class="ri-save-line"></i> Filter Anwenden</button>
  <button
    (click)="removeFilter()"
    type="button"
    class="btn btn-sm btn-danger"
    i18n="@@benchmarkFilterRemoveFilter"
  ><i class="ri-delete-bin-line"></i>Filter Entfernen</button>
  <button
    (click)="cancelFilter()"
    type="button"
    class="btn btn-sm btn-secondary"
    i18n="@@benchmarkFilterCancel"
  >Abbrechen</button>
</div>