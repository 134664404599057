import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MainService } from '../../shared/main.service';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-help-area',
  templateUrl: './help-area.component.html',
  styleUrls: []
})
export class HelpAreaComponent {
  public topic: string;
  public text: string = '';
  public buttons = [$localize`:@@technicalProblems:Technische Probleme`];
  constructor(private toastService: ToastrService, public loginService: LoginService, private mainService: MainService) {

  }

  public async sendSupportMail() {
    if (!this.topic || !this.text) { return; }
    const result = await this.mainService.sendSupportMessage(this.topic, this.text);
    if (result) {
      this.toastService.success(
        $localize`:@@requestProcessedMessage:Ihre Anfrage wurde gesendet`,
        $localize`:@@successMessageTitle:Erfolg!`);
      this.topic = undefined;
      this.text = '';
    }
  }

}
