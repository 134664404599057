import { Directive, ElementRef, HostListener, Input } from '@angular/core';


@Directive({
  selector: '[scrollFadeOut]'
})
export class ScrollFadeOutDirective {
  //private scrollPosition
  @HostListener('window:scroll') onScroll() {
    const scrollY = window.scrollY;
    if (this.scrollFadeOut != null) {
      const opacity = this.calcOpacity(scrollY);

      this.el.nativeElement.style.opacity = opacity;
      if (opacity < 0.25) {
        this.el.nativeElement.style.pointerEvents = "none";
      } else {
        this.el.nativeElement.style.pointerEvents = "all";
      }
    }
  }
  @Input() scrollFadeOut: number = null;
  constructor(private el: ElementRef) {
  }
  private calcOpacity(scrollY: number): number {
    return 1 - this.transformLinear(0, this.scrollFadeOut, 0, 1, scrollY);
  }

  transformLinear(minInput: number, maxInput: number, minOutput: number, maxOutput: number, inputValue: number) {
    const inputBetween0And1 = (inputValue - minInput) / (maxInput - minInput)
    return minOutput + (inputBetween0And1 * (maxOutput - minOutput))
  }

}
