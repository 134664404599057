import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import { AdminGuard } from './shared/admin.guard';
import { AuthGuard } from './shared/auth.guard';
import { CustomerGuard } from './shared/customer.guard';
import { FeedbackreceiverGuard } from './shared/feedbackreceiver.guard';
import { MainServiceResolver } from './shared/main-service.resolver';
import { AdmingroupsComponent } from './views/admingroups/admingroups.component';
import { AdmingroupsResolver } from './views/admingroups/admingroups.resolver';
import { AdminsComponent } from './views/admins/admins.component';
import { CoachesComponent } from './views/coaches/coaches.component';
import { CoachesResolver } from './views/coaches/coaches.resolver';
import { CustomersComponent } from './views/customers/customers.component';
import { CustomgroupComponent } from './views/customgroup/customgroup.component';
import { CustomgroupResolver } from './views/customgroup/customgroup.resolver';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DocumentComponent } from './views/document/document.component';
import { DocumentsResolver } from './views/document/document.resolver';
import { ErrorComponent } from './views/error/error.component';
import { FeedbackInvitationsComponent } from './views/feedback-invitations/feedback-invitations.component';
import { FeedbacksComponent } from './views/feedbacks/feedbacks.component';
import { HelpAreaComponent } from './views/help-area/help-area.component';
import { ImportComponent } from './views/import/import.component';
import { LoginHistoryComponent } from './views/login-history/login-history.component';
import { LoginComponent } from './views/login/login.component';
import { MailManagerComponent } from './views/mail-manager/mail-manager.component';
import { MyDocumentsComponent } from './views/my-documents/my-documents.component';
import { MyFeedbacksComponent } from './views/my-feedbacks/my-feedbacks.component';
import { MyUserComponent } from './views/my-user/my-user.component';
import { NominationComponent } from './views/nomination/nomination.component';
import { PasswordRecoveryComponent } from './views/password-recovery/password-recovery.component';
import { PerspectiveComponent } from './views/perspective/perspective.component';
import { SubsidiariesComponent } from './views/subsidiaries/subsidiaries.component';
import { SubsidiariesResolver } from './views/subsidiaries/subsidiaries.resolver';
import { SurveysComponent } from './views/surveys/surveys.component';
import { UsergroupsComponent } from './views/usergroups/usergroups.component';
import { UsergroupsResolver } from './views/usergroups/usergroups.resolver';
import { UsersComponent } from './views/users/users.component';
import { ReportingComponent } from './views_reporting/reporting/reporting.component';
import { SharedReportingComponent } from './views_reporting/shared-reporting/shared-reporting.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'error/:error_code',
    component: ErrorComponent,
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: $localize`:@@feedbackSuiteLoginPage:Login-Seite`
    }
  },
  {
    path: 'password-recovery/:email/:recovery_code',
    component: PasswordRecoveryComponent
  },
  {
    path: 'shared-feedback-report/:feedback_id/:share_token',
    component: SharedReportingComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: $localize`:@@feedbackSuiteHomepage:Hauptansicht`
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'help-area',
        component: HelpAreaComponent,
        data: {
          title: $localize`:@@feedbackSuiteHelpArea:Hilfe anfordern`
        }
      },
      {
        path: 'customers',
        canActivate: [AdminGuard],
        component: CustomersComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navCustomers:Mandanten`
        }
      },
      {
        path: 'documents',
        canActivate: [CustomerGuard],
        component: DocumentComponent,
        resolve: {
          mainservice: MainServiceResolver,
          adminGroups: AdmingroupsResolver,
        },
        data: {
          title: $localize`:@@navDocuments:Dokumente`
        }
      },
      {
        path: 'import',
        component: ImportComponent,
        canActivate: [CustomerGuard],
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@feedbackSuiteImport:Import`
        }
      },
      {
        path: 'users',
        canActivate: [CustomerGuard],
        component: UsersComponent,
        resolve: {
          mainservice: MainServiceResolver,
          subsidiaries: SubsidiariesResolver,
          coaches: CoachesResolver,
          adminGroups: AdmingroupsResolver
        },
        data: {
          title: $localize`:@@navUsers:Nutzer`
        }
      },
      {
        path: 'login-history',
        component: LoginHistoryComponent,
        canActivate: [AdminGuard],
        data: {
          title: $localize`:@@navLoginHistory:Login-Historie`
        }
      },
      {
        path: 'admins',
        canActivate: [AdminGuard],
        component: AdminsComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navAdmins:Administratoren`
        }
      },
      {
        path: 'mail-manager/:survey_id',
        canActivate: [CustomerGuard],
        component: MailManagerComponent,
        data: {
          adminView: false,
          title: $localize`:@@navMailManager:E-Mailvorlagen`
        }
      },
      {
        path: 'mail-manager',
        canActivate: [CustomerGuard],
        component: MailManagerComponent,
        data: {
          adminView: false,
          title: $localize`:@@navMailManager:E-Mailvorlagen`
        }
      },
      {
        path: 'mail-manager-admin',
        canActivate: [AdminGuard],
        component: MailManagerComponent,
        data: {
          adminView: true,
          title: $localize`:@@navMailManagerAdmin:E-Mailvorlagen (Admin)`
        }
      },
      {
        pathMatch: "full",
        path: 'surveys',
        canActivate: [CustomerGuard],
        component: SurveysComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navSurveys:Befragungen`
        }
      },
      {
        path: 'surveys/report/:survey_id',
        component: ReportingComponent,
        data: {
          //title: $localize`:@@navMyFeedbacks:Meine Feedbacks`
        },
        resolve: {
          mainservice: MainServiceResolver
        }
      },
      {
        path: 'customgroup/:survey_id',
        canActivate: [CustomerGuard],
        component: CustomgroupComponent,
        resolve: {
          data: CustomgroupResolver,
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navCustomGroup:Zusatzgruppierung von Fragen`
        }
      },
      {
        path: 'usergroups',
        canActivate: [CustomerGuard],
        component: UsergroupsComponent,
        resolve: {
          data: UsergroupsResolver,
          mainservice: MainServiceResolver,
          adminGroups: AdmingroupsResolver
        },
        data: {
          title: $localize`:@@navUserGroups:Befragungsgruppen`
        }
      },
      {
        path: 'admingroups',
        canActivate: [CustomerGuard],
        component: AdmingroupsComponent,
        resolve: {
          adminGroups: AdmingroupsResolver,
          mainservice: MainServiceResolver,
          documents: DocumentsResolver,
          coaches: CoachesResolver
        },
        data: {
          title: $localize`:@@navAdminGroups:Teams`
        }
      },
      {
        path: 'subsidiaries',
        canActivate: [CustomerGuard],
        component: SubsidiariesComponent,
        resolve: {
          subsidiaries: SubsidiariesResolver,
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navSubsidiaries:Tochtergesellschaften`
        }
      },
      {
        path: 'coaches',
        canActivate: [CustomerGuard],
        component: CoachesComponent,
        resolve: {
          mainservice: MainServiceResolver,
          coaches: CoachesResolver,
          adminGroups: AdmingroupsResolver
        },
        data: {
          title: $localize`:@@navCoaches:Coaches`
        }
      },
      {
        path: 'perspective/:survey_id',
        canActivate: [CustomerGuard],
        component: PerspectiveComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navPerspective:Perspektive`
        }
      },
      {
        path: 'perspective/:survey_id/:perspective_id',
        canActivate: [CustomerGuard],
        component: PerspectiveComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navPerspective:Perspektive`
        }
      },
      {
        path: 'feedbacks',
        canActivate: [CustomerGuard],
        component: FeedbacksComponent,
        data: {
          title: $localize`:@@navFeedbacksOverview:Feedback Übersicht`
        },
        resolve: {
          mainservice: MainServiceResolver
        },
        children: [
          {
            path: ':feedback_id',
            component: FeedbacksComponent,
            resolve: {
              mainservice: MainServiceResolver
            },
            data: {
              title: $localize`:@@navFeedback:Feedback`
            }
          }
        ]
      },
      {
        path: 'my-feedbacks',
        canActivate: [FeedbackreceiverGuard],
        data: {
          title: $localize`:@@navMyFeedbacks:Meine Feedbacks`,
          resolve: {
            mainservice: MainServiceResolver
          }
        },
        children: [
          {
            path: ':feedback_id/nomination',
            component: NominationComponent,
            pathMatch: 'full',
            resolve: {
              mainservice: MainServiceResolver
            },
            data: { title: $localize`:@@navNomination:Nominierung` }
          },
          {
            path: ':feedback_id/nomination/:perspective',
            component: NominationComponent,
            pathMatch: 'full',
            resolve: {
              mainservice: MainServiceResolver
            },
            data: { title: $localize`:@@navNomination:Nominierung` }
          },
          {
            path: ':feedback_id/report',
            pathMatch: 'full',
            resolve: {
              mainservice: MainServiceResolver
            },
            data: { title: $localize`:@@navReporting:Bericht` },
            children: [
              {
                path: '**',
                component: ReportingComponent,
                data: {
                  title: $localize`:@@navMyFeedbacks:Meine Feedbacks`
                },
                resolve: {
                  mainservice: MainServiceResolver
                }
              }
            ]
          },
          {
            path: '**',
            component: MyFeedbacksComponent,
            data: {
              title: $localize`:@@navMyFeedbacks:Meine Feedbacks`
            },
            resolve: {
              mainservice: MainServiceResolver
            }
          }
        ]
      },
      {
        path: 'my-documents',
        component: MyDocumentsComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navMyDocuments:Meine Dokumente`
        }
      },
      {
        path: 'nomination/:feedback_id',
        canActivate: [FeedbackreceiverGuard],
        component: NominationComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: { title: $localize`:@@navNomination:Nominierung` }
      },
      {
        path: 'feedback-invitations',
        component: FeedbackInvitationsComponent,
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navInvitations:Feedback geben`
        }
      },
      {
        path: 'myuser',
        resolve: {
          mainservice: MainServiceResolver
        },
        data: {
          title: $localize`:@@navMyUser:Meine Nutzerinformationen`
        },
        component: MyUserComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
