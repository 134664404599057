<app-page-header>
    <app-ds-search
        (searchEvent)="dsSearch($event)"
        placeholder="Dokumente durchsuchen"
        i18n-placeholder="@@myDocumentsSearchDocuments"
    ></app-ds-search>
</app-page-header>
<lib-rag-datasupply-overlay-asside
    #overlay
    (closed)="this.urlHelper.setSelectedId(undefined)"
>
    <div
        class="p-5"
        *ngIf="overlay.selectedObject"
    >
        <rag-form
            [translateTexts]="translateTexts"
            [translatedLabels]="translatedLabelsForm"
            [data]="overlay.selectedObject"
            [formFields]="[
                { fieldName: 'metadata.name', required: true, type: 'text' },
                { fieldName: 'metadata.allowedRole', type: 'select', required: true,
                    options: {
                        selectValues: [
                            {label: this.hrTranslation, value: 'hr'},
                            {label: this.hrFnTranslation, value: 'feedbackreceiver'},
                            {label: this.hrFnFgTranslation, value: 'feedbackgiver'}
                        ],
                        bindValue: 'value', bindLabel: 'label'
                    }
                },
                {
                    fieldName: 'metadata.adminGroups', type: 'ng-multiselect-dropdown', required: this.loginService.loggedInUser.type=='groupadmin',
                    options: {
                        placeholder: '...',
                        enableCheckAll: true,
                        handleValuesAsIds: true,
                        dropdownList: this.adminGroups,
                        idField: '_id',
                        textField: 'name',
                        unSelectAllText: translatedLabelsForm.get('unSelectAllText'),
                        selectAllText: translatedLabelsForm.get('selectAllText')
                    }
                },
                {
                    fieldName: '',
                    type: 'template',
                    options: {
                        template: allowedUserSelect
                    }
                }
            ]"
            (delete)="deleteItem($event); overlay.selectedObject = undefined"
            (submit)="updateItem($event)"
        ></rag-form>
        <ng-container *ngIf="overlay.selectedObject">
            <div class="mt-3"><b i18n="@@globalMoreOptions">Weitere Optionen:</b>
                <div class="row">
                    <div class="col-auto">
                        <div
                            i18n="@@documentOpenPreview"
                            class="btn btn-primary"
                            (click)="openDocumentPreview(overlay.selectedObject._id, overlay.selectedObject.metadata.name)"
                        >Vorschau öffnen</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">

    <app-content-headline
        i18n-pagetitle="@@documentsCardTitle"
        pagetitle="Dokumente"
        #headline
    ></app-content-headline>
    <app-new-and-help-row
        #newhelpRow
        [showNewButton]="headline.switcher.isTable()"
        newBtnLabel="Anlegen"
        i18n-newBtnLabel="@@globalAdd"
        [newFormTpl]="newFormTpl"
        topic="documents"
    >
        <div class="row">
            <div
                class="col"
                i18n="@@documentsCol1"
            >
                Hier können Unterlagen den feedbackgebenen und -nehmenden Personen zur Verfügung gestellt werden.
            </div>
        </div>
    </app-new-and-help-row>
    <div class="content-grid-content overflow-visible">
        <ng-container *ngIf="headline.switcher.isGrid()">
            <lib-rag-datasupply-grid
                [templateFirstItem]="firstTile"
                [template]="tile"
                [service]="this.frontendService"
            >
            </lib-rag-datasupply-grid>
        </ng-container>
        <ng-container *ngIf="headline.switcher.isTable()">
            <app-card>
                <lib-rag-datasupply-table
                    #table
                    (onSelect)="this.overlay.selectedObject = table.selectedObject; this.urlHelper.setSelectedId(table.selectedObject._id)"
                    [service]="this.frontendService"
                    [columns]="[{ fieldName: 'metadata.name', label: 'Name' ,renderFunc:this.getMetaName.bind(this) }]"
                >
                    <ng-container *ngIf="table.selectedObject">
                    </ng-container>
                </lib-rag-datasupply-table>
            </app-card>
        </ng-container>
    </div>
</div>




<!-- TEMPLATES -->

<ng-template
    #allowedUserSelect
    let-data="data"
    let-initialdata="initialdata"
    let-formfield="formfield"
    let-flatdata="flatdata"
>
    <ng-container *ngIf="data">


        <div class="input-group mb-3">
            <ng-select
                #userInput
                id="selectUsers"
                class="form-control"
                [items]="this.users"
                [multiple]="false"
                [closeOnSelect]="true"
                bindLabel="email"
                bindValue="_id"
                i18n-placeholder="@@selectUserForDocument"
                placeholder="Nutzer auswählen"
                [(ngModel)]="userForAdd"
            ></ng-select>
            <div class="input-group-append">
                <div
                    class="btn btn-primary"
                    (click)="addUser(data)"
                    [ngClass]=" {'disabled':userForAdd==null}"
                >
                    <i class="ri-add-line"></i>
                </div>
            </div>
        </div>
        <ul class="list-group list-group-flush">

            <li
                class="list-group-item pe-0"
                *ngFor="let uid of data.metadata.allowedUsers"
            >
                <div class="row">
                    <div class="col">
                        {{getUserLabel(uid) }}
                    </div>
                    <div class="col-auto">
                        <div
                            class="btn btn-outline-danger btn-sm"
                            (click)="removeUser(data, uid)"
                        >
                            <i class="ri-close-line"></i>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </ng-container>
</ng-template>

<ng-template
    #groupTmp
    ng-label-tmp
    ng-option-tmp
    let-item="item"
    let-search="searchTerm"
    let-data="data"
    let-formField="formField"
>
    <ng-option value="{{item.value}}">
        {{item.label}}
    </ng-option>
</ng-template>

<ng-template #newFormTpl>
    <div
        *ngIf="this.upload == null || this.upload.state == 'DONE'"
        class="btn btn-primary"
        (click)="fileInput.click()"
        i18n="@@documentUploadSelectFile"
    >
        Datei auswählen
    </div>
    <div *ngIf="this.fileForUpload != null">
        {{this.fileForUpload?.name}}
    </div>
    <input
        hidden
        accept="application/pdf"
        type="file"
        #fileInput
        (change)="onFileInput(fileInput.files)"
    />
    <rag-multiselect-dropdown
        #adminGroupsSelection
        [standalone]="true"
        [label]="this.translatedLabelsForm.get('metadata.adminGroups')"
        [value]="[]"
        [fieldName]="'adminGroupsSelection'"
        [required]="this.loginService.loggedInUser.type=='groupadmin'"
        [options]="
        {
        placeholder: '...'
        ,
        enableCheckAll:
        true,
        handleValuesAsIds:
        true,
        dropdownList:
        this.adminGroups,
        idField: '_id'
        ,
        textField: 'name'
        }"
    ></rag-multiselect-dropdown>

    <div
        *ngIf="this.fileForUpload != null && (this.upload == null || this.upload.state=='DONE')"
        type="submit"
        class="btn btn-primary mt-3"
        (click)="this.onSubmit();"
        i18n="@@documentUploadSubmit"
    >
        Hochladen
    </div>

    <div *ngIf="upload">
        <progress
            max="100"
            [value]="upload.progress"
        > </progress>
        {{upload.progress}} %
    </div>

</ng-template>

<ng-template
    #tile
    let-data="data"
>
    <app-fbs-grid-card
        (click)="overlay.selectedObject=data; this.urlHelper.setSelectedId(data._id)"
        [config]="{
            icon: 'ri-file-text-line',
            iconColor: '#A78042',
            headerBgColor: '#E2DACC'
        }"
    >
        <ng-container card-body>
            <div class="row">
                <div class="col-1"></div>
                <div class="col">
                    <b>{{data.metadata.name}}</b>
                </div>
            </div>
        </ng-container>
    </app-fbs-grid-card>
</ng-template>

<ng-template #firstTile>
    <div
        class="btn btn-outline-primary d-flex  shadow "
        *ngIf="this.documents"
        (click)="newhelpRow.newOverlay.open()"
    >
        <i class="ri-add-line ri-5x align-self-center m-auto   "></i>
    </div>
</ng-template>