import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxForm, ObjectDepthService } from '@reflact/ngx-forms';
import { AllPrmUserType, PrmUserType, UserForCreationData, UserGroup } from '@reflact/prmfeedback';
import { DatasupplyRequest, RagDatasupplyFrontendService, RagDatasupplyLocalBackendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTableQuicklookAsideComponent, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import * as EmailValidator from 'email-validator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { translateTexts } from '../../app.component';
import { CustomerService } from '../../shared/customer.service';
import { MainService } from '../../shared/main.service';
import { NewAndHelpRowComponent } from '../../shared/new-and-help-row/new-and-help-row.component';
import { UserService } from '../../shared/user.service';
import { LoginService } from '../login/login.service';
import { TableSortUtil } from './../../shared/TableSortUtil';
import { UserGroupsService } from './../../shared/user-groups.service';

@Component({
  selector: 'app-page-user-management',
  templateUrl: './page-user-management.component.html',
  styleUrls: [],
  providers: [
    RagDatasupplyLocalBackendService
  ]
})
export class PageUserManagementComponent<T extends AllPrmUserType> implements OnInit, OnDestroy, OnChanges {
  @ViewChild('userGroups') userGroups;
  @ViewChild('table') table: RagDatasupplyTableQuicklookAsideComponent<AllPrmUserType>;
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  @Input() public backendServiceUserType: PrmUserType | 'all';
  @Input() public allTypes: { label: string, value: (PrmUserType | 'all') }[] = [];
  @Input() public service: RagDatasupplyFrontendService<T>;
  @Input() public isAdminArea: boolean = false;
  @Input() public singularName: string = 'singularName';
  @Input() public pluralName: string = 'pluralName';
  @Input() public columns: RagDatasupplyTypes.TableColumn[];
  @Input() public formFields: NgxForm.FormField[];
  @Input() public formCreationData: any;
  @Input() public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = [];
  @Input() public translatedLabelsForm: NgxForm.TranslatedLabels = new Map();
  @Output() public tableSelect: EventEmitter<T> = new EventEmitter();
  @Output() public userCreate: EventEmitter<T> = new EventEmitter();

  public translateTexts = translateTexts;
  public searchPlaceholder = "";
  public archiveUserModalRef: BsModalRef;
  public newUserText = "";
  public pageTitle = "";
  public types: PrmUserType[] = [];
  public subscriptions: Subscription[] = [];
  private users;
  public editTitle;
  public singularTitle;
  private selectedUser: AllPrmUserType;
  public userGroupsService: UserGroupsService;
  public selectableGroups: UserGroup[] = [];
  public selectedGroup: UserGroup;
  public tableSort: TableSortUtil<AllPrmUserType>;
  public myUser: AllPrmUserType;
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["firstname", "lastname", "email", "type"]
  }
  constructor(public toaster: ToastrService,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    public customerService: CustomerService,
    public modalService: BsModalService,
    public mainService: MainService,
    public loginService: LoginService,
    public backendService: RagDatasupplyLocalBackendService<T>,
    public urlHelper: RagDatasupplyOverlayUrlService,
    public objService: ObjectDepthService
  ) {
    this.userGroupsService = mainService.userGroupsService;
    this.myUser = this.loginService.loggedInUser$.value;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.backendServiceUserType && !changes.backendServiceUserType.isFirstChange()) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.tableSort = new TableSortUtil<AllPrmUserType>(this.service);
    this.backendService.loadData = () => {
      return new Promise<T[]>((resolve, reject) => {
        if (this.users) {
          resolve([...this.users])
        } else {
          reject([])
        }
      })
    }
    this.editTitle = this.singularName + $localize`:@@globalEditText: bearbeiten`;
    this.singularTitle = $localize`:@@globalNew:Neuer ` + this.singularName;
    this.searchPlaceholder = $localize`:@@pageUserSearch: ${this.pluralName} durchsuchen`
    this.subscriptions.push(this.userService.error$.subscribe((i) => {
      this.toaster.error(i.message);
    }));

    this.subscriptions.push(this.userService.usersCreated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalUserCreated:Benutzer erfolgreich angelegt`);
      this.loadData();
    }));

    this.subscriptions.push(this.userService.userUpdated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalUserUpdated:Benutzer erfolgreich gespeichert`);
    }));


    this.subscriptions.push(this.userService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.loadData();
      }
    }));

    this.subscriptions.push(this.backendService.results$.subscribe(results => {
      results.forEach(u => {
        u.benchmarkValues.sort((a, b) => a.id.localeCompare(b.id));
      })

      const dataForTable = results.filter(u => !u.archived)
      if (this.loginService.loggedInUser.type == "groupadmin") {
        for (const u of dataForTable) {
          u.adminGroups = this.mainService.filterAdminGroupIdsForGroupAdmin(this.loginService.loggedInUser, u.adminGroups);
        }
      }
      this.service.fromArray(dataForTable);



      this.urlHelper.getSelectedId(true);
    }));

    this.urlHelper.newSelection$.subscribe(s => {
      this.overlay.selectedObject = this.users.find(u => u._id == s);
      if (this.overlay.selectedObject) {
        this.overlay.selectedObject.adminGroups = this.mainService.filterAdminGroupIdsForGroupAdmin(this.loginService.loggedInUser, this.overlay.selectedObject.adminGroups);
      }
    });

    this.mainService.isDataLoaded.subscribe(done => {
      if (!done) return
      if (this.backendServiceUserType == "prmadmin") return;
      if (!this.formFields.find(ff => ff.fieldName.startsWith("benchmarkValues"))) {

        const customer = this.customerService.customerMap.get(this.loginService.loggedInUser.customer_id);
        customer.benchmarkConfigs.sort((a, b) => a.id.localeCompare(b.id)).forEach((bc, index) => {
          this.formCreationData.benchmarkValues.push({ id: bc.id, value: '' });
          this.translatedLabelsForm.set('benchmarkValues.' + index + '.value', bc.label);
          this.formFields.push({ fieldName: 'benchmarkValues.' + index + '.value', type: 'text', label: bc.label })
        })
      }
    });
  }

  public dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest)
  }

  public async updateItem(i: AllPrmUserType) {
    if (!EmailValidator.validate(i.email)) {
      this.toaster.error($localize`:@@globalInvalidMail:Ungültige E-Mail` + ': ' + i.email);
      return;
    }
    await this.userService.updateUser(i);
  }

  public async deleteItem(i: AllPrmUserType) {
    await this.userService.deleteUsers([i]);
    this.toaster.success($localize`:@@globalUserDeleted:Benutzer erfolgreich gelöscht`);
  }

  public async createItem(i: AllPrmUserType, newAndHelpViewComp: NewAndHelpRowComponent) {
    if (!EmailValidator.validate(i.email)) {
      this.toaster.error($localize`:@@globalInvalidMail:Ungültige E-Mail` + ': ' + i.email);
      return;
    }
    if (this.userService.users.find(u => u._id != null && u.email.trim().toLowerCase() == i.email.trim().toLowerCase()) != null) {
      this.toaster.error($localize`:@@globalDuplicateMail:E-Mail existiert bereits` + ': ' + i.email);
      return
    }

    await this.userService.createUsers([i]);
    newAndHelpViewComp.newOverlay.close()
    return
  }

  public loadData() {
    const users = (this.backendServiceUserType === 'all' ? this.userService.users : this.userService.usersByType.get(this.backendServiceUserType)) as T[];
    if (this.backendServiceUserType === 'all') {
      this.users = users.filter(u => u.type !== 'prmadmin');
    } else {
      this.users = users;
    }
    this.backendService.load(this.standardRequest)
    this.setTexts(this.users.length);
  }

  public onImportObjects(objects: UserForCreationData[]) {
    this.userService.createUsers(objects);
  }

  private setTexts(userCount: number) {
    this.newUserText = $localize`:@@globalNew:Neuer ` + " " + this.singularName
    this.pageTitle = this.pluralName + ' - ' + userCount + ' -';
  }

  public archiveUserConfirm(template: TemplateRef<any>, user: AllPrmUserType) {
    this.selectedUser = user;
    this.archiveUserModalRef = this.modalService.show(template);
  }

  public archiveUser() {
    this.userService.archiveUser(this.selectedUser);
    this.archiveUserModalRef.hide();
    this.table.closeQuicklook();
  }

  public async inviteUser(user: AllPrmUserType) {
    this.userService.inviteUser(user);
    this.toaster.success($localize`:@@globalUserInvited:Benutzer erfolgreich eingeladen`);
  }

  public filterSelectableGroups(gids: string[]) {
    this.selectedGroup = undefined;
    this.selectableGroups = this.userGroupsService.userGroups.filter(g => !gids.includes(g._id));
  }
}