import { Component, Input, OnInit } from '@angular/core';
import { Perspective } from '@reflact/prmfeedback';
import { ColorService } from '../../shared/color.service';
import { PerspectivesData, ReportData } from '../../shared/feedback-report.service';
import { ScaleEntry } from './../../shared/feedback-report.service';

@Component({
  selector: 'app-reporting-group',
  templateUrl: './reporting-group.component.html',
  styleUrls: ['./reporting-group.component.scss']
})
export class ReportingGroupComponent implements OnInit {
  @Input() perspectives: Perspective[];
  @Input() public isOdd: boolean = false;
  @Input() public group: ReportData;
  @Input() public groupIndex: number;
  @Input() public scale: ScaleEntry[];
  @Input() public scaleMin: number;
  @Input() public scaleMax: number;
  @Input() public responseRatesMap: Map<string, number>;
  public viewMode: 'open' | 'close' | 'print' = 'close';

  constructor(public colorService: ColorService) { }

  ngOnInit(): void {
    //for server-side pdf generation
    if (localStorage.getItem("openAll") != null) {
      this.viewMode = 'print';
    }
  }

  public perspectiveNameById(id) {
    let found: Perspective[] = this.perspectives.filter((p: Perspective) => p.id == id);
    return found[0].name;

  }
  public evenOrOdd(perspectivesIndex: number): 'even' | 'odd' {
    const totalIndex = (this.groupIndex * this.perspectives.length) + perspectivesIndex;
    return totalIndex % 2 === 0 ? 'even' : 'odd';
  }
  public filterPerspectiveData(perspectiveData: PerspectivesData[]) {
    const pData = perspectiveData.filter(pD => this.perspectives.some(p => p.id === pD.perspectiveId));
    return pData
  }
  public changeViewMode() {
    if (this.viewMode === 'close') {
      this.viewMode = 'open';
    } else {
      this.viewMode = 'close';
    }
  }
  public getResponseRate(perspectiveId: string) {
    if (this.responseRatesMap.has(perspectiveId)) {
      return this.responseRatesMap.get(perspectiveId);
    } else {
      return 0;
    }
  }

}
