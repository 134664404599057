import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';

@Component({
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  constructor(
    private loginService: LoginService
  ) {

  }
  ngOnInit(): void {
    this.loginService.navigateAfterLogin();
  }
}
