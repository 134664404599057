import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdminGroupsService } from '../../shared/admin-groups.service';

@Injectable({
  providedIn: 'root'
})
export class AdmingroupsResolver {
  constructor(private adminGroupsService: AdminGroupsService) { }
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return await this.adminGroupsService.getAdminGroups();
  }
}
