<table
  class="w-100"
  aria-label="reporting"
>
  <th>
    <tr>
      <td class="d-print-table-cell d-none">
        <!-- print header x
-->
        <img
          class="sidebar-brand-full"
          height="46"
          [src]="this.layoutService.getLogoUrl()"
          alt="logo"
        >
        <hr>
      </td>
    </tr>
  </th>
  <tbody>
    <tr>
      <td>
        <section
          class="pb-5 d-none d-print-block mt-5 "
          style="page-break-after: always;"
        >
          <h1 class="text-center">{{feedbackName}}</h1>
          <h2
            class="text-center"
            i18n="@@reportingResultFor"
          >Ergebnisbericht für:</h2>
          <h3 class="text-center">{{feedbackReceiverName}}</h3>
          <br>
          <br>
          <h3 class="text-center">{{getDateString()}}</h3>
          <h3
            class="text-center"
            i18n="@@reportingConfidentialReport"
          >Dieser Bericht ist vertraulich.</h3>

        </section>
        <section class="pb-5">
          <app-page-header>
          </app-page-header>
          
          <app-content-headline
            i18n-pagetitle="@@reportingResponseHeader"
            pagetitle="Übersicht Rückläufe per Perspektive"
            [showSwitcher]="false"
          >
          
            <div
              before
              class="no-print"
            ><a
                class="text-primary text-decoration-none d-flex align-middle d-shared-invisible"
                style="font-family: sans-serif;"
                [routerLink]="'/my-feedbacks'"
                [queryParams]="{selected: this.feedbackId}"
                i18n="@@reportingBackBtn"
              ><i class="ri-arrow-left-line"></i> Feedback-Übersicht</a></div>

            <i class="ri-team-fill fix-ri"></i>{{getGlobalResponseRate()}}
          </app-content-headline>
          <!--
FULL DATA: {{data | json}}
-->
          <div class="row  mt-3 ">
            <div class="col-12 col-xl-6 ">
              <app-card [useCardBody]="false">
                <div class="row perspectiveHeader m-2">
                  <div
                    class="col-6 py-2 "
                    *ngFor="let perspective of allPerspectives; let pIndex = index"
                    [ngClass]="{
        'even': 0 == pIndex % 2,
        'odd': 0 !== pIndex % 2}"
                  >
                    <div class="row ">
                      <div
                        class="col"
                        *ngIf="perspective.id != 'SE'"
                      >{{this.loginService.translate(perspective.name)}}</div>
                      <div
                        class="col"
                        *ngIf="perspective.id == 'SE'"
                      >{{this.loginService.translate("Selbsteinschätzung")}}</div>
                      <div class="col text-end text-muted"><i class="ri-team-fill fix-ri"></i>
                        {{getReponseRate(perspective.id)}}/{{getNominationRate(perspective.id)}}
                      </div>
                    </div>

                  </div>
                </div>
              </app-card>
            </div>
          </div>
        </section>

        <popper-content #copied>
          <span
            class=""
            i18n="@@reportingCopiedText"
          >
            Link in Zwischenablage kopiert.
            <br>
            Ihr Freigabelink ist 7 Tage gültig.
          </span>
        </popper-content>
        <section class="debug">
          <app-content-headline
            class="pt-2 d-print-none"
            id="reportingGroupHeader"
            i18n-pagetitle="@@reportingGroupHeader"
            pagetitle="Übersicht Themenfelder"
            [showSwitcher]="false"
          >
            <ng-container afterSwitcher>
              <div class="row">
                <div class="col text-end">
                  <div
                    *ngIf="isSurveyView"
                    (click)="openBenchmarkFilterModal()"
                    class=" text-primary   cursor-pointer fw-normal d-print-none"
                    i18n="@@benchmarkOpenBtnLabel"
                  ><i class="ri-filter-line text-primary fix-ri"></i> Filter</div>
                </div>
                <div
                  class="col-auto"
                  *ngIf="!isSurveyView && this.loginService.shareToken == undefined"
                >
                  <div
                    [popper]="copied"
                    [popperTrigger]="popperTriggerClick"
                    (popperOnShown)="popperShow($event)"
                    (click)="copyShareReportLink()"
                    class="text-primary text-decoration-none d-print-none cursor-pointer"
                  >
                    <i class="ri-share-box-line text-primary fix-ri"></i>
                    <span
                      class="text-primary"
                      i18n="@@reportingShareBtnText"
                    >Teilen</span>
                  </div>
                </div>
                <div class="col-auto">
                  <a
                    [href]="getDownloadUrl()"
                    class="text-primary text-decoration-none d-print-none d-shared-invisible"
                    i18n="@@globalDownload"
                  > <i class="ri-printer-line text-primary fix-ri"></i> Herunterladen</a>
                </div>
              </div>

            </ng-container>
            <ng-container after>
              <div class="row my-0 align-items-center  mb-3 pb-3 d-print-none">
                <div class="col-12 col-lg-auto">
                  <div i18n="@@reportingGroupFilter">Selbsteinschätzung vergleichen mit:</div>
                </div>
                <div class="col">
                  <span
                    class="btn btn-outline-secondary me-2 py-1 cursor-pointer  fw-normal "
                    *ngFor="let perspective of this.allPerspectives"
                    [ngStyle]="{'background-color': isSelected(perspective.id) ? this.colorService.stringToColor(perspective.name): 'transparent'}"
                    [ngClass]="{
              'text-dark  ': !isSelected(perspective.id) ,
              'text-white': isSelected(perspective.id)
            }"
                    (click)="togglePerspectiveFilter(perspective.id)"
                  >{{perspective.name}}</span>
                </div>
                <div class="col-auto">
                  <ng-container *ngTemplateOutlet="legende"></ng-container>
                </div>
              </div>



            </ng-container>

          </app-content-headline>



          <app-card [useCardBody]="false">
            <div
              id="scaleHeader"
              *ngIf="!onlyText"
              class=" position-sticky bg-white pt-5 mt-2 mx-0 align-items-center sticky-print-none"
              style="top:110px; z-index: 1;"
            >
              <div class="m-0 p-0 d-print-none">

                <app-reporting-header-with-scala [scale]="scale">

                </app-reporting-header-with-scala>
              </div>
            </div>

            <ng-container *ngIf="!onlyText">
              <ng-container *ngFor="let g of data; let gIndex = index">

                <div style="page-break-before: always;">
                  <app-reporting-group
                    [perspectives]="selectedPerspectives"
                    [responseRatesMap]="responseRatesMap"
                    [group]="g"
                    [scale]="scale"
                    [scaleMax]="scaleMax"
                    [scaleMin]="scaleMin"
                    [isOdd]="0 !== gIndex % 2 "
                    [groupIndex]="gIndex"
                  ></app-reporting-group>
                </div>
              </ng-container>
            </ng-container>
            <app-text-comments-group
              [perspectives]="selectedPerspectives"
              [allPerspectives]="allPerspectives"
              [data]="data"
            ></app-text-comments-group>
          </app-card>
        </section>


        <ng-template #legende>
          <div class="row my-4 align-items-center text-smaller d-print-none">
            <div class="col-12 col-sm-auto">
              <div class="row  align-items-center ">
                <div class="col-auto me-0 pe-0">
                  <div
                    style="height: 30px;width: 4px;display: inline-block;border-radius: 5px;"
                    [ngStyle]="{'background-color': this.colorService.stringToColor('SE')}"
                  >
                  </div>
                </div>
                <div
                  class=" col-auto  ps-2"
                  i18n="@@reportingLegendSelfestimation"
                >
                  <span>SE: Selbsteinschätzung</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row  align-items-center ">
                <div class="col-auto me-0 pe-0">
                  <div
                    style="height: 20px;  width: 20px;    display: inline-block;    background-color: #cccc;    border-radius: 0px;top:2px"
                  >
                  </div>
                </div>
                <div class=" col-auto  ps-2">
                  <span i18n="@@reportingLegendMinMax">Einschätzung Min. (links) / Max. (rechts)</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <section style="page-break-inside: avoid;">
          <app-content-headline
            i18n-pagetitle="@@reportingFactsHeader"
            pagetitle="Weitere Fakten aller Aussagen aller Themenfelder"
            [showSwitcher]="false"
          ></app-content-headline>
          <app-fact-card
            *ngIf="!onlyText"
            [allfacts]="getFacts()"
          ></app-fact-card>
        </section>
      </td>
    </tr>
  </tbody>

  <tfoot>
    <tr>
      <td class="text-center">
        <hr>
        &copy; feedback-suite.com
        <br><br><br>
      </td>
    </tr>
  </tfoot>
</table>