import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Perspective } from '@reflact/prmfeedback';
import { ColorService } from '../../../shared/color.service';
import { ReportData } from '../../../shared/feedback-report.service';
import { LoginService } from '../../../views/login/login.service';

@Component({
  selector: 'app-text-comments-group',
  templateUrl: './text-comments-group.component.html',
  styleUrls: ['./text-comments-group.component.scss']
})
export class TextCommentsGroupComponent implements OnChanges {
  @Input() perspectives: Perspective[];
  @Input() data: ReportData[] = [];
  @Input() allPerspectives: Perspective[];
  public hasTextAnswers: boolean = false;
  public viewMode: 'open' | 'close' | 'print' = 'open';
  constructor(public colorService: ColorService, private loginService: LoginService) {
    if (localStorage.getItem("openAll") != null) {
      this.viewMode = 'print';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      this.hasTextAnswers = this.includesTextAnswers(changes["data"].currentValue)
    }
  }
  public perspectiveNameById(id) {
    if (id === 'SE') {
      return this.loginService.translate('Selbsteinschätzung')
    }
    let found: Perspective[] = this.perspectives.filter((p: Perspective) => p.id == id);
    return found[0].name;
  }
  public filterPerspectiveData(perspectiveData: { perspectiveId: string, comments: string[] }[]) {
    // if allPerspectives includes SE -> HR Report
    // then perspectiveData can also be without SE values
    if (this.allPerspectives.some(p => p.id === 'SE')) {
      return perspectiveData.filter(pD => this.perspectives.some(p => p.id === pD.perspectiveId));
    }
    // otherwise SE if always included
    return perspectiveData.filter(pD => pD.perspectiveId === 'SE' || this.perspectives.some(p => p.id === pD.perspectiveId));
  }
  public changeViewMode() {
    if (this.viewMode === 'close') {
      this.viewMode = 'open';
    } else {
      this.viewMode = 'close';
    }
  }
  private includesTextAnswers(data: ReportData[]) {
    return data.some(perspective => {
      return perspective.textQuestions.some(tQ => {
        return tQ.perspectiveData.some(pD => pD.comments.length > 0) ||
          tQ.subQuestions.some(sQ => sQ.perspectiveData.some(pD => pD.comments.length > 0))
      })
    });
  }
}
