<app-page-header></app-page-header>
<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        i18n-pagetitle="@@helpAreaCardTitle"
        pagetitle="Hilfe anfordern"
        #headline
    ></app-content-headline>
    <app-new-and-help-row
        topic="helpArea"
        [showNewButton]="false"
    >


    </app-new-and-help-row>
    <div class="content-grid-content overflow-visible">
        <app-card>
            <div>
                <div class="tab-area-content-wrapper">
                    <label
                        for="topic"
                        class="form-label"
                        i18n="@@globalSubjectLabel"
                    >Betreff</label>
                    <input
                        type="text"
                        id="topic"
                        class="form-control"
                        [(ngModel)]="topic"
                        aria-describedby="supportTopic"
                    >
                    <div
                        id="supportTopic"
                        class="form-text"
                        i18n="@@globalSubjectInfoText"
                    >
                        Bitte teilen Sie uns den Betreff Ihrer Anfrage mit.
                    </div>
                    <label
                        for="text"
                        class="form-label"
                        i18n="@@globalDescriptionTitle"
                    >Beschreibung</label>
                    <textarea
                        type="text"
                        [(ngModel)]="text"
                        id="text"
                        class="form-control"
                        rows="8"
                        aria-describedby="supportText"
                    >
                    </textarea>
                    <div
                        id="supportText"
                        class="form-text"
                        i18n="@@globalDescriptionInfoText"
                    >
                        Bitte beschreiben Sie Ihr technisches Problem möglichst genau.
                    </div>
                    <button
                        (click)="sendSupportMail()"
                        class="btn btn-sm btn-primary mt-4"
                        i18n="@@globalSendButtonText"
                    >Abschicken</button>
                    <br>
                    <br>
                    <span
                        class="text-muted"
                        i18n="@@globalAltContactText"
                    >Alternativ senden Sie uns eine E-Mail an <a
                            href="mailto:support@feedback-suite.de">support@feedback-suite.de</a>.
                    </span>
                </div>
            </div>
        </app-card>
    </div>
</div>