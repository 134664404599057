import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CustomGroup } from '@reflact/prmfeedback';
import { CustomgroupService } from '../../shared/customgroup.service';

@Injectable({
  providedIn: 'root'
})
export class CustomgroupResolver  {
  constructor(private customGroupService: CustomgroupService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CustomGroup[]> {
    const surveyId = route.params['survey_id'];
    return this.customGroupService.loadCustomGroups(surveyId);
  }
}
