import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public errorCode: string = '';
  private errorMessages = {
    global404: $localize`:@@errorGlobal404:Diese Seite ist nicht erreichbar.`,
    limessurveylink_invalid_1: $localize`:@@errorSurveylinkInvalid1:Dieser Link ist abgelaufen. Bitte kontaktieren Sie den Feedbacknehmer, um einen neuen Link anzufordern.`,
    limessurveylink_invalid_2: $localize`:@@errorSurveylinkInvalid2:Dieser Link ist abgelaufen. Bitte kontaktieren Sie den Feedbacknehmer, um einen neuen Link anzufordern.`,
    limessurveylink_outdated: $localize`:@@errorSurveylinkOutdated:Dieses Feedback ist bereits abgeschlossen.`,
    limessurveylink_usesused: $localize`:@@errorSurveylinkUsesUsed:Sie haben dieses Feedback bereits abgeschlossen.`,
    sharelink_outdated: $localize`:@@errorSharelinkOutdated:Dieser Link ist abgelaufen. Bitte kontaktieren Sie den Feedbacknehmer, um einen neuen Link anzufordern.`,
  };

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.errorCode = params.error_code;
    });
  }

  public getErrorMessage(errorCode: string) {
    return (this.errorMessages[this.errorCode]) ? this.errorMessages[this.errorCode] : errorCode;
  }
}