import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxForm } from '@reflact/ngx-forms';
import { AdminGroup, AllPrmUserType, Coach, FeedbackDocument, Survey, UserGroup } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTableQuicklookAsideComponent } from '@reflact/rag-datasupply';
import { waitSeconds } from '@reflact/tsutil';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest } from 'rxjs';
import { translateTexts } from '../../app.component';
import { TableSortUtil } from '../../shared/TableSortUtil';
import { AdminGroupsService } from '../../shared/admin-groups.service';
import { DocumentService } from '../../shared/document.service';
import { MainService } from '../../shared/main.service';
import { BucketListItem } from './bucketselect/bucketselect.component';

export interface AdminGroupWithGranted {
  userGroup: AdminGroup;
  feedbackgiver: AllPrmUserType[];
  feedbackreceiver: AllPrmUserType[];
  assignedbucketmanager: AllPrmUserType[];
  surveys: Survey[];
  bucketManager: AllPrmUserType[];
  documents: FeedbackDocument[];
  userGroups: UserGroup[];
  coaches: Coach[];
}

@Component({
  selector: 'app-admingroups',
  templateUrl: './admingroups.component.html',
  styleUrls: ['./admingroups.component.scss']
})
export class AdmingroupsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('table') table: RagDatasupplyTableQuicklookAsideComponent<AdminGroup>;
  @ViewChild('overlay') overlay: RagDatasupplyOverlayAssideComponent;
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ['name', $localize`:@@adminGroupsName:Teamname`],
  ]);
  public translateTexts = translateTexts;
  public tableSort: TableSortUtil<AdminGroup>;
  public subscriptions: Subscription[] = [];

  public currentGroupSelectedItems: AdminGroupWithGranted = null;
  public currentGroupAvailableItems: AdminGroupWithGranted = null;
  public adminGroups: AdminGroup[];

  public users: AllPrmUserType[] = [];
  public selectedUser: AllPrmUserType = null;

  public bucketManager: AllPrmUserType[] = [];
  public selectedAdmin: AllPrmUserType = null;

  public surveys: Survey[] = [];
  public selectedSurvey: Survey = null;

  public allDocuments: FeedbackDocument[] = [];
  public unselectedDocuments: FeedbackDocument[] = [];
  public selectedDocument: FeedbackDocument = null;

  public userGroups: UserGroup[] = [];
  public selectedUserGroup: UserGroup = null;

  public coaches: Coach[] = [];
  public selectedCoach: Coach = null;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    public urlHelper: RagDatasupplyOverlayUrlService,
    public toaster: ToastrService,
    public adminGroupService: AdminGroupsService,
    public frontendService: RagDatasupplyFrontendService<AdminGroup>,
    public documentService: DocumentService
  ) {
    this.route.data.subscribe(d => {
      this.adminGroups = d.adminGroups;
      this.allDocuments = d.documents;
      this.coaches = d.coaches;
    });
  }

  ngOnInit(): void {
    this.tableSort = new TableSortUtil(this.frontendService);
    this.subscriptions.push(
      this.mainService.isDataLoaded.subscribe((done) => {
        if (done) {
          this.frontendService.fromArray(this.adminGroups);
        }
      })
    );

    this.subscriptions.push(
      this.urlHelper.newSelection$.subscribe(async (s) => {
        await this.loadGroup(s);
        this.overlay.selectedObject = this.adminGroups.find(u => u._id == s)
      })
    );
  }

  ngAfterViewInit(): void {
    this.urlHelper.getSelectedId(true);
    combineLatest([this.route.queryParams, this.mainService.isDataLoaded]).subscribe(([params, done]) => {
      if (this.table.selectedObject && done) {
        this.loadGroup(this.table.selectedObject._id);
      }
    });

    this.subscriptions.push(
      this.table.onSelect.subscribe(g => this.loadGroup(g._id))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private async loadGroup(id: string) {
    this.selectedUser = undefined;
    this.selectedSurvey = undefined;
    this.currentGroupAvailableItems = {
      bucketManager: this.mainService.userService.users.filter(u => u.type == "groupadmin" && !u.adminGroupsAdmin.includes(id)),
      userGroup: this.adminGroups.find(g => g._id === id),
      feedbackgiver: this.mainService.userService.users.filter(u => u.type == "feedbackgiver" && !u.adminGroups.includes(id)),
      feedbackreceiver: this.mainService.userService.users.filter(u => u.type == "feedbackreceiver" && !u.adminGroups.includes(id)),
      assignedbucketmanager: this.mainService.userService.users.filter(u => u.type == "groupadmin" && !u.adminGroups.includes(id)),
      surveys: this.mainService.customerService.surveys.filter(s => !s.adminGroups.includes(id)),
      documents: this.allDocuments.filter(d => !d.metadata.adminGroups.includes(id)),
      userGroups: this.mainService.userGroupsService.userGroups.filter(ug => !ug.adminGroups.includes(id)),
      coaches: this.coaches.filter(c => !c.adminGroups.includes(id))
    }

    this.currentGroupSelectedItems = {
      bucketManager: this.mainService.userService.users.filter(u => u.type == "groupadmin" && u.adminGroupsAdmin.includes(id)),
      userGroup: this.adminGroups.find(g => g._id === id),

      feedbackgiver: this.mainService.userService.users.filter(u => u.type == "feedbackgiver" && u.adminGroups.includes(id)),
      feedbackreceiver: this.mainService.userService.users.filter(u => u.type == "feedbackreceiver" && u.adminGroups.includes(id)),
      assignedbucketmanager: this.mainService.userService.users.filter(u => u.type == "groupadmin" && u.adminGroups.includes(id)),

      surveys: this.mainService.customerService.surveys.filter(s => s.adminGroups.includes(id)),
      documents: this.allDocuments.filter(d => d.metadata.adminGroups.includes(id)),
      userGroups: this.mainService.userGroupsService.userGroups.filter(ug => ug.adminGroups.includes(id)),
      coaches: this.coaches.filter(c => c.adminGroups.includes(id))
    };
  }

  public async createItem(i) {
    const newGroup = await this.adminGroupService.addAdminGroup(i);
    this.adminGroups = await this.adminGroupService.getAdminGroups();
    this.frontendService.fromArray(this.adminGroups);
    this.urlHelper.setSelectedId(newGroup._id);
    this.loadGroup(newGroup._id);
    this.toaster.success($localize`:@@adminGroupsAddedGroup:Team Hinzugefügt`);
  }

  public async updateItem(i) {
    await this.adminGroupService.editAdminGroup(i);
    this.toaster.success($localize`:@@adminGroupsSavedGroup:Team gespeichert`);
  }

  public async deleteItem(i) {
    await this.adminGroupService.deleteAdminGroup(i._id);
    this.adminGroups = await this.adminGroupService.getAdminGroups();
    this.frontendService.fromArray(this.adminGroups);
    this.toaster.success($localize`:@@adminGroupsRemovedGroup:Team entfernt`);
  }

  public searchFn(term: string, item: AllPrmUserType) {
    return (item.firstname + item.lastname + item.email).toLowerCase().includes(term.toLowerCase());
  }

  public async addGroupToAdmin(groupId: string, user: AllPrmUserType) {
    user.adminGroupsAdmin.push(groupId);
    this.selectedAdmin = undefined;
    await this.adminGroupService.assignAdminGroup({ groupId, assignmentType: 'admingroupadmin', ids: [user._id] });
    this.loadGroup(groupId);
  }

  public async removeGroupFromAdmin(groupId: string, user: AllPrmUserType) {
    user.adminGroupsAdmin = user.adminGroupsAdmin.filter(gid => gid !== groupId);
    await this.adminGroupService.unassignAdminGroup({ groupId, assignmentType: 'admingroupadmin', ids: [user._id] });
    this.loadGroup(groupId);
  }

  public async onAddStack(items: BucketListItem[]) {
    await this.stackProgressItems(items, "add")
  }

  public async onRemoveStack(items: BucketListItem[]) {
    await this.stackProgressItems(items, "remove")
  }

  private async stackProgressItems(items: BucketListItem[], type: "add" | "remove") {
    let func: Function;
    if (type == "add") func = async (a) => this.adminGroupService.assignAdminGroup(a);
    if (type == "remove") func = async (a) => this.adminGroupService.unassignAdminGroup(a);
    let groupId = this.overlay.selectedObject._id;
    let allUserIds: string[] = [];
    let allSurveyIds: string[] = [];
    let allDocumentIds: string[] = [];
    let allUserGroupIds: string[] = [];
    let allCoachIds: string[] = [];
    items.forEach((i: BucketListItem) => {
      switch (i.type) {
        case "feedbackreceiver": case "feedbackgiver": case "assignedbucketmanager":
          allUserIds.push(i.data._id);
          if (type == "add") (i.data as AllPrmUserType).adminGroups.push(groupId);
          if (type == "remove") (i.data as AllPrmUserType).adminGroups = (i.data as AllPrmUserType).adminGroups.filter(gid => gid !== groupId);
          break;
        case "surveys":
          allSurveyIds.push(i.data._id);
          if (type == "add") (i.data as Survey).adminGroups.push(groupId);
          if (type == "remove") (i.data as Survey).adminGroups = (i.data as Survey).adminGroups.filter(gid => gid !== groupId);
          break;
        case "documents":
          allDocumentIds.push(i.data._id);
          if (type == "add") (i.data as FeedbackDocument).metadata.adminGroups.push(groupId);
          if (type == "remove") (i.data as FeedbackDocument).metadata.adminGroups = (i.data as FeedbackDocument).metadata.adminGroups.filter(gid => gid !== groupId);
          break;
        case "userGroups":
          allUserGroupIds.push(i.data._id);
          if (type == "add") (i.data as UserGroup).adminGroups.push(groupId);
          if (type == "remove") (i.data as UserGroup).adminGroups = (i.data as UserGroup).adminGroups.filter(gid => gid !== groupId);
          break;
        case "coaches":
          allCoachIds.push(i.data._id);
          if (type == "add") (i.data as Coach).adminGroups.push(groupId);
          if (type == "remove") (i.data as Coach).adminGroups = (i.data as Coach).adminGroups.filter(gid => gid !== groupId);
          break;
      }
    });
    const toast = this.toaster.info("Processing Updates", "Teams", {
      'extendedTimeOut': 100000000,
      'timeOut': 100000000, // we need to set a timeout otherwise ngx-toastr won't display the progressBar
      'enableHtml': true,
      'tapToDismiss': false,
      'progressBar': true,
      'progressAnimation': 'increasing'
    })//https://github.com/scttcper/ngx-toastr/issues/640
    toast.toastRef.componentInstance.updateProgress = () => {
      toast.toastRef.componentInstance.width = 0
    };
    if (allUserIds.length > 0) await func({ groupId, assignmentType: 'user', ids: allUserIds });
    toast.toastRef.componentInstance.updateProgress = () => {
      toast.toastRef.componentInstance.width = 20
    };
    if (allSurveyIds.length > 0) await func({ groupId, assignmentType: 'survey', ids: allSurveyIds });
    toast.toastRef.componentInstance.updateProgress = () => {
      toast.toastRef.componentInstance.width = 40
    };
    if (allDocumentIds.length > 0) await func({ groupId, assignmentType: 'document', ids: allDocumentIds });
    toast.toastRef.componentInstance.updateProgress = () => {
      toast.toastRef.componentInstance.width = 60
    };
    if (allUserGroupIds.length > 0) await func({ groupId, assignmentType: 'usergroup', ids: allUserGroupIds });
    toast.toastRef.componentInstance.updateProgress = () => {
      toast.toastRef.componentInstance.width = 80
    };
    if (allCoachIds.length > 0) await func({ groupId, assignmentType: 'coach', ids: allCoachIds });
    toast.toastRef.componentInstance.updateProgress = () => {
      toast.toastRef.componentInstance.width = 90
    };

    await this.loadGroup(groupId);
    toast.toastRef.componentInstance.updateProgress = () => {
      toast.toastRef.componentInstance.width = 100
    };
    await waitSeconds(1)
    this.toaster.remove(toast.toastId)

  }
}