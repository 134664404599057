import { Component, EventEmitter, Input, Output } from '@angular/core';

export type FbsGridCardConfig = {
  icon?: string,
  iconColor?: string,
  headerBgColor?: string,
  badgeBgColor?: string,
  badge?: {
    text?: string,
    icon?: string
  }
}

@Component({
  selector: 'app-fbs-grid-card',
  templateUrl: './fbs-grid-card.component.html',
  styleUrls: ['./fbs-grid-card.component.scss']
})
export class FbsGridCardComponent {
  public selectedObject: any;
  @Input() config: FbsGridCardConfig;
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
}