import { Pipe, PipeTransform } from '@angular/core';
import { UserForCreationData } from '@reflact/prmfeedback';

@Pipe({
  name: 'userselectionFilter',
})
export class UserSelectionFilterPipe implements PipeTransform {

  transform(value: UserForCreationData[], selectedUsers: UserForCreationData[], changeNumber: number): unknown {
    if (!selectedUsers) return value;
    return value.filter(o => !selectedUsers.some(user => user.email === o.email));
  }

}
