import { Component, Input } from '@angular/core';
import { ScaleEntry } from './../../../shared/feedback-report.service';

@Component({
  selector: 'app-reporting-header-with-scala',
  templateUrl: './reporting-header-with-scala.component.html',
  styleUrls: []
})
export class ReportingHeaderWithScalaComponent {
  @Input() scale: ScaleEntry[];
}
