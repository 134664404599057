<ng-container *ngIf="hasTextAnswers">
  <div
    class="mainrow mx-0 row align-items-center mb-0 pb-0  removePrintShadow "
    [ngClass]="{'isopen': this.viewMode == 'open'}"
  >
    <div class="col ps-2">
      <span
        i18n="@@textCommentsHeader"
        *ngIf="this.viewMode == 'close'"
      >
        Weitere Kommentare <u
          (click)="changeViewMode()"
          style="cursor: pointer;"
        >anzeigen</u>
      </span>
      <span
        i18n="@@textCommentsHeaderOpen"
        *ngIf="this.viewMode != 'close'"
      >
        Weitere Kommentare
      </span>
    </div>
    <div class="col-auto">
      <i
        class=" ri-2x cursor-pointer d-print-none"
        [ngClass]="{
                'invisible': this.viewMode == 'print',
                'ri-arrow-up-s-line': this.viewMode != 'close',
                'ri-arrow-down-s-line': this.viewMode == 'close'
            }"
        (click)="changeViewMode()"
      ></i>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="this.viewMode != 'close'">

  <ng-container *ngFor="let group of data">
    <ng-container *ngFor="let textQuestion of group.textQuestions">
      <div class="row openBorder ms-0 removePrintShadow me-0">
        <div class="col-12 col-lg-4 ps-2 ">

          <span
            _ngcontent-bgw-c130=""
            class="badge bg-primary"
            style="background-color: #3979fe !important;"
          >{{textQuestion.questionTitle}}</span>
          <br>
          {{textQuestion.questionText}}

        </div>
        <div class="col">
          <div class="row">
            <div class="col pb-5 ps-0  ">

              <ng-container *ngFor="let perspectiveData of filterPerspectiveData(textQuestion.perspectiveData)">

                <div
                  *ngIf="perspectiveData.comments.length > 0"
                  style="border: 1px solid transparent ; page-break-inside: avoid"
                  class="callout  border-1  p-1 mb-0"
                  [ngStyle]="{
                  'border-left': '0px solid '+this.colorService.stringToColor(perspectiveNameById(perspectiveData.perspectiveId))
                }"
                >
                  <span
                    class="badge"
                    style="border-bottom-right-radius: 0px;"
                    [ngStyle]="{
                    'background-color': this.colorService.stringToColor(perspectiveNameById(perspectiveData.perspectiveId))


                  }"
                  >
                    {{perspectiveNameById(perspectiveData.perspectiveId)}}</span>
                  <ul class="list-group list-group-flush mt-2  ">
                    <li
                      *ngFor="let comment of  perspectiveData.comments"
                      class="text-smaller pb-2 list-group-item border-0"
                    >
                      <div class="row">
                        <div class="col-auto p-0"> <i
                            class="ri-message-3-fill "
                            [ngStyle]="{'color': this.colorService.stringToColor(perspectiveNameById(perspectiveData.perspectiveId))}"
                          ></i></div>
                        <div class="col">
                          <div>{{comment}}</div>
                        </div>
                      </div>


                    </li>
                    <li
                      class="text-smaller pb-2 list-group-item border-0"
                      *ngIf="perspectiveData.comments.length == 0"
                      i18n="@@textCommentsNoAnswers"
                    >Keine Freitextantworten</li>

                  </ul>
                </div>
                <div *ngFor="let subQuestion of textQuestion.subQuestions">
                  {{subQuestion.subQuestionTitle}}: {{subQuestion.subQuestionText}}
                  <!--Text Antworten auf Unterfragen-->
                  <div
                    *ngFor="let perspectiveData of filterPerspectiveData(subQuestion.perspectiveData)"
                    style="border: 1px solid transparent;"
                    [ngStyle]="{'border-color': this.colorService.stringToColor(perspectiveNameById(perspectiveData.perspectiveId))}"
                  >
                    {{perspectiveNameById(perspectiveData.perspectiveId)}}
                    <ul>
                      <li *ngFor="let comment of perspectiveData.comments">{{comment}}</li>
                    </ul>
                  </div>

                </div>
              </ng-container>


            </div>

          </div>
        </div>
      </div>



    </ng-container>
  </ng-container>

</ng-container>