<app-page-header></app-page-header>
<lib-rag-datasupply-overlay-asside
  #overlay
  [overrideWidth]="80"
  (closed)="this.urlHelper.setSelectedId(undefined)"
>
  <div
    class="p-5"
    *ngIf="overlay.selectedObject"
  >
    <h4 i18n="@@adminGroups">Basis Einstellungen</h4>
    <rag-form
      [translateTexts]="this.translateTexts"
      [translatedLabels]="this.translatedLabelsForm"
      (delete)="this.deleteItem($event);overlay.selectedObject = undefined"
      (submit)="this.updateItem($event)"
      [formFields]="[
        { fieldName: 'name',type: 'text', label: 'Teamname', required: true }
      ]"
      [data]="overlay.selectedObject"
    ></rag-form>

    <div class="col-12 mt-3">
      <h4 i18n="@@adminGroupsAdmins">Berechtigt zur Verwaltung des Teams:</h4>
      <div class="row">
        <div class="col ">

          <ng-select
            #adminSelect
            [items]="this.currentGroupAvailableItems.bucketManager"
            i18n-placeholder="@@adminGroupsAddAdmin"
            placeholder="Team-Admin hinzufügen"
            bindLabel="email"
            [searchFn]="searchFn.bind(this)"
            [clearable]="false"
            [(ngModel)]="this.selectedAdmin"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
            >
              {{item.firstname}} {{item.lastname}}
              ({{item.email}})
            </ng-template>
          </ng-select>
        </div>
        <div class="col-auto">
          <div
            class="btn btn-primary btn-sm"
            style="height: 36px;  padding-top: calc(0.25rem + 2px)"
            [ngClass]=" {'disabled':!this.selectedAdmin}"
            (click)="this.addGroupToAdmin(overlay.selectedObject._id, this.selectedAdmin)"
          >
            <i class="ri-add-line"></i>
          </div>
        </div>
        <ul class="col-12 list-group list-group-flush">
          <li
            class="list-group-item pe-0"
            *ngFor="let user of currentGroupSelectedItems?.bucketManager"
          >
            <div class="row">
              <div class="col">
                {{user.firstname + " " + user.lastname + " - " + user.email}}
              </div>
              <div class="col-auto">
                <div
                  class="btn btn-outline-danger btn-sm"
                  (click)="this.removeGroupFromAdmin(overlay.selectedObject._id, user)"
                >
                  <i class="ri-close-line"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <hr>
    <h4 i18n="@@adminGroupsAssignment">Zuweisungen</h4>
    <app-bucketselect
      (addStack)="this.onAddStack($event)"
      (removeStack)="this.onRemoveStack($event)"
      [selectedItems]="currentGroupSelectedItems"
      [availableItems]="currentGroupAvailableItems"
    ></app-bucketselect>
  </div>
</lib-rag-datasupply-overlay-asside>
<div class="content-grid">
  <app-content-headline
    [showSwitcher]="false"
    i18n-pagetitle="@@adminGroupsCardTitle"
    pagetitle="Teams"
    #headline
  >
  </app-content-headline>
  <app-new-and-help-row
    #newhelpRow
    [showNewButton]="true"
    newBtnLabel="Anlegen"
    i18n-newBtnLabel="@@globalAdd"
    [newFormTpl]="newFormTpl"
    topic="adminGroups"
  >
  </app-new-and-help-row>
  <div class="content-grid-content overflow-visible">
    <app-card>
      <lib-rag-datasupply-table
        #table
        (sort)="this.tableSort.sort($event)"
        (onSelect)="overlay.selectedObject = table.selectedObject; this.urlHelper.setSelectedId(table.selectedObject._id)"
        [service]="this.frontendService"
        [columns]="[{ fieldName: 'name', label: 'Name'}]"
      >
        <ng-container *ngIf="table.selectedObject"></ng-container>
      </lib-rag-datasupply-table>
    </app-card>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #newFormTpl>
  <rag-form
    [translateTexts]="translateTexts"
    [type]="'create'"
    [data]="{name: '', adminUserIds: []}"
    [formFields]="[
            { fieldName: 'name', type:'text', label: 'Name',  required: true },
        ]"
    (submit)="newhelpRow.newOverlay.close(); this.createItem($event)"
  ></rag-form>
</ng-template>