import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxForm } from '@reflact/ngx-forms';
import { MainService } from './shared/main.service';

export const translateTexts: NgxForm.TranslatedTexts = {
  create: $localize`:@@globalNgxCreate:Erstellen`,
  delete: $localize`:@@globalNgxDelete:Löschen`,
  save: $localize`:@@globalNgxSave:Speichern`,
  revert: $localize`:@@globalNgxReset:Verwerfen`,
  confirmNo: $localize`:@@globalNo:Nein`,
  confirmYes: $localize`:@@globalYes:Ja`,
  confirmText: $localize`:@@globalConfirmText:Bist du dir sicher?`
}

@Component({
  selector: 'body',
  templateUrl: 'app.component.html',
  providers: [MainService]

})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    public mainservice: MainService,
  ) {

  }


  ngAfterViewInit(): void {
    if (localStorage.getItem("pdf-debug") == "true") {
      setTimeout(() => {
        window.print();
      }, 3000)
    }
  }

  private lastUrl: string;
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (this.lastUrl) {
        if (evt.url.split("?")[0] != this.lastUrl.split("?")[0]) {
          window.scrollTo(0, 0);
        }
      }
      this.lastUrl = evt.url;
    });
  }
}
