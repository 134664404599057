<div class="row flex-nowrap">
  <div class="col">
    <b i18n="@@adminGroupsAvailable">Verfügbar</b>
    <app-bucketlist
      [showNumbersInTabs]="true"
      [list]="availableBucketItems"
      #availList
    ></app-bucketlist>
  </div>
  <div class="col-auto mt-5 ">
    <button
      type="button"
      class="btn btn-primary position-relative"
      [disabled]="availList.checkedItems().length == 0"
      (click)="this.addStack.emit(availList.checkedItems())"
    >
      <i class="ri-arrow-right-fill"></i>
      <span
        *ngIf="availList.checkedItems().length > 0"
        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
      >
        {{availList.checkedItems().length}}
      </span>
    </button>
    <hr>
    <button
      type="button"
      class="btn btn-primary position-relative"
      [disabled]="selectedList.checkedItems().length == 0"
      (click)="this.removeStack.emit(selectedList.checkedItems())"
    >
      <i class="ri-arrow-left-fill"></i>
      <span
        *ngIf="selectedList.checkedItems().length > 0"
        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
      >
        {{selectedList.checkedItems().length}}
      </span>
    </button>
  </div>
  <div class="col">
    <b i18n="@@adminGroupsAssigned">Zugewiesen</b>
    <app-bucketlist
      [list]="selectedBucketItems"
      #selectedList
    ></app-bucketlist>
  </div>
</div>