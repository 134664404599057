<div class="row p-0 m-0 {{class}}">
  <div class="col p-0">
    <div
      class="layer"
      data-for="bgsacle"
    >
      <div class="row m-0 bgsacle">
        <ng-container *ngFor="let s of scale">
          <div
            class="col text-center  py-0 cell"
            style="border:0px solid red"
          >
            <div class="dottedLine"></div>
          </div>
        </ng-container>
      </div>

    </div>
    <div
      class="layer"
      data-for="feLine"
    >
      <div
        id="feLine"
        [ngClass]="{'invisible': feMin == 0 || feMin == feMax}"
        [ngStyle]="{'left.%': pointToPos(feMin),
          'width.%' :feLineWidth()
        }"
      ></div>
    </div>
    <div
      class="layer"
      data-for="mean"
    >

      <div
        id="mean"
        [ngClass]="{'invisible': meanPoint == 0}"
        [ngStyle]="{
        'left.%': pointToPos(meanPoint),
        'background-color':this.meanColor
      }"
      ></div>
    </div>
    <div
      class="layer"
      m
      data-for="meanLine"
    >
      <div
        id="meanLine"
        [ngClass]="{'invisible': meanPoint == 0 || sePoint == 0}"
        [ngStyle]="{
       'width.%' :meanLineWidth(),
      'left.%': mathMin(pointToPos(meanPoint),pointToPos(sePoint)),
      'background-color':this.meanColor
      }"
      >
      </div>
    </div>
    <div
      class="layer"
      data-for="se"
    >
      <div
        id="se"
        [ngClass]="{'invisible': sePoint == 0}"
        [ngStyle]="{'left.%': pointToPos(sePoint), 'border-color': this.colorService.stringToColor('SE')}"
      ></div>
    </div>

    <div
      class="layer"
      data-for="feMin"
    >
      <div
        id="feMin"
        class=" position-relative align-items-center d-flex "
        [ngStyle]="{'left.%': pointToPos(feMin)}"
        [ngClass]="{'invisible': feMin == 0 || feMin == feMax }"
      >
        <span class="badge bg-secondary">
          {{this.feMin}}
        </span>

      </div>
    </div>
    <div
      class="layer"
      data-for="feMax"
    >
      <div
        id="feMax"
        class=" position-relative align-items-center d-flex "
        [ngStyle]="{'left.%': pointToPos(feMax)}"
        [ngClass]="{'invisible': feMin == 0 || feMin == feMax}"
      ><span class="badge bg-secondary">{{this.feMax}}</span>
      </div>
    </div>
  </div>
</div>