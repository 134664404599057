import { Component, Input, ViewChild } from '@angular/core';
import { TableSwitcherComponent } from '../table-switcher/table-switcher.component';

@Component({
  selector: 'app-content-headline',
  templateUrl: './content-headline.component.html',
  styleUrls: ['./content-headline.component.scss']
})
export class ContentHeadlineComponent {
  @Input() showSwitcher: boolean = true;
  @Input() class: string = "";
  @Input() id: string = "";
  @ViewChild('switcher', { static: true }) public switcher: TableSwitcherComponent;
  @Input() pagetitle: string = "No Title Set";

  public get switcherMode(): "table" | "grid" {
    if (!this.showSwitcher) {
      return "table"
    } else {
      return this.switcher.mode
    }
  }
}