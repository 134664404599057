import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { Subsidiary } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { translateTexts } from '../../app.component';
import { TableSortUtil } from '../../shared/TableSortUtil';
import { MainService } from '../../shared/main.service';

@Component({
  selector: 'app-subsidiaries',
  templateUrl: './subsidiaries.component.html',
  styleUrls: []
})
export class SubsidiariesComponent implements OnInit {
  public buttons = [$localize`:@@globalOverview:Übersicht`, $localize`:@@globalAdd:Anlegen`];
  public subsidiaries: Subsidiary[];
  public subscriptions: Subscription[] = [];
  public tableSort: TableSortUtil<Subsidiary>;

  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["company", $localize`:@@globalCompany:Firma`],
    ["contact", $localize`:@@globalContact:Kontakt`],
    ["email", $localize`:@@globalEmail:E-Mail`],
    ["country", $localize`:@@globalCountry:Land`],
    ["city", $localize`:@@globalCity:Stadt`],
    ["postalcode", $localize`:@@globalPostalcode:PLZ`],
    ["street", $localize`:@@globalStreet:Straße`],
    ["orderNumber", $localize`:@@globalOrderNumber:Bestellnummer`],
    ["vatId", $localize`:@@globalVatId:Umsatzsteuer-ID`]
  ])

  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public translateTexts = translateTexts;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public toaster: ToastrService,
    public mainService: MainService,
    public frontendService: RagDatasupplyFrontendService<Subsidiary>
  ) {
    this.route.data.subscribe(d => {
      this.subsidiaries = d.subsidiaries;
    });
  }

  async ngOnInit() {
    this.tableSort = new TableSortUtil(this.frontendService);
    this.subscriptions.push(
      this.mainService.isDataLoaded.subscribe((done) => {
        if (done) {
          this.frontendService.fromArray(this.subsidiaries);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public async deleteItem(subsidiary: Subsidiary) {
    await this.mainService.subsidiaryService.deleteSubsidiary(subsidiary._id);
    this.subsidiaries = await this.mainService.subsidiaryService.getSubsidiaries();
    this.frontendService.fromArray(this.subsidiaries);
    this.toaster.success($localize`:@@subsidiariesRemovedSubsidiary:Tochtergesellschaft entfernt`);
  }

  public async updateItem(subsidiary: Subsidiary) {
    await this.mainService.subsidiaryService.editSubsidiary(subsidiary);
    this.toaster.success($localize`:@@subsidiariesUpdatedSubsidiary:Tochtergesellschaft gespeichert`);
  }

  public async createItem(subsidiary: Subsidiary) {
    await this.mainService.subsidiaryService.addSubsidiary(subsidiary);
    this.subsidiaries = await this.mainService.subsidiaryService.getSubsidiaries();
    this.frontendService.fromArray(this.subsidiaries);
    this.toaster.success($localize`:@@subsidiariesAddedSubsidiary:Tochtergesellschaft hinzugefügt`);
  }
}