import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentMetadata, FeedbackDocument } from '@reflact/prmfeedback';
import dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { Observable, firstValueFrom, scan } from 'rxjs';
import { LoginService } from '../views/login/login.service';
export interface Upload {
  progress: number
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
}
const initialState: Upload = { state: 'PENDING', progress: 0 }

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  )
}

const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
  if (isHttpProgressEvent(event)) {
    return {
      progress: event.total
        ? Math.round((100 * event.loaded) / event.total)
        : upload.progress,
      state: 'IN_PROGRESS',
    }
  }
  if (isHttpResponse(event)) {
    return {
      progress: 100,
      state: 'DONE',
    }
  }
  return upload
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient, private loginService: LoginService, public toastr: ToastrService) {

  }




  public uploadDocument(file: File, adminGroups: string[] = []): Observable<Upload> {
    const formData: FormData = new FormData();
    formData.append("adminGroups", adminGroups.join(","))
    formData.append(file.name, file);
    const url = '/api/document/upload';
    const initialState: Upload = { state: 'PENDING', progress: 0 }
    const result = this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events',
      headers: this.loginService.getAuthHeaders()
    }).pipe(scan(calculateState, initialState))



    return result;

  }

  public async deleteDocumentById(documentId: string) {
    const url = "/api/document/" + documentId;
    const result = await firstValueFrom(this.http.delete(url, { headers: this.loginService.getAuthHeaders() }));
    return result;
  }
  public async editDocumentMetadataById(documentId: string, metadata: Partial<DocumentMetadata>) {
    const url = "/api/document/" + documentId;
    const result = await firstValueFrom(this.http.post(url, metadata, { headers: this.loginService.getAuthHeaders() }));
    return result;
  }


  public async removeUserFromDocument(documentId: string, userId: string) {
    const doc = await this.getDocumentById(documentId);
    const allowedUsers = doc.metadata.allowedUsers.filter(u => u != userId);
    const result = await this.editDocumentMetadataById(documentId, { allowedUsers })
    return result;
  }
  public async addUserToDocument(documentId: string, userId: string) {

    const doc = await this.getDocumentById(documentId);
    if (doc.metadata.allowedUsers.find(u => u == userId) != null) {
      return "userAlready exists"
    }
    doc.metadata.allowedUsers.push(userId);
    const result = await this.editDocumentMetadataById(documentId, { allowedUsers: doc.metadata.allowedUsers })
    return result;
  }
  public async getDocumentById(documentId: string) {
    const url = "/api/document/" + documentId;
    const result = await firstValueFrom(this.http.get<FeedbackDocument>(url, { headers: this.loginService.getAuthHeaders() }));
    return result;
  }
  public async getDocumentFileById(documentId: string) {
    const url = "/api/document/file/" + documentId;
    return url;
  }
  public async listDocuments() {
    const url = "/api/documents/list";
    const result = await firstValueFrom(this.http.get<FeedbackDocument[]>(url, { headers: this.loginService.getAuthHeaders() }));
    result.sort((a, b) => dayjs(b.uploadDate).unix() - dayjs(a.uploadDate).unix())
    return result;
  }
}
