<div class="tab-area-content-wrapper">
    <div class="row mb-1">
        <div
            class="col"
            *ngIf="this.nominationImportCheckResult"
        >
            <div
                *ngFor="let nomination of this.nominationImportCheckResult.nominations"
                class="row"
            >
                <div class="col-12">
                    <div
                        class="card p-1  mb-1 border"
                        [ngClass]="{'border-success':false, 'border-danger': false}"
                    >
                        <div class="row mt-2">
                            <div class="col-auto text-start">
                                <ngx-avatars [name]="nomination.firstname + ' ' + nomination.lastname">
                                </ngx-avatars>
                            </div>
                            <div class="col">
                                <small class="text-muted">{{nomination.perspective}}</small><br>
                                {{nomination.firstname}} {{nomination.lastname}}
                                <small class="text-muted">&lt;{{nomination.email}}&gt;</small>

                            </div>
                            <div class="col-auto">
                                <div class="text-end">
                                    <i
                                        *ngIf="nomination.create"
                                        class="ri-check-line text-success"
                                    ></i>
                                    <i
                                        *ngIf="!nomination.create"
                                        class="ri-alert-line text-danger"
                                    ></i>
                                </div>
                                <span
                                    class="invalid text-danger small"
                                    *ngFor="let message of nomination.message;  let i = index"
                                >{{this.translateMessage(message)}}<ng-container
                                        *ngIf="i+1 < nomination.message.length "
                                    >, </ng-container>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col">
            <ng-container *ngIf="!this.nominationImportCheckResult">
                <div class="row">
                    <div class="col-auto"><i class="ri-arrow-right-fill"></i></div>
                    <div class="col ps-0">
                        <span i18n="@@NominationImportStep1">
                            Laden Sie die <b
                                (click)="this.exportTemplate()"
                                class="cursor-pointer text-primary"
                            >
                                <i
                                    class="ri-download-2-line"
                                    style="position: relative; top:2px"
                                ></i>
                                Excel-Vorlage
                            </b> herunter.
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto"><i class="ri-arrow-right-fill"></i></div>
                    <div class="col ps-0">
                        <span i18n="@@NominationImportStep2">
                            Nachdem Sie die Excel-Vorlage befüllt haben, laden Sie diese über die Schaltfläche
                            "Importliste hochladen" in das System.
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto"><i class="ri-arrow-right-fill"></i></div>
                    <div class="col ps-0">
                        <span i18n="@@NominationImportStep3">
                            Nach dem Hochladen können Sie den Import noch einmal überprüfen, bevor die Daten über die
                            Schaltfläche "Nominierungen importieren" final übernommen werden.
                        </span>
                    </div>
                </div>
            </ng-container>

            <div
                class="callout callout-primary  mt-0 "
                [ngClass]="{
                    'position-fixed': this.nominationImportCheckResult,
                    ' w-100': !this.nominationImportCheckResult
                }"
                style="background-color: #004FA021"
            >
                <input
                    type="file"
                    class="form-control-file d-none"
                    id="excelInput"
                    name="excelInput"
                    accept=".xls, .xlsx"
                    (change)="this.onExcelInput($event)"
                    #importInput
                >
                <div
                    *ngIf="this.nominationImportCheckResult"
                    class="btn btn-sm btn-cta"
                    i18n="@@NominationImportSubmit"
                    (click)="this.submitImport()"
                ><i class="ri-check-double-line"></i> Nominierungen importieren</div>
                <div
                    class="btn btn-sm btn-cta"
                    [ngClass]="{'btn-cta': !this.nominationImportCheckResult, 'btn-outline-cta': this.nominationImportCheckResult}"
                    i18n="@@NominationImportCheck"
                    (click)="this.showFileBrowser()"
                >
                    <i class="ri-upload-2-line"></i>
                    <ng-container *ngIf="!this.nominationImportCheckResult">
                        &nbsp;Importliste hochladen
                    </ng-container>
                    <ng-container *ngIf="this.nominationImportCheckResult">
                        &nbsp;erneut hochladen
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>