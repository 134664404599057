import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { INavData } from '../_nav';
import { CachedSubject } from '../core/cached-subject';
import { MainService } from '../shared/main.service';
import { NominationService } from '../shared/nomination.service';
import { LoginService } from '../views/login/login.service';
@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})

export class SidebarNavComponent implements OnInit, OnChanges, OnDestroy {
  @Input() navItems: INavData[] | null = [];
  @Input() showChildren: boolean = false;
  @Input() hideDropArrow: boolean = false;
  @HostBinding('class.sidebar-nav') sidebarNavClass = true;
  @HostBinding('attr.role') @Input() role = 'nav';
  public navItemsArray: INavData[] = [];
  public loginService: LoginService;
  public userLanguage: string;
  private subscriptions: Subscription[] = [];
  readonly openInvitations$: Observable<number>;
  private _openInvitations$ = new CachedSubject<number>(0);

  constructor(
    public router: Router,
    public mainService: MainService,
    public nominationService: NominationService,
  ) {
    this.loginService = this.mainService.loginService;
    // initialize openInvitations$
    this.openInvitations$ = this._openInvitations$;
    this.subscriptions.push(this.nominationService.myInvitations$.subscribe((i) => {
      this._openInvitations$.next(this.nominationService.myInvitations.length);
    }));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.navItemsArray = Array.isArray(this.navItems) ? this.navItems.slice() : [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public ngOnInit(): void {
    this.loginService.loggedInUser$.subscribe(user => {
      if (user) this.userLanguage = user.language;
    });
    this.subscriptions.push(this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        // get number of open invitations from nominationService
        this._openInvitations$.next(this.nominationService.myInvitations.length);
      }
    }));
  }
}
