<ng-template
    #languageTmp
    ng-label-tmp
    ng-option-tmp
    let-item="item"
    let-search="searchTerm"
    let-data="data"
    let-formField="formField"
>
    <ng-option value="{{item.value}}">
        <span class="fi fi-{{item.img}}"></span>
        {{item.label}}
    </ng-option>
</ng-template>

<app-page-user-management
    [isAdminArea]="true"
    [backendServiceUserType]="'prmadmin'"
    [service]="this.frontendService"
    i18n-singularName="@@adminSingularTitle"
    i18n-pluralName="@@adminPluralTitle"
    singularName="Administrator"
    pluralName="Administratoren"
    [columns]="[
        {fieldName:'firstname', label:'Vorname'},
        {fieldName:'lastname', label:'Nachname'},
        {fieldName:'email', label:'E-Mail'}
    ]"
    [formFields]="[
        {fieldName: 'firstname', type: 'text', required: true},
        {fieldName: 'lastname', type: 'text', required: true},
        {fieldName: 'email', type: 'text', required: true },
        {fieldName: 'language',type: 'select', required: true, options: {
            selectValues: this.mainService.languages,
            bindValue: 'value',
            bindLabel: 'label'
        }},
        {fieldName: '', type: 'template', options:{}},
        { fieldName: '', type: 'template', options: {template: userGroups}}
    ]"
    [formCreationData]="{firstname: '', lastname: '', email: '', language: 'de', type: 'prmadmin', userGroups: [], benchmarkValues: []}"
    [translatedLabels]="translatedLabels"
    [translatedLabelsForm]="translatedLabelsForm"
    (tableSelect)="this.filterSelectableGroups($event.userGroups)"
></app-page-user-management>

<ng-template
    #userGroups
    let-data="data"
>
    <ng-container *ngIf="data">
        <div class="row">
            <div class="col">
                <ng-select
                    class="form-control"
                    [items]="selectableGroups"
                    [(ngModel)]="selectedGroup"
                    i18n-placeholder="@@surveysAddUserGroups"
                    placeholder="Befragungsgruppe zuweisen"
                    bindLabel="name"
                    [clearable]="false"
                >
                </ng-select>
            </div>
            <div class="col-auto">
                <div
                    class="btn btn-primary btn-sm"
                    style="height: 36px;  padding-top: calc(0.25rem + 2px)"
                    [ngClass]=" {'disabled':!selectedGroup}"
                    (click)="addGroupToUser(selectedGroup._id, data)"
                >
                    <i class="ri-add-line"></i>
                </div>
            </div>
            <ul class="col-12 list-group list-group-flush">
                <li
                    class="list-group-item pe-0"
                    *ngFor="let group of data.userGroups"
                >
                    <div class="row">
                        <div class="col">
                            {{this.userGroupsService.userGroupsMap?.get(group)?.name}}
                        </div>
                        <div class="col-auto">
                            <div
                                class="btn btn-outline-danger btn-sm"
                                (click)="removeGroupFromUser(group, data)"
                            >
                                <i class="ri-close-line"></i>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </ng-container>
</ng-template>