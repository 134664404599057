import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiselectDropdownComponent, NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { AdminGroup, AllPrmUserType, FeedbackDocument } from '@reflact/prmfeedback';
import { DatasupplyRequest, RagDatasupplyFrontendService, RagDatasupplyLocalBackendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { translateTexts } from '../../app.component';
import { DocumentService, Upload } from '../../shared/document.service';
import { LayoutService } from '../../shared/layout.service';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';
import { LoginService } from '../login/login.service';
import { MainService } from './../../shared/main.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: [],
  providers: [RagDatasupplyFrontendService<FeedbackDocument>, RagDatasupplyLocalBackendService]
})
export class DocumentComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  @ViewChild("fileInput") fileInput: HTMLInputElement;
  public userForAdd: string = null;
  public documents: FeedbackDocument[];
  public fileForUpload: File | null = null
  public subscription: Subscription | undefined
  public upload: Upload | undefined
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["document", $localize`:@@documentDocument:Dokument`],
    ["metadata.language", $localize`:@@globalLanguage:Sprache`],
    ["metadata.name", $localize`:@@docname:Name`],
    ["metadata.allowedRole", $localize`:@@documentAllowedRole:Berechtigung`],
    ['metadata.adminGroups', $localize`:@@globalAdminGroups:Teams`],
    ['unSelectAllText', $localize`:@@unselectAllText:Alle abwählen`],
    ['selectAllText', $localize`:@@selectAllText:Alle auswählen`]
  ])
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public translateTexts = translateTexts;
  public hrTranslation = $localize`:@@documentHR:Human Resource`
  public hrFnTranslation = $localize`:@@documentHRFN:Human Resource, Feedbacknehmer`
  public hrFnFgTranslation = $localize`:@@documentHrFnFg:Human Resource, Feedbacknehmer, Feedbackgeber`

  public buttons = [
    $localize`:@@globalOverview:Übersicht`,
    $localize`:@@globalAdd:Anlegen`,
  ];
  public bsModalRef?: BsModalRef;
  public subscriptions: Subscription[] = [];
  public users: AllPrmUserType[];
  public NotFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["metadata__name", "uploadDate"]
  }
  public adminGroups: AdminGroup[] = [];
  @ViewChild("adminGroupsSelection") adminGroupsSelection: MultiselectDropdownComponent;

  constructor(
    public toaster: ToastrService,
    public mainService: MainService,
    public docService: DocumentService,
    public frontendService: RagDatasupplyFrontendService<FeedbackDocument>,
    public router: Router,
    public layoutService: LayoutService,
    public sanitizer: DomSanitizer,
    private modalService: BsModalService,
    public backendService: RagDatasupplyLocalBackendService<FeedbackDocument>,
    public urlHelper: RagDatasupplyOverlayUrlService,
    public route: ActivatedRoute,
    public loginService: LoginService
  ) {
    this.backendService.initialRequest(this.standardRequest)
    this.route.data.subscribe(d => {
      this.adminGroups = d.adminGroups;
    });
  }

  async ngOnInit() {
    this.backendService.loadData = () => {
      return new Promise<FeedbackDocument[]>((resolve, reject) => {
        if (this.documents) {
          resolve([...this.documents])
        } else {
          reject([])
        }
      })
    }


    this.subscriptions.push(
      this.mainService.isDataLoaded.subscribe((done) => {
        if (done) {
          this.users = this.mainService.userService.users.filter(u => u.type != "prmadmin");
          this.loadDocs();
        }
      })
    );

    this.subscriptions.push(this.backendService.results$.subscribe(results => {
      this.frontendService.fromArray(results)
      if (this.overlay && this.overlay.selectedObject) {
        this.overlay.selectedObject.metadata.adminGroups = this.mainService.filterAdminGroupIdsForGroupAdmin(this.loginService.loggedInUser, this.overlay.selectedObject.metadata.adminGroups);
      }
      this.urlHelper.getSelectedId(true);
    }));
  }

  ngAfterViewInit(): void {
    this.urlHelper.newSelection$.subscribe(s => {
      this.overlay.selectedObject = this.documents.find(d => d._id == s)
      if (this.overlay.selectedObject) {
        this.overlay.selectedObject.metadata.adminGroups = this.mainService.filterAdminGroupIdsForGroupAdmin(this.loginService.loggedInUser, this.overlay.selectedObject.metadata.adminGroups);
      }
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public onFileInput(files: FileList | null): void {
    if (files) {
      this.fileForUpload = files.item(0)
    }
  }

  public onSubmit() {
    const adminGroupsSelection = [];
    this.adminGroupsSelection.value.forEach((ag: AdminGroup) => { adminGroupsSelection.push(ag._id) });
    if (this.fileForUpload) {
      this.subscriptions.push(this.docService
        .uploadDocument(this.fileForUpload, adminGroupsSelection)
        .subscribe(
          (upload) => {
            this.upload = upload
            if (upload.state == "DONE") {
              this.toaster.success($localize`:@@documentUploadDone:Dokument ${this.fileForUpload.name} erfolgreich hochgeladen`)
              this.upload = undefined;
              this.fileForUpload = null;
            }
            this.loadDocs()
          }, (error => {
            if (error.error != null && error.error.msg) {
              this.toaster.error($localize`:@@documentUploadError:Fehler beim Upload: ${error.error.msg}`)
            } else {
              this.toaster.error($localize`:@@documentUploadError:Fehler beim Upload: ${JSON.stringify(error)}`)
            }
            this.upload = null
          })))
    }
  }

  public getMetaName(o: FeedbackDocument) {
    if (o && o.metadata) {
      return o.metadata.name;
    }
  }

  public openDocumentPreview(id: string, filename: string) {
    const temp = '/api/document/file/' + id + '?token=' + localStorage.getItem('authToken');
    const url = this.sanitizer.bypassSecurityTrustResourceUrl(temp);
    const initialState: ModalOptions = {
      class: 'modal-lg',
      initialState: {
        url,
        filename
      }
    };
    this.bsModalRef = this.modalService.show(DocumentPreviewComponent, initialState);
    this.bsModalRef.content.modalRef = this.bsModalRef;
  }

  public async loadDocs() {
    this.documents = await this.docService.listDocuments();
    this.backendService.load(this.standardRequest)
  }

  public async addUser(document: FeedbackDocument) {
    await this.docService.addUserToDocument(document._id, this.userForAdd);
    await this.loadDocs();
    this.userForAdd = null;
    this.toaster.success('Nutzer Hinzugefügt');
  }

  public async removeUser(document, uid) {
    await this.docService.removeUserFromDocument(document._id, uid);
    await this.loadDocs();
    this.userForAdd = null;
    this.toaster.success('Nutzer Entfernt');
  }

  public getUserLabel(uid: string) {
    const user = this.mainService.userService.userMap.get(uid);
    if (user == null) {
      return 'unknown user';
    }
    return user.firstname + ' ' + user.lastname + ' (' + user.email + ')';
  }

  public async deleteDocument(id: string) {
    await this.docService.deleteDocumentById(id);
  }

  public dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest)
  }

  public async updateItem(i: FeedbackDocument) {
    try {
      await this.docService.editDocumentMetadataById(i._id, { ...i.metadata });
    } catch (e) {
      this.toaster.warning('Document konnte nicht gespeichert werden');
    }
    this.toaster.info($localize`: @@globalDokumentSaved: Dokument erfolgreich gespeichert`);
  }

  public async deleteItem(i: FeedbackDocument) {
    try {
      await this.deleteDocument(i._id);
    } catch (e) {
      this.toaster.info($localize`: @@globalDokumentSaveError: Dokument konnte nicht gelöscht werden`);
    }
    await this.loadDocs();
    this.toaster.success($localize`: @@globalDocumentDeleted: Dokument gelöscht`);
  }
}