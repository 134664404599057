<div class="modal-header">
    <h4
        class="modal-title pull-left"
        i18n="@@documentpreviewTitle"
    >Vorschau: {{filename}}</h4>
    <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
    >
        <span
            aria-hidden="true"
            class="visually-hidden"
        >&times;</span>
    </button>
</div>
<div
    class="modal-body"
    style="padding: 0; padding-top: 1rem"
>
    <iframe
        *ngIf="url != null"
        [src]="url"
        style="height: 80vh"
        width="100%"
        title="docpreview"
    ></iframe>
</div>