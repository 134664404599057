<app-page-header>
</app-page-header>

<div class="content-grid">
    <app-content-headline
        pagetitle="Feedback geben"
        i18n-pagetitle="@@invitationCardTitle"
        #headline
    >
    </app-content-headline>
    <app-new-and-help-row
        [showNewButton]="false"
        topic="feedbackInvitation"
    >

    </app-new-and-help-row>

    <div class="content-grid-content overflow-visible">
        <ng-container *ngIf="headline.switcher.isGrid()">
            <ng-template
                #tile
                let-data="data"
            >
                <app-fbs-grid-card [config]="{
                        icon: 'ri-user-voice-line',
                        iconColor: '#A78042',
                        headerBgColor: '#E2DACC'
                    }">
                    <ng-container card-body>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col">
                                <b>{{data.feedbackName}}</b>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-1 my-auto">
                                <i class="ri-user-fill"></i>
                            </div>
                            <div class="col">
                                <small i18n="@@globalFN">Feedbacknehmer*in</small><br>
                                {{data.feedbackReceivername}}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-1 my-auto">
                                <i class="ri-user-location-fill"></i>
                            </div>
                            <div class="col">
                                <small i18n="@@globalPerspective">Perspektive</small><br>
                                {{this.loginService.translate(data.perspectiveName)}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container card-buttons>
                        <div class="container text-center">
                            <div class="row">
                                <div class="col px-0">
                                    <div
                                        (click)="this.navigateToFeedback($event, this.mainService.getSurveyLinkInvitation(data))"
                                        class="card-btn btn btn-sm bg-light border-0"
                                    >
                                        <div class="card-btn-inner-content">
                                            <i class="ri-arrow-right-circle-fill me-1"></i>
                                            <span i18n="@@invitationToSurvey">Zur Befragung</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </app-fbs-grid-card>
            </ng-template>
            <lib-rag-datasupply-grid
                [template]="tile"
                [service]="this.frontendService"
                [templateEmptyData]="templateEmptyData"
            ></lib-rag-datasupply-grid>
        </ng-container>
        <ng-container *ngIf="headline.switcher.isTable()">
            <app-card>
                <lib-rag-datasupply-table
                    #table
                    [selectable]="false"
                    [noDataText]="this.noDataText"
                    [service]="this.frontendService"
                    [columns]="[
                        {fieldName: 'feedbackReceivername', label: 'Feedbacknehmer*in'},
                        {fieldName: 'feedbackName', label: 'Feedback'},
                        {fieldName: 'perspectiveName', renderFunc:this.renderPerspectiveLabel.bind(this)},
                        {fieldName: 'survey', template: surveyLink}
                    ]"
                    [translatedLabels]="translatedLabels"
                >
                </lib-rag-datasupply-table>
            </app-card>
        </ng-container>
    </div>
</div>


<!-- TEMPLATES -->
<ng-template
    #surveyLink
    let-data="data"
>
    <a
        class="btn btn-sm btn-cta"
        *ngIf="data.feResult == null"
        [href]="this.mainService.getSurveyLinkInvitation(data)"
    >
        <i
            class="ri-user-shared-fill"
            style="position: relative; top:2px"
        ></i>
        <span i18n="@@invitationToSurvey">Zur Befragung</span>
    </a>
    <div
        *ngIf="data.feResult != null"
        i18n="@@invitationFilled"
    >
        Ausgefüllt
    </div>
</ng-template>

<ng-template #templateEmptyData>
    <div
        class="alert alert-info mt-5"
        role="alert"
    >
        {{ this.noDataText }}
    </div>
</ng-template>