<ng-container
    i18n-title="@@importCardTitle"
    title="Benutzer Import"
>
    <div
        class="form-group row"
        *ngIf="!this.isAdminArea"
    >
        <div class="form-floating mb-3">
            <ng-select
                class="form-control"
                [items]="this.allTypes"
                [searchable]="false"
                [clearable]="false"
                [(ngModel)]="this.type"
                (ngModelChange)="this.onTypeSelected()"
            >
                <ng-template
                    ng-label-tmp
                    let-item="item"
                >
                    {{this.userTypeToName(item)}}
                </ng-template>
                <ng-template
                    ng-option-tmp
                    let-item="item"
                >
                    {{this.userTypeToName(item)}}
                </ng-template>
            </ng-select>
            <label
                i18n="@@globalType:Typ"
                style="margin-left: 1rem;"
            >
                Typ
            </label>
        </div>
    </div>
    <app-excel-import [fields]="this.fields"></app-excel-import>
</ng-container>