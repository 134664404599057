<ul class="ps-0">
    <li
        *ngFor="let text of alltexts"
        style="list-style-type:none"
    >
        <div class="row">
            <div class="col-auto"><i class="ri-arrow-right-fill"></i></div>
            <div class="col ps-0"><span [innerHTML]="text"></span></div>
        </div>


    </li>
    <li
        style="list-style-type:none"
        *ngIf="this.showCtaButtons"
    >
        <div class="row">
            <div class="col-auto"><i class="ri-arrow-right-fill invisible"></i></div>
            <div class="col ps-0">
                <app-feedback-cta-btn
                    [buttonsOnly]="true"
                    [nominationpage]="true"
                    [feedback]="feedback"
                ></app-feedback-cta-btn>
            </div>
        </div>

    </li>

</ul>