import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../views/login/login.service';

@Injectable()
export class AdminGuard  {


  constructor(public loginService: LoginService, public router: Router) {
  }

  canActivate(): boolean {
    const user = this.loginService.loggedInUser$.value;
    return user && user.type === 'prmadmin';
  }
}
