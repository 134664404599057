<app-page-header></app-page-header>

<div class="content-grid">

    <app-content-headline
        [showSwitcher]="false"
        pagetitle="Nutzerinformation"
        i18n-pagetitle="@@myUserInfoTitle"
    >

    </app-content-headline>
    <div class="content-grid-content overflow-visible">
        <app-card>
            <div class="tab-area-content-wrapper">
                <p i18n="@@userTypeLabel">Nutzertyp: {{userService.userTypeToName(myUser.type)}}</p>
                <rag-form
                    [translateTexts]="translateTexts"
                    [translatedLabels]="translatedLabelsForm"
                    [data]="loginService.loggedInUser$ | async"
                    (submit)="this.updateItem($event)"
                    [hideDeleteButton]="true"
                    [formFields]="[
                        { fieldName: 'firstname', type: 'text',required:true},
                        {fieldName: 'lastname',type: 'text',required:true},
                        {fieldName: 'email',type: 'text',required:true}
                    ]"
                ></rag-form>
            </div>
        </app-card>
    </div>
</div>

<!-- TEMPLATES -->

<ng-template
    #typeColRenderer
    let-data="data"
>
    {{userService.userTypeToName(data.type)}}
</ng-template>