<app-page-header>
  <app-ds-search
    (searchEvent)="dsSearch($event)"
    placeholder="Login Historie durchsuchen"
    i18n-placeholder="@@loginHistorySearchHistory"
  ></app-ds-search>
</app-page-header>

<div class="content-grid">

  <app-content-headline
    [showSwitcher]="false"
    pagetitle="Login-Historie"
    i18n-pagetitle="@@loginHistoryTitle"
  ></app-content-headline>

  <div class="content-grid-content overflow-visible">
    <app-card>
      <lib-rag-datasupply-table
        #loginHistoryTable
        (sort)="this.tableSort.sort($event)"
        [noDataText]="this.noLoginHistoryText"
        [service]="this.loginHistoryService"
        [columns]="[
            {fieldName: 'loginDate', label: 'Login-Datum'},
            {fieldName: 'userName', label: 'Name des Nutzers'},
            {fieldName: 'userType', label: 'Rolle des Nutzers'},
            {fieldName: 'adminUserName', label: 'Name des Administrators'},
            {fieldName: 'loginMethod', label: 'Login-Methode'},
        ]"
        [translatedLabels]="translatedLabels"
      >
      </lib-rag-datasupply-table>
    </app-card>
  </div>
</div>