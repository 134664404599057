<app-page-header>

</app-page-header>
<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        pagetitle="Perspektive"
        i18n-pagetitle="@@perspectiveContentHeadline"
    >
        <div
            before
            class="no-print"
        ><a
                class="text-primary text-decoration-none d-flex align-middle"
                style="font-family: sans-serif;"
                [routerLink]="'/surveys'"
                [queryParams]="{selected:this.survey_id}"
                i18n="@@perspectivesBackBtn"
            ><i class="ri-arrow-left-line"></i> Befragungen</a></div>
    </app-content-headline>
    <div class="content-grid-content overflow-visible">
        <app-card>
            <form
                [formGroup]="perspectiveForm"
                (ngSubmit)="onSubmit()"
                *ngIf="this.survey"
            >
                <input
                    type="hidden"
                    formControlName="id"
                >
                <div class="form-floating mb-2">
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        formControlName="name"
                        placeholder="name@example.com"
                    >
                    <label
                        for="name"
                        i18n="@@globalDesignation"
                    >Bezeichnung</label>
                </div>


                <div class="form-floating mb-2">

                    <input
                        type="number"
                        class="form-control"
                        id="min"
                        formControlName="min"
                        min="0"
                        placeholder="name@example.com"
                    >
                    <label
                        for="min"
                        i18n="@@globalMinCount"
                    >Minimum-Anzahl</label>
                </div>
                <div class="form-floating mb-2">

                    <input
                        type="number"
                        class="form-control"
                        id="max"
                        formControlName="max"
                        min="0"
                        placeholder="name@example.com"
                    > <label
                        for="max"
                        i18n="@@gobalMaxCount"
                    >Maximal-Anzahl</label>
                </div>

                <div class="form-floating mb-2">
                    <input
                        type="number"
                        class="form-control"
                        id="anonymity"
                        formControlName="anonymity"
                        min="0"
                    >

                    <label
                        for="
                        anonymity"
                        i18n="@@globalAnonymity"
                    >Anonymitätsgrenze</label>
                </div>
                <div class="form-floating mb-2">
                    <select
                        class="form-control"
                        id="type"
                        formControlName="type"
                        placeholder="name@example.com"
                    >
                        <option
                            value="bound"
                            i18n="@@globalBound"
                        >Gebunden</option>
                        <option
                            value="unbound"
                            i18n="@@globalUnbound"
                        >Ungebunden</option>
                        <option
                            value="both"
                            i18n="@@globalBoth"
                        >Kombiniert</option>
                    </select> <label
                        for="type"
                        i18n="@@globalNominationType"
                    >Nominierungsverfahren</label>
                </div>
                <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    i18n="@@globalSave"
                >Sichern</button>
            </form>
        </app-card>
    </div>
</div>