import { Injectable } from '@angular/core';

import { waitSeconds } from '@reflact/tsutil';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class MainServiceResolver {
  async resolve() {
    if (MainService.singleton.isDataLoaded.getValue()) {
      return true;
    }
    await waitSeconds(0.5);
    return await this.resolve();
  }
}