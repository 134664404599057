<app-page-header>
    <app-ds-search
        (searchEvent)="dsSearch($event)"
        placeholder="Alle Feedbacks durchsuchen"
        i18n-placeholder="@@feedbacksSearchFeedbacks"
    ></app-ds-search>
</app-page-header>

<lib-rag-datasupply-overlay-asside
    #overlay
    [overrideWidth]="75"
    (closed)="this.urlHelper.setSelectedId(undefined)"
>
    <div
        *ngIf="overlay.selectedObject"
        class="p-5"
    >
        <rag-form
            [translateTexts]="getTranslatedTexts(overlay.selectedObject)"
            [translatedLabels]="translatedLabelsForm"
            (delete)="this.deleteItem($event); overlay.selectedObject = undefined"
            (submit)="this.updateItem($event)"
            [data]="overlay.selectedObject"
            [hideDeleteButton]="isArchived(overlay.selectedObject)"
            [hideSaveButton]="isArchived(overlay.selectedObject)"
            [formFields]="[
                {fieldName: '', type: 'template', options: {template: archiveInfo}},
                {fieldName: 'feedbackreceiver_id', type: 'template', options: { template: feedbackRecieverTpl }},
                {fieldName: 'name', type: 'text', required: true, readonly: isArchived(overlay.selectedObject)},
                {fieldName: 'survey_id', type: 'select', readonly: true, options: {
                    selectValues: this.customerService.surveys,
                    bindLabel: 'name',
                    bindValue: '_id'
                }},
                { fieldName: 'endDate', type: hasEndDate(overlay.selectedObject), readonly: overlay.selectedObject.status == 'ended', options: {}},
                { fieldName: 'status', type: 'select', readonly: true, options: {
                    selectValues: statusSelectValues,
                    bindLabel: 'label',
                    bindValue: 'value'
                }}
            ]"
        ></rag-form>
        <ng-template
            #responsecountTpl
            let-data="data"
        >
            {{this.mainService.getResponseCount(overlay.selectedObject._id, data.id)}}
        </ng-template>

        <ng-template
            #nominationsTpl
            let-data="data"
        >
            {{this.mainService.getNominations(overlay.selectedObject._id, data.id).length}}
        </ng-template>

        <div
            class="row mb-3"
            *ngIf="overlay.selectedObject.status === 'ended' && this.mainService.loginService.loggedInUser.type === 'prmadmin' && !isArchived(overlay.selectedObject)"
        >
            <div class="col-8 mt-2">
                <button
                    class="btn btn-secondary"
                    (click)="this.openRestartConfirm(confirmRestartFeedback, overlay.selectedObject)"
                    i18n="@@feedbacksResetStatus"
                >Feedback reaktivieren</button>
            </div>
        </div>

        <div class="form-group row ">
            <div
                class="col col-12 col-form-label col-lg-2"
                i18n="@@feedbackSelfEvaluation"
            ><b>Selbsteinschätzung</b></div>
            <div class="col">
                <table
                    class="table table-striped bg-white m-0 table-sm"
                    aria-label="selfevaluation"
                >
                    <thead class="thead-light">
                        <tr>
                            <th i18n="@@globalPerspective">Perspektive</th>
                            <th i18n="@@globalMinCount">Minimum-Anzahl</th>
                            <th i18n="@@gobalMaxCount">Maximal-Anzahl</th>
                            <th i18n="@@globalAnonymity">Anonymitätsgrenze</th>
                            <th i18n="@@globalNominationType">Nominierungsverfahren</th>
                            <th i18n="@@globalResponseRate">Rücklauf</th>
                            <th i18n="@@globalNominations">Nominierungen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td i18n="@@myFeedbackSelfEvaluation">Selbsteinschätzung</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{{this.mainService.getResponseCount(overlay.selectedObject._id, "-1")}}</td>
                            <td>1</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-group row">

            <div
                class="col col-12 col-form-label col-lg-2"
                i18n="@@globalResponseRate"
            >Rücklauf</div>
            <div class="col">

                <lib-rag-datasupply-table
                    [service]="this.getFeedbackInfoService( this.mainService.getFeedbackInfo(overlay.selectedObject._id).survey.perspectives)"
                    [columns]="[
                                {fieldName: 'name', label: 'Perspektive'},
                                {fieldName: 'min', label: 'Min.'},
                                {fieldName: 'max', label: 'Max.'},
                                {fieldName: 'anonymity', label: 'Anonymität'},
                                {fieldName: 'type', label: 'Typ'},
                                {fieldName: 'responserate', label: 'Rücklauf',template:responsecountTpl},
                                {fieldName: 'nomination', label: 'Nominierungen',template:nominationsTpl}
                            ]"
                    [translatedLabels]="quickLookTranslations"
                ></lib-rag-datasupply-table>
            </div>
        </div>
        <div class="form-group">
            <div
                class="col col-12 col-form-label col-lg-2"
                i18n="@@globalNominations"
            >Nominierungen</div>
            <div class="col">
                <table
                    class="table table-striped bg-white m-0 table-sm"
                    aria-label="feedbackinfo"
                >
                    <ng-container
                        *ngFor="let i of this.mainService.getFeedbackInfo(overlay.selectedObject._id).survey.perspectives"
                    >
                        <thead class="thead-light">
                            <tr>
                                <th>{{i.name}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let n of this.mainService.getNominations(overlay.selectedObject._id, i.id)">
                                <td *ngIf="n && !n.archived">{{n.firstname}} {{n.lastname}} ({{n.email}})</td>
                                <td *ngIf="!n || n.archived">Unbekannter / Gelöschter Nutzer
                                </td>
                            </tr>
                        </tbody>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</lib-rag-datasupply-overlay-asside>

<div class="content-grid">
    <app-content-headline
        [showSwitcher]="false"
        i18n-pagetitle="@@feedbacksCardTitle"
        pagetitle="Alle Feedbacks"
        #headline
    >
    </app-content-headline>
    <app-new-and-help-row
        #newhelpRow
        [showNewButton]="false"
        i18n="@@feedbacksCol1"
        topic="allFeedbacks"
    >
        Unter "<b>Übersicht</b>" sind alle aktiven Feedbacks des ausgewählten Kunden aufgelistet (=
        360°-Feedback einer Person).
    </app-new-and-help-row>
    <div class="content-grid-content overflow-visible">
        <app-card>
            <lib-rag-datasupply-table
                #table
                (onSelect)="overlay.selectedObject = table.selectedObject; this.urlHelper.setSelectedId(table.selectedObject._id)"
                (sort)="this.tableSort.sort($event)"
                [service]="this.frontendService"
                [noDataText]="this.noDataText"
                [columns]="[

                {fieldName: 'feedbackreceiver_id', template: feedbackRecieverIdToNameTpl},
                {fieldName: 'name', label: 'Name'},
                {fieldName: 'survey_id', template: surveyIdToNameTpl},
                {fieldName: 'status', renderFunc: this.mainService.feedbackService.feedbackStatusToName.bind(this)}
            ]"
                [translatedLabels]="titleTranslations"
            >

            </lib-rag-datasupply-table>
        </app-card>
    </div>
</div>


<!-- TEMPLATES -->

<ng-template
    #surveyIdToNameTpl
    let-data="data"
>
    <ng-container *ngIf="data">
        {{surveyIdToNameObj[data.survey_id] ?? 'Not found'}}
    </ng-container>
</ng-template>
<ng-template
    #feedbackRecieverIdToNameTpl
    let-data="data"
>
    <ng-container *ngIf="data">
        {{feedbackRecieverIdToNameObj[data.feedbackreceiver_id] ?? 'Not found'}}
    </ng-container>
</ng-template>

<ng-template
    #feedbackRecieverTpl
    let-data="data"
>
    <rag-input
        [value]="feedbackRecieverIdToNameObj[data.feedbackreceiver_id] ?? 'Not found'"
        [readonly]="true"
        [required]="false"
        label="Feedbacknehmer*in"
        i18n-label="@@globalFN"
        fieldName="feedbackreceiver_id"
        [standalone]="true"
    ></rag-input>
</ng-template>
<ng-template
    #archiveInfo
    let-data="data"
>
    <div
        *ngIf="isArchived(data)"
        class="alert alert-info"
        role="alert"
        i18n="@@feedbackArchiveInfo"
    >
        Dieses Feedback wurde archiviert.
    </div>
</ng-template>

<ng-template #confirmRestartFeedback>
    <div class="modal-header">
        <h4
            class="modal-title pull-left"
            i18n="@@confirmRestartFeedbackTitle"
        >Zurücksetzen bestätigen</h4>
        <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="this.modalRefConfirmRestart?.hide()"
        >
            <span
                aria-hidden="true"
                class="visually-hidden"
            >&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p i18n="@@confirmFeedbackRestartText">
            Den Status zurückzusetzen erlaubt es den Feedbackgebern ihre Befragungen weiter auszufüllen.
        </p>

        <p i18n="@@confirmFeedbackStartPickNewEnd">
            Hier kann ein neues Ende eingegeben werden.
        </p>
        <div class="form-floating mb-3">
            <input
                [(ngModel)]="this.selectedFeedback.endDate"
                class="form-control"
                type="date"
                [ngClass]="{'is-invalid': !dateIsInFuture(this.selectedFeedback.endDate)}"
            >
            <label for="floatingInputGrid">
                Ende
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            i18n="@@globalCancel"
            (click)="this.modalRefConfirmRestart?.hide()"
        >Abbrechen</button>
        <button
            type="button"
            class="btn btn-primary"
            [disabled]="!dateIsInFuture(this.selectedFeedback.endDate)"
            (click)="this.restartFeedbackByAdmin()"
            i18n="@@confirmFeedbackRestart"
        >Feedbackprozess wieder starten</button>
    </div>
</ng-template>