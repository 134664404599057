import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Coach } from '@reflact/prmfeedback';
import { CoachesService } from '../../shared/coaches.service';

@Injectable({
  providedIn: 'root'
})
export class CoachesResolver  {
  constructor(private coachesServives: CoachesService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Coach[]> {
    return await this.coachesServives.getCoaches();
  }
}