<app-page-header>
  <app-ds-search
    (searchEvent)="dsSearch($event)"
    placeholder="Alle E-Mailvorlagen durchsuchen"
    i18n-placeholder="@@mainManagerSearchTemplates"
  ></app-ds-search>
</app-page-header>

<div class="content-grid">
  <ng-container *ngIf="!adminView">
    <app-content-headline
      [showSwitcher]="false"
      [pagetitle]="nonAdminPageTitle"
    ></app-content-headline>
  </ng-container>


  <ng-container *ngIf="adminView">
    <app-content-headline
      [showSwitcher]="false"
      i18n-pagetitle="@@mailmanCardTitleAdmin"
      pagetitle="E-Mailvorlagen (Admin)"
    ></app-content-headline>
  </ng-container>
  <app-new-and-help-row
    [showNewButton]="false"
    topic="mailManager"
  >

  </app-new-and-help-row>
  <div class="content-grid-content overflow-visible">
    <app-card>
      <lib-mail-manager
        #mailmanager
        *ngIf="(this.mandant != 'default' && !adminView) || adminView"
        [showTableAddons]="false"
        [httpHeaderFunction]="this.loginService.getAuthHeaders"
        [mandant]="this.mandant"
        [lang]="this.loginService.loggedInUser.language"
        [mail]="this.loginService.loggedInUser.email"
        [adminView]="this.adminView"
        [nameIsEditable]="this.adminView"
        [languageToFlagMapping]="{en: 'gb'}"
      ></lib-mail-manager>
    </app-card>
  </div>
</div>