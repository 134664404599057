import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Login2FactorResult, StatusMsg } from '@reflact/prmfeedback';
import dayjs from 'dayjs';
import { SocketService } from './../../shared/SocketService';
import { LoginService } from './login.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginState = 'emailInput';
    loginStatusMessage = '';
    attemptsLeftOrLocked = '';
    email = '';
    constructor(public loginService: LoginService, private router: Router, private route: ActivatedRoute, private http: HttpClient, private socketService: SocketService) {

    }

    ngOnInit() {
        this.loginService.loginByToken();

        this.route.queryParams.subscribe(params => {
            if (params.code === 'true') {
                this.loginState = 'confirmation';
                this.loginService.setMail(params.email);
            } else if (params.password === 'true') {
                this.loginState = 'password-forgotten';
            }
        });

        this.loginService.loginStatus.subscribe((statusMessage: StatusMsg) => {
            if (statusMessage.status === 'ok') {
                this.loginStatusMessage = '';
                this.loginState = 'confirmation';
            } else {
                this.loginStatusMessage = 'wrong-user';
                if (statusMessage.message.includes("Locked until")) {
                    this.attemptsLeftOrLocked = $localize`:@@loginLockedUntil:Gesperrt bis: ` + dayjs(statusMessage.message.split("Locked until ").join("")).toDate().toLocaleString()
                } else {
                    this.attemptsLeftOrLocked = $localize`:@@loginAttemptsLeft:Versuche übrig: ` + statusMessage.message.split("Attempts left: ").join("")
                }
            }
        });

        this.loginService.confirmStatus.subscribe((confirmMessage: Login2FactorResult) => {
            if (confirmMessage.status === 'ok') {
                this.router.navigate(['']);
            } else {
                this.loginStatusMessage = 'wrong-code';
                if (confirmMessage.message.includes("Locked until")) {
                    this.attemptsLeftOrLocked = $localize`:@@loginLockedUntil:Gesperrt bis: ` + dayjs(confirmMessage.message.split("Locked until ").join("")).toDate().toLocaleString()
                } else {
                    this.attemptsLeftOrLocked = $localize`:@@loginAttemptsLeft:Versuche übrig: ` + confirmMessage.message.split("Attempts left: ").join("")
                }
            }
        });
    }

    async checkLoginMechanism(data) {
        const result = await this.loginService.checkLoginMechamism(data.email);
        if (result.loginMechanism == 'password') {
            this.loginState = "loginbypasswd"
        } else {
            this.loginService.openWindow = window.open(result.ssoUrl, "popup", 'popup=true,width=600,height=500')
        }
    }

    onLogin(data) {
        this.loginService.login(data.email, data.password);
    }

    onConfirmation(data) {
        this.loginService.confirmLogin(data.code.trim());
    }

    onPasswordForgotten() {
        this.loginState = 'password-forgotten';
    }

    onBackToLogin() {
        this.loginState = 'loginbypasswd';
    }

    onResetPasswort(data) {
        this.loginStatusMessage = 'password-send';
        this.loginService.requestPassword(data.email);
    }
}
