import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { jwtDecode } from "jwt-decode";

@Component({
  templateUrl: './shared-reporting.component.html',
  styleUrls: ['./shared-reporting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedReportingComponent {
  constructor(private router: Router, private aRoute: ActivatedRoute) {
    const decoded = jwtDecode(aRoute.snapshot.params.share_token);
    if (dayjs(decoded.exp * 1000).isBefore(dayjs())) {
      this.router.navigate(['/', 'error', 'sharelink_outdated']);
    }
  }
}
