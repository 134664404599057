import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Feedback, StartFeedbackResult, StatusMsg, Survey } from '@reflact/prmfeedback';
import { waitSeconds } from '@reflact/tsutil';
import dayjs from 'dayjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { FeedbackService } from '../feedback.service';
import { MainService } from '../main.service';
import { NominationService } from '../nomination.service';

@Component({
  selector: 'app-feedback-cta-btn',
  templateUrl: './feedback-cta-btn.component.html',
  styleUrls: ['./feedback-cta-btn.component.scss']
})
export class FeedbackCtaBtnComponent implements OnInit, OnDestroy {
  @Input() public nominationpage: boolean;
  @Input() public feedback: Feedback;
  @Input() public buttonsOnly = false;
  @Input() public buttonsForCard = false;
  public modalRef: BsModalRef;
  public nominationService: NominationService;
  public backendService: FeedbackService;
  public subscriptions: Subscription[] = [];
  public survey: Survey;
  public endDate: Date;
  public dayjsHtml = dayjs;

  constructor(
    public toaster: ToastrService,
    public mainService: MainService,
    public modalService: BsModalService,
    public router: Router
  ) {
    this.backendService = this.mainService.feedbackService;
    this.nominationService = this.mainService.nominationService;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.survey = this.mainService.customerService.surveys.find(s => s._id === this.feedback.survey_id);
    if (this.survey.feedbackDuration && this.survey.feedbackDuration > 0) {
      let endDate = dayjs();
      endDate = endDate.add(this.survey.feedbackDuration, 'day');
      this.endDate = endDate.toDate();
    }

    this.subscriptions.push(this.backendService.feedbackStarted$.subscribe((i) => {
      this.toaster.success($localize`:@@globalMyFeedbackStarted:Feedback gestartet`);
      this.nominationService.reloadInvitations();
    }));

    this.subscriptions.push(this.backendService.feedbackEnded$.subscribe((i) => {
      this.toaster.success($localize`:@@globalMyFeedbackBeendet:Feedback beendet`);
    }));
  }

  public async startFeedback(confirmMoveOnAfterStart: TemplateRef<any>): Promise<void> {
    this.backendService.startFeedback(this.feedback).catch((e: StartFeedbackResult) => {
      if (e.message == "missingPerspectives") {
        this.toaster.error($localize`:@@errorOccurredMissingNominations:Es ist ein Fehler aufgetreten:\n"Es fehlen noch Nominierungen"`);
      } else {
        this.toaster.error($localize`:@@globalErrorOccurred:Es ist ein Fehler aufgetreten:` + ' "' + e.message + '"');
      }
    });
    this.modalRef?.hide();
    await waitSeconds(1)
    this.modalRef = this.modalService.show(confirmMoveOnAfterStart)
  }

  public hasSe() {
    return this.mainService.getResponseCount(this.feedback._id, "-1");
  }

  public minNominationsReached() {
    return this.mainService.minNominationsReached(this.feedback._id);
  }
  public hasArchivedUsersInNominations() {
    return this.mainService.archivedUsersInNominationCount(this.feedback._id) > 0;
  }

  public getSeUrl() {
    return this.mainService.getSurveyLinkSE(this.feedback._id);
  }

  public maxNominationsReached() {
    return this.mainService.maxNominationsReached(this.feedback._id);
  }

  public openConfirm(event: MouseEvent, template: TemplateRef<any>) {
    event.stopPropagation();
    this.modalRef = this.modalService.show(template);
  }

  public endFeedback(): void {
    this.backendService.endFeedback(this.feedback).catch((error: StatusMsg) => {
      this.toaster.error($localize`:@@globalErrorOccurred:Es ist ein Fehler aufgetreten:` + ' "' + error.message + '"');
    });
    this.modalRef?.hide();
  }

  public openSE(event: MouseEvent) {
    event.stopPropagation();
    window.location.href = this.getSeUrl();
  }

  public openReport(event: MouseEvent) {
    event.stopPropagation();
    this.router.navigate(['my-feedbacks', this.feedback._id, 'report']);
  }

  public openNomination(event: MouseEvent) {
    event.stopPropagation();
    this.router.navigate(['my-feedbacks', this.feedback._id, 'nomination']);
  }
}