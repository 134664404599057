import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SurveyInvitation } from '@reflact/prmfeedback';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MainService } from '../../shared/main.service';
import { NominationService } from './../../shared/nomination.service';
import { LoginService } from './../login/login.service';

@Component({
  selector: 'app-feedback-invitations',
  templateUrl: './feedback-invitations.component.html',
  styleUrls: []
})
export class FeedbackInvitationsComponent implements OnInit, OnDestroy {
  public backendService: NominationService;
  public buttons = [$localize`:@@globalOverview:Übersicht`];
  public subscriptions: Subscription[] = [];
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = [
    { fieldName: 'feedbackReceivername', translated: $localize`:@@globalFN:Feedbacknehmer*in` },
    { fieldName: 'feedbackName', translated: $localize`:@@navFeedback:Feedback` },
    { fieldName: 'perspectiveName', translated: $localize`:@@globalPerspective:Perspektive` },
    { fieldName: 'survey', translated: $localize`:@@globalSurvey:Befragung` }
  ];
  public noDataText: string = $localize`:@@noDataTextFeedbackInvitations:Sie haben bereits alle Anfragen beantwortet`;

  constructor(
    public toaster: ToastrService,
    public mainService: MainService,
    public loginService: LoginService,
    public frontendService: RagDatasupplyFrontendService<SurveyInvitation>,
    public router: Router
  ) {
    this.backendService = this.mainService.nominationService;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(this.backendService.error$.subscribe((i) => { this.toaster.error(i.message); }));

    this.subscriptions.push(this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.frontendService.fromArray(this.backendService.myInvitations);
      }
    }));
  }

  public navigateToFeedback(event: MouseEvent, url: string) {
    event.stopPropagation();
    window.location.href = url;
  }

  public renderPerspectiveLabel(e) {
    return this.loginService.translate(e.perspectiveName)
  }
}