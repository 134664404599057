<table
    class="login-dark"
    aria-label="recoverpw"
>
    <tr>
        <td class="p-2">
            <form
                (ngSubmit)="onSavePassword()"
                [formGroup]="formGroup"
            >
                <div class="m-2">
                    <div class="col-auto text-center p-1">
                        <img
                            class="p-2"
                            src="assets/img/brand/ProfilM_360Feedback_Visual_weiss.svg"
                            width="60%"
                            style="max-width: 150px;"
                            height="60%"
                            alt="logo"
                        />
                    </div>
                </div>

                <div
                    role="alert"
                    class="alert alert-light alert-validate-password"
                    *ngIf="statusMessage === ''"
                >
                    <lib-ng-validate-password
                        (valid)="onPasswordValidationChange($event)"
                        [passwordField]="$any(formGroup.controls.password)"
                        [minLength]="minLength"
                        [language]="this.locale"
                    ></lib-ng-validate-password>
                </div>
                <p
                    *ngIf="statusMessage === 'code-error'"
                    class="alert alert-danger"
                    i18n="@@recoveryInvalidCode"
                >
                    Der Wiederherstellungscode ist nicht mehr gültig, Sie können sich
                    <a
                        [routerLink]="['/login']"
                        [queryParams]="{password: true}"
                    ><u>hier</u></a>
                    einen neuen anfordern.
                </p>
                <div class="input-group mb-3">
                    <input
                        i18n-placeholder="@@loginPasswortPlaceholder"
                        formControlName="password"
                        type="password"
                        class="form-control"
                        placeholder="Passwort"
                        required
                        [minlength]="minLength"
                        #password
                    >
                </div>

                <div class="input-group">
                    <input
                        i18n-placeholder="@@recoveryPWRepeatPlaceholder"
                        formControlName="password-repeat"
                        type="password"
                        class="form-control"
                        placeholder="Passwort wiederholen"
                        required
                        [minlength]="minLength"
                        #passwordRepeat
                        [ngClass]="{'is-invalid': statusMessage === 'password-mismatching'}"
                    >
                    <div class="invalid-feedback">Die Passwörter stimmen nicht überein</div>
                </div>

                <div class="form-group mb-2">
                    <div class="d-grid">
                        <button
                            i18n="@@recoverySetPW"
                            type="submit"
                            [disabled]="!formGroup.valid"
                            class="btn btn-primary px-4"
                        >Passwort setzen</button>
                    </div>
                </div>
            </form>
        </td>
</table>