import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-perspective-name-display',
  templateUrl: './perspective-name-display.component.html',
  styleUrls: []
})
export class PerspectiveNameDisplayComponent {
  @Input() public name: string = "";
  @Input() public color: string = "";
  @Input() public value: number = 0;


}
