import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { Feedback, StatusMsg, Survey } from '@reflact/prmfeedback';
import {
  DatasupplyRequest,
  RagDatasupplyFrontendService,
  RagDatasupplyLocalBackendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTypes
} from '@reflact/rag-datasupply';
import dayjs from 'dayjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPopperjsDirective, NgxPopperjsTriggers } from 'ngx-popperjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { translateTexts } from '../../app.component';
import { CustomerService } from '../../shared/customer.service';
import { FeedbackService } from '../../shared/feedback.service';
import { MainService } from '../../shared/main.service';
import { LoginService } from '../login/login.service';
import { TableSortUtil } from './../../shared/TableSortUtil';
import { NominationService } from './../../shared/nomination.service';
import { UserService } from './../../shared/user.service';

@Component({
  selector: 'app-my-feedbacks',
  templateUrl: './my-feedbacks.component.html',
  styleUrls: [],
  providers: [
    RagDatasupplyLocalBackendService,
    RagDatasupplyOverlayUrlService
  ]
})
export class MyFeedbacksComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  public popperTriggerClick = NgxPopperjsTriggers.click;
  public modalRef: BsModalRef;
  private selectedFeedback: Feedback;
  public subscriptions: Subscription[] = [];
  public nominationService: NominationService;
  public customerService: CustomerService;
  public feedbackService: FeedbackService;
  public loginService: LoginService;
  public availableSurveys: Survey[] = [];
  public lastFeedback: Feedback;
  public userLanguage: string;
  public buttons = [$localize`:@@globalOverview:Übersicht`, $localize`:@@globalAdd:Anlegen`];
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["survey_id", $localize`:@@globalSurvey:Befragung`],
    ["reponserate", $localize`:@@globalResponseRate:Rücklauf`],
    ["endDate", $localize`:@@globalEnd:Ende`],
    ["name", $localize`:@@myFeedbacksName:Titel des Feedbacks`],
    ["action", $localize`:@@globalAction:Aktion`],
    ["status", $localize`:@@globalStatus:Status`]
  ])
  public translateTexts = translateTexts;
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public NotFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;
  public dayjs = dayjs;
  public tableSort: TableSortUtil<Feedback>;
  public noDataText: string = $localize`:@@noDataTextMyFeedbacks:Klicken Sie auf ANLEGEN um Ihren ersten Feedbackprozess zu starten`;
  public fakeEntrys = [];
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["name"]
  }
  constructor(
    public toaster: ToastrService,
    public mainService: MainService,
    public frontendService: RagDatasupplyFrontendService<Feedback>,
    public backendService: RagDatasupplyLocalBackendService<Feedback>,
    public router: Router,
    public modalService: BsModalService,
    public userService: UserService,
    public urlHelper: RagDatasupplyOverlayUrlService
  ) {
    this.nominationService = this.mainService.nominationService;
    this.customerService = this.mainService.customerService;
    this.feedbackService = this.mainService.feedbackService;
    this.loginService = this.mainService.loginService;
    for (let i = 0; i < 100; i++) {
      this.fakeEntrys.push(i);
    }
  }
  ngAfterViewInit(): void {
    this.urlHelper.getSelectedId(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.tableSort = new TableSortUtil<Feedback>(this.frontendService,
      {
        customSortFunctions:
          { reponserate: (a, b) => this.responseRateStatus(a).localeCompare(this.responseRateStatus(b)) }
      }
    );

    this.backendService.loadData = () => {
      return new Promise<Feedback[]>((resolve, reject) => {
        resolve(this.getMyFeedbacks())
      })
    }

    this.subscriptions.push(this.urlHelper.newSelection$.subscribe(s => {
      this.overlay.selectedObject = this.feedbackService.myFeedbacks.find(f => f._id == s)
    }))
    this.subscriptions.push(this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.availableSurveys = this.customerService.surveys.filter(s =>
          s.status === 'published'
          && (s.userGroups.length == 0
            || s.userGroups.some(sg => this.loginService.loggedInUser.userGroups.some(lg => "" + lg == "" + sg))
          )
        );
      }
    }));

    this.subscriptions.push(this.backendService.results$.subscribe(results => {
      this.frontendService.fromArray(results);
    }));


    this.subscriptions.push(this.feedbackService.error$.subscribe((i) => {
      this.toaster.error(i.message);
    }));

    this.subscriptions.push(this.feedbackService.feedbackCreated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalMyFeedbackCreated:Feedback erfolgreich angelegt`);
      this.urlHelper.setSelectedId(i._id);
    }));

    this.subscriptions.push(this.feedbackService.feedbackUpdated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalMyFeedbackUpdated:Feedback erfolgreich gespeichert`);
    }));

    this.subscriptions.push(this.feedbackService.feedbackDeleted$.subscribe((i) => {
      this.toaster.success($localize`:@@globalMyFeedbackDeleted:Feedback erfolgreich gelöscht`);
      this.urlHelper.setSelectedId(undefined);
      this.backendService.loadInitialRequest()
    }));

    this.subscriptions.push(this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.backendService.loadInitialRequest()
      }
    }));

    this.subscriptions.push(this.loginService.loggedInUser$.subscribe((i) => {
      this.userLanguage = i.language;
    }));

    this.subscriptions.push(this.feedbackService.feedbackStarted$.subscribe(f => {
      this.overlay.selectedObject = f;
      this.backendService.load(this.standardRequest)
    }));

    this.subscriptions.push(this.feedbackService.feedbackEnded$.subscribe(f => {
      this.overlay.selectedObject = f;
      this.backendService.load(this.standardRequest);
    }));
  }

  dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest)
  }

  public openConfirm(template: TemplateRef<any>, feedback: Feedback) {
    this.selectedFeedback = feedback;
    this.modalRef = this.modalService.show(template);
  }


  public whoNeedsReminder() {
    return this.nominationService.allNominations.filter((nomintaion) => {
      return !nomintaion.feResultUpdatedDate && nomintaion.feedback_id === this.selectedFeedback._id;
    }).map((nomination) => {
      return this.userService.users.find((user) => user._id === nomination.feedbackgiver_id);
    });
  }

  public sendReminder(): void {
    this.feedbackService.sendReminder(this.selectedFeedback).then(result => {
      this.modalRef?.hide();
      this.toaster.success($localize`:@@globalReminderSent:Erinnerung verschickt`);
    }).catch((error: StatusMsg) => {
      this.toaster.error($localize`:@@globalErrorOccurred:Es ist ein Fehler aufgetreten:` + ' "' + error.message + '"');
    });
  }

  public async deleteItem(i: Feedback) {
    await this.feedbackService.deleteFeedback(i);
  }

  public async createItem(i: Feedback) {
    await this.feedbackService.createFeedback(i);
    this.frontendService.fromArray(this.getMyFeedbacks());
  }

  public async updateItem(i: Feedback) {
    await this.feedbackService.updateFeedback(i);
  }

  public surveyIdToName(s: Feedback) {
    const fi = this.mainService.getFeedbackInfo(s._id);
    if (fi.survey == null) {
      return $localize`:@@globalSurveyNotFound:Befragung nicht gefunden`;
    }
    return fi.survey.name;
  }

  public responseRateStatus(s: Feedback) {
    const fi = this.mainService.getFeedbackInfo(s._id);
    let str = '';
    if (s.status === 'nomination') {
      str = $localize`:@@myFeedbacksNotStarted:Feedback noch nicht gestartet`;
    } else if (fi.responseRates.length === 0) {
      str = $localize`:@@myFeedbacksNoResponseRate:Kein Rücklauf vorhanden`;
    } else {
      const missing = $localize`:@@myFeedbacksMissing:Es fehlen: `;
      str = missing;
      fi.responseRates.forEach(rr => {
        if (rr.perspective_id === '-1' && rr.responseCount !== 1) {
          if (str !== '' && str !== missing) {
            str += ', ';
          }
          str += $localize`:@@myFeedbacksNoSelfEvaluation:Selbsteinschätzung`;
        } else if (rr.perspective_id !== '-1') {
          const perspective = fi.survey.perspectives.find(f => f.id === rr.perspective_id);
          if (perspective.anonymity > rr.responseCount) {
            if (str !== '' && str !== missing) {
              str += ', ';
            }
            str += perspective.name + ' (' + (perspective.anonymity - rr.responseCount) + ')';
          }
        }
      });
      if (str === missing) {
        str = $localize`:@@myFeedbacksFeedbackCompleted:Ausreichend Feedback für die Auswertung`;
      }
    }
    return str;
  }

  public isFeedbackStartable(f: Feedback) {
    if (f.status !== 'nomination') {
      return false;
    }
    const { survey, nominations } = this.mainService.getFeedbackInfo(f._id);

    const notEnoughNominationsPerspectiveNames = survey.perspectives.filter((p => {
      return p.min > nominations.filter(n => n.perspective_id === p.id).length;
    }));
    return notEnoughNominationsPerspectiveNames.length > 0;
  }

  public showReminderModal(template: TemplateRef<any>, feedback: Feedback) {
    this.selectedFeedback = feedback;
    this.modalRef = this.modalService.show(template);
  }

  public surveyHasDuration(survey: Survey) {
    return survey.feedbackDuration && survey.feedbackDuration > 0;
  }

  public getCardBadge(feedback: Feedback) {
    let badgeText = '';
    let badgeIcon = '';

    switch (feedback.status) {
      case 'nomination':
        badgeText = $localize`:@@myFeedbackPhaseBadgeNomination:Nominierung`;
        badgeIcon = 'ri-user-fill';
        break;
      case 'running':
        badgeText = $localize`:@@myFeedbackPhaseBadgeRunning:Durchführung`;
        badgeIcon = 'ri-speed-fill';
        break;
      case 'ended':
        badgeText = $localize`:@@myFeedbackPhaseBadgeEnded:Abgeschlossen`;
        badgeIcon = 'ri-check-line';
        break;
      default:
        break;
    }

    return { text: badgeText, icon: badgeIcon };
  }
  private getMyFeedbacks() {
    const myFeedbacks = [...this.feedbackService.myFeedbacks.filter(f => f.archived == null)];
    myFeedbacks.sort((a, b) => dayjs(a.created).isBefore(dayjs(b.created)) ? 1 : -1);
    return myFeedbacks;
  }
  popperShow(event: NgxPopperjsDirective) {
    setTimeout(() => {
      event.hide();
    }, 3000);
  }
}