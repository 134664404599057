import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AdminGroup, BenchmarkConfig, UserForCreationData, UserGroup } from '@reflact/prmfeedback';
import { RagDatasupplyTypes } from '@reflact/rag-datasupply';
import dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { LoginService } from '../../views/login/login.service';
import { AdminGroupsService } from '../admin-groups.service';
import { CustomerService } from '../customer.service';
import { UserService } from '../user.service';
import { MainService } from './../main.service';
const merge = (a, b, predicate = (a, b) => a === b) => {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c;
}
export type ExcelData = {
  keys: string[],
  values: UserForCreationData[]
};

@Component({
  selector: 'app-excel-import[fields]',
  templateUrl: './excel-import.component.html',
  styleUrls: ['./excel-import.component.scss']
})
export class ExcelImportComponent {
  @ViewChild('inputFile') inputFile: ElementRef;
  @Input() public label = $localize`:@@excelImportSelectLabel:Datei wählen`;
  @Input() public fields: RagDatasupplyTypes.FormField[];
  @Output() createObjects: EventEmitter<UserForCreationData[]> = new EventEmitter();
  importedData: ExcelData;
  benchmarkConfigs: BenchmarkConfig[]

  constructor(
    public toastrService: ToastrService,
    private userService: UserService,
    private customerService: CustomerService,
    private loginService: LoginService,
    public mainService: MainService,
    public adminGroupsService: AdminGroupsService
  ) { }

  public onBtnClick() {
    this.inputFile.nativeElement.click();
  }

  public async onExcelInput(event) {
    let data;
    if (this.adminGroupsService.adminGroups.length == 0) {
      await this.adminGroupsService.loadData();
    }
    if (!this.fields.find(f => f.fieldName.startsWith('adminGroups.'))) {
      this.adminGroupsService.adminGroups.forEach(ag => {
        this.fields.push({
          fieldName: 'adminGroups.' + ag.name,
          required: false,
          defaultValue: ''
        });
      });
    }

    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
      this.toastrService.info($localize`:@@excelImportSelectOneOnly:Bitte nur eine Excel-Datei auswählen.`);
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      // Excel Mappe einlesen
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      // zu json umwandeln
      data = XLSX.utils.sheet_to_json(ws);
    };
    reader.readAsBinaryString(target.files[0]);
    reader.onloadend = (e) => {
      let isAllDataValid = true;
      let allKeys = []
      data.forEach((d: any) => {
        let keys = Object.keys(d);
        // Set all benchmarkValues from Fields
        d.benchmarkValues = [];
        const customerId = this.loginService.loggedInUser$.value.customer_id
        const customer = this.customerService.customerMap.get(customerId);
        this.benchmarkConfigs = customer.benchmarkConfigs
        if (customer != null) {
          customer.benchmarkConfigs.forEach(b => {
            const index = keys.indexOf("benchmark." + b.label);
            if (index > -1) {
              const o = { id: b.id, value: d["benchmark." + b.label] };
              d.benchmarkValues.push(o);
              delete d["benchmark." + b.label];
            }
          });
        }
        // Set all userGroups from Fields
        const userGroups = this.fields.filter(f => f.fieldName.startsWith("userGroups."))
        d.userGroups = []
        userGroups.forEach(ug => {
          if ("" + d[ug.fieldName] == "x") {
            const groupToAdd: UserGroup = this.mainService.userGroupsService.userGroups.find(u => {
              return u.name.trim() == ug.fieldName.split("userGroups.").join("").trim()
            })
            if (groupToAdd != null) {
              d.userGroups.push(groupToAdd._id)
            }
          }
          delete d[ug.fieldName];
        });
        // Admin Groups / Teams
        const adminGroups = this.fields.filter(f => f.fieldName.startsWith('adminGroups.'));
        d.adminGroups = [];
        adminGroups.forEach(ag => {
          if ("" + d[ag.fieldName] == "x") {
            const groupToAdd: AdminGroup = this.adminGroupsService.adminGroups.find(group => {
              return group.name.trim() == ag.fieldName.split('adminGroups.').join('').trim();
            })
            if (groupToAdd != null) {
              d.adminGroups.push(groupToAdd._id)
            }
          }
          delete d[ag.fieldName];
        });
        // Remove non-access Admin Groups / Teams
        Object.keys(d).forEach(key => {
          if (key.startsWith('userGroups.')) {
            delete d[key];
          }

          if (key.startsWith('adminGroups.')) {
            delete d[key];
          }
        });
        // Language
        if (d.language == undefined) {
          isAllDataValid = false;
        } else {
          d.language = (d.language as string).toLowerCase()
        }
        if (d.language != 'en' && d.language != 'de') {
          delete d.language
        }
        // Validity
        keys = Object.keys(d);
        if (!this.hasAllKeys(keys)) {
          isAllDataValid = false;
        }
        allKeys = merge(allKeys, keys);
      })
      if (isAllDataValid) {
        this.importedData = {
          keys: allKeys,
          values: data
        };
      } else {
        this.toastrService
          .error($localize`:@@excelImportCheckList:Bitte überprüfen Sie Ihre Liste.`,
            $localize`:@@excelImportInvalidList:Achtung! Excel-Datei enthält falsche Felder`);
      }
      this.inputFile.nativeElement.value = '';
    }
  }

  public getLabelOfBenchmarkValue(id: string) {
    return this.benchmarkConfigs.find(b => b.id == id).label
  }

  public async downloadDemo() {
    type DemoRow = {
      firstname: string,
      lastname: string,
      email: string,
      language: string,
      subsidiaryId: string,
      [key: string]: string
    };
    const data: DemoRow[] = [];
    const addData: DemoRow = {
      firstname: "Sam",
      lastname: "Sampleman",
      email: "sam.sampleman@prmtest.reflact.info",
      language: "en",
      subsidiaryId: ""
    };

    const subsidiaries = await this.mainService.subsidiaryService.getSubsidiaries();
    if (subsidiaries.length > 0) {
      addData.subsidiaryId = subsidiaries[0].company;
    }

    const customer = this.mainService.customerService.customerMap.get(this.mainService.loginService.loggedInUser.customer_id)
    customer.benchmarkConfigs.forEach(b => {
      addData["benchmark." + b.label] = ""
    });

    this.mainService.userGroupsService.userGroups.forEach(ug => {
      addData["userGroups." + ug.name] = (Math.random() > 0.5) ? "x" : ""
    });

    await this.adminGroupsService.loadData();
    this.adminGroupsService.adminGroups.forEach(ag => {
      addData["adminGroups." + ag.name] = (Math.random() > 0.5) ? "x" : ""
    });

    data.push(addData);

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(data))
    XLSX.writeFileXLSX(workbook, 'userimport_demo_' + dayjs().format('YYYYMMDD_HHmm') + '.xlsx')
  }

  private hasAllKeys(keys: string[]): boolean {
    let result = true;
    this.fields.filter(f => f.required).forEach(key => {
      if (!keys.some(k => (k === key.fieldName))) {
        result = false;
      }
    });
    return result;
  }

  public clearImportedData() {
    this.importedData = undefined;
    this.inputFile.nativeElement.value = '';
  }

  public async clickCreate() {
    const objects: UserForCreationData[] = this.importedData.values;
    const typeField: RagDatasupplyTypes.FormField = this.fields.filter(f => f.fieldName === 'type' && f.defaultValue).reduce((a, b) => b, undefined);
    if (!typeField) {
      this.toastrService.error($localize`:@@feedbackSuiteUnexErr:Unerwarteter Fehler:\nFeldtyp wurde nicht gefunden`);
      return;
    }
    const subsidiaries = await this.mainService.subsidiaryService.getSubsidiaries();
    objects.forEach(p => {
      const subsidiary = subsidiaries.find(s => {
        return s.company === p.subsidiaryId;
      });
      p.type = typeField.defaultValue;
      p.subsidiaryId = (subsidiary) ? subsidiary._id : '';
    });
    const createdUsers = await this.userService.createUsers(objects);
    this.importedData.values = this.importedData.values.filter(data => !createdUsers.some(cUser => cUser.email.trim().toLowerCase() === data.email.trim().toLowerCase()));
    if (this.importedData.values.length > 0) {
      this.toastrService
        .warning($localize`:@@excelImportIncompleteMessage:Es konnten nicht alle Nutzer importiert werden.`,
          $localize`:@@excelImportIncompleteTitle:Achtung! Import unvollständig`);
    } else {
      this.toastrService
        .success($localize`:@@excelImportCompleteMessage:Alle Nutzer wurden importiert`,
          $localize`:@@excelImportCompleteTitle:Import erfolgreich`);
      this.importedData = undefined;
    }
  }
}