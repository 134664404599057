<div class="d-print-none">

  <div class="row mt-3">
    <div class="col-12 ps-2">
      <span
        class="badge bg-primary  "
        style="background-color: #3979fe !important;  "
      >{{subQuestion.subQuestionTitle}}</span>
    </div>
    <div
      class="col mt-3 text-smaller"
      style="line-height: inherit;"
    >{{subQuestion.subQuestionText}}</div>
  </div>
</div>
<div class="row d-none d-print-flex mt-3 ms-5">
  <div class="col ps-0">{{groupQuestion.questionText}}</div>
</div>

<div class="d-none d-print-flex ms-5">
  <div class="row mt-0 ">
    <div class="col-auto ps-2">
      <span
        class="badge bg-primary  "
        style="background-color: #3979fe !important;  "
      >{{subQuestion.subQuestionTitle}}</span>
    </div>
    <div
      class="col mt-1 text-smaller"
      style="line-height: inherit;"
    >{{subQuestion.subQuestionText}}</div>
  </div>
</div>