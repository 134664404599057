<div
  class="row  me-n1 ms-n1 mt-2 mb-2 row-se"
  style="border:0px solid red ;margin-left:-0.1rem !important"
>
  <div class="text-center m-0 col text-primary px-0">
    <div
      class="layer"
      style="position: relative;height: 20px;"
    >
      <div
        class="badge"
        style="width: 100px; position: absolute; margin-left: -50px; top:5px;"
        [ngStyle]="{'left.%': pointToPos(this.seValue ), 'color': this.colorService.stringToColor('SE')}"
      >
        <span [ngClass]="{'invisible': this.seValue == 0}">SE: {{this.seValue | number :'1.2-2'}}</span>


      </div>

    </div>
  </div>
</div>