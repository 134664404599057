<table
    class="login-dark"
    aria-label="login"
>
    <tr>
        <td class="p-2">

            <form
                *ngIf="loginState === 'emailInput'"
                #login="ngForm"
                (keyup.enter)="checkLoginMechanism(login.value)"
            >
                <h2
                    i18n="@@loginHeader"
                    class="sr-only"
                >Login</h2>

                <div class="m-2">
                    <div class="col-auto text-center p-1">
                        <img
                            class="p-2"
                            src="assets/img/brand/ProfilM_360Feedback_Visual_weiss.svg"
                            width="100%"
                            style="max-width: 150px;"
                            height="100%"
                            alt="logo"
                        />
                    </div>
                </div>

                <div
                    *ngIf="loginStatusMessage === 'wrong-user'"
                    class="alert alert-danger"
                    i18n="@@loginError"
                    role="alert"
                >Der Login ist fehlgeschlagen<br><small>{{attemptsLeftOrLocked}}</small></div>

                <div class="input-group">
                    <input
                        name="email"
                        type="email"
                        class="form-control"
                        placeholder="E-Mail"
                        autocomplete="email"
                        required
                        ngModel
                        [(ngModel)]="email"
                    >
                </div>

                <div class="input-group mb-2">
                    <div class="d-grid">
                        <button
                            class="btn btn-primary"
                            type="submit"
                            (click)="checkLoginMechanism(login.value)"
                            i18n="@@loginNext"
                        >Weiter</button>
                    </div>
                </div>
            </form>

            <form
                *ngIf="loginState === 'loginbypasswd'"
                #login="ngForm"
                (keyup.enter)="onLogin(login.value)"
            >
                <h2
                    i18n="@@loginHeader"
                    class="sr-only"
                >Login</h2>

                <div class="m-2">
                    <div class="col-auto text-center p-1">
                        <img
                            class="p-2"
                            src="assets/img/brand/ProfilM_360Feedback_Visual_weiss.svg"
                            width="100%"
                            style="max-width: 150px;"
                            height="100%"
                            alt="logo"
                        />
                    </div>
                </div>

                <div
                    *ngIf="loginStatusMessage === 'wrong-user'"
                    class="alert alert-danger"
                    i18n="@@loginError"
                    role="alert"
                >Der Login ist fehlgeschlagen<br><small>{{attemptsLeftOrLocked}}</small></div>

                <div class="input-group">
                    <input
                        name="email"
                        type="email"
                        class="form-control"
                        readonly
                        placeholder="E-Mail"
                        autocomplete="email"
                        required
                        [(ngModel)]="email"
                        ngModel
                    >
                </div>

                <div class="input-group">
                    <input
                        name="password"
                        type="password"
                        class="form-control"
                        i18n-placeholder="@@globalPassword"
                        placeholder="Passwort"
                        autocomplete="current-password"
                        required
                        ngModel
                    >
                </div>

                <div class="input-group mb-2">
                    <div class="d-grid">
                        <button
                            class="btn btn-primary"
                            type="submit"
                            (click)="onLogin(login.value)"
                            i18n="@@loginHeader"
                        >Login</button>
                    </div>
                </div>

                <a
                    (click)="onPasswordForgotten()"
                    i18n="@@loginForgotPW"
                >Passwort vergessen?</a>
            </form>

            <form
                *ngIf="loginState === 'confirmation'"
                #confirmation="ngForm"
                (keyup.enter)="onConfirmation(confirmation.value)"
            >
                <h2
                    i18n="@@loginHeader"
                    class="sr-only"
                >Login</h2>

                <div class="m-2">
                    <div class="col-auto text-center p-1">
                        <img
                            class="p-2"
                            src="assets/img/brand/ProfilM_360Feedback_Visual_weiss.svg"
                            style="max-width: 150px;"
                            width="100%"
                            height="100%"
                            alt="logo"
                        />
                    </div>
                </div>

                <p
                    *ngIf="loginStatusMessage === 'wrong-code'"
                    class="alert alert-danger"
                    i18n="@@loginWrongCode"
                >Der eingegebene Code ist ung&uuml;ltig <br><small>{{attemptsLeftOrLocked}}</small></p>

                <div class="input-group">
                    <input
                        name="code"
                        type="text"
                        class="form-control"
                        placeholder="Code"
                        required
                        ngModel
                    >
                </div>
                <small
                    style="color:#434a52"
                    i18n="@@globalCodeSentToMail"
                >Der Code wurde an Ihre E-Mail Adresse gesendet</small>

                <div class="input-group mb-2">
                    <div class="d-grid">
                        <button
                            class="btn btn-primary"
                            type="submit"
                            (click)="onConfirmation(confirmation.value)"
                            i18n="@@loginDone"
                        >Bestätigen</button>
                    </div>
                </div>
            </form>

            <form
                *ngIf="loginState === 'password-forgotten'"
                #recoverPassword="ngForm"
                (keyup.enter)="onResetPasswort(recoverPassword.value)"
            >
                <h2
                    i18n="@@loginReset"
                    class="sr-only"
                >Passwort zurücksetzen</h2>

                <div class="m-2">
                    <div class="col-auto text-center p-1">
                        <img
                            class="p-2"
                            style="max-width: 150px;"
                            src="assets/img/brand/ProfilM_360Feedback_Visual_weiss.svg"
                            width="100%"
                            height="100%"
                            alt="logo"
                        />
                    </div>
                </div>

                <p
                    *ngIf="loginStatusMessage === 'password-send'"
                    class="alert alert-success"
                    i18n="@@loginResetSend"
                    role="alert"
                >
                    Es wurde ein Link zum Passwort zurücksetzen an die eingegebene E-Mail-Adresse verschickt.
                </p>

                <div class="input-group">
                    <input
                        name="email"
                        type="text"
                        class="form-control"
                        placeholder="E-Mail"
                        autocomplete="email"
                        required
                        [(ngModel)]="email"
                    >
                </div>

                <div class="input-group mb-2">
                    <div class="d-grid">
                        <button
                            class="btn btn-primary"
                            type="submit"
                            (click)="onResetPasswort(recoverPassword.value)"
                            i18n="@@loginReset"
                        >Passwort zurücksetzen</button>
                    </div>
                </div>
                <a
                    (click)="onBackToLogin()"
                    i18n="@@backToLogin"
                >Zurück zum Login</a>
            </form>

        </td>
    </tr>
</table>