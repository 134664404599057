import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Feedback } from '@reflact/prmfeedback';
import dayjs from 'dayjs';
import { FeedbackService } from '../feedback.service';
import { MainService } from '../main.service';

@Component({
  selector: 'app-feedback-cta-txt',
  templateUrl: './feedback-cta-txt.component.html',
  styleUrls: []
})
export class FeedbackCtaTxtComponent implements OnInit, OnChanges {
  public backendService: FeedbackService;
  @Input() public showCtaButtons: boolean;
  @Input() public feedback: Feedback;
  public alltexts: string[] = [];
  constructor(public mainService: MainService) {
    this.backendService = this.mainService.feedbackService;
  }

  _run() {
    this.alltexts = [];
    switch (this.feedback.status) {
      case 'nomination':
        this.alltexts.push($localize`:@@ctaTxtFeedbackNomination:Das Feedback wurde noch nicht gestartet.`);

        if (this.nominationArchivedUsersCount() > 0) {
          this.alltexts.push($localize`:@@ctaTxtFeedbackNominationArchivedUsers:Entfernen Sie archivierte Benutzer aus Ihren Nominierungen`);
        }

        if (!this.minNominationsReached()) {
          this.alltexts.push($localize`:@@ctaTxtFeedbackNominationPleaseNominate:Nominieren Sie weitere Feedbackgeber*innen, um das Feedback starten zu können`);
        }
        if (this.minNominationsReached() && !this.maxNominationsReached() && this.nominationArchivedUsersCount() == 0) {
          this.alltexts.push($localize`:@@ctaTxtFeedbackNominationNomReached:Sie haben genügend Feedbackgeber*innen nominiert. Sie können weitere Feedbackgeber*innen nominieren, oder das Feedback jetzt starten.`);
        }
        if (this.maxNominationsReached() && this.nominationArchivedUsersCount() == 0) {
          this.alltexts.push($localize`:@@ctaTxtFeedbackNominationMaxNomReached:Das Maximum der Nominierungen wurde erreicht. Starten Sie als nächstes das Feedback.`);
        }
        break;
      case 'running':
        this.alltexts.push($localize`:@@ctaTxtFeedbackStarted:Das Feedback wurde bereits gestartet.`);
        if (dayjs(this.feedback.endDate).isAfter(dayjs())) {
          this.alltexts.push($localize`:@@ctaTxtFeedbackEnd:Es kann bis zum ${dayjs(this.feedback.endDate).format('DD.MM.YYYY')} feedback gegeben werden.`);
        }
        break;
      case 'ended':
        this.alltexts.push($localize`:@@ctaTxtFeedbackEndet:Dieses Feedback ist bereits abgeschlossen.`);
        break;
    }
  }
  ngOnChanges(): void {
    this.update();
  }

  public update() {
    setTimeout(() => { this._run() }, 100);

  }

  ngOnInit(): void {
    this.update();

  }

  public nominationArchivedUsersCount() {
    return this.mainService.archivedUsersInNominationCount(this.feedback._id)
  }

  public hasSe() {
    return this.mainService.getResponseCount(this.feedback._id, "-1");
  }
  public minNominationsReached() {
    return this.mainService.minNominationsReached(this.feedback._id);
  }

  public getSeUrl() {
    return this.mainService.getSurveyLinkSE(this.feedback._id);
  }
  public maxNominationsReached() {
    return this.mainService.maxNominationsReached(this.feedback._id);
    //
  }
}
