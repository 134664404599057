<app-page-user-management
    *ngIf="this.formFields.length > 0"
    [backendServiceUserType]="type"
    [allTypes]="allTypes"
    [service]="this.frontendService"
    i18n-singularName="@@usersSingularTitle"
    i18n-pluralName="@@usersPluralTitle"
    singularName="Nutzer"
    pluralName="Nutzer"
    [columns]="[
        { fieldName: 'firstname', label: 'Vorname' },
        { fieldName: 'lastname', label: 'Nachname' },
        { fieldName: 'email', label: 'E-Mail' },
        { fieldName: 'creationDate', template: dateColRenderer },
        { fieldName: 'type', template: typeColRenderer },
        { fieldName: 'adminGroups', template: adminGroupsRenderer }
    ]"
    [formCreationData]="this.formCreationData"
    [formFields]="this.formFields"
    [translatedLabels]="translatedLabels"
    [translatedLabelsForm]="translatedLabelsForm"
    (tableSelect)="this.filterSelectableGroups($event.userGroups)"
    (userCreate)="this.filterSelectableGroups([])"
>
</app-page-user-management>


<!-- TEMPLATES -->
<ng-template
    #adminGroupsRenderer
    let-data="data"
>
    <ng-container *ngFor="let adminGroupId of data.adminGroups">
        <small class="badge bg-primary text-start text-white m-1">{{this.adminGroupIdToName(adminGroupId)}}</small>
    </ng-container>
</ng-template>

<ng-template
    #typeColRenderer
    let-data="data"
>
    {{userService.userTypeToName(data.type)}}
</ng-template>

<ng-template
    #dateColRenderer
    let-data="data"
>
    {{data.creationDate | date}}
</ng-template>

<ng-template
    #languageTmp
    ng-label-tmp
    ng-option-tmp
    let-item="item"
    let-search="searchTerm"
    let-data="data"
    let-formField="formField"
>
    <ng-option value="{{item.value}}">
        <span class="fi fi-{{item.img}}"></span>
        {{item.label}}
    </ng-option>
</ng-template>

<ng-template
    #typeTmp
    ng-label-tmp
    ng-option-tmp
    let-item="item"
    let-search="searchTerm"
    let-data="data"
    let-formField="formField"
>
    {{userService.userTypeToName(item)}}
</ng-template>
<ng-template
    #userGroups
    let-data="data"
>
    <ng-container *ngIf="data">
        <div class="row">
            <div class="col">
                <ng-select
                    class="form-control"
                    [items]="selectableGroups"
                    [(ngModel)]="selectedGroup"
                    i18n-placeholder="@@surveysAddUserGroups"
                    placeholder="Befragungsgruppe zuweisen"
                    bindLabel="name"
                    [clearable]="false"
                >
                </ng-select>
            </div>
            <div class="col-auto">
                <div
                    class="btn btn-primary btn-sm"
                    style="height: 36px;  padding-top: calc(0.25rem + 2px)"
                    [ngClass]=" {'disabled':!selectedGroup}"
                    (click)="addGroupToUser(selectedGroup._id, data)"
                >
                    <i class="ri-add-line"></i>
                </div>
            </div>
            <ul class="col-12 list-group list-group-flush">
                <li
                    class="list-group-item pe-0"
                    *ngFor="let group of data.userGroups"
                >
                    <div class="row">
                        <div class="col">
                            {{this.userGroupsService.userGroupsMap?.get(group)?.name}}
                        </div>
                        <div class="col-auto">
                            <div
                                class="btn btn-outline-danger btn-sm"
                                (click)="removeGroupFromUser(group, data)"
                            >
                                <i class="ri-close-line"></i>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </ng-container>
</ng-template>

<ng-template #userFieldSeperator>
    <hr>
    <p i18n="@@userFieldSeperatorDescription">Die folgenden Felder sind nur relevant für Feedbacknehmende</p>
</ng-template>