import { Component, Input } from '@angular/core';
import { ColorService } from '../../shared/color.service';

@Component({
  selector: 'app-reporting-se-header',
  templateUrl: './reporting-se-header.component.html',
  styleUrls: []
})
export class ReportingSeHeaderComponent {
  @Input() seValue: number;
  @Input() scaleMin: number;
  @Input() scaleMax: number;

  constructor(public colorService: ColorService) { }

  public pointToPos(point: number) {
    return this.transformLinear(this.scaleMin - 0.5, this.scaleMax + 0.5, 0, 100, point);
  }

  transformLinear(minInput: number, maxInput: number, minOutput: number, maxOutput: number, inputValue: number) {
    const inputBetween0And1 = (inputValue - minInput) / (maxInput - minInput)
    return minOutput + (inputBetween0And1 * (maxOutput - minOutput))
  }

}
