import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ds-search',
  templateUrl: './ds-search.component.html',
  styleUrls: ['./ds-search.component.scss']
})
export class DsSearchComponent {
  @Input() placeholder: string = "Suche..."

  @Output() searchEvent: EventEmitter<string> = new EventEmitter();

  public emitSearch(event: Event) {
    this.searchEvent.emit((event.target as HTMLInputElement).value)
  }

}
