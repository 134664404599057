<div class="row m-0  align-items-center force-group">
  <div class="col-12 text-center col-xl-2 text-xl-start ">
    <ng-content select="[colA]"></ng-content>

  </div>
  <div class="col px-0">
    <ng-content select="[colB]"></ng-content>
  </div>
  <div class="col-auto align-self-start d-print-none">
    <ng-content select="[colC]"></ng-content>
  </div>

</div><!---->