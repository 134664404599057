import { Component, Input } from '@angular/core';
import { GroupQuestion, SubQuestion } from './../../shared/feedback-report.service';

@Component({
  selector: 'app-anker-col',
  templateUrl: './anker-col.component.html',
  styleUrls: []
})
export class AnkerColComponent {
  @Input() subQuestion: SubQuestion;
  @Input() groupQuestion: GroupQuestion;

}
