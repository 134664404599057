<div
  [id]="id"
  class=" content-grid-header sticky-top mx-n5 px-5  {{class}}  sticky-print-none"
>
  <ng-content select="[before]"></ng-content>



  <div class="row  align-items-center">
    <div class="col-auto">
      <h3 class="card-headline-over-switcher ">{{pagetitle}}</h3>
    </div>
    <div class="col">
      <ng-content></ng-content>
    </div>
    <div class="col-auto switcherdiv">
      <app-table-switcher
        #switcher
        [show]="showSwitcher"
      ></app-table-switcher>
    </div>
    <div
      class="col text-end"
      [ngClass]="{'d-none': !ref.innerHTML.trim()}"
    ><span #ref><ng-content select="[afterSwitcher]"></ng-content></span>
    </div>
  </div>

  <ng-content select="[after]"></ng-content>
</div>