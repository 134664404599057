import { Injectable } from '@angular/core';
import { GetFeedbackReportShareLinkResult } from '@reflact/prmfeedback';
import { ObjectId } from 'bson';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class ShareReportService {

  constructor(
    private socketService: SocketService
  ) { }

  getFeedbackReportShareLink(feedbackId: ObjectId): Promise<GetFeedbackReportShareLinkResult> {
    return new Promise<GetFeedbackReportShareLinkResult>((resolve, reject) => {
      this.socketService.socket.emit('getFeedbackReportShareLink', { feedback_id: feedbackId + "" }, data => {
        if (data.status === 'ok') {
          resolve(data);
        } else {
          reject(data);
        }
      })
    });
  }
}
