import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomGroup, CustomGroupCreationData, Survey } from '@reflact/prmfeedback';
import { ObjectId } from 'bson';
import * as DOMPurify from 'dompurify';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { CustomgroupService } from '../../shared/customgroup.service';
import { FeedbackReportService } from '../../shared/feedback-report.service';
import { MainService } from '../../shared/main.service';


type QuestionSelectorQuestion = {
  group: string,
  label: string
  title: string

}

@Component({
  selector: 'app-customgroup',
  templateUrl: './customgroup.component.html',
  styleUrls: []
})


export class CustomgroupComponent {
  public customGroups: CustomGroup[] = [];
  public questions: QuestionSelectorQuestion[] = [];
  public survey_id: string;
  public survey: Survey = null;
  public customGroupForm = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    min: new UntypedFormControl(0),
    max: new UntypedFormControl(0),
    anonymity: new UntypedFormControl(0),
    type: new UntypedFormControl('bound')
  });

  constructor(private aRoute: ActivatedRoute, private customGroupService: CustomgroupService, public toastrService: ToastrService, public mainService: MainService, public router: Router, public feedbackReportService: FeedbackReportService) {
    this.aRoute.data.subscribe(d => {
      this.customGroups = d.data;
    });

    combineLatest([this.aRoute.params, this.mainService.isDataLoaded]).subscribe(([params, isDataLoaded]) => {
      if (isDataLoaded) {
        this.survey_id = params.survey_id;
        this.getQuestions();
        this.survey = this.mainService.customerService.surveyMap.get(params.survey_id);
      }
    });
  }

  async deleteCustomGroup(customGroup: CustomGroup) {
    const id = await this.customGroupService.deleteCustomGroup(customGroup._id);
    this.customGroups.splice(this.customGroups.findIndex(c => c._id === id), 1);
    this.toastrService.success($localize`:@@globalDeleted:Gelöscht`);
  }

  async editCustomGroup(customGroup: CustomGroup) {
    await this.customGroupService.editCustomGroup(customGroup);
    this.toastrService.success($localize`:@@globalSaved:Gespeichert`);
  }

  async createCustomGroup(customGroup: CustomGroupCreationData) {
    const result = await this.customGroupService.createCustomGroup(customGroup);
    this.toastrService.success($localize`:@@globalAdded:Erstellt`);
    this.customGroups.push(result);
  }

  async getQuestions() {
    const result = await this.feedbackReportService.loadReportQuestions(new ObjectId("" + this.survey_id), this.mainService.loginService.loggedInUser.language);
    this.questions = []
    for (const q of result.questions) {
      if (q.subQuestionText == null) {
        this.questions.push({ group: this.convertHtmlToPlain(q.questionText), label: q.questionText, title: q.title })
      } else {
        this.questions.push({ group: this.convertHtmlToPlain(q.questionText), label: q.subQuestionText, title: q.title })
      }
    }
  }

  private convertHtmlToPlain(input: string) {
    return DOMPurify.sanitize(input, {
      ALLOWED_TAGS: []
    })
  }

  public goBack() {
    this.router.navigate(['surveys'], { queryParams: { selected: this.survey_id } });
  }
}