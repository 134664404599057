<div class="row m-0 align-items-center">
    <div class="col-auto p-0">
        <a
            class="nav-link"
            href="#"
            [routerLink]='["/myuser"]'
        >
            <div [ngSwitch]="this.user.type">
                <i
                    *ngSwitchCase="'prmadmin'"
                    class="ri-shield-user-fill fix-ri "
                ></i>
                <i
                    class="ri-user-2-fill fix-ri "
                    *ngSwitchCase="'hr'"
                ></i>
                <i
                    *ngSwitchCase="'feedbackreceiver'"
                    class="ri-user-received-fill fix-ri "
                ></i>
                <i
                    *ngSwitchCase="'feedbackgiver'"
                    class="ri-user-shared-fill fix-ri "
                ></i>
                <i
                    *ngSwitchDefault
                    class="ri-user-3-fill fix-ri "
                ></i>
            </div>
        </a>
    </div>
    <div class="col p-0 m-0">
        <a
            class="nav-link-text text-dark"
            href="#"
            [routerLink]='["/myuser"]'
        >
            {{this.user.firstname}}
            {{this.user.lastname}}</a>
    </div>
</div>