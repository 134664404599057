import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgxForm, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { Feedback, Perspective, StatusMsg, Survey } from '@reflact/prmfeedback';
import {
  DatasupplyRequest,
  RagDatasupplyFrontendService,
  RagDatasupplyLocalBackendService, RagDatasupplyOverlayAssideComponent, RagDatasupplyOverlayUrlService, RagDatasupplyTypes
} from '@reflact/rag-datasupply';
import { SerializedDate } from '@reflact/spftypes';
import dayjs from "dayjs";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { translateTexts } from '../../app.component';
import { CustomerService } from '../../shared/customer.service';
import { MainService } from '../../shared/main.service';
import { TableSortUtil } from './../../shared/TableSortUtil';
import { FeedbackService } from './../../shared/feedback.service';

@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: [],
  providers: [
    RagDatasupplyLocalBackendService
  ]
})
export class FeedbacksComponent implements OnInit, OnDestroy {
  @ViewChild("overlay") overlay: RagDatasupplyOverlayAssideComponent;
  public modalRefConfirmRestart: BsModalRef;
  public buttons = [$localize`:@@globalOverview:Übersicht`];
  public subscriptions: Subscription[] = [];
  public customerService: CustomerService;
  public feedbackService: FeedbackService;
  public feedbackInfoService: RagDatasupplyFrontendService<Perspective>;
  public availableSurveys: Survey[] = [];
  public selectedFeedback: Feedback;
  public noDataText: string = $localize`:@@noDataTextFeedbacks:Hier erscheint eine Übersicht aller Feedbacks sobald diese von Nutzern angelegt wurden`;
  public translatedLabelsForm: NgxForm.TranslatedLabels = new Map([
    ["feedbackreceiver_id", $localize`:@@globalFN:Feedbacknehmer*in`],
    ["survey_id", $localize`:@@globalSurvey:Befragung`],
    ["endDate", $localize`:@@globalEnd:Ende`],
    ["name", $localize`:@@globalTitle:Titel`],
    ["status", $localize`:@@globalStatus:Status`]
  ])
  public titleTranslations: RagDatasupplyTypes.TranslatedLabels[] = convertTranslatedLabelsToDatasupply(this.translatedLabelsForm)
  public quickLookTranslations: RagDatasupplyTypes.TranslatedLabels[] = [
    { fieldName: 'name', translated: $localize`:@@globalPerspective:Perspektive` },
    { fieldName: 'anonymity', translated: $localize`:@@globalAnonymity:Anonymitätsgrenze` },
    { fieldName: 'type', translated: $localize`:@@globalNominationType:Nominierungsverfahren` },
    { fieldName: 'responserate', translated: $localize`:@@globalResponseRate:Rücklauf` },
    { fieldName: 'nomination', translated: $localize`:@@globalNominations:Nominierungen` },
    { fieldName: 'min', translated: $localize`:@@globalMinCount:Minimum-Anzahl` },
    { fieldName: 'max', translated: $localize`:@@globalMaxCount:Maximal-Anzahl` }
  ];
  public statusSelectValues = [
    { value: 'nomination', label: $localize`:@@navNomination:Nominierung` },
    { value: 'running', label: $localize`:@@globalFeedbackRunning:Feedback läuft` },
    { value: 'ended', label: $localize`:@@globalDone:Abgeschlossen` }
  ];
  public NotFoundText: string = $localize`:@@globalNotFound:Nicht gefunden`;
  public archivedText: string = $localize`:@@globalToArchive:Archivieren`;
  public deleteText: string = $localize`:@@globalDelete:Löschen`;

  public tableSort: TableSortUtil<Feedback>;
  public standardRequest: DatasupplyRequest = {
    sortfield: "",
    sortorder: "asc",
    skip: 0,
    limit: 10000,
    search: '',
    searchablefields: ["name", "status"]
  }
  public feedbackRecieverIdToNameObj: { [key: string]: string } = {}
  public surveyIdToNameObj: { [key: string]: string } = {}

  public localTransLatedTexts = { ...translateTexts }

  constructor(public toaster: ToastrService, public mainService: MainService, public frontendService: RagDatasupplyFrontendService<Feedback>, public modalService: BsModalService, public urlHelper: RagDatasupplyOverlayUrlService,
    public backendService: RagDatasupplyLocalBackendService<Feedback>) {
    this.customerService = mainService.customerService;
    this.feedbackService = mainService.feedbackService;
    this.backendService.initialRequest(this.standardRequest);
  }

  public getTranslatedTexts(f: Feedback) {
    let texts = this.localTransLatedTexts
    if (f.status == 'ended') {
      texts.delete = $localize`:@@globalArchive:Archivieren`
    } else {
      texts.delete = $localize`:@@globalDelete:Löschen`
    }
    return texts;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.tableSort = new TableSortUtil(this.frontendService);

    this.backendService.loadData = () => {
      return new Promise<Feedback[]>((resolve, reject) => {
        if (this.feedbackService.feedbacks) {
          resolve([...this.feedbackService.feedbacks])
        } else {
          reject([])
        }
      })
    }
    this.subscriptions.push(this.mainService.isDataLoaded.subscribe((done) => {
      if (done) {
        this.backendService.loadInitialRequest()
        this.availableSurveys = this.customerService.surveys.filter(l => l.status === 'published');
        this.feedbackRecieverIdToName();
        this.surveyIdToName();
      }
    }));

    this.subscriptions.push(this.backendService.results$.subscribe(results => {
      this.frontendService.fromArray(results);
      this.urlHelper.getSelectedId(true);
    }))

    this.subscriptions.push(this.feedbackService.error$.subscribe((i) => {
      this.toaster.error(i.message);
    }));

    this.subscriptions.push(this.feedbackService.feedbackCreated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalFeedbackCreated:Feedback erfolgreich angelegt`);
    }));

    this.subscriptions.push(this.feedbackService.feedbackUpdated$.subscribe((i) => {
      this.toaster.success($localize`:@@globalFeedbackUpdated:Feedback erfolgreich gespeichert`);
      this.overlay.selectedObject = i;
      this.backendService.load(this.standardRequest);
    }));

    this.subscriptions.push(this.feedbackService.feedbackDeleted$.subscribe((i) => {
      this.toaster.success($localize`:@@globalFeedbackDeleted:Feedback erfolgreich gelöscht`);
      this.backendService.load(this.standardRequest);
    }));
    this.subscriptions.push(this.urlHelper.newSelection$.subscribe(s => {
      this.overlay.selectedObject = this.feedbackService.feedbacks.find(d => d._id == s)
    }))
  }

  public dsSearch(search: string) {
    this.standardRequest.search = search;
    this.backendService.load(this.standardRequest);
  }

  public async updateItem(i: Feedback) {
    this.feedbackService.updateFeedback(i);
  }
  public async deleteItem(i: Feedback) {
    const feedback = await this.feedbackService.deleteFeedback(i);
    if (feedback.archived) {
      //archiviert
      const archivedFeedback = this.frontendService.rawData.find(rd => rd._id == feedback._id)
      if (archivedFeedback != null) {
        archivedFeedback.archived = true
        this.frontendService.fromArray([...this.frontendService.rawData])
      }
    } else {
      this.backendService.loadInitialRequest()
      // gelöscht
    }
  }
  public async newItem(i: Feedback) {
    this.feedbackService.createFeedback(i);
  }

  public feedbackRecieverIdToName() {
    this.mainService.userService.users.forEach(u => {
      this.feedbackRecieverIdToNameObj[u._id] = u.firstname + " " + u.lastname;
    })
  }

  public surveyIdToName() {
    this.customerService.surveys.forEach(s => {
      this.surveyIdToNameObj[s._id] = s.name;
    })
  }

  public getFeedbackInfo(f: Feedback) {
    return this.mainService.getFeedbackInfo(f._id);
  }

  public getFeedbackInfoService(feedbacks: Perspective[]): RagDatasupplyFrontendService<Perspective> {
    this.feedbackInfoService = new RagDatasupplyFrontendService<Perspective>();
    this.feedbackInfoService.fromArray(feedbacks);
    return this.feedbackInfoService;
  }

  public openRestartConfirm(template: TemplateRef<any>, feedback: Feedback) {
    this.selectedFeedback = feedback;
    this.selectedFeedback.endDate = dayjs(this.selectedFeedback.endDate).format('YYYY-MM-DD');
    this.modalRefConfirmRestart = this.modalService.show(template);
  }

  public restartFeedbackByAdmin() {
    this.feedbackService.restartFeedbackByAdmin(this.selectedFeedback).catch((error: StatusMsg) => {
      this.toaster.error($localize`:@@globalErrorOccurred:Es ist ein Fehler aufgetreten:` + ' "' + error.message + '"');
    });
    this.modalRefConfirmRestart.hide();
  }

  public isArchived(f: Feedback) {
    return f.archived ?? false;
  }
  public hasEndDate(f: Feedback): 'date' | 'template' {
    return !dayjs(f.endDate).isSame(dayjs(0)) ? 'date' : 'template'
  }
  public dateIsInFuture(date: SerializedDate) {
    return dayjs().isBefore(dayjs(date));
  }
}