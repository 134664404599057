<lib-rag-datasupply-overlay-asside
  #help
  [mode]="'manual'"
>
  <div class="p-5">
    <h1 i18n="@@help">Hilfe</h1>
    <ng-content></ng-content>
  </div>
</lib-rag-datasupply-overlay-asside>

<lib-rag-datasupply-overlay-asside
  #newOverlay
  [mode]="'manual'"
>
  <div class="p-5">
    <ng-container *ngTemplateOutlet="newFormTpl"></ng-container>
  </div>
</lib-rag-datasupply-overlay-asside>

<lib-rag-datasupply-overlay-asside
  #importOverlay
  [overrideWidth]="75"
  [mode]="'manual'"
>
  <div class="p-5">
    <ng-container *ngTemplateOutlet="importFormTpl"></ng-container>
  </div>
</lib-rag-datasupply-overlay-asside>

<div class="row">
  <div
    class="col-auto"
    *ngIf="showNewButton && newBtnLabel != ''"
  >
    <div
      class="btn btn-primary"
      (click)="newOverlay.open(); newBtnClick.emit()"
    >{{newBtnLabel}}</div>
  </div>
  <div
    class="col-auto"
    *ngIf="showImportButton"
  >
    <div
      class="btn btn-primary"
      (click)="importOverlay.open()"
    >{{importBtnLabel}}</div>
  </div>
  <div class="col text-end">
    <a
      [href]="this.faqService.getFAQLink(this.topic)"
      style="text-decoration: none;"
      target="_blank"
      rel="noopener"
    >
      <i
        class="ri-questionnaire-line ri-2x"
        style="color:#004FA0;"
      ></i>
    </a>
  </div>
</div>