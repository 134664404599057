export interface INavBadge {
  text: string;
  variant: string;
  class?: string;
}

export interface INavAttributes {
  [propName: string]: any;
}

export interface INavData {
  name?: string;
  url?: string;
  href?: string;
  icon?: string;
  badge?: INavBadge;
  title?: boolean;
  children?: INavData[];
  variant?: string;
  attributes?: INavAttributes;
  divider?: boolean;
  class?: string;
  label?: INavLabel;
  wrapper?: INavWrapper;
  linkProps?: INavLinkProps;
}

export interface INavLabel {
  class?: string;
  variant: string;
}

export interface INavWrapper {
  attributes: INavAttributes;
  element: string;
}

export interface INavLinkProps {
  queryParams?: {
    [k: string]: any;
  };
  fragment?: string;
  queryParamsHandling?: 'merge' | 'preserve' | '';
  preserveFragment?: boolean;
  skipLocationChange?: boolean;
  replaceUrl?: boolean;
  state?: {
    [k: string]: any;
  };
  routerLinkActiveOptions?: {
    exact: boolean;
  };
  routerLinkActive?: string | string[];
}

export const navItemsAdmin: INavData[] = [

  {
    title: true,
    name: $localize`:@@navFeedbackSuite:Feedback Suite`
  },
  {
    name: $localize`:@@navMyFeedbacks:Meine Feedbacks`,
    url: '/my-feedbacks',
    icon: "ri-chat-3-line"
  },
  {
    name: $localize`:@@navMyDocuments:Meine Dokumente`,
    url: '/my-documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navGiveFeedback:Feedback geben`,
    url: '/feedback-invitations',
    icon: "ri-user-voice-line"
  },
  {
    name: $localize`:@@navHelpArea:Hilfe anfordern`,
    url: '/help-area',
    icon: "ri-question-line"
  },
  {
    title: true,
    name: $localize`:@@navAdministration:Administration`
  },
  {
    name: $localize`:@@navUsers:Nutzer`,
    url: '/users',
    icon: "ri-user-line"
  },
  {
    name: $localize`:@@navUserGroups:Befragungsgruppen`,
    url: '/usergroups',
    icon: "ri-group-line"
  },
  {
    name: $localize`:@@navAdminGroups:Teams`,
    url: '/admingroups',
    icon: "ri-group-2-line"
  },
  {
    name: $localize`:@@navSurveys:Befragungen`,
    url: '/surveys',
    icon: "ri-menu-line"
  },
  {
    name: $localize`:@@navDocuments:Dokumente`,
    url: '/documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navFeedbacks:Alle Feedbacks`,
    url: '/feedbacks',
    icon: "ri-chat-3-line"
  },
  {
    name: $localize`:@@navMailManager:E-Mailvorlagen`,
    url: '/mail-manager',
    icon: "ri-mail-line"
  },
  {
    name: $localize`:@@navSubsidiaries:Tochtergesellschaften`,
    url: '/subsidiaries',
    icon: 'ri-building-line'
  },
  {
    name: $localize`:@@navCoaches:Coaches`,
    url: '/coaches',
    icon: 'ri-customer-service-2-fill'
  },
  {
    title: true,
    name: $localize`:@@navSuperadmin:Super-Administration`,
  },
  {
    name: $localize`:@@navCustomers:Mandanten`,
    url: '/customers',
    icon: "ri-building-2-line"
  },
  {
    name: $localize`:@@navAdmins:Administratoren`,
    url: '/admins',
    icon: "ri-user-star-line"
  },
  {
    name: $localize`:@@navLoginHistory:Login-Historie`,
    url: '/login-history',
    icon: "ri-history-line"
  },
  {
    name: $localize`:@@navMailManagerAdmin:E-Mailvorlagen (Admin)`,
    url: '/mail-manager-admin',
    icon: "ri-mail-star-line"
  }
];

export const navItemsHr: INavData[] = [
  {
    title: true,
    name: $localize`:@@navFeedbackSuite:Feedback Suite`,
  },
  {
    name: $localize`:@@navMyFeedbacks:Meine Feedbacks`,
    url: '/my-feedbacks',
    icon: "ri-chat-3-line"
  },
  {
    name: $localize`:@@navMyDocuments:Meine Dokumente`,
    url: '/my-documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navGiveFeedback:Feedback geben`,
    url: '/feedback-invitations',
    icon: "ri-user-voice-line"
  },
  {
    name: $localize`:@@navHelpArea:Hilfe anfordern`,
    url: '/help-area',
    icon: "ri-question-line"
  },
  {
    title: true,
    name: $localize`:@@navAdministration:Administration`
  },
  {
    name: $localize`:@@navUsers:Nutzer`,
    url: '/users',
    icon: "ri-user-line"
  },
  {
    name: $localize`:@@navUserGroups:Befragungsgruppen`,
    url: '/usergroups',
    icon: "ri-group-line"
  },
  {
    name: $localize`:@@navAdminGroups:Teams`,
    url: '/admingroups',
    icon: "ri-group-2-line"
  },
  {
    name: $localize`:@@navSurveys:Befragungen`,
    url: '/surveys',
    icon: "ri-menu-line"
  },
  {
    name: $localize`:@@navDocuments:Dokumente`,
    url: '/documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navFeedbacks:Alle Feedbacks`,
    url: '/feedbacks',
    icon: "ri-chat-3-line"
  },
  {
    name: $localize`:@@navMailManager:E-Mailvorlagen`,
    url: '/mail-manager',
    icon: "ri-mail-line"
  },
  {
    name: $localize`:@@navSubsidiaries:Tochtergesellschaften`,
    url: '/subsidiaries',
    icon: 'ri-building-line'
  },
  {
    name: $localize`:@@navCoaches:Coaches`,
    url: '/coaches',
    icon: 'ri-customer-service-2-fill'
  },
];

export const navItemsGroupAdmin: INavData[] = [
  {
    title: true,
    name: $localize`:@@navFeedbackSuite:Feedback Suite`,
  },
  {
    name: $localize`:@@navMyFeedbacks:Meine Feedbacks`,
    url: '/my-feedbacks',
    icon: "ri-chat-3-line"
  },
  {
    name: $localize`:@@navMyDocuments:Meine Dokumente`,
    url: '/my-documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navGiveFeedback:Feedback geben`,
    url: '/feedback-invitations',
    icon: "ri-user-voice-line"
  },
  {
    name: $localize`:@@navHelpArea:Hilfe anfordern`,
    url: '/help-area',
    icon: "ri-question-line"
  },
  {
    title: true,
    name: $localize`:@@navAdministration:Administration`
  },
  {
    name: $localize`:@@navUsers:Nutzer`,
    url: '/users',
    icon: "ri-user-line"
  },
  {
    name: $localize`:@@navUserGroups:Befragungsgruppen`,
    url: '/usergroups',
    icon: "ri-group-line"
  },
  {
    name: $localize`:@@navSurveys:Befragungen`,
    url: '/surveys',
    icon: "ri-menu-line"
  },
  {
    name: $localize`:@@navDocuments:Dokumente`,
    url: '/documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navFeedbacks:Alle Feedbacks`,
    url: '/feedbacks',
    icon: "ri-chat-3-line"
  },
  {
    name: $localize`:@@navSubsidiaries:Tochtergesellschaften`,
    url: '/subsidiaries',
    icon: 'ri-building-line'
  },
  {
    name: $localize`:@@navCoaches:Coaches`,
    url: '/coaches',
    icon: 'ri-customer-service-2-fill'
  },
];

export const navItemsFeedbackreceiver: INavData[] = [
  {
    title: true,
    name: $localize`:@@navFeedbackSuite:Feedback Suite`,
  },
  {
    name: $localize`:@@navMyFeedbacks:Meine Feedbacks`,
    url: '/my-feedbacks',
    icon: "ri-chat-3-line"
  },
  {
    name: $localize`:@@navMyDocuments:Meine Dokumente`,
    url: '/my-documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navGiveFeedback:Feedback geben`,
    url: '/feedback-invitations',
    icon: "ri-user-voice-line"
  },
  {
    name: $localize`:@@navHelpArea:Hilfe anfordern`,
    url: '/help-area',
    icon: "ri-question-line"
  }
];

export const navItemsFeedbackgiver: INavData[] = [
  {
    title: true,
    name: $localize`:@@navFeedbackSuite:Feedback Suite`,
  },
  {
    name: $localize`:@@navMyDocuments:Meine Dokumente`,
    url: '/my-documents',
    icon: "ri-file-text-line"
  },
  {
    name: $localize`:@@navGiveFeedback:Feedback geben`,
    url: '/feedback-invitations',
    icon: "ri-user-voice-line"
  },
  {
    name: $localize`:@@navHelpArea:Hilfe anfordern`,
    url: '/help-area',
    icon: "ri-question-line"
  }
];
