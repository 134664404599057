import { Component, Input } from '@angular/core';
import { NgxForm } from '@reflact/ngx-forms';
import { BucketListItem, BucketListItemType, BucketListItemTypeArray } from '../bucketselect.component';

@Component({
  selector: 'app-bucketlist',
  templateUrl: './bucketlist.component.html',
  styleUrls: ['./bucketlist.component.scss']
})
export class BucketlistComponent {
  @Input() list: BucketListItem[] = [];
  public search: string = "";
  public filterTranslation = {}
  public translatedFilter: NgxForm.TranslatedLabels = new Map([
    ["coaches", $localize`:@@filterCoaches:Filter: Coaches`],
    ["feedbackgiver", $localize`:@@filterFeedbackgiver:Filter: Feedbackgebende`],
    ["feedbackreceiver", $localize`:@@filterFeedbackreceiver:Filter: Feedbacknehmende`],
    ["assignedbucketmanager", $localize`:@@filterAssignedbucketmanager:Filter: Team-Admins`],
    ["userGroups", $localize`:@@filterUserGroups:Filter: Befragungsgruppen`],
    ["surveys", $localize`:@@filterSurveys:Filter: Befragungen`],
    ["documents", $localize`:@@filterDocuments:Filter: Dokumente`],
    ["none", $localize`:@@filterCheckedOnly:Filter: Ausgewählte`]
  ])
  //  selectedFilter
  public buttonTypes = BucketListItemTypeArray;
  public selectedFilter: BucketListItemType | "none" = BucketListItemTypeArray[0];
  @Input() showNumbersInTabs: boolean = false;

  itemHiddenByFilter(item: BucketListItem) {
    if (this.selectedFilter == "none" && item.checked) return false;
    if (this.selectedFilter != item.type) return true;
    return !item.label.toLowerCase().includes((this.search.toLowerCase()));
  }

  itemsOfThisTypeCount(type: BucketListItemType | "none") {
    let counter = 0;
    counter = this.list.filter((i) => i.type == type).length;
    return counter;
  }

  itemOfThisTypeSelected(type: BucketListItemType | "none") {
    let typeFoundInSelected = false;
    this.checkedItems().forEach((i: BucketListItem) => {
      if (i.checked && type == i.type) {
        typeFoundInSelected = true;
      }
    })
    return typeFoundInSelected;
  }

  toggleAll(newVal: boolean) {
    this.list.forEach((item: BucketListItem) => {
      if (!this.itemHiddenByFilter(item)) {
        item.checked = newVal;
      }
    })
  }

  allVisibleAreChecked() {
    let foundUnchecked = false;
    this.list.forEach((item: BucketListItem) => {
      if (!this.itemHiddenByFilter(item)) {
        if (!item.checked) {
          foundUnchecked = true;
        }
      }

    });
    return !foundUnchecked;
  }


  checkedItems() {
    return this.list.filter(li => li.checked);
  }

  hasSelectedItems() {
    return this.list.filter(li => li.checked).length > 0;
  }
  highlighted(str: string) {

    return str.split(this.search.trim()).join("<b><u>" + this.search.trim() + "</u></b>");







  }
  getItemClassObject(type: BucketListItemType | "none") {
    return {
      'ri-checkbox-multiple-line': type == 'none',
      'ri-user-star-line': type == 'assignedbucketmanager',
      'ri-user-shared-line': type == 'feedbackgiver',
      'ri-user-received-line': type == 'feedbackreceiver',
      'ri-menu-line': type == 'surveys',
      'ri-file-text-line': type == 'documents',
      'ri-group-line': type == 'userGroups',
      'ri-customer-service-2-fill': type == 'coaches'
    }
  }
}
