import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_AnswersForFeedbackGet, API_AnswersForSurveyGet, API_FeedbackinfoGet, API_QuestionsGet, API_Surveyinfo, BenchmarkFilter, CustomGroup, FeedbackInfoRequest, FeedbackInfoResponse, getReportLang, ReportAnswer, ReportingAnswerForFeedbackRequest, ReportingAnswerForSurveyRequest, ReportingAnswerResponse, ReportingQuestionRequest, ReportingQuestionResponse, ReportingResponseRate, ReportQuestion, SurveyInfoRequest, SurveysInfoResponse } from '@reflact/prmfeedback';
import { deserialize, ObjectId, serialize } from 'bson';
import { firstValueFrom } from 'rxjs';

export type MinMaxMeanCount = {
  min: number;
  max: number;
  mean: number;
  count: number;
}

export type PerspectivesData = MinMaxMeanCount & {
  perspectiveId: string;
};

export type GroupQuestion = {
  questionTitle: string;
  questionText: string;
  subQuestions: SubQuestion[]
};

export type TextGroupQuestion = {
  questionTitle: string;
  questionText: string;
  subQuestions: TextSubQuestion[],
  perspectiveData: { perspectiveId: string, comments: string[] }[];
};

export type SubQuestion = {
  subQuestionTitle: string;
  subQuestionText: string;
  perspectiveData: PerspectivesData[];
  selfEvaluation: MinMaxMeanCount;
}
export type TextSubQuestion = {
  subQuestionTitle: string;
  subQuestionText: string;
  perspectiveData: { perspectiveId: string, comments: string[] }[];
}
export type BenchmarkInfoMap = Map<string, {
  id: string,
  label: string,
  values: string[]
}>
export type ReportData = {
  groupId: string;
  groupName: string;
  perspectiveData: PerspectivesData[]
  selfEvaluation: MinMaxMeanCount
  groupQuestions: GroupQuestion[],
  textQuestions: TextGroupQuestion[],
  aggMean: number;
  aggSEDifference: number;
  benchmarkInfoMap: BenchmarkInfoMap;
}

export type QuestionGroupIdToNameMap = Map<string, string>;
export type ScaleEntry = {
  key: string;
  text: string;
  order: number;
};

@Injectable({
  providedIn: 'root'
})
export class FeedbackReportService {
  private textTypes = ['S', 'Q', 'T', 'U'];
  private matrixTypes = ["F"]
  private notSupportedTypes = ['M'];
  private API_PREFIX = '/api/bson';
  private questionGroupIdToNameMap: QuestionGroupIdToNameMap;

  private scale: ScaleEntry[];

  private questions: ReportQuestion[];
  private answers: ReportAnswer[];
  private customGroupMap: Map<string, CustomGroup>
  constructor(private http: HttpClient) {
  }
  public async loadFullDataForReport(feedbackOrSurveyId: ObjectId, userLanguage: string, isSurveyId: boolean, benchmarkFilters?: BenchmarkFilter[]) {

    const data: ReportData[] = [];
    const { scale, questionGroupIdToNameMap, perspectives, responseRates, benchmarkInfoMap, feedbackName, feedbackReceiverName } = await this.loadDataForReport(feedbackOrSurveyId, userLanguage, isSurveyId, benchmarkFilters);
    let scaleMin = undefined;
    let scaleMax = undefined;
    if (this.scale.length > 0) {
      scaleMin = Math.min(...scale.map(s => +s.key))
      scaleMax = Math.max(...scale.map(s => +s.key))
    }
    for (const [groupId, groupName] of this.questionGroupIdToNameMap.entries()) {
      const groupQuestions: GroupQuestion[] = this.getGroupQuestionForGroupId(perspectives, groupId)
      const textQuestions: TextGroupQuestion[] = [];

      const questionsWithTextTitles = this.getTextTitlesFromGroupId(groupId);
      questionsWithTextTitles.forEach(q => {
        const textSubQuestions: TextSubQuestion[] = [];
        const subQuestions = this.getSubQuestionsFromTextTitle(q.parentTitle);
        subQuestions.forEach(sq => {
          const subPerspectiveData: {
            perspectiveId: string;
            comments: string[];
          }[] = [];
          perspectives.forEach(p => {
            subPerspectiveData.push({
              perspectiveId: p.id,
              comments: this.getCommentsForQuestionAndPerspectiveId(p.id, sq.title)
            });
          });
          if (!perspectives.some(p => p.id === 'SE')) {
            subPerspectiveData.unshift({
              perspectiveId: 'SE',
              comments: this.getCommentsForQuestionAndPerspectiveId('SE', sq.title)
            });
          }
          const textSubQuestion: TextSubQuestion = {
            subQuestionTitle: sq.title,
            subQuestionText: sq.subQuestionText,
            perspectiveData: subPerspectiveData
          }
          textSubQuestions.push(textSubQuestion);
        });
        const perspectiveData: {
          perspectiveId: string;
          comments: string[];
        }[] = [];
        perspectives.forEach(p => {
          perspectiveData.push({
            perspectiveId: p.id,
            comments: this.getCommentsForQuestionAndPerspectiveId(p.id, q.title)
          });
        });
        if (!perspectives.some(p => p.id === 'SE')) {
          perspectiveData.unshift({
            perspectiveId: 'SE',
            comments: this.getCommentsForQuestionAndPerspectiveId('SE', q.title)
          });
        }
        const textGroupQuestion: TextGroupQuestion = {
          questionTitle: q.title,
          questionText: q.questionText,
          subQuestions: textSubQuestions,
          perspectiveData
        }
        textQuestions.push(textGroupQuestion);
      });
      const entry: ReportData = {
        groupId,
        groupName,
        perspectiveData: [],
        selfEvaluation: this.getDataForGroupAndPerspectiveId('SE', groupId),
        groupQuestions,
        textQuestions,
        aggMean: 0,
        aggSEDifference: 0,
        benchmarkInfoMap
      };
      perspectives.forEach(perspective => {
        const pData: PerspectivesData = {
          perspectiveId: perspective.id,
          ...this.getDataForGroupAndPerspectiveId(perspective.id, groupId)
        }
        entry.perspectiveData.push(pData);
      });
      const perspectiveDataWithInAnonymityRange = entry.perspectiveData.filter(p => p.perspectiveId != 'SE' && p.count > 0);
      const groupTotal = perspectiveDataWithInAnonymityRange.map(p => p.mean).reduce((a, b) => a + b, 0);
      const groupLength = perspectiveDataWithInAnonymityRange.length;
      entry.aggMean = groupTotal / groupLength;
      // Old version: entry.aggSEDifference = 100 - (Math.abs(entry.aggMean - entry.selfEvaluation.mean) / (scaleMax - scaleMin) * 100);
      entry.aggSEDifference = entry.aggMean - entry.selfEvaluation.mean;
      data.push(entry);
    }

    return { scale, scaleMin, scaleMax, data, questionGroupIdToNameMap, perspectives, responseRates, benchmarkInfoMap, feedbackName, feedbackReceiverName };
  }

  public getGroupQuestionForGroupId(perspectives, groupId: string) {
    const subQuestions = []

    if (!this.isCustomGroupId(groupId)) {
      subQuestions.push(...this.questions.filter(q =>
        !this.textTypes.includes(q.type)
        && q.subQuestionText != null
        && q.group_order == groupId))
    } else {
      subQuestions.push(...this.questions.filter(q =>
        !this.textTypes.includes(q.type)
        && q.subQuestionText != null
        && this.customGroupMap.get(groupId).questionTitles.includes(q.title)
      ))
    }
    subQuestions.sort((sq1, sq2) => sq1.title.localeCompare(sq2.title))

    const groupQuestionMap: Map<string, GroupQuestion> = new Map()

    for (const s of subQuestions) {
      const perspectiveData: PerspectivesData[] = [];
      perspectives.forEach(p => {
        perspectiveData.push({
          perspectiveId: p.id,
          ...this.getDataForGroupAndPerspectiveId(p.id, groupId, s.title)
        });
      });
      const subQuestion: SubQuestion = {
        subQuestionTitle: s.title,
        subQuestionText: s.subQuestionText,
        selfEvaluation: this.getDataForGroupAndPerspectiveId('SE', groupId, s.title),
        perspectiveData
      }
      if (!groupQuestionMap.has(s.parentTitle)) {
        groupQuestionMap.set(s.parentTitle, {
          questionTitle: s.parentTitle,
          questionText: s.questionText.replace(/[\n\r]+/, ': '),
          subQuestions: []
        })
      }

      groupQuestionMap.get(s.parentTitle).subQuestions.push(subQuestion)

    };
    return Array.from(groupQuestionMap.values())
  }

  public async loadDataForReport(feedbackOrSurveyId: ObjectId, userLanguage: string, isSurveyId: boolean, benchmarkFilters?: BenchmarkFilter[]) {
    let surveyId = undefined;
    let feedbackId = undefined;
    let responseRates: ReportingResponseRate[] = undefined;
    let answerResponse: ReportingAnswerResponse;
    let feedbackName: string = undefined;
    let feedbackReceiverName: string = undefined;
    if (isSurveyId) {
      surveyId = feedbackOrSurveyId;
      answerResponse = await this.loadSurveyReportAnswers(surveyId);

    } else {
      feedbackId = feedbackOrSurveyId;
      const feedbackInfo = await this.loadFeedbackInfo(feedbackId);
      responseRates = feedbackInfo.responseRates;
      feedbackName = feedbackInfo.feedback.name;
      feedbackReceiverName = feedbackInfo.receiverName;
      answerResponse = await this.loadFeedbackReportAnswers(feedbackId);
      surveyId = feedbackInfo.feedback.survey_id;
    }
    const surveyInfo = await this.loadSurveyInfo(surveyId);
    if (responseRates == undefined) {
      responseRates = surveyInfo.responseRates;
    }

    const benchmarkInfoMap: BenchmarkInfoMap = new Map()
    for (const bConfigs of surveyInfo.benchmarkConfigs) {
      benchmarkInfoMap.set(bConfigs.id, { id: bConfigs.id, label: bConfigs.label, values: [] })
    }
    for (const a of answerResponse.answers) {
      for (const benchmark of a.feedbackReceiverBenchmarks) {
        const confToUpdate = benchmarkInfoMap.get(benchmark.id)
        if (confToUpdate != null) {
          if (confToUpdate.values.indexOf(benchmark.value) < 0) {
            confToUpdate.values.push(benchmark.value)
          }
        }
      }
    }

    if (benchmarkFilters != null && benchmarkFilters.length > 0) {
      answerResponse.answers = answerResponse.answers.filter(a =>
        benchmarkFilters.every(bf =>
          a.feedbackReceiverBenchmarks.some(frb => bf.benchmarkId == frb.id && bf.benchmarkValue == frb.value))
      )
    }



    const language = getReportLang(userLanguage, surveyInfo.availableLanguages);
    const questionResponse = await this.loadReportQuestions(surveyId, language);
    this.questions = questionResponse.questions;
    this.answers = answerResponse.answers;





    this.questionGroupIdToNameMap = new Map(questionResponse.questions.map(q => [q.group_order, q.group_name]));
    this.customGroupMap = new Map(questionResponse.customGroups.map(cg => {
      this.questionGroupIdToNameMap.set("" + cg._id, cg.groupName)
      return ["" + cg._id, cg]
    }))


    this.scale = [];
    if (questionResponse.answeroptions != undefined) {
      for (const [key, value] of Object.entries(questionResponse.answeroptions)) {
        const scaleEntry = {
          key,
          text: value.answer,
          order: +value.order,

        }
        if (key != 'N') {
          this.scale.push(scaleEntry);
        }
      }
      this.scale.sort((a, b) => a.order - b.order);
    }

    if (isSurveyId) {
      surveyInfo.survey.perspectives.push({ id: "SE", max: 1, min: 1, type: "bound", anonymity: 1, name: "SE" })
    }

    return {
      scale: this.scale, questionGroupIdToNameMap: this.questionGroupIdToNameMap, perspectives: surveyInfo.survey.perspectives, responseRates, benchmarkInfoMap, feedbackName, feedbackReceiverName
    };
  }

  public isCustomGroupId(groupId: string) {
    return groupId.length > 5
  }

  public getSubQuestionsFromParentTitle(parentTitle: string, groupId: string) {
    return this.questions.filter(q => (!this.textTypes.includes(q.type)) && q.group_order == groupId && q.parentTitle === parentTitle && q.subQuestionTitle != null);
  }
  public getTextTitlesFromGroupId(groupId: string) {
    return this.questions.filter(q => (this.textTypes.includes(q.type)) && q.group_order === groupId && q.subQuestionTitle == null);
  }
  public getSubQuestionsFromTextTitle(parentTitle: string) {
    return this.questions.filter(q => (this.textTypes.includes(q.type)) && q.parentTitle === parentTitle && q.subQuestionTitle != null);
  }
  public getDataForGroupAndPerspectiveId(perspectiveId: string, groupId?: string, subQuestionId?: string): MinMaxMeanCount {
    const questionTitles = this.questions.filter(q =>
      (this.matrixTypes.includes(q.type)) &&
      (groupId == null ||
        (groupId == q.group_order ||
          this.customGroupMap.has(groupId) && this.customGroupMap.get(groupId).questionTitles.indexOf(q.title) >= 0

        )) &&
      (subQuestionId == null || q.title == subQuestionId)
    ).map(q => q.title);
    const answers = this.answers.filter(a => questionTitles.includes(a.questionTitle) && a.perspective_id === perspectiveId);

    const minMaxMean = this.calcMinMaxMeanCount(answers);
    return minMaxMean
  }
  public getCommentsForGroupAndPerspectiveId(perspectiveId: string, groupId?: string, subQuestionId?: string): string[] {
    const questionTitles = this.questions.filter(q =>
      (this.textTypes.includes(q.type)) &&
      (groupId == null ||
        (groupId == q.group_order ||
          this.customGroupMap.has(groupId) && this.customGroupMap.get(groupId).questionTitles.indexOf(q.title) >= 0

        )) &&
      (subQuestionId == null || q.title == subQuestionId)
    ).map(q => q.title);
    const answers = this.answers.filter(a => questionTitles.includes(a.questionTitle) && a.perspective_id === perspectiveId);
    return answers.map(a => a.answer);
  }
  public getCommentsForQuestionAndPerspectiveId(perspectiveId: string, questionTitle: string): string[] {
    const answers = this.answers.filter(a => a.questionTitle === questionTitle && a.perspective_id === perspectiveId && a.answer != "");
    return answers.map(a => a.answer);
  }
  private calcMinMaxMeanCount(answers: ReportAnswer[]): MinMaxMeanCount {
    if (answers.length === 0) {
      return { count: 0, min: 0, max: 0, mean: 0 };
    }
    const answerNumbers = answers.map(a => +a.answer);
    const min = Math.min(...answerNumbers);
    const max = Math.max(...answerNumbers);
    const total = answerNumbers.reduce((a, b) => a + b, 0);
    const count = answers.length;
    const mean = total / count;
    return { min, max, mean, count };
  }
  public async loadFeedbackInfo(feedbackId: ObjectId) {
    const body: FeedbackInfoRequest = {
      feedbackId
    }
    const result = await firstValueFrom(this.http.post(this.API_PREFIX + API_FeedbackinfoGet, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result as Uint8Array) as FeedbackInfoResponse;
  }
  public async loadSurveyInfo(surveyId: ObjectId) {
    const body: SurveyInfoRequest = {
      surveyId
    }
    const result = await firstValueFrom(this.http.post(this.API_PREFIX + API_Surveyinfo, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result as Uint8Array) as SurveysInfoResponse;
  }
  public async loadReportQuestions(surveyId: ObjectId, language: string) {
    const body: ReportingQuestionRequest = {
      surveyId,
      language
    }
    const result = await firstValueFrom(this.http.post(this.API_PREFIX + API_QuestionsGet, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result as Uint8Array) as ReportingQuestionResponse;
  }
  private async loadFeedbackReportAnswers(feedbackId: ObjectId) {
    const body: ReportingAnswerForFeedbackRequest = { feedbackId };
    const result = await firstValueFrom(this.http.post(this.API_PREFIX + API_AnswersForFeedbackGet, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result as Uint8Array) as ReportingAnswerResponse;
  }
  private async loadSurveyReportAnswers(surveyId: ObjectId) {
    const body: ReportingAnswerForSurveyRequest = { surveyId };
    const result = await firstValueFrom(this.http.post(this.API_PREFIX + API_AnswersForSurveyGet, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result as Uint8Array) as ReportingAnswerResponse;
  }
  private transformToBson(body: any) {
    const buffer = serialize(body)
    return new Blob([buffer]);
  }
}
