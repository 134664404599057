<ng-container *ngIf="!this.buttonsForCard else cardButtons">
  <ng-container *ngIf="!this.buttonsOnly else buttons">
    <div class="callout callout-primary ">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #cardButtons>
  <ng-container *ngIf="feedback.status =='nomination'">
    <ng-container *ngTemplateOutlet="nominationCardButton"></ng-container>
    <div
      *ngIf="minNominationsReached() && !hasArchivedUsersInNominations()"
      (click)="this.openConfirm($event, confirmStartFeedback)"
      class="card-btn btn btn-sm bg-light border-0"
    >
      <div class="card-btn-inner-content d-block">
        <i class="ri-arrow-right-circle-fill me-1"></i>
        <span i18n="@@myFeedbackStartFeedback">Feedback starten</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="feedback.status =='running'">
    <div
      *ngIf="!hasSe()"
      (click)="this.openSE($event)"
      class="card-btn btn btn-sm bg-light border-0 d-block"
    >
      <div class="card-btn-inner-content">
        <i class="ri-user-star-fill me-1"></i>
        <span i18n="@@myFeedbackAddSelfEvaluation">Selbsteinschätzung abgeben</span>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="nominationCardButton"></ng-container>
    <div
      (click)="this.openConfirm($event, confirmEndFeedback)"
      class="card-btn btn btn-sm bg-light border-0 d-block"
    >
      <div class="card-btn-inner-content">
        <i class="ri-stop-circle-line me-1"></i>
        <span i18n="@@myFeedbackStopFeedback">Feedback abschließen</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="feedback.status =='ended'">
    <ng-container *ngTemplateOutlet="nominationCardButton"></ng-container>
    <div
      (click)="this.openReport($event)"
      class="card-btn btn btn-sm bg-light border-0 d-block"
    >
      <div class="card-btn-inner-content">
        <i class="ri-file-chart-line me-1"></i>
        <span i18n="@@feedbackSuiteViewReport">Bericht ansehen</span>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #nominationCardButton>
  <div
    (click)="this.openNomination($event)"
    class="card-btn btn btn-sm bg-light border-0  d-block"
  >
    <div class="card-btn-inner-content">
      <i class="ri-user-add-line me-1"></i>
      <span
        *ngIf="!minNominationsReached() && feedback.status !='ended' "
        i18n="@@myFeedbackNominatePerson"
      >Personen nominieren</span>

      <span
        *ngIf="minNominationsReached()  && !maxNominationsReached() && feedback.status !='ended'"
        i18n="@@myFeedbackNominateMorePerson"
        class=" d-block"
      >Weitere Personen nominieren</span>

      <span
        *ngIf="maxNominationsReached() || feedback.status =='ended'"
        i18n="@@myFeedbackNominateShowNominations"
        
      >Nominierungen ansehen</span>
    </div>
  </div>
</ng-template>

<ng-template #nominationButton>
  <a
    *ngIf="!nominationpage"
    class="btn-sm  btn "
    [ngClass]="{
            'btn-cta': !minNominationsReached(),
            'btn-outline-cta': minNominationsReached()
        }"
    [routerLink]="[feedback._id, 'nomination']"
  >
    <i class="ri-user-add-line"></i>
    <span
      i18n="@@myFeedbackNominatePerson"
      *ngIf="!minNominationsReached() && feedback.status !='ended' "
    >Personen nominieren</span>
    <span
      i18n="@@myFeedbackNominateMorePerson"
      *ngIf="minNominationsReached()  && !maxNominationsReached() && feedback.status !='ended'"
    >Weitere Personen nominieren</span>
    <span
      i18n="@@myFeedbackNominateShowNominations"
      *ngIf="maxNominationsReached() || feedback.status =='ended'"
    >Nominierungen ansehen</span>
  </a>
</ng-template>

<ng-template #buttons>
  <ng-container *ngIf="feedback.status =='nomination'">
    <ng-container *ngTemplateOutlet="nominationButton"></ng-container>

    <span
      [ngClass]="{
            'btn btn-sm': !nominationpage,
            'btn-cta-textmode': nominationpage,
            'btn-cta':true
        }"
      *ngIf="minNominationsReached() && !hasArchivedUsersInNominations()"
      (click)='this.openConfirm($event, confirmStartFeedback)'
      i18n="@@myFeedbackStartFeedback"
    >Feedback starten</span>
  </ng-container>

  <ng-container *ngIf="feedback.status =='running'">
    
    <a
      [href]="getSeUrl()"
      [ngClass]="{
        'btn btn-sm': !nominationpage,
        'btn-cta-textmode': nominationpage,
        'btn-cta': true
      }"
      *ngIf="!hasSe()"
    >
      <i class="ri-user-star-fill"></i>
      <span i18n="@@myFeedbackAddSelfEvaluation">Selbsteinschätzung abgeben</span>
    </a>

    <ng-container *ngTemplateOutlet="nominationButton"></ng-container>
    <a
      [ngClass]="{
        'btn btn-sm': !nominationpage,
        'btn-cta': hasSe(),'btn-outline-cta': !hasSe(),
        'btn-cta-textmode': nominationpage
      }"
      (click)='this.openConfirm($event, confirmEndFeedback)'
    >
      <i class="ri-stop-circle-line"></i>
      <span i18n="@@myFeedbackStopFeedback">Feedback abschließen</span>
    </a>
  </ng-container>

  <ng-container *ngIf="feedback.status =='ended'">
    <ng-container *ngTemplateOutlet="nominationButton"></ng-container>
    <a
      [ngClass]="{
        'btn btn-cta btn-sm': !nominationpage,
        'btn-cta-textmode': nominationpage
      }"
      [routerLink]="feedback._id + '/report'"
    >
      <i class="ri-file-chart-line"></i>
      <span i18n="@@feedbackSuiteViewReport">Bericht ansehen</span>
    </a>
  </ng-container>
</ng-template>

<!-- TEMPATES FÜR MODAL -->

<ng-template #confirmStartFeedback>
  <div class="modal-header">
    <h4
      class="modal-title pull-left"
      i18n="@@confirmStartFeedbackTitle"
    >Start bestätigen</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="this.modalRef?.hide()"
    >
      <span
        aria-hidden="true"
        class="visually-hidden"
      >&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="@@confirmFeedbackStartText">
      Der Feedbackprozess wird nun gestartet und Einladungsmails an die Feedbackgeber*innen versandt.
    </p>

    <ng-container *ngIf="!this.survey.feedbackDuration || this.survey.feedbackDuration <= 0">
      <p i18n="@@confirmFeedbackStartPickEnd">
        Du hast hier die Möglichkeit ein Ende für deinen Feedbackprozess festzulegen.
      </p>

      <div class="form-floating mb-3">
        <input
          #endDateInput
          [(ngModel)]="this.feedback.endDate"
          class="form-control"
          type="date"
          [ngClass]="{'is-invalid': dayjsHtml(this.feedback.endDate).startOf('day').isBefore(dayjsHtml()) && (dayjsHtml(this.feedback.endDate).unix() != 0 )}"
        >
        <label for="floatingInputGrid">
          Ende
        </label>
      </div>
    </ng-container>

    <p
      *ngIf="this.survey.feedbackDuration && this.survey.feedbackDuration > 0"
      i18n="@@confirmFeedbackStartFixedEnd"
    >
      Das Feedback läuft {{ this.survey.feedbackDuration }} Tage lang, bis zum {{ this.endDate | date }}.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-bs-dismiss="modal"
      i18n="@@globalCancel"
      (click)="this.modalRef?.hide()"
    >Abbrechen</button>
    <button
      type="button"
      class="btn btn-primary"
      [ngClass]="{'disabled': !(this.survey.feedbackDuration && this.survey.feedbackDuration > 0) && dayjsHtml(this.feedback.endDate).startOf('day').isBefore(dayjsHtml()) && (dayjsHtml(this.feedback.endDate).unix() != 0 )}"
      (click)="this.startFeedback(confirmMoveOnAfterStart)"
      i18n="@@confirmFeedbackStart"
    >Feedbackprozess jetzt starten</button>
  </div>
</ng-template>

<ng-template #confirmMoveOnAfterStart>
  <div
    class="modal-header"
    i18n="@@navGiveFeedback"
  >Feedback geben</div>
  <div
    class="modal-body"
    i18n="@@confirmMoveOnAfterStart"
  >
    Den Link zu Ihrer Selbsteinschätzung finden Sie nun direkt unter "Feedback geben".
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-bs-dismiss="modal"
      i18n="@@globalOkay"
      (click)="this.modalRef?.hide()"
    >Ok</button>
  </div>
</ng-template>

<ng-template #confirmEndFeedback>
  <div class="modal-header">
    <h4
      class="modal-title pull-left"
      i18n="@@confirmEndFeedbackTitle"
    >Ende bestätigen</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="this.modalRef?.hide()"
    >
      <span
        aria-hidden="true"
        class="visually-hidden"
      >&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    i18n="@@confirmFeedbackEndText"
  >
    Wenn Sie den Feedbackprozess stoppen, können keine weiteren Feedbacks abgegeben werden.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-bs-dismiss="modal"
      i18n="@@globalCancel"
      (click)="this.modalRef?.hide()"
    >Abbrechen</button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="this.endFeedback()"
      i18n="@@confirmFeedbackEnd"
    >Feedbackprozess jetzt beenden</button>
  </div>
</ng-template>