import { Component } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: []
})
export class DocumentPreviewComponent {
  modalRef?: BsModalRef;
  public url: SafeResourceUrl;
  public filename: string;

}
