import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Perspective, Survey } from '@reflact/prmfeedback';
import { Subscription, combineLatest } from 'rxjs';
import { MainService } from '../../shared/main.service';

@Component({
  selector: 'app-perspective',
  templateUrl: './perspective.component.html',
  styleUrls: []
})
export class PerspectiveComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];
  public tabName = $localize`:@@feedbackSuiteEditPerspective:Perspektive bearbeiten`;


  public perspectiveForm = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    min: new UntypedFormControl(0),
    max: new UntypedFormControl(0),
    anonymity: new UntypedFormControl(0),
    type: new UntypedFormControl('bound')
  });
  public survey: Survey = null;
  public survey_id: string;

  constructor(public mainService: MainService, private router: Router, private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(combineLatest([this.mainService.isDataLoaded, this.route.params]).subscribe(value => {
      if (value[0]) {
        this.survey = this.mainService.customerService.surveyMap.get(value[1].survey_id);

        if (value[1].perspective_id) {
          const perspective = this.survey.perspectives.find(p => {
            return p.id === value[1].perspective_id;
          });

          if (perspective) {
            this.perspectiveForm.setValue(perspective);
            if (this.survey.status != 'draft') {
              this.perspectiveForm.controls["anonymity"].disable()
            } else {
              this.perspectiveForm.controls["anonymity"].enable()

            }
          }
          this.survey_id = this.survey._id
        } else {
          this.survey_id = value[1].survey_id;
          this.tabName = $localize`:@@feedbackSuiteCreatePerspective:Perspektive anlegen`;
        }
      }
    }));
  }

  public async onSubmit() {
    const perspective: Perspective = this.perspectiveForm.value;
    if (this.perspectiveForm.value.id === '') {
      let id = 0;
      this.survey.perspectives.forEach(p => {
        const pId = Number(p.id);
        if (pId > id) {
          id = pId;
        }
      });
      id++;
      perspective.id = id.toString();
      this.survey.perspectives.push(perspective);
    } else {
      const index = this.survey.perspectives.findIndex(el => {
        return el.id === perspective.id;
      });
      this.survey.perspectives[index] = perspective;
    }
    this.mainService.customerService.updateSurvey(this.survey);
    this.router.navigate(['/', 'surveys'], { queryParams: { selected: this.survey._id } });
  }
}